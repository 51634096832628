<template>
  <div>
    <TeacherHeader
      v-if="name == 'header'"
      v-model="currentUsergroup"
      :lang="'en_hk'"
      :identities="[{'id':'672','name':'TT 182','type':'teacher','displayName':null,'avatarName':'','__typename':'User'}]"
      :isLoading="true"
      :userRole="'teacher'"
      :usergroupEdges="[
        {
          usergroup: {
            id: 'test',
            name: 'Test',
            type: 'class',
            tschemaEdges: [{ tschema: { id: 'test' } }]
          },
          isUserTeacherOwner: true,
          isUsergroupGroupArchived: false,
          cache: {
            isUsergroupGroupShared: false
          }
        },
        {
          usergroup: {
            id: 'test2',
            name: 'Test2',
            type: 'usergroup',
            tschemaEdges: [{ tschema: { id: 'test' } }]
          },
          isUserTeacherOwner: true,
          isUsergroupGroupArchived: false,
          cache: {
            isUsergroupGroupShared: false
          }
        }
      ]"
    />
  </div>
</template>
<script>
import TeacherHeader from '@/components/teacher/header/Header.vue'
export default {
  components: {
    TeacherHeader
  },
  props: {},
  data () {
    return {
      name: this.$route.query.name,
      currentUsergroup: {
        id: 'test',
        name: 'Test',
        type: 'class',
        tschemaEdges: [{ tschema: { id: 'test' } }]
      }
    }
  },
  computed: {},
  methods: {}
}
</script>
<style src="" />
