<template>
  <div class="practice-topic-item" :class="color">
    <div class="practice-topic-title">
      {{ $t($interfaceLang, nameEn, nameZh) }}
    </div>
    <div v-if="$interfaceLang == 'en_hk'" class="practice-topic-cover-num">
      {{ numSubtopic }} subtopics
    </div>
    <div v-else-if="$interfaceLang == 'zh_hk'" class="practice-topic-cover-num">
      {{ numSubtopic }} 個副題
    </div>
    <button class="white-btn org-btn" @click="$emit('do-practice')">
      {{ $t($interfaceLang, 'Practice', '練習') }}
      <i class="icon-font icon-arrow_right">&#xe805;</i>
    </button>
    <div class="topics-img">
      <img :src="imagePath" />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    topicId: String,
    nameEn: String,
    nameZh: String,
    numSubtopic: Number,
    imagePath: String,
    color: String
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="../../Practice.css" scoped></style>
