<template>
  <div id="randomlyAddQuestions-model">
    <Modal @close="$emit('close')">
      <template v-slot:header>
        <i class="icon-title icon-font icon-random">&#xe819;</i>
        <span class="title">
          {{ $t($interfaceLang, 'RANDOMLY ADD QUESTIONS', '隨機新增題目') }}
        </span>
        <i @click="$emit('close')" class="icon-font icon-close">
          &#xe808;
        </i>
      </template>
      <template v-slot:body>
        <div class="modal-section">
          <div class="section-row select-row">
            <p class="sm-title">
              {{ $t($interfaceLang, 'Term', '學期') }}
            </p>
            <div class="topic-list">
              <div id="select-topic-col">
                <YearTermDropdown
                  v-model="selectedYearTerm"
                  :topics="topics"
                  @input="
                    selectedTopic = null
                    selectedSubtopic = null
                  "
                />
              </div>
            </div>
          </div>
          <div class="section-row select-row">
            <p class="sm-title">
              {{ $t($interfaceLang, 'Topic', '題目') }}
            </p>
            <div class="topic-list">
              <div id="select-topic-col">
                <TopicDropdown
                  v-model="selectedTopic"
                  :topics="
                    topics.filter(
                      x =>
                        x.schoolYear == selectedYearTerm[0] &&
                        x.term == selectedYearTerm[1]
                    )
                  "
                  @input="selectedSubtopic = null"
                />
              </div>
            </div>
          </div>
          <div class="section-row select-row">
            <p class="sm-title">
              {{ $t($interfaceLang, 'Subtopic', '副題') }}
            </p>
            <div class="topic-list">
              <div id="select-topic-col">
                <SubtopicDropdown
                  :class="{ disabled: selectedTopic == null }"
                  v-model="selectedSubtopic"
                  :subtopics="selectedTopic?.subtopics"
                />
              </div>
            </div>
          </div>
          <div class="section-row">
            <p class="sm-title">
              {{ $t($interfaceLang, 'Amount', '數量') }}
            </p>
            <InputQuestionQty
              :inputs="[1, 2, 3, 4, 5]"
              :defaultInputIndex="0"
              @input="numQuestion = $event"
            />
          </div>
          <button
            :class="{ disabled: selectedTopic == null }"
            id="btn-random"
            class="modal-btn right-item"
            @click="
              $emit('add', {
                topic: selectedTopic,
                subtopic: selectedSubtopic,
                num: numQuestion
              })
            "
          >
            <i class="icon-font icon-plus">&#xe817;</i>
            {{ $t($interfaceLang, 'Add', '加入') }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/teacher/modal/Modal.vue'
import YearTermDropdown from './YearTermDropdown.vue'
import TopicDropdown from './TopicDropdown.vue'
import SubtopicDropdown from './SubtopicDropdown.vue'
import InputQuestionQty from './InputQuestionQty.vue'

export default {
  name: 'RandomAdd',
  components: {
    Modal,
    YearTermDropdown,
    TopicDropdown,
    SubtopicDropdown,
    InputQuestionQty
  },
  props: {
    topics: Array,
    yearTerm: Array
  },
  data () {
    return {
      selectedYearTerm: [4, 1],
      selectedTopic: null,
      selectedSubtopic: null,
      numQuestion: 1
    }
  },
  computed: {},
  methods: {}
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>
<style scoped>
.disabled {
  opacity: 0.65;
  pointer-events: none;
}
</style>
