<template>
  <DuplicateModal
    @save="
      duplicateQuestion(currentUserId, question, $event.num, lang, questionList)
    "
    @close="$emit('close')"
  />
</template>
<script>
import DuplicateQuery from './Duplicate.graphql'
import DuplicateModal from '../../modal/Duplicate.vue'
export default {
  components: {
    DuplicateModal
  },
  props: {
    currentUserId: String,
    questionList: Array,
    question: Object,
    lang: String
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    async duplicateQuestion (
      currentUserId,
      question,
      numQuestion,
      lang,
      questionList
    ) {
      this.$emit('query-start')
      try {
        let questions = await this.$apolloQuery(DuplicateQuery, {
          currentUserId,
          lang,
          numQuestion,
          id: question?.displayQuestion?.id,
          exceptQuestionIds: questionList?.map(x => x.displayQuestion?.id)
        }).then(res => res?.data?.question?.templateMutations)
        questions.length > 0
          ? this.$emit('query-done', { questions })
          : this.$emit('query-error')
      } catch (e) {
        this.$log(e.message)
        this.$emit('query-error')
      }
    }
  }
}
</script>
<style src="" />
