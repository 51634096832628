<template>
  <div class="w-100">
    <div class="page-info">
      <div class="page-title-desc">
        <div class="page-title">
          {{
            $t($interfaceLang, 'Teaching Schedule Setting', '教學時間表設定')
          }}
        </div>
        <div class="page-desc">
          {{
            $t(
              $interfaceLang,
              'Customize your teaching schedule, decide which topics to activate and when',
              '訂制您的教學時間表，決定啟動哪些主題以及何時啟動'
            )
          }}
        </div>
      </div>
      <PageButtons
        :readOnly="readOnly"
        :hasChanged="hasChanged"
        @reset="$emit('reset')"
        @overview="$emit('overview')"
        @save="$emit('save', { topics })"
      />
    </div>
    <div class="teaching-schedule-topics-col">
      <template v-for="(topic, i) in topics">
        <TopicRow :readOnly="readOnly" :topic="topic" />
      </template>
    </div>
    <div class="page-info">
      <PageButtons
        :readOnly="readOnly"
        :hasChanged="hasChanged"
        @reset="$emit('reset')"
        @overview="$emit('overview')"
        @save="$emit('save', { topics })"
      />
    </div>
  </div>
</template>
<script>
import PageButtons from './PageButtons.vue'
import TopicRow from './TopicRow.vue'
export default {
  components: {
    PageButtons,
    TopicRow
  },
  props: {
    topics: Array,
    readOnly: Boolean,
    hasChanged: Boolean
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="../../TeachingSchedule.css" scoped />
