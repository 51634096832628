<template>
  <div class="assignment-row" :class="paperTypeClass">
    <div class="assignment-info">
      <div class="assignment-name">
        {{ name }}
      </div>
      <div class="assignment-lock">
        <i class="icon-font icon-locked" v-if="isLocked"> &#xe814; </i>
      </div>
      <div class="assignment-question-num"> {{ numQuestion }} </div>
    </div>
    <div class="assignment-date">
      <p>{{ wordStartAt }}</p>
      <p class="assignment-start-date"> {{ displayStart }} </p>
    </div>
    <div class="assignment-date">
      <p>{{ wordDueDate }}</p>
      <p class="assignment-due-date">
        {{ displayDue }}
      </p>
    </div>
  </div>
</template>
<script>
import dateFormat from 'dateformat'
export default {
  components: {},
  props: {
    paperType: String,
    name: String,
    isLocked: Boolean,
    numQuestion: Number,
    startTime: String,
    expiryTime: String
  },
  computed: {
    wordStartAt() {
      return this.$t(this.$interfaceLang, 'Start at', '開始日期')
    },
    wordDueDate() {
      return this.$t(this.$interfaceLang, 'Due', '遞交日期')
    },
    paperTypeClass() {
      const typeMap = {
        'homework': 'type-homework',
        'recursive': 'type-homework',
        'recursive_followup': 'type-homework',
        'freepractice': 'type-practice',
        'test': 'type-test'
      }
      return typeMap[this.paperType]
    },
    displayStart() {
      return this.displayDate(Number(this.startTime ?? 0))
    },
    displayDue() {
      const oneDayTime = 3600 * 24 * 1000
      return this.displayDate(Number(this.expiryTime ?? 0) - oneDayTime)
    }
  },
  methods: {
    displayDate(value) {
      if ((value ?? 0) <= 0) {
        return '-'
      } else {
        let date = new Date(value)

        return this.$t(
          this.$interfaceLang,
          dateFormat(date, 'dd mmm yyyy'),
          dateFormat(date, 'dd-mm-yyyy')
        )
      }
    }
  }
}
</script>

<style scoped>
.assignment-row {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: 0.5px lightgray solid;
}

.assignment-row p {
  margin: 0px;
}

.assignment-row .btn-group {
  width: 100%;
}

.assignment-info {
  position: relative;
  width: 60%;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  border-right: 1px solid #adadad;
}

.assignment-name {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
  width: 83%;
}

.assignment-lock {
  font-size: 24px;
  color: #f65a58;
}

.assignment-question-num {
  font-size: 13px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  text-align: center;
  color: #1c2d60;
  background-color: #f1f3f1;
  border-radius: 100%;
}

.assignment-question-num::after {
  position: absolute;
  content: '';
  height: 20px;
  background-color: #f1f3f1;
}

.assignment-date {
  width: 20%;
  font-size: 11px;
  color: #adadad;
  padding: 0px 10px;
}

.assignment-start-date,
.assignment-due-date {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
}

.type-homework {
  border-left: 5px solid #42c9f1;
}

.type-practice {
  border-left: 5px solid #ffd32d;
}

.type-auto {
  border-left: 5px solid #d0d0d0;
}
</style>
