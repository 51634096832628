import Vue from 'vue'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

// import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import App from './App.vue'

import router from './router'
import appStore from './store/app-store'
import apolloStore from './store/apollo-store'

import graphqlUtil from './graphql-util.js'

import dateFormat from 'dateformat'
import log from './log.js'
import config from './config.json'

Vue.use(VueToast);
// Vue.use(BootstrapVue);
// Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false

Vue.prototype.$appStore = appStore
Vue.prototype.$apolloStore = apolloStore
Vue.prototype.$log = log

Vue.prototype.$graphqlUtil = graphqlUtil

Vue.prototype.$toast_success = (msg) => {
  Vue.prototype.$toast.open({
    message: msg,
    type: 'custom-success',
    position: 'bottom',
    duration: 3000
  })
}

Vue.prototype.$toast_error = (msg) => {
  Vue.prototype.$toast.open({
    message: msg,
    type: 'error',
    position: 'bottom',
    duration: 3000
  })
}

Vue.prototype.$t = function (lang, wordsEn, wordsZh) {
  return lang == 'en_hk' ? wordsEn : lang == 'zh_hk' ? wordsZh : wordsEn
}

Vue.prototype.$displayDate = function (value) {
  if (value == null) {
    return '-'
  }
  let localTimeValue = new Date(Number(value) + appStore.appInfo.timezoneOffset * 3600 * 1000)
  return this.$t(
    this.$interfaceLang,
    dateFormat(localTimeValue, 'GMT:dd mmm yyyy'),
    dateFormat(localTimeValue, 'GMT:dd-mm-yyyy')
  )
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// version 'v(M).(m).(p).(v)' where (M).(m).(p) is the current version of backend api and (v) is the frontend release version

console.log(`version: ${config.version}`)