<template>
  <div class="item-list">
    <div class="item-check">
      <input class="item-check-input" type="checkbox" id="check-null" v-model="checkItemAll" @change="checkAll" />
      <label class="item-check-label" for="check-null">
        {{ $t($interfaceLang, 'All subtopics', '所有副題') }}
      </label>
    </div>
    <div class="item-check" v-for="s in subtopics">
      <input class="item-check-input" :value="s" name="item-check" type="checkbox" :id="'item-' + s.id"
        v-model="checkedItems" @change="checkItem" />
      <label class="item-check-label" :for="'item-' + s.id">
        {{ $t($interfaceLang, s.nameEn, s.nameZh) }}
      </label>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    subtopics: Array,
    value: Array
  },
  data() {
    return {
      checkItemAll: true,
      checkedItems: []
    }
  },
  created() {
    this.checkedItems = this.value
    this.checkItem()
  },
  watch:{
    checkedItems(newValue, oldValue){
      this.$emit('input', newValue)
    }
  },
  computed: {},
  methods: {
    checkAll() {
      if (this.checkItemAll) {
        this.checkedItems = this.subtopics
      } else {
        this.checkedItems = []
      }
    },
    checkItem() {
      this.checkItemAll =
        this.checkedItems.length == this.subtopics.length &&
        this.subtopics.length > 0
    }
  }
}
</script>

<style src="@/components/student/modal/modal.css" scoped></style>