<template>
  <div class="homework-status-col">
    <div class="homework-status-info">
      <div class="homework-status-title">
        {{ $t($interfaceLang, 'Homework Status', '功課狀況') }}
      </div>
      <div class="doughnut-chart-col">
        <StatusChart
          :numNew="numNew"
          :numProgress="numProgress"
          :numOverdue="numOverdue"
          @update-legend="legend = $event"
        />
        <div class="doughnut-chart-text">
          {{ $t($interfaceLang, 'Homework to do', '待完成功課') }}
        </div>
      </div>
      <div class="doughnut-legend" v-html="legend" />
    </div>
    <div class="homework-status-image">
      <img class="img-fluid" src="@/assets/images/img_homework_listing.png" />
    </div>
    <LocalLoadingLayer v-if="loading" />
  </div>
</template>
<script>
import LocalLoadingLayer from '@/components/LocalLoadingLayer.vue'
import StatusChart from './StatusChart.vue'
export default {
  components: {
    StatusChart,
    LocalLoadingLayer
  },
  props: {
    numNew: Number,
    numProgress: Number,
    numOverdue: Number,
    loading: Boolean
  },
  data () {
    return {
      legend: null
    }
  },
  computed: {},
  methods: {}
}
</script>
<style src="../../Homework.css" scoped></style>
