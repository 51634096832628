<template>
  <div class="performance-card-col-2">
    <TopicPerformance
      :topics="bestTopicEdges"
      :title="$t($interfaceLang, 'Best performing topic', '最佳表現題目')"
    />
    <TopicPerformance
      :topics="worstTopicEdges"
      :title="$t($interfaceLang, 'Worst performing topic', '最差表現題目')"
    />
  </div>
</template>
<script>
import LoadTopicPerformanceQuery from './LoadTopicPerformance.graphql'
import TopicPerformance from '../../components/topic-performance/TopicPerformance.vue'

export default {
  components: {
    TopicPerformance
  },
  props: {
    currentUserId: String,
    usergroupId: String
  },
  data () {
    return {
      bestTopicEdges: [],
      worstTopicEdges: []
    }
  },
  async created () {
    await this.loadTopicPerformance()
  },
  methods: {
    async loadTopicPerformance () {
      this.bestTopicEdges = await this.$apolloQuery(LoadTopicPerformanceQuery, {
        currentUserId: this.currentUserId,
        usergroupId: this.usergroupId,
        sortOrder: 'DESC'
      }).then(res => res?.data?.topicUserEdges)
      this.worstTopicEdges = await this.$apolloQuery(
        LoadTopicPerformanceQuery,
        {
          currentUserId: this.currentUserId,
          usergroupId: this.usergroupId,
          sortOrder: 'ASC'
        }
      ).then(res => res?.data?.topicUserEdges)
    }
  }
}
</script>
<style src="" />
