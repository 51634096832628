var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.questionData != null)?_c('div',{staticClass:"question-content"},[(_vm.questionData.contentImageData == null)?_c('div',{directives:[{name:"katex",rawName:"v-katex"}],staticClass:"question-title katex",domProps:{"innerHTML":_vm._s(_vm.questionData.content)}}):_c('div',{directives:[{name:"katex",rawName:"v-katex"}],staticClass:"question-title katex"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.questionData.content)}}),_c('img',{staticClass:"question-image",attrs:{"src":_vm.questionData.contentImageData}})]),(_vm.answers != null)?_c('ul',{staticClass:"question-ans-list"},_vm._l((_vm.answers),function(a,i){return _c('li',{key:'ans-' + i,staticClass:"question-ans",class:{
        'correct-ans': _vm.isCorrectAnswerAt(i) && _vm.correctAnswerIndex != null,
        'wrong-ans': !_vm.isCorrectAnswerAt(i) && _vm.correctAnswerIndex != null
      },attrs:{"id":'ans-choice-' + _vm.indexNo+'-'+ _vm.questionId + '-' + i},on:{"click":function($event){_vm.$emit('choose-ans', {
          indexNo: i,
          ansHTML: _vm.$(
            '#ans-choice-' + _vm.indexNo + '-' + _vm.questionId.replace(/\./g, '\\.') + '-' + i
          )[0].innerHTML
        })}}},[(_vm.isShowUserMarkerAt(i))?_c('i',{staticClass:"icon-font icon-profile"},[_vm._v("  ")]):_vm._e(),_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.displayAnswerAlphabet(i))+". ")]),(_vm.questionData.isAnswerImage)?_c('img',{staticClass:"ans-image",attrs:{"src":a}}):_c('span',{directives:[{name:"katex",rawName:"v-katex"}]},[_vm._v(" "+_vm._s(a)+" ")])])}),0):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }