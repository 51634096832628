<template>
  <div id="select-topic" class="btn-group select-dropdown" role="group">
    <button
      type="button"
      class="btn select-topic-dropdown select-questions-dropdown dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <p class="select-title">{{ $t($interfaceLang, 'Topic', '題目') }}</p>

      <span class="select-content">
        <template v-if="value == null">
          {{ $t($interfaceLang, 'Please select', '請選擇') }}
        </template>
        <template
          v-for="(t, i) in topics"
          v-if="value != null && t.id == value.id"
        >
          {{ $t($interfaceLang, t.nameEn, t.nameZh) }}
        </template>
      </span>
    </button>
    <div
      class="dropdown-menu select-topic-dropdown-group select-questions-dropdown-group  btn-select-topic-group"
    >
      <a
        v-for="(t, i) in topics"
        v-show="
          (value == null || value.id != t.id) &&
            compareYearTerm([t?.schoolYear, t?.term], yearTerm) == 0
        "
        class="dropdown-item select-topic-dropdown-item select-questions-dropdown-item"
        href="#"
        @click="$emit('input', t)"
      >
        {{ $t($interfaceLang, t.nameEn, t.nameZh) }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    yearTerm: Array,
    topics: Array,
    value: Object
  },
  computed: {},
  methods: {
    compareYearTerm (yt1, yt2) {
      if (yt1[0] == yt2[0]) {
        return yt1[1] - yt2[1]
      } else {
        return yt1[0] - yt2[0]
      }
    }
  }
}
</script>
<style src="@/pages/teacher/edit-assignment/EditAssignment.css" scoped />
