<template>
  <div class="progress-col">
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        :style="'width: ' + percent + '%'"
        :aria-valuenow="percent"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <span class="item-hightlight-text"> {{ part }}/{{ total }} </span>
  </div>
</template>
<script>
export default {
  props: {
    part: Number,
    total: Number
  },
  computed: {
    percent () {
      if (this.total != null && this.total != 0 && this.part != null) {
        return Math.round((this.part / this.total) * 100)
      }
      return (this.part / this.total) * 100
    }
  },
  methods: {}
}
</script>