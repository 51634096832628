<template>
  <div class="select-usergroup">
    <template v-for="(type) in ['class', 'group']">
      <div class="form-check form-check-inline check-class" v-for="ug in usergroups" v-if="ug.type == type">
        <input :id="'usergroup-input-' + ug.id" v-model="selectedUsergroupIds" :value="ug.id" class="form-check-input"
          type="checkbox" />
        <label :for="'usergroup-input-' + ug.id" class="form-check-label">
          {{ ug.name }}
        </label>
      </div>
      <div class="break" />
    </template>
    <div class="form-check form-check-inline">
      <input :id="'usergroup-input-other'" v-model="selectedUsergroupIds" value="other" class="form-check-input"
        type="checkbox" />
      <label for="class-other" class="form-check-label">
        {{ wordOtherTeacherClass }}
      </label>
    </div>
    <div>
      <span class="remark" v-html="wordRemarks" />
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    usergroups: Array,
    value: Array
  },
  data() {
    return {
      selectedUsergroupIds: []
    }
  },
  computed: {
    wordNoUsergroup() {
      return this.$t(this.$interfaceLang, "No class or groups. Cannot duplicate.",
        "沒有班別及分組。不能複製。")
    },
    wordOtherTeacherClass() {
      return this.$t(this.$interfaceLang, "Other teacher's classes", "其他老師的班別")
    },
    wordRemarks() {
      return this.$t(this.$interfaceLang,
        `If you choose <b>${this.wordOtherTeacherClass}</b> and duplicate this assignment, a link will be copied to your clipboard. By sharing this link with other teachers, they will be able to clone the content of this assignment.`,
        `如果你剔選<b>${this.wordOtherTeacherClass}</b>並複製這份習作，一條連結會被複製至你的剪貼簿。跟其他老師分享這條連結，他們便可以複製這份習作的內容。`
      )
    }
  },
  watch: {
    selectedUsergroupIds(value) {
      this.$emit('input', value)
    }
  },
  methods: {}
}
</script>
<style scoped>
.break {
  flex-basis: 100%;
  width: 0px;
  height: 0px;
  overflow: hidden;
}

.select-usergroup {
  display: flex;
  flex-flow: row wrap;
}

.remark {
  font-size: 15px;
  color: #357ebd;
  margin-left: 5px;
  white-space: pre-line;
}
</style>