<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <span class="title">Terms of Use</span>
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <div class="terms-wrapper">
                <pre>
1.	Acceptance of terms and interpretation
 	 	 
 	1.1	OneEd is owned and operated by at OneEd Limited (“1Ed”). Please read this Term of Use (“TOU”) carefully. By using the Website, it will signify the users’ acceptance of this TOU.
 	1.2	In this TOU, user(s) is/are defined as those people(s) who visit(s) this Website, Users, who visit this Website, no matter whether they use the Website Services or not), are deemed to accept the terms and conditions of this TOU and shall be bound by the terms and conditions.
 	1.3	OneEd Limited reserves all its rights to alter, modify, change. amend etc. of terms and conditions of this TOU at any time without notice to any user. Users are advised to and responsible for regularly reviewing terms and conditions this TOU before using any Website Services. Users continued use of the Website shall constitute their agreement to all such modified or altered terms and conditions.
 	1.4	Under this TOU, save where the context otherwise requires: -
(a)	words in the singular shall include the plural, and vice versa.
(b)	the masculine gender shall include the feminine and neutral and vice versa.
(c)	a reference to a person shall include a reference to a firm, a body corporate, an unincorporated association or to a person's executors or administrators.
(d)	the headings in this TOU are for convenience only and shall not affect the interpretation of any provision of this TOU.
 	1.5	PROPRIETARY SERVICES FOR REGISTERED USERS: OneEd Limited operates an electronic platform/system that enable users (no matter whether they are students, instructors, agents, staffs and administrators of educational institutions or organizations to access and use certain online products, facilities and services offered by OneEd Limited through this Website. The materials provided includes general non-proprietary information available to all users of the Website, but to access and use the Website Services (as defined hereinbelow), a user will be required to register on the Website, through his/her educational institution. If any of the users register to use this Website Services, on behalf of any educational institution or organization, such user will be required to comply with all additional terms and conditions in connection with the registration process such those terms and conditions stipulated in the respective subscription agreement or other documents (if any) made between the subscriber and OneEd Limited
 	1.6	RESTRICTIONS ON USE: User(s) shall not, and shall cause its/his/her employees, officers, directors, members managers, partners agents, third party service providers, or other designated persons not to take any of the following actions:
(a)	creating or enabling the creation of derivative works, modifications, or adaptions of any of the Website Services (as defined hereinbelow)
(b)	decompiling, reverse engineering or disassembling any of the Website Services (as defined hereinbelow)
(c)	distributing or disclosing any of materials and information (except non-proprietary information available to all users) obtained from the Website Services (as defined hereinbelow) to third parties.
(d)	removing or modifying any proprietary marking or restrictive legends placed on any of the Website Service (as defined hereinbelow), or
(e)	using any robot, spider, other automatic device or programme or manual process to monitor, copy or reproduce any of the Website Services (as defined hereinbelow.
 	1.7	If any provision of this TOU shall be held to be illegal, invalid, or unenforceable, in whole or in part, under any enactment or rule of law, such provision or part shall to that extent be deemed not to form part of this TOU but the legality, validity or enforceability of the remainder of this TOU shall not be affected.
 	1.8	This TOU shall also apply to i) Website; and (ii) other learning and teaching resource books & materials and other products offered by OneEd Limited (“Products”) to the extent that any term of this TOU is applicable thereto.
 	1.9	This TOU shall be governed by and construed in accordance with the laws of Hong Kong and each user irrevocably agrees that the Courts of Hong Kong shall have non-exclusive jurisdiction in relation to any claim, dispute or difference concerning this TOU and any matter arising therefrom.
 	 
2.	Trademarks, copyrights, and other intellectual property rights
 	 	 
 	2.1	Unless otherwise indicated, OneEd Limited owns all intellectual property rights in the text, images, audio/video clips, pictures, charts, diagrams, articles, software, games, teaching tools, activities, devices and other content of the Website and those information and materials contained in e-Products and Products (“Materials”). Except and save general non-proprietary information available to all users, any reproduction, redistribution, transmission, unauthorized use or otherwise dealing with the Materials without the prior written approval of OneEd Limited is strictly prohibited.
 	2.2	For avoidance of doubt, the intellectual property rights of the Materials exclusively belong to OneEd Limited and for protection of the intellectual property rights of e-Products owned by OneEd Limited, all users are not allowed to reproduce, republish, disassemble, decompile, reverse engineer, or any attempt to discover or gain access to the source code thereof.
 	 
3.	Description of website services and others
 	 	 
 	3.1	In this website, OneEd Limited provides the users with access to a variety of resources such as Materials, Software (as defined hereinbelow) etc. which may include the download services, communication forums, information-providing, online products, and other services offered by OneEd Limited in this Website (collectively “Website Services”). The Website Services, including any subsequent updates, enhancements, modifications, new features, and/or the addition of any new web properties, are subject to this TOU. For the avoidance of doubt, the ownership (together with all intellectual property rights) of the Website and the content of the Website Services shall, in all aspects and always, belong to OneEd Limited solely and exclusively.
 	3.2	Any user, who intends to use the Website Services, 
, shall be subject to this TOU and those additional terms imposed by OneEd Limited (if applicable).
 	 
4.	Privacy and protection of personal information
 	 	 
 	4.1	Subject to Clause 4.2 hereinbelow, each of the users hereby expressly and voluntarily grants its/his/her consent to provide OneEd Limited with its/his/her personal data directly, such as when a user creates a login account with OneEd Limited as directed or OneEd Limited, at its sole discretion, distributes a login account with login details to a user, administers its/his/her organization’s licensing account etc. Users hereby allows and consents OneEd Limited to collect and use the personal data of the users to: -
(a)	operate OneEd Limited and its group’s business effectively.
(b)	provide users with OneEd Limited’s promotional materials.
(c)	handle and follow up the request or complaint lodged by the users.
(d)	pass to OneEd Limited’s legal representatives for seeking legal advice thereto and/or for any purpose relating to any of civil or criminal litigation(s).
(e)	use the personal data for the communication with them; and
(f)	to divulge them in accordance with the law or to any regulatory bodies upon their respective request(s).
 	4.2	The submission of personal data to OneEd Limited is voluntary. A user has the option not to tender its/his/her personal data to OneEd Limited by notifying OneEd Limited at its email address at info@1ed.hk. However, insufficient personal data may prevent OneEd Limited from responding to and handling a request or answering an enquiry.
 	 
5.	Software available on this Website / products
 	 	 
 	5.1	Any software is made available to download from the Website Services (“Software”) is also the copyrighted work and intellectual properties of OneEd Limited and the ownership thereof (including but not limited to its intellectual property rights) shall belong to OneEd Limited solely and exclusively. Use of the Software by the users is subject to and governed by this TOU and those additional terms imposed by OneEd Limited but in any event, all users thereof are treated as licences only and no ownership and intellectual property rights of the Software will be assigned to any of the users. The Software is made available for download solely for use by those users as a licensee and subject to this TOU and those additional terms imposed by OneEd Limited (if applicable). Unless otherwise stated or indicated, any reproduction, redistribution, transmission, download, unauthorized use or otherwise dealing with the Materials and Software, without prior written approval of OneEd Limited, is expressly and strictly prohibited.
 	5.2	Unless otherwise stated or indicated, all Materials and Software are the copyrighted work and intellectual property of OneEd Limited solely. OneEd Limited reserves all rights to the Materials and the Software.
 	5.3	Users hereby agrees that OneEd Limited shall not be liable for any liability that any of users incur or may incur because of visiting this Website using the Website Services and others.
 	5.4	Without prior written approval of OneEd Limited, all users are not allowed to: -
(a)	use the Materials, e-Products and Software for any purpose not allowed by OneEd Limited.
(b)	distribute and pass the Materials to third parties, including by posting the Materials on any networked computer for access by any other computer on the network, or broadcast the Materials in any media; or
(c)	remove, modify, or tamper with any copyright notices (if any).
(d)	make any alteration and modifications to the Materials and/or the Software; and
(e)	transfer or distribute any login details of this Website, distributed to any party (whether an individual or a company) without the prior written approval of OneEd Limited.
 	5.5	Materials specified above do not include the design or layout of this Website or any other OneEd Limited-owned, operated, licensed, or controlled website. Websites of OneEd Limited including this Website are protected by the relevant law of trademark, copyrights, or those laws applicable to the protection of intellectual property rights. No logo, mark, graphic, diagram, sound, image etc. from this Website may be copied or retransmitted unless expressly permitted by OneEd Limited in writing.
 	5.6	All intellectual property rights of the Website Services shall belong to OneEd Limited solely and exclusively and all users are licensees only subject to the terms of this TOU and all those additional terms imposed by OneEd Limited as OneEd Limited thinks fit, if any.
 	 
6.	Liability disclaimer and indemnity
 	 	 
 	6.1	The information, Materials, Software, products, and services included in or available through this Website may include inaccuracies or typographical errors. Changes are periodically made to this Website, and to the information contained therein and users should not assume that the information contained therein are complete or up to date. OneEd Limited makes no representation about the suitability and accuracy of the information contained in the Materials, Software, Products, and related materials such as graphics, charts, diagrams etc. published as part of the services for any purpose. All such Materials, Software, related materials such graphics, charts, diagrams etc. are provided as “AS IS” without warranty of any kind.
 	6.2	OneEd Limited also disclaims all warranties and conditions with regarding to the above, including all warranties and conditions of title, merchantability, whether statutory, express, or implied, fitness for a particular purpose and non-infringement. The Materials, Software, and related materials such graphics, charts, diagrams etc. on or downloaded from this Website, Products and/or e-Products could include technical inaccuracies, typographical errors, subsequent changes etc. OneEd Limited may make improvements and/or changes to the Website Services and/or the Materials at any time as OneEd Limited thinks fit.
 	6.3	In no event shall OneEd Limited be liable for any special, incidental, direct, indirect, or consequential damages or any damages whatsoever in nature resulting from loss of use, data or profits and etc., whether in an action of contract, negligence, other tortious action or etc., arising out of or in connection with the use or performance of the Website Services, to, any provision of or failure to provide the use or performance of the Website Services, , or any information/Materials available from the Website Services.
 	6.4	The Website may contain advertisements by third parties. Unless otherwise specifically stated, OneEd Limited does not endorse or makes any representation regarding the liability, quality or accuracy of any products or services featured in, or linked to any advertisement that appears on the Website
	6.5	Each of the users hereby agrees to indemnify OneEd Limited against any claim, losses, costs, and damages (including but not limited to any legal fees and disbursements) suffered or incurred by OneEd Limited because of any other person’s use of this Website (including Website Services), by using its/his/her user’s ID or login ID or login details and/or password or any omission, default or mistake made by the user.
 	6.6	OneEd Limited shall do its best endeavour to ensure the security of the use of this Website, but all users are hereby advised to take proper precaution and security measure to safeguard themselves in visiting this Website, and using the Website Services, services of therein. In case that this Website is invaded or hacked which causes any loss to any user, no blame and complaint shall be made to OneEd Limited, and no liability shall be borne by OneEd Limited.
 	 
7.	Unsolicited idea submission policy
 	 	 
 	7.1	Neither OneEd Limited nor any of its employees accept or consider unsolicited ideas, including but not limited to ideas for new advertising campaigns, promotions, products or technologies, processes, materials, marketing plans, product names etc. Despite OneEd Limited’s makes its stance clear that no user is requested to send OneEd Limited of its/his/her ideas or materials, any user still send them to OneEd Limited or its employees by any means of communication and OneEd Limited makes no assurance that such ideas or materials will be treated as confidential or proprietary and no blame shall be made to OneEd Limited.
 	 
8.	Member account password and security
 	 	 
 	8.1	If any visit or use of the Website Services, services of requires a user to open an account, the user must complete the registration process by providing OneEd Limited with current, complete, and accurate information as prompted by the applicable registration form. The user will be given or distributed by OneEd Limited a login account with login details or create a login account as directed by OneEd Limited. Any user is entirely responsible for maintaining the confidentiality of its/his/her password, login details and any other non-public account information. Furthermore, any user is entirely responsible for all activities that occur under its/his/her account. All users shall notify OneEd Limited immediately of any unauthorized or unlawful use of its/his/her account or any other breach of security. OneEd Limited will not be liable for any loss that the user(s) may incur because of someone else using its/his/her name, login details, password, or account, either with or without its/his/her knowledge. However, user(s) shall be held fully liable for all losses incurred by OneEd Limited or another party due to someone else using its/his/her username, login ID, login details, password, or account to visit the Website, or use the Website Services. No one shall be allowed to use anyone else's (login) account at any time without prior written permission of OneEd Limited.
 	 
9.	No unlawful or prohibited use
 	 	 
 	9.1	As a condition of the use of the Website Services, no user is allowed to use the Website Services, the Materials and Software for any purpose that is unlawful or prohibited by this TOU, under any laws of Hong Kong or the laws of other proper jurisdictions of the place where the users use the Website Services. User(s) are not allowed to use the Website Services, in any manner that could damage, disable, overburden, or impair any OneEd Limited’s server (“OneEd Limited Server”), or any networks connected to any OneEd Limited Server, or that could interfere with any other party's use and enjoyment of any Website Services. No one is allowed to make any attempt to gain unauthorized access to any Website Services, other accounts, computer systems, or networks connected to any OneEd Limited server or to any of the Website Services, through hacking, password mining, or any other means. Unless otherwise approved by OneEd Limited, no one is allowed to obtain or attempt to obtain any material or information through any means not intentionally made available through the Website Services.
 	 
10.	Use of communication services
 	 	 
 	10.1	The Website Services and other services of OneEd Limited may contain message or communication facilities with similar nature of chat forum etc. (if any) designed to enable any user to communicate or share materials, information, opinion etc. with others (each a “Communication Service” and collectively the “Communication Services”). All users shall agree to use the Communication Services only to post, send, and receive messages and materials that are proper and, when applicable, related to the Communication Service. In using the Communication Services, no user is not allowed to: -
•	use the Communication Services in connection with surveys or contests (except and to the extent offered by OneEd Limited, pyramid schemes, junk email, spamming, or any duplicative or unsolicited messages (commercial or otherwise).
•	defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights (such as rights of privacy and publicity) of others.
•	publish, post, upload, distribute, or disseminate any inappropriate, profane, defamatory, obscene, indecent, or unlawful topic, name, material, or information.
•	upload, or otherwise make available, files that contain images, photographs, software, or other material protected by intellectual property laws, including, by way of example and not as limitation, copyright, or trademark laws (or by rights of privacy or publicity) unless the user owns or controls the rights thereto or have received all necessary consent to do the same.
•	use any material or information, including images or photographs, which are made available through the Website Services in any manner that infringes any copyright, trademark, patent, trade secret, or other proprietary right of any party.
•	upload files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, or any other similar software or programs that may damage the operation of another's computer or property.
•	advertise or offer to sell or buy any goods or services for any business purpose unless a Communications Service specifically allows such messages.
•	download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally reproduced, displayed, performed, or distributed in such manner.
•	falsify or delete any copyright management information, such as author attributions, legal or other proper notices, or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded.
•	restrict or inhibit any other unauthorized person (not allowed by OneEd Limited) from using and enjoying the Communication Services.
•	violate any code of conduct or other guidelines (if any) that may be applicable for any Communication Service.
•	collect information about others, including e-mail addresses.
•	violate any applicable laws or regulations.
•	create a false identity for the purpose of misleading others; or
•	use, download, or otherwise copy or provide (whether for a fee) to a person or entity any directory of users of the Website Services or other user or usage information or any portion thereof.
 	10.2	OneEd Limited has no obligation to monitor the Communication Services. However, OneEd Limited reserves its right to review materials posted to the Communication Services and to remove, erase or delete any materials in its sole discretion without notice. OneEd Limited reserves its right to terminate any access to any Communication Service at any time, without notice, for any reason whatsoever.
 	10.3	OneEd Limited reserves its right always to disclose any information to satisfy any applicable law, regulation, legal process, or governmental request, or to edit, refuse to post, or remove any information or materials, in whole or in part, in OneEd Limited's sole discretion.
 	10.4	OneEd Limited specifically disclaims any liability about the Communication Services and any actions resulting from any participation in any Communication Services.
 	10.5	Materials uploaded to the Communication Services may be subject to posted limitations on usage, reproduction, or dissemination. Users are responsible for adhering to such limitations if anyone downloads such materials.
 	 
11.	Termination
 	 	 
 	11.1	OneEd Limited shall be entitled to cease any user using the Website Services and prohibit any user from visiting the Website. respectively without giving any prior notice if any of the following circumstances arises: -
(a)	a user fails to pay any fee or payment to OneEd Limited for using the Website Services, respectively (if applicable) or is indebted to OneEd Limited for any reason.
(b)	a user becomes bankrupt.
(c)	a user breaches any term of this TOU.
(d)	a user breaches any agreement with OneEd Limited.
(e)	a user terminates its/his/her (login) account to the Website Services, respectively; or
(f)	a user, if OneEd Limited considers, does not properly use or is suspected of not properly using the Website Services respectively
 	11.2	Without prejudice to the above, OneEd Limited shall, at any time and at its own discretion: -
(a)	suspend or close the Website and e-Mall, and stop rendering the Website Services, without any prior notice or tendering any reason therefor; or
(b)	have full rights to stop or prohibit any user from visiting the Website. and cease to provide the Website Services to any user without giving any reason or tendering any explanation.
 	11.3	Each of the Users hereby agrees to waive all claims, action, proceedings etc. against OneEd Limited for any loss and damage in case that OneEd Limited exercises its rights under this Clause 11.
 	 
12.	Reservation of rights
 
 	12.1	OneEd Limited retains all its rights, titles, and interests in and to this Website, Software, Materials, the Website Services and including all copyrights, patents, trade secrets, trademarks, and other intellectual property rights thereof. OneEd Limited reserves all rights not expressly granted.
 	12.2	All rights of OneEd Limited are hereby expressly reserved.

                </pre>
              </div>
              <div class="modal-section">
                <div class="check-wrapper">
                  <input
                    v-model="checkedTerms"
                    class="form-check-input"
                    type="checkbox"
                    id="checkTerms"
                  />
                  <label class="form-check-label" for="checkTerms">
                    I have read the above terms of use.
                  </label>
                </div>
                <div class="button-wrapper">
                  <button
                    class="modal-btn accept-btn"
                    :class="{ disabled: !checkedTerms }"
                    @click="$emit('accept')"
                  >
                    Accept
                  </button>
                  <button
                    class="modal-btn decline-btn"
                    @click="$emit('decline')"
                  >
                    Decline
                  </button>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
      <div class="modal-blackdrop"></div>
    </div>
  </transition>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      checkedTerms: false
    }
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>
.modal-container {
  margin-left: 10vw !important;
  margin-right: 10vw !important;
  width: 80vw !important;
  max-width: 80vw !important;
}
.terms-wrapper {
  width: 100%;
  height: calc(90vh - 200px);
  overflow: auto;
  padding: 20px 20px;
  /* border: black solid 1px; */
  border-radius: 7px;
  background: #eeeeee;
}
.check-wrapper {
  margin: 5px 20px;
}
.accept-btn {
  position: relative !important;
  margin-left: auto !important;
}
.decline-btn {
  position: relative !important;
  background-color: #a0a0a0 !important;
  margin-left: 10px;
}
.button-wrapper {
  display: flex;
}
.disabled {
  opacity: 0.67;
  pointer-events: none;
}
</style>
<style src="@/components/teacher/modal/modal.css" scoped></style>
