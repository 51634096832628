<template>
  <div class="modal-row">
    <div v-for="i in 7" class="item-check">
      <input
        v-model="recurrence[i - 1]"
        @change="$emit('input', recurrence)"
        :id="'check-day-' + i"
        class="item-check-input"
        name="item-check"
        type="checkbox"
        value=""
      />
      <label class="item-check-label" :for="'check-day-' + i">
        {{ $t($interfaceLang, weekdayNamesEn[i - 1], weekdayNamesZh[i - 1]) }}
      </label>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Array
  },
  data () {
    return {
      recurrence: this.value,
      weekdayNamesEn: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      weekdayNamesZh: ['日', '一', '二', '三', '四', '五', '六']
    }
  }
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>

<style scoped>
.item-check label:before {
  border: 1.5px solid #969696;
}

.item-check input[type='checkbox']:checked + label:before {
  background-color: #ff881b;
  border-color: #ff881b;
}
</style>
