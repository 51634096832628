<template>
  <div>
    <QuestionsChart
      v-if="name == 'question-chart'"
      :numQuestion="40"
      :topics="[
        { name: 'Test', count: 10 },
        { name: 'Test2', count: 20 },
        { name: 'Test3', count: 5 }
      ]"
    />
    <ReportAssignmentInfo
      v-if="name == 'info'"
      :paperId="'1'"
      :paperName="'Test'"
      :paperType="'homework'"
      :paperExpiryTime="'' + Date.now()"
      :numSubmission="10"
      :numStudent="20"
      :avgTimeSpent="3600000"
      :avgCorrectRate="30"
      :topics="[
        { name: 'test1', count: 10 },
        { name: 'test2', count: 10 },
        { name: 'test3', count: 10 },
        { name: 'test4', count: 10 }
      ]"
    />
    <ReportAssignmentQuestions
      v-if="name == 'questions'"
      :questions="[
        {
          indexNo: 0,
          correctRate: 10,
          avgTimeSpent: 6000
        },
        { indexNo: 1, correctRate: 20 },
        { indexNo: 2, correctRate: 30 },
        { indexNo: 3, correctRate: 40 },
        { indexNo: 4, correctRate: 50 },
        { indexNo: 5, correctRate: 60 },
        { indexNo: 6, correctRate: 70 },
        { indexNo: 7, correctRate: 80 },
        { indexNo: 8, correctRate: 90 },
        { indexNo: 9, correctRate: 100 }
      ]"
    />
    <ReportAssignmentTopicMastery
      v-if="name == 'topic-mastery'"
      :topics="[
        { nameEn: 'Test', nameZh: '測試', numQuestion: 10, avgCorrectRate: 25 },
        { nameEn: 'Test', nameZh: '測試', numQuestion: 10, avgCorrectRate: 60 },
        { nameEn: 'Test', nameZh: '測試', numQuestion: 10, avgCorrectRate: 25 }
      ]"
    />
    <ReportAssignmentStudents
      v-if="name == 'students'"
      :users="[
        {
          name: 'Test',
          identifier: '01',
          correctRate: 20,
          wrongRate: 30,
          timeSpent: 600000
        }
      ]"
    />
    <ReportAssignmentWostQuestions
      v-if="name == 'worst-questions'"
      :questionEdges="[
        { indexNo: 0, correctRate: 58 },
        { indexNo: 9 },
        { indexNo: 5 },
        { indexNo: 8 }
      ]"
    />
    <ReportAssignmentDetailQuestionTable
      v-if="name == 'question-table'"
      :numQuestion="10"
      :paper="{ id: '1', numQuestion: 15 }"
      :users="[
        { id: '2', identifier: '4A 01', name: 'Chan Tai Man David', score: 8 }
      ]"
      :useranswerLookup="{ '2@0': 1 }"
    />
    <ModalFollowup v-if="name == 'modal-followup'" />
    <ModalFollowupAdapter
      v-if="name == 'modal-followup-adapter'"
      :currentUserId="'763'"
      :usergroupId="'60'"
      :paper="{ id: 169 }"
    />
  </div>
</template>
<script>
import ReportAssignmentInfo from '@/pages/teacher/report-assignment/components/report-assignment-info/Info.vue'
import ReportAssignmentInfoAdapter from '@/pages/teacher/report-assignment/components/report-assignment-info/InfoAdapter.vue'
import ReportAssignmentQuestions from '@/pages/teacher/report-assignment/components/report-assignment-questions/Questions.vue'
import ReportAssignmentQuestionsAdapter from '@/pages/teacher/report-assignment/components/report-assignment-questions/QuestionsAdapter.vue'
import ReportAssignmentTopicMastery from '@/pages/teacher/report-assignment/components/report-assignment-topic-mastery/TopicMastery.vue'
import ReportAssignmentTopicMasteryAdapter from '@/pages/teacher/report-assignment/components/report-assignment-topic-mastery/TopicMasteryAdapter.vue'
import ReportAssignmentStudents from '@/pages/teacher/report-assignment/components/report-assignment-students/Students.vue'
import ReportAssignmentStudentsAdapter from '@/pages/teacher/report-assignment/components/report-assignment-students/StudentsAdapter.vue'
import ReportAssignmentWostQuestions from '@/pages/teacher/report-assignment/components/report-assignment-worst-questions/WorstQuestions.vue'
import ReportAssignmentDetailQuestionTable from '@/pages/teacher/report-assignment-details/components/question-table/QuestionTable.vue'
import ModalFollowup from '@/pages/teacher/report-n-statistics/components/modal-followup/ModalFollowup.vue'
import ModalFollowupAdapter from '@/pages/teacher/report-n-statistics/adapters/modal-followup/ModalFollowupAdapter.vue'
import QuestionsChart from '@/pages/teacher/report-assignment/components/report-assignment-info/QuestionsChart.vue'
export default {
  components: {
    ReportAssignmentInfo,
    ReportAssignmentInfoAdapter,
    ReportAssignmentQuestions,
    ReportAssignmentQuestionsAdapter,
    ReportAssignmentTopicMastery,
    ReportAssignmentTopicMasteryAdapter,
    ReportAssignmentStudents,
    ReportAssignmentStudentsAdapter,
    ReportAssignmentWostQuestions,
    ReportAssignmentDetailQuestionTable,
    QuestionsChart,
    ModalFollowup,
    ModalFollowupAdapter
  },
  props: {},
  data () {
    return {
      name: this.$route.query?.name
    }
  },
  computed: {},
  methods: {}
}
</script>
<style src="" />
