<template>
  <div id="createPractice-model">
    <Modal @close="$emit('close')">
      <template v-slot:header>
        <i class="icon-title icon-font icon-practice">&#xe827;</i>
        <span class="title">
          {{ $t($interfaceLang, 'Do Practice', '做練習') }}</span
        >
        <i @click="$emit('close')" class="icon-font icon-close"> &#xe808; </i>
      </template>
      <template v-slot:body>
        <div class="modal-section">
          <div class="section-row select-row">
            <p class="sm-title">
              {{ $t($interfaceLang, 'Topic', '主題') }}
            </p>
            <p class="item-hightlight-text">{{ topicName }}</p>
          </div>
          <div class="section-row select-row">
            <p class="sm-title">
              {{ $t($interfaceLang, 'Subtopic', '副題') }}
            </p>
            <SubtopicCheckBoxes
              :subtopics="subtopics"
              v-model="checkedSubtopics"
            />
          </div>
          <div class="section-row select-row">
            <p class="sm-title">
              {{ $t($interfaceLang, 'No. of questions', '題目數量') }}
            </p>
            <QtyInput
              :minNumQuestion="minNumQuestion"
              :maxNumQuestion="maxNumQuestion"
              :increment="increment"
              v-model="numQuestion"
            />
          </div>
          <div class="section-row">
            <p class="sm-title">
              {{ $t($interfaceLang, 'Difficulty', '難度') }}
            </p>
            <DifficultyInput v-model="difficulty" />
          </div>
          <div class="section-row">
            <button
              id="btn-go"
              class="org-btn"
              @click="
                $emit('done', {
                  numQuestion,
                  subtopicIds: checkedSubtopics.map(x => x.id),
                  difficulty
                })
              "
            >
              <i class="icon-font icon-arrow_right">&#xe805;</i>
              {{ $t($interfaceLang, 'Start', '開始') }}
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/student/modal/Modal.vue'
import SubtopicCheckBoxes from '@/pages/student/practice/components/modal-create-practice/SubtopicCheckBoxes.vue'
import QtyInput from '@/pages/student/practice/components/modal-create-practice/QtyInput.vue'
import DifficultyInput from '@/components/student/difficulty-input/DifficultyInput.vue'

export default {
  components: {
    Modal,
    SubtopicCheckBoxes,
    QtyInput,
    DifficultyInput
  },
  props: {
    topicName: String,
    minNumQuestion: Number,
    maxNumQuestion: Number,
    increment: Number,
    subtopics: Array
  },
  data () {
    return {
      numQuestion: 10,
      checkedSubtopics: this.subtopics,
      difficulty: 3
    }
  },
  computed: {},
  methods: {}
}
</script>
<style src="@/components/student/modal/modal.css" scoped></style>
