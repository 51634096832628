<template>
  <div>
    <SetFavQgroup
      ref="save-fav-qgroup"
      :currentUserId="currentUserId"
      :usergroupId="usergroupId"
    />
    <LoadLibrarySamples
      v-if="doLoadQuestions == true"
      :selector="selector"
      :currentUserId="currentUserId"
      :tschemaId="tschemaId"
      :yearTerm="yearTerm"
      :favQgroups="favQgroups"
      :loadSize="10"
      :endCursor="endCursor"
      @loaded="
        $log('loaded')
        endCursor = $event.endCursor
        questions = [...questions, ...$event.questions]
        infiniteState.loaded()
        doLoadQuestions = false
      "
      @complete="
        $log('complete')
        endCursor = null
        infiniteState.complete()
        doLoadQuestions = false
      "
    />
    <Library
      ref="library"
      :topics="topics"
      :yearTerm="yearTerm"
      :questions="questions"
      :readOnly="readOnly"
      :showFav="showFav"
      @select="
        $log('library-input', selector)
        doLoadQuestions = false
        endCursor = null
        questions = []
        selector = $event
      "
      @add="addQuestions($event)"
      @set-isfav="setIsFav($event)"
      @infinite="atInfinity($event)"
      @similar="$emit('similar', $event)"
    />
  </div>
</template>
<script>
import SetFavQgroup from '@/components/set-fav-qgroup/SetFavQgroup.vue'
import LoadLibrarySamples from './LoadLibrarySamples.vue'
import LoadQgroupMutations from './LoadQgroupMutations.graphql'
import Library from '../../components/library/Library.vue'
export default {
  components: {
    Library,
    LoadLibrarySamples,
    SetFavQgroup
  },
  props: {
    currentUserId: String,
    tschemaId: String,
    usergroupId: String,
    topics: Array,
    yearTerm: Array,
    paperQuestions: Array,
    favQgroups: Array,
    readOnly: Boolean,
    showFav: Boolean
  },
  data () {
    return {
      loadSize: 10,
      selector: null,
      questions: [],
      infiniteState: null,
      endCursor: null,
      doLoadQuestions: false
    }
  },
  computed: {},
  methods: {
    atInfinity (state) {
      // console.log('at-infinity')
      this.infiniteState = state
      this.doLoadQuestions = true
    },
    appendQuestions (questions) {
      this.questions.splice(this.questions.length, 0, ...questions)
    },
    async addQuestions ({ question, num }) {
      this.$emit('add-query-start')
      try {
        let questions = await this.$apolloQuery(LoadQgroupMutations, {
          currentUserId: this.currentUserId,
          id: question?.displayQuestion?.id,
          lang: this.$interfaceLang,
          numQuestion: num,
          exceptQuestionIds: this.paperQuestions
            .map(x => x?.displayQuestion?.id)
            .filter(x => x != null)
        }).then(res => res?.data?.question?.groupMutations)
        if (questions.length > 0) {
          this.$emit('add', { questions })
          this.$emit('add-query-done')
        } else {
          this.$emit('add-query-error')
        }
      } catch (e) {
        this.$log(e.message)
        this.$emit('add-query-error')
      }
    },
    async setIsFav ({ question, isFav }) {
      await this.$refs['save-fav-qgroup'].save(
        question?.displayQuestion?.qgroup?.id,
        isFav
      )
      this.$emit('set-isfav', { question, isFav })
    }
  }
}
</script>
<style src="" />
