<template>
  <div id="switch-adapter">
    <AccountSwitchMenuItem @switch="switchAccount" />
  </div>
</template>
<script>
import AccountSwitchMenuItem from '@/components/account-switch-menu-item/AccoutSwitchMenuItem.vue'
export default {
  components: {
    AccountSwitchMenuItem
  },
  props: {},
  data () {
    return {}
  },
  computed: {},
  methods: {
    switchAccount (identity) {
      this.$appStore.switchIdentity(identity)
      this.$router.go(0)
    }
  }
}
</script>
<style scoped>
#switch-adapter >>> .switch-txt {
  position: relative;
  display: block;
  font-size: 12px;
  width: 80%;
  padding-top: 10px;
  margin: 0 auto;
  color: #ddc254;
}

#switch-adapter >>> .dropdown-item {
  color: white;
  font-size: 13px;
  font-weight: normal;
  line-height: 2.3;
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  margin: 5px 0px;
}

#switch-adapter >>> .dropdown-item:hover,
#switch-adapter >>> .dropdown-item:active {
  color: #ddc254;
  background-color: #000;
}

#switch-adapter >>> .dropdown-item:hover .profile-name {
  color: #ddc254;
}

#switch-adapter >>> .dropdown-item .profile-pic {
  margin-right: 5px;
}

#switch-adapter >>> .dropdown-item .profile-name {
  color: #fff;
  line-height: 1.1em;
}

#switch-adapter >>> .profile-text {
  padding: 0px;
  margin: 0px;
}

#switch-adapter >>> .role-name {
  font-size: 10px;
}

#switch-adapter >>> .profile-pic {
  cursor: pointer;
}

#switch-adapter >>> .profile-pic img {
  width: 32px;
}

#switch-adapter >>> .profile-name {
  font-size: 14px;
  color: #1e2d60;
  padding: 0px 5px;
  text-align: left;
}
</style>
