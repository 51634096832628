<template>
  <div class="container-teacher">
    <div class="bulk-select-text">
      {{
        $t(
          $interfaceLang,
          `${numSelected ?? 0} assignments are selected`,
          `選取了 ${numSelected ?? 0} 份習作`
        )
      }}
    </div>
    <div class="bulk-btn-col">
      <div @click.prevent="$emit('cancel')" class="bulk-btn btn-cancel">
        <i class="icon-font icon-close">&#xe808;</i>
        <span>{{ $t($interfaceLang, 'cancel', '取消') }}</span>
      </div>
      <div @click.prevent="$emit('delete')" class="bulk-btn btn-delete">
        <i class="icon-font icon-delete">&#xe80a;</i>
        <span>{{
          $t(
            $interfaceLang,
            `delete (${numSelected ?? 0})`,
            `刪除 (${numSelected ?? 0})`
          )
        }}</span>
      </div>
      <!-- <div @click.prevent="$emit('edit-schedule')" class="bulk-btn btn-edit">
        <i class="icon-font icon-edit">&#xe80f;</i>
        <span>{{
          $t(
            $interfaceLang,
            `edit dates (${numSelected ?? 0})`,
            `編輯日期 (${numSelected ?? 0})`
          )
        }}</span>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    papers: Array
  },
  data () {
    return {}
  },
  computed: {
    numSelected () {
      return this.papers?.filter(x => x.selected && !x.isDeleted).length
    }
  },
  methods: {}
}
</script>
<style src="../ManageAssignment.css" scoped />
