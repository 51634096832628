<template>
  <div>
    <div class="position-relative">
      <div class="report-info-col-title">
        {{ $t($interfaceLang, 'Question Performance', '題目表現') }}
      </div>
      <div class="nav-row">
        <SelectTypes v-model="tab" />
        <!-- select topics -->
        <button
          @click="
            $emit('show-question')
          "
          class="btn-question"
        >
          {{ $t($interfaceLang, 'View questions', '檢視題目') }}
        </button>
      </div>
      <div class="chart-col">
        <template v-if="questions?.length > 0">
          <CorrectBars
            v-if="tab == 'correct-rate'"
            :questions="questions"
            @click-element="
              $emit('click-question', questions[$event.index].indexNo)
            "
          />
          <TimeBars
            v-if="tab == 'time-spent'"
            :questions="questions"
            @click-element="
              $emit('click-question', questions[$event.index].indexNo)
            "
          />
        </template>
        <template v-else>
          {{ noData }}
        </template>
      </div>
      <LocalLoadingLayer v-if="isLoading" />
    </div>
  </div>
</template>
<script>
import SelectTypes from './SelectTypes.vue'
import CorrectBars from './CorrectBars.vue'
import TimeBars from './TimeBars.vue'
import LocalLoadingLayer from '@/components/LocalLoadingLayer.vue'

export default {
  components: {
    SelectTypes,
    CorrectBars,
    TimeBars,
    LocalLoadingLayer
  },
  props: {
    questions: Array,
    isLoading: Boolean
  },
  data () {
    return {
      tab: 'correct-rate'
    }
  },
  computed: {
    noData () {
      return this.$t(this.$interfaceLang, 'No data', '沒有資料')
    }
  },
  methods: {}
}
</script>
<style src="../../ReportInside.css" scoped></style>
<style scoped>
.btn-question {
  border: 1px solid #000;
  border-radius: 20px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 12px;
  background-color: #f1f3f1;
}
</style>
