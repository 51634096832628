<template>
  <div>
    <Header
      :lang="$interfaceLang"
      :studentName="displayName ?? $t($interfaceLang, 'Student', '學生')"
      @set-lang="setLang($event)"
    />
    <template v-if="loadedHeader">
      <Terms
        v-if="agreeTermVersion != currentTermVersion"
        @accept="acceptTerms()"
        @decline="$router.push('login')"
      />
    </template>
  </div>
</template>
<script>
import config from '@/config.json'
import Header from './Header.vue'
import Terms from '@/components/terms/Terms.vue'

import checkVersionQuery from './CheckVersion.graphql'
import LoadStudentHeaderQuery from './LoadStudentHeader.graphql'
import AgreeMyTermsQuery from './AgreeMyTerms.graphql'

export default {
  components: {
    Header,
    Terms
  },
  data () {
    return {
      currentUserId: this.$appStore.userInfo.userid,
      identities: this.$appStore.userData,
      loadedHeader: false,
      displayName: null,
      agreeTermVersion: null,
      currentTermVersion: config.app.currentTermVersion
    }
  },
  async created () {
    var version = await this.$apolloQuery(checkVersionQuery).then(
      res => res?.data.version
    )
    console.log('API-version:', version)
    this.displayName =
      this.$appStore?.userInfo?.displayName ?? this.$appStore?.userInfo?.name
    this.agreeTermVersion = this.$appStore?.userInfo?.agreeTermVersion
    if (this.$appStore?.userInfo?.usergroup == null) {
      let success = await this.loadHeader(this.currentUserId)
      if (success) {
        this.loadedHeader = true
        this.$emit('loaded')
      } else {
        this.$toast_error(this.$t(this.$interfaceLang, 'Load fail', '載入失敗'))
      }
    } else {
      this.loadedHeader = true
      this.$emit('loaded')
    }
  },
  methods: {
    async loadHeader (currentUserId) {
      let me = await this.$apolloQuery(LoadStudentHeaderQuery, {
        currentUserId
      }).then(res => res?.data?.me)
      if (me?.usergroupEdges?.length > 0) {
        const getUsergroup = ({ id, name }) => ({ id, name })
        let usergroup = getUsergroup(me.usergroupEdges[0]?.usergroup)
        let tschema = me.usergroupEdges[0]?.usergroup?.tschemaEdges[0]?.tschema
        this.$appStore.updateUserInfo({
          usergroup: usergroup,
          tschema: tschema
        })
        return true
      } else {
        this.$appStore.updateUserInfo({ usergroup: null })
        this.$emit('error')
      }
    },
    async acceptTerms () {
      let currentTermVersion = config.app.currentTermVersion
      for (var i = 0; i < this.identities.length; ++i) {
        await this.$apolloMutation(AgreeMyTermsQuery, {
          currentUserId: this.identities[i].id,
          termVersion: currentTermVersion
        })
        this.identities[i].agreeTermVersion = currentTermVersion
        this.$appStore.updateUserData(this.identities)
      }
      this.$appStore.updateUserInfo({
        agreeTermVersion: currentTermVersion
      })
      this.agreeTermVersion = currentTermVersion
      this.$emit('accepted')
    },
    setLang (lang) {
      this.$appStore.updateUserPrefrences({ interfaceLang: lang })
      this.$router.go(0)
    }
  }
}
</script>
<style src="" />
