<template>
  <div class="btn-group select-type" role="group">
    <button
      type="button"
      :class="{
        'dropdown-toggle': !readOnly
      }"
      class="btn assignment-dropdown"
      :data-toggle="readOnly ? null : 'dropdown'"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{
        value
          ? $t($interfaceLang, 'Dynamic', '動態')
          : $t($interfaceLang, 'Static', '靜態')
      }}
    </button>
    <div
      v-if="!readOnly"
      class="dropdown-menu assignment-dropdown-group btn-select-type-group"
    >
      <a
        class="dropdown-item assignment-dropdown-item"
        href="#"
        @click.prevent="$emit('input', !value)"
      >
        {{
          value
            ? $t($interfaceLang, 'Static', '靜態')
            : $t($interfaceLang, 'Dynamic', '動態')
        }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: { type: Boolean, default: true },
    readOnly: Boolean
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="" />
