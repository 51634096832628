<script>
import { Line } from 'vue-chartjs'
import zoom from 'chartjs-plugin-zoom'

export default {
  extends: Line,
  props: ['chartData', 'options'],
  mounted () {
    this.addPlugin(zoom)
    this.render()
  },
  watch: {
    chartData (to, from) {
      this.render()
    }
  },
  methods: {
    render () {
      this.renderChart(this.chartData, this.options)
      this.$emit('renderLabel')
    }
  }
}
</script>
