<template>
  <table id="overview-chart" class="overview-chart w-100">
    <tr>
      <th class="table-id">ID</th>
      <th class="table-student">{{ $t($interfaceLang, 'Student', '學生') }}</th>
      <th v-if="isLoadingPapers && !isLoadingStudents">
        <img class="loading-gif" src="@/assets/images/loading.gif" />
      </th>
      <th
        v-else
        v-for="paper in papers"
        :key="'paper-' + paper.id"
        class="table-assignment"
      >
        <PaperPopper
          :paperName="paper.name"
          :paperExpiryTime="paper.expiryTime"
        />
      </th>
      <th
        v-for="i in numPaper - papers.length > 0 ? numPaper - papers.length : 0"
        class="table-assignment"
      ></th>
      <th class="table-overdue-title table-overdue">
        {{ $t($interfaceLang, 'Overdue Assignment', '遲交習作') }}
      </th>
    </tr>
    <tr v-if="isLoadingStudents">
      <td class="table-id">
        <img class="loading-gif" src="@/assets/images/loading.gif" />
      </td>
    </tr>

    <tr v-for="student in students" :key="'student-' + student.id">
      <td class="table-id">#{{ student.userIdentifier }}</td>
      <td class="table-student">{{ student.name }}</td>
      <td
        v-for="paper in papers"
        :id="student.id + '-' + paper.id"
        :key="student.id + '-' + paper.id"
        class="table-assignment"
      >
        <template
          v-if="tileLookup != null"
          v-for="t in [tileLookup[tileIndex(student.id, paper.id)]]"
        >
          <TilePopper
            :isLoading="t == null"
            :numSubmittedQuestion="t?.numSubmittedQuestion"
            :numCorrectQuestion="t?.numCorrectQuestion"
            :numQuestion="t?.numQuestion"
            :submitStatus="t?.submitStatus"
            :submitTime="t?.submitTime"
          />
        </template>
      </td>
      <td
        v-for="i in numPaper - papers.length > 0 ? numPaper - papers.length : 0"
        class="table-assignment"
      ></td>
      <td v-if="papers != null" class="table-overdue-td table-overdue">
        <OverduePopper
          v-if="student.overduePapers?.length > 0"
          :overduePapers="student.overduePapers"
        />
        <template v-else>
          <div class="gray-circle">0</div>
        </template>
      </td>
    </tr>
  </table>
</template>
<script>
import PaperPopper from './PaperPopper.vue'
import TilePopper from './TilePopper.vue'
import OverduePopper from './OverduePopper.vue'

export default {
  components: {
    PaperPopper,
    TilePopper,
    OverduePopper
  },
  props: {
    students: Array,
    papers: Array,
    tileLookup: Object,
    isLoadingStudents: Boolean,
    isLoadingPapers: Boolean,
    isLoading: Boolean,
    numPaper: Number
  },
  data () {
    return {}
  },
  computed: {
    hasOverdue () {
      return (
        this.students.map(x => x.overduePapers?.length).filter(x => x > 0)
          .length > 0
      )
    }
  },
  methods: {
    tileIndex (studentId, paperId) {
      return (studentId ?? 'null') + '-' + (paperId ?? 'null')
    }
  }
}
</script>

<style
  src="@/pages/teacher/report-n-statistics/ReportnStatistics.css"
  scoped
></style>

<style scoped>
.table-title-container {
  position: relative;
  display: flex;
  align-items: center;
}

.popper {
  color: white;
  background-color: #626262;
  border: 1px #626262 solid;
  box-shadow: none;
  padding: 5px 10px;
}

#overview-container >>> .popper__arrow {
  border-color: transparent transparent #626262 transparent !important;
}

.overview-chart-col {
  position: relative;
  margin-top: 30px;
  overflow-x: auto;
  max-width: 952px;
}

.overview-chart {
  position: relative;
  background-color: white;
}

.overview-chart th {
  font-size: 12px;
  font-weight: 600;
  color: #ccc;
  border-bottom: 0.8px solid #ccc;
  height: 30px;
  padding: 5px 10px;
}

.overview-chart td {
  font-size: 14px;
  color: #192c64;
  height: 40px;
}

.table-id {
  min-width: 30px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
}

.table-student {
  min-width: 130px;
  width: 130px;
  text-align: right;
  vertical-align: middle;
  padding-right: 10px;
}

.table-assignment {
  min-width: 80px;
  width: 80px;
  text-align: center;
  vertical-align: middle;
}

.table-overdue-title {
  min-width: 75px;
  width: 75px;
  color: #2b95d3 !important;
}

.table-overdue {
  min-width: 75px;
  text-align: center;
  vertical-align: middle;
}

.color-bar {
  width: 85%;
  height: 85%;
}

.color-on_time {
  background-color: #3bcc89;
}

.color-late {
  background-color: #9ee5c5;
}

.color-overdue {
  background-color: #f55a58;
}

.color-partial {
  background-color: #f1f3f0;
}

.table-overdue-td {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gray-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #192c64;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #f1f3f0;
}

.red-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #f55a58;
}

.col-content {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.hidden-text {
  position: absolute;
  display: block;
  opacity: 0;
}

.loading-gif {
  width: 30px;
  height: 30px;
}
</style>
