<template>
  <div>
    <div class="btn-group select-dropdown" role="group">
      <button
        type="button"
        class="btn select-topic-dropdown select-questions-dropdown dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span class="select-content">
          {{ displayYearTerm(value) }}
        </span>
      </button>
      <div
        class="dropdown-menu select-topic-dropdown-group select-questions-dropdown-group  btn-select-topic-group"
      >
        <a
          v-for="yt in yearTerms"
          @click="$emit('input', yt)"
          class="dropdown-item select-topic-dropdown-item select-questions-dropdown-item"
          href="#"
        >
          {{ displayYearTerm(yt) }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    topics: Array,
    value: Array
  },
  data () {
    let zhNumber = ['一', '二', '三', '四', '五', '六']
    let yearTermLookup = {}
    for (let year of [1, 2, 3, 4, 5, 6]) {
      yearTermLookup[`en_hk-${year}-${1}`] = `Form ${year} First Term`
      yearTermLookup[`en_hk-${year}-${2}`] = `Form ${year} Second Term`
      yearTermLookup[`zh_hk-${year}-${1}`] = `中${zhNumber[year - 1]}上學期`
      yearTermLookup[`zh_hk-${year}-${2}`] = `中${zhNumber[year - 1]}下學期`
    }
    return {
      yearTermLookup
    }
  },
  computed: {
    yearTerms () {
      return this.getYearTerms(this.topics)
    }
  },
  methods: {
    displayYearTerm (yearTerm) {
      if (yearTerm != null) {
        return this.yearTermLookup[
          `${this.$interfaceLang}-${yearTerm.join('-')}`
        ]
      }
    },
    getYearTerm (topic) {
      return [topic.schoolYear, topic.term]
    },
    getYearTerms (topics) {
      let yearTerms = topics.map(this.getYearTerm).map(x => `${x[0]}-${x[1]}`)
      return [...new Set(yearTerms)]
        .map(x => x.split('-').map(Number))
        .sort(this.compareYearTerm)
    },
    compareYearTerm (yt1, yt2) {
      if (yt1[0] == yt2[0]) {
        return yt1[1] - yt2[1]
      } else {
        return yt1[0] - yt2[0]
      }
    }
  }
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>
