var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.name == 'header')?_c('TeacherHeader',{attrs:{"lang":'en_hk',"identities":[{'id':'672','name':'TT 182','type':'teacher','displayName':null,'avatarName':'','__typename':'User'}],"isLoading":true,"userRole":'teacher',"usergroupEdges":[
      {
        usergroup: {
          id: 'test',
          name: 'Test',
          type: 'class',
          tschemaEdges: [{ tschema: { id: 'test' } }]
        },
        isUserTeacherOwner: true,
        isUsergroupGroupArchived: false,
        cache: {
          isUsergroupGroupShared: false
        }
      },
      {
        usergroup: {
          id: 'test2',
          name: 'Test2',
          type: 'usergroup',
          tschemaEdges: [{ tschema: { id: 'test' } }]
        },
        isUserTeacherOwner: true,
        isUsergroupGroupArchived: false,
        cache: {
          isUsergroupGroupShared: false
        }
      }
    ]},model:{value:(_vm.currentUsergroup),callback:function ($$v) {_vm.currentUsergroup=$$v},expression:"currentUsergroup"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }