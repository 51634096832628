<template>
  <div class="date-container-content">
    <p class="sm-title">
      {{
        $t(
          $interfaceLang,
          'Select date by the below calendar',
          '使用下面的月曆選擇日期'
        )
      }}
    </p>
    <div class="date-col">
      <DatePicker
        ref="date-picker"
        v-model="inputDateRanges"
        @input="$emit('input', inputDateRanges)"
      />
    </div>
    <div class="date-input-col">
      <p class="sm-title">
        {{ $t($interfaceLang, 'Start date', '開始日期') }}
      </p>
      <div class="input-date-text">
        {{ $displayDate(inputDateRanges.start) }}
      </div>
      <div v-if="isToday(inputDateRanges.start)" class="remark">
        {{ $t($interfaceLang, todayWarningEn, todayWarningZh) }}
      </div>
      <p class="sm-title">
        {{ $t($interfaceLang, 'Due date', '遞交日期') }}
      </p>
      <div class="input-date-text">
        {{ $displayDate(inputDateRanges.end) }}
      </div>
    </div>
  </div>
</template>
<script>
import DatePicker from './DatePicker.vue'
export default {
  components: {
    DatePicker
  },
  props: {
    value: Object
  },
  data () {
    return {
      inputDateRanges: this.value,
      todayWarningEn:
        'Warning: Once you set the date as today, you cannot change the start date and also the questions in the assignment.',
      todayWarningZh:
        '注意：把開始日期設定為今天之後，你不能再更改開始日期以及作業中的題目。'
    }
  },
  computed: {},
  methods: {
    isToday (value) {
      return (
        Number(value) <= Date.now() &&
        Date.now() - Number(value) < 3600 * 24 * 1000
      )
    }
  }
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>
<style scoped>
.remark {
  width: 100%;
  font-size: 11px;
  color: #007fca;
  margin-top: 1em;
}
</style>
