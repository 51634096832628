<template>
  <div class="question-container">
    <div class="question-info">
      <div class="question-info-left">
        <FavButton
          v-if="showFav"
          v-model="isFav"
          @input="$emit('set-isfav', { question, isFav })"
        />
        <button
          @click="$emit('similar', { question })"
          class="btn-relate add-question-btn"
        >
          <i class="icon-font icon-relate">&#xe81a;</i>
          {{ $t($interfaceLang, 'Similar Questions', '相似題目') }}
        </button>
      </div>
      <div class="question-info-right">
        <InputQuestionQty
          v-if="!readOnly"
          v-model="addNumber"
          :inputs="[1, 2, 3, 4, 5]"
          :defaultInputIndex="0"
        />
        <button
          v-if="!readOnly"
          @click="$emit('add', { question, num: addNumber })"
          class="btn-add add-question-btn"
        >
          {{ $t($interfaceLang, 'Add', '新增') }}
        </button>
      </div>
    </div>
    <QuestionView
      :question="question"
      :isShuffled="false"
      :permutation="[0, 1, 2, 3]"
      :mcAnswerPermutation="question?.displayQuestion?.sampleEdgePermutation"
    />
  </div>
</template>
<script>
import FavButton from '@/components/FavButton.vue'
import QuestionView from '@/components/teacher/new-question-view/QuestionView.vue'
import InputQuestionQty from './InputQuestionQty.vue'
export default {
  components: {
    FavButton,
    InputQuestionQty,
    QuestionView
  },
  props: {
    readOnly: Boolean,
    question: Object,
    isQgroupFav: Boolean,
    showFav: Boolean
  },
  data () {
    return {
      isFav: this.isQgroupFav,
      addNumber: 1
    }
  },
  watch: {
    isQgroupFav (newValue) {
      this.isFav = newValue
    }
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>
.question-container {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-right: 20px;
  border-radius: 4px;
  background-color: white;
  margin-top: 20px;
}

.question-info {
  position: relative;
  display: flex;
}

.question-info-left {
  position: relative;
  display: flex;
  align-items: center;
}

.btn-relate.add-question-btn {
  display: flex;
  align-items: center;
  color: #1c2d60;
  font-size: 12px;
  font-weight: 600;
  padding: 0px 10px;
  margin-left: 10px;
  border-radius: 12px;
  background-color: #f1f3f1;
}

.btn-relate.add-question-btn i {
  color: #007fca;
  font-size: 16px;
  margin-right: 5px;
}

.btn-relate.add-question-btn:focus,
.btn-relate.add-question-btn:hover {
  background-color: #a3a3a3;
}

.question-info-right {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.add-question-btn {
  border: none;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  color: #1c2d60;
  font-size: 24px;
}

.add-question-btn:focus,
.add-question-btn:hover,
.add-question-btn:active {
  box-shadow: none;
  outline: none;
}

.btn-favourite {
  padding-right: 5px;
  border-right: 1px solid #f1f3f1;
}

.add-question-qty {
  display: flex;
  align-items: center;
}

.question-qty {
  font-size: 13px;
  font-weight: 600;
  color: #1c2d60;
  padding: 0px 10px;
}

.img-qty {
  width: 20px;
  cursor: pointer;
}

.btn-add {
  font-size: 13px;
  font-weight: 600;
  color: white;
  padding: 2px 10px;
  border-radius: 12px;
  background-color: #1c2d60;
  margin-left: 10px;
}

.btn-add:hover,
.btn-add:focus {
  background-color: #2a4186;
}

.edit-question-btn {
  border: none;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  color: #1c2d60;
  font-size: 24px;
  margin-right: 20px;
}

.edit-question-btn:focus,
.edit-question-btn:hover,
.edit-question-btn.active {
  box-shadow: none;
  outline: none;
}
</style>
