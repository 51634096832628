<template>
  <div id="duplicate-paper-modal">
    <Modal @close="$emit('close')">
      <template v-slot:header>
        <i class="icon-font icon-duplicate mr-2"> &#xe80e; </i>
        {{ wordTitle }}
        <i @click="$emit('close')" class="icon-font icon-close"> &#xe808; </i>
      </template>
      <template v-slot:body>
        <div class="modal-body-content">
          <div class="modal-section">
            <div class="info-title"> {{ wordSource }} </div>
            <div class="info-row">
              <PaperRow :paper-type="paper.type" :name="paper.name" :isLocked="paper.isLocked"
                :numQuestion="paper.numQuestion" :startTime="paper.startTime" :expiryTime="paper.expiryTime" />
            </div>
            <div v-if="hasQuestion" class="question-row-container">
              <QuestionRow v-for="(e, i) in paperQuestionEdges" :indexNo="i" :question="e.question"
                :key="'question-row-' + i" />
            </div>
            <div v-else class="info-row m-4"> {{ wordNoQuestion }} </div>
          </div>
          <div class="modal-section" v-if="hasQuestion">
            <div class="info-title"> {{ wordDestination }} </div>
            <div class="info-row">
              <div class="info-row-name"> {{ wordAssignmentName }} </div>
              <div class="info-row-content">
                <input v-model="destAssignmentName" class="form-control" type="text" />
              </div>
            </div>
            <div class="info-row">
              <div class="info-row-name"> {{ wordClassOrGroups }} </div>
              <div class="info-row-content">
                <SelectUsergroup v-model="selectedUsergroupIds" :usergroups="usergroups" class="m-2" />
              </div>
            </div>
            <div class="info-row">
              <div id="duplicate-btn" :class="{ disabled: disableDuplicate }" @click="duplicate()">
                {{ wordDuplicate }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/teacher/modal/Modal.vue'
import PaperRow from './PaperRow.vue'
import QuestionRow from './QuestionRow.vue'
import SelectUsergroup from './SelectUsergroup.vue'
export default {
  components: {
    Modal, PaperRow, QuestionRow, SelectUsergroup
  },
  props: {
    paper: {
      type: Object,
      default: _ => ({
        id: null,
        type: null,
        name: null,
        isLocked: null,
        numQuestion: null,
        startTime: null,
        expiryTime: null
      })
    },
    paperQuestionEdges: { type: Array, default: _ => [] },
    usergroups: { type: Array, default: _ => [] }
  },
  data() {
    return {
      destAssignmentName: this.paper.name,
      selectedUsergroupIds: []
    }
  },
  computed: {
    wordTitle() {
      return this.$t(this.$interfaceLang, 'Duplicate Assignment', '複製習作')
    },
    wordSource() {
      return this.$t(this.$interfaceLang, 'Source', '來源')
    },
    wordNoQuestion() {
      return this.$t(
        this.$interfaceLang,
        'There is no questions in this assignment. Cannot duplicate.',
        '這份習作沒有題目。不能複製。'
      )
    },
    wordDestination() {
      return this.$t(this.$interfaceLang, 'Destination(s)', '目的地')
    },
    wordAssignmentName() {
      return this.$t(this.$interfaceLang, 'Assignment Name', '習作名稱')
    },
    wordClassOrGroups() {
      return this.$t(this.$interfaceLang, 'Classes or groups', '班別／分組')
    },
    wordDuplicate() {
      return this.$t(this.$interfaceLang, 'Duplicate', '複製')
    },
    disableDuplicate() {
      return this.selectedUsergroupIds.length == 0
    },
    hasQuestion() {
      return this.paperQuestionEdges?.length > 0
    }
  },
  methods: {
    duplicate() {
      this.$emit('duplicate', {
        paperId: this.paper?.id,
        paperName: this.destAssignmentName,
        usergroupIds: this.selectedUsergroupIds
      })
    }
  }
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped>

</style>
<style scoped>
.modal-container {
  width: 800px !important;
  max-width: 90% !important;
  max-height: 85% !important;
}

.info-title {
  font-size: 16pt;
  font-weight: 600px;
  margin: 10px;
  color: #1c2d60;
}

.info-row {
  display: flex;
  align-items: flex-start;
  color: #1c2d60;
  margin-left: 15px;
  margin-right: 15px;
}

.info-row-name {
  display: flex;
  margin: 10px;
  width: 25%;
  align-items: center;
  font-weight: 600;
  letter-spacing: normal;
  color: #1c2d60;
}

.info-row-content {
  width: 75%;
}

.check-class {
  margin: 10px;
}

.modal-body-content {
  max-height: 70vh;
  overflow-y: auto;
}

.question-row-container {
  margin-left: 15px;
  margin-right: 15px;
}

#duplicate-btn {
  display: block;
  border-radius: 20px;
  background-color: #ff881b;
  border-style: none;
  font-size: 13px;
  font-weight: 600;
  color: white;
  padding: 5px 15px;
  height: 30px;
  z-index: 55;
  margin-left: auto;
}

#duplicate-btn:focus,
#duplicate-btn:hover,
#duplicate-btn:active {
  box-shadow: none;
  outline: none;
  background-color: #ffa856;
}

#duplicate-btn i {
  font-size: 16px;
  padding-right: 5px;
}

.remark {
  font-size: 15px;
  color: #42c9f1;
  margin-left: 5px;
  white-space: pre-line;
}

.disabled {
  pointer-events: none;
  opacity: 0.67;
}
</style>
