<template>
  <div>
    <div
      v-for="(user, i) in userData"
      v-if="user.id != $appStore.userInfo.userid"
    >
      <a
        :key="'acc-' + i"
        @click.prevent="$emit('switch', user)"
        class="dropdown-item"
        href="#"
      >
        <div class="profile-pic">
          <img :src="profileImage(user.type, user.avatarName)" />
        </div>
        <span class="profile-name">
          <p class="profile-text">
            {{
              user.displayName ??
                user.name ??
                roleName($interfaceLang, user.type)
            }}
          </p>
          <p class="profile-text role-name">
            {{ roleName($interfaceLang, user.type) }}
          </p>
        </span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      userData: this.$appStore.userData
    }
  },
  computed: {},
  methods: {
    roleName (lang, val) {
      let names = {
        'en_hk-teacher': 'Teacher',
        'en_hk-super_teacher': 'Super Teacher',
        'en_hk-student': 'Student',
        'en_hk-inspector': 'Inspector',
        'zh_hk-teacher': '教師',
        'zh_hk-super_teacher': '超級教師',
        'zh_hk-student': '學生',
        'zh_hk-inspector': '監督'
      }
      return names[`${lang}-${val}`]
    },
    profileImage (type, val) {
      var avatarName
      if (type == 'student') {
        avatarName = 'profile_student_01'
      } else {
        if (val == null || val == 'undefined' || val == '') {
          avatarName = 'profile_teacher_01'
        } else {
          avatarName = val
        }
      }
      return require('@/assets/images/avatar/' + avatarName + '.png')
    }
  }
}
</script>
