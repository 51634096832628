var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('SetFavQgroup',{ref:"save-fav-qgroup",attrs:{"currentUserId":_vm.currentUserId,"usergroupId":_vm.usergroupId}}),(_vm.doLoadQuestions == true)?_c('LoadLibrarySamples',{attrs:{"selector":_vm.selector,"currentUserId":_vm.currentUserId,"tschemaId":_vm.tschemaId,"yearTerm":_vm.yearTerm,"favQgroups":_vm.favQgroups,"loadSize":10,"endCursor":_vm.endCursor},on:{"loaded":function($event){_vm.$log('loaded')
      _vm.endCursor = $event.endCursor
      _vm.questions = [..._vm.questions, ...$event.questions]
      _vm.infiniteState.loaded()
      _vm.doLoadQuestions = false},"complete":function($event){_vm.$log('complete')
      _vm.endCursor = null
      _vm.infiniteState.complete()
      _vm.doLoadQuestions = false}}}):_vm._e(),_c('Library',{ref:"library",attrs:{"topics":_vm.topics,"yearTerm":_vm.yearTerm,"questions":_vm.questions,"readOnly":_vm.readOnly,"showFav":_vm.showFav},on:{"select":function($event){_vm.$log('library-input', _vm.selector)
      _vm.doLoadQuestions = false
      _vm.endCursor = null
      _vm.questions = []
      _vm.selector = $event},"add":function($event){return _vm.addQuestions($event)},"set-isfav":function($event){return _vm.setIsFav($event)},"infinite":function($event){return _vm.atInfinity($event)},"similar":function($event){return _vm.$emit('similar', $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }