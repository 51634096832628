<template></template>
<script>
import LoadTopicsQuery from './LoadTopics.graphql'

export default {
  components: {},
  props: {
    currentUserId: String,
    usergroupId: String,
  },
  data() {
    return {
      colors: [
        'purple',
        'green',
        'pink',
        'blue',
        'pink2',
        'green2',
        'green3',
        'blue2'
      ]
    };
  },
  created(){
    this.fetchTopicUsergroupEdges(this.currentUserId, this.usergroupId, null)
  },
  methods: {
    async fetchTopicUsergroupEdges(currentUserId, usergroupId, after) {
      var result = await this.$apolloQuery(LoadTopicsQuery, {
        currentUserId,
        usergroupId
      })
      var topics = []
      var topicEdges = result.data.topicUsergroupEdges;
      for (var i = 0; i < topicEdges.length; i++) {
        var subtopics = []
        var subtopicEdges = topicEdges[i].topic.subtopicEdges
        for (var j = 0; j < subtopicEdges.length; j++) {
          subtopics.push({
            id: subtopicEdges[j].subtopic.id,
            nameEn: subtopicEdges[j].subtopic.nameEn,
            nameZh: subtopicEdges[j].subtopic.nameZh
          })
        }
        topics.push({
          topicId: topicEdges[i].topic.id,
          nameEn: topicEdges[i].topic.nameEn,
          nameZh: topicEdges[i].topic.nameZh,
          numSubtopic: topicEdges[i].topic.numSubtopic, 
          imagePath: null,  // TODO '../static/images/' + topicFigure[t.id] + '.png'
          color: this.colors[i % this.colors.length],  // TODO
          yearTerm: topicEdges[i].cache.topicYear,
          subtopics: subtopics
        })
      }
      this.$emit('loaded', { topics })
    }
  },
};
</script>
