<template>
  <EditRecursive
    :currentUserId="currentUserId"
    :autohomework="autohomework"
    @close="$emit('close')"
    @save="updateAutohomework($event)"
  />
</template>
<script>
import EditRecursiveGraphql from './EditRecursive.graphql'
import EditRecursive from '../../modal/EditRecursive.vue'
export default {
  components: {
    EditRecursive
  },
  props: {
    currentUserId: String,
    autohomework: Object
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    async updateAutohomework (autohomework) {
      this.$emit('mutate-start')
      try {
        var success = await this.$apolloQuery(EditRecursiveGraphql, {
          currentUserId: this.currentUserId,
          autohomeworkId: autohomework.id,
          paperName: autohomework.paperName,
          expiryTime: autohomework.expiryTime,
          numQuestion: autohomework.numQuestion,
          difficulty: autohomework.difficulty,
          scheduleWeekdays: autohomework.scheduleWeekdays,
          scheduleRepeatFrequency: autohomework.scheduleRepeatFrequency,
          homeworkDuration: Number(autohomework.homeworkDuration),
          numFollowupQuestion: autohomework.numFollowupQuestion,
          numAutohomeworkPerFollowup: autohomework.numAutohomeworkPerFollowup
        }).then(res => res?.data?.updateMyAutohomework?.success)
        success ? this.$emit('mutate-done') : this.$emit('mutate-error')
      } catch (e) {
        this.$log(e)
        this.$emit('mutate-error')
      }
    }
  }
}
</script>
<style src="" />
