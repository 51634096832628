<template>
  <div class="page-btn-group">
    <button
      v-if="!readOnly"
      @click.prevent="$emit('reset')"
      id="btn-reset"
      :class="{ disabled: hasChanged == false }"
      class="page-btn white-btn"
    >
      <i class="icon-font icon-undo">&#xe833;</i>
      {{ $t($interfaceLang, 'Reset', '重設') }}
    </button>
    <button
      @click.prevent="$emit('overview')"
      id="btn-overview"
      class="page-btn white-btn"
    >
      <i class="icon-font icon-inspect">&#xe815;</i>
      {{ $t($interfaceLang, 'Overview', '概覽') }}
    </button>
    <button
      :class="{ disabled: readOnly || hasChanged == false }"
      @click.prevent="$emit('save')"
      id="btn-save"
      class="page-btn org-btn"
    >
      <i class="icon-font icon-save">&#xe81d;</i>
      {{ $t($interfaceLang, 'Save', '儲存') }}
    </button>
  </div>
</template>
<script>
export default {
  props: {
    readOnly: Boolean,
    hasChanged: Boolean
  }
}
</script>
<style src="../../TeachingSchedule.css" scoped />
<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.67;
}
</style>
