<template>
  <transition name="modal">
    <div id="loading-model" class="modal-mask">
      <img class="loading-gif" src="@/assets/images/loading.gif" />
      <div class="modal-blackdrop"></div>
    </div>
  </transition>
</template>
<script>
export default {
  created () {
    setTimeout(
      function () {
        this.$emit('error')
      }.bind(this),
      8000
    )
  },
  watch: {
    hasError () {
      this.$emit('error')
    }
  }
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>
<style scoped>
#loading-model .loading-gif {
  width: 50px;
  height: 50px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9996;
}

#loading-model .modal-blackdrop {
  background-color: rgba(255, 255, 255, 0.5);
}
.modal-mask {
  z-index: 100000 !important;
}
</style>
