<template>
  <PaperList
    ref="paper-list"
    :papers="papers"
    :readOnly="readOnly"
    @sort-by="applySort($event.sortBy)"
    @isdynamic="setIsDynamic($event.paper, $event.isDynamic)"
    @goto="
      $router.push({
        path: '/teacher/editassignment',
        query: { id: $event.paper.id }
      })
    "
    @bulk-select="$emit('bulk-select', $event)"
    @edit="$emit('edit', $event)"
    @share="$emit('share', $event)"
    @delete="$emit('delete', $event)"
    @duplicate="$emit('duplicate', $event)"
    @infinite="atInfinity($event)"
  />
</template>
<script>
import LoadPaperListQuery from './LoadPaperList.graphql'
import SavePaperIsDynamicQuery from './SavePaperIsDynamic.graphql'

import PaperList from '../../components/paper-list/PaperList.vue'
export default {
  components: {
    PaperList
  },
  props: {
    currentUserId: String,
    usergroupId: String,
    readOnly: Boolean
  },
  data () {
    return {
      loadPaperBatchSize: 10,
      papers: [],
      infiniteState: null,
      endCursor: null,
      sortByData: null
    }
  },
  computed: {},
  methods: {
    atInfinity (state) {
      setTimeout(
        (_ => {
          this.infiniteState = state
          this.fetchMorePaperList().then(papers => this.appendPapers(papers))
        }).bind(this),
        200
      )
    },
    appendPapers (morePapers) {
      this.papers = [...this.papers, ...morePapers]
    },
    applySort (sortByData) {
      this.sortByData = sortByData
      this.papers = []
      this.endCursor = null
      this.$nextTick(_ => this.$refs['paper-list'].reset())
    },
    async setIsDynamic (paper, isDynamic) {
      this.$emit('mutate-start')
      try {
        var success = await this.$apolloMutation(SavePaperIsDynamicQuery, {
          currentUserId: this.currentUserId,
          paperId: paper.id,
          isDynamic
        }).then(res => res?.data?.updateMyPaperIsDynamic)
        success == true ? this.$emit('mutate-done') : this.$emit('mutate-error')
      } catch (e) {
        this.$log(e.message)
        this.$emit('mutate-error')
      }
    },
    async fetchMorePaperList () {
      if (this.sortByData != null) {
        let res = await this.$apolloQuery(LoadPaperListQuery, {
          currentUserId: this.currentUserId,
          usergroupId: this.usergroupId,
          first: this.loadPaperBatchSize,
          after: this.endCursor,
          sortBy: this.sortByData.sortType,
          sortOrder: this.sortByData.sortOrder
        })
        let paperEdges = res.data.usergroupPaperEdges
        let papers = paperEdges.map(x => {
          x.paper.selected = false
          x.paper.isDeleted = false
          x.paper.numQuestion =
            x.paper.cache?.stats?.numQuestion ?? x.paper.questionEdgeCount
          return x.paper
        })
        if (papers.length < this.loadPaperBatchSize) {
          this.endCursor = null
          this.infiniteState.complete()
        } else {
          if (paperEdges.length > 0) {
            this.endCursor = paperEdges[paperEdges.length - 1].id
          }
          this.infiniteState.loaded()
        }
        return papers
      }
    },
    clearSelected () {
      this.papers.forEach(x => {
        x.selected = false
      })
      this.$refs['paper-list'].clearSelected()
    }
  }
}
</script>
<style src="" />
