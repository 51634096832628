<template>
  <div class="recursive-homework-col">
    <RecursiveList
      :autohomeworkEdges="autohomeworkEdges"
      :readOnly="readOnly"
      @edit-recursive="$emit('edit-recursive', $event)"
      @delete-recursive="$emit('delete-recursive', $event)"
    />
    <LocalLoadingLayer v-if="loading" />
  </div>
</template>
<script>
import config from '@/config.json'
import LocalLoadingLayer from '@/components/LocalLoadingLayer.vue'
import LoadRecursiveHomeworkListQuery from './LoadRecursiveHomeworkList.graphql'
import RecursiveList from '../../components/RecursiveList.vue'

export default {
  components: {
    RecursiveList,
    LocalLoadingLayer
  },
  props: {
    readOnly: Boolean,
    currentUserId: String,
    usergroupId: String
  },
  data () {
    return {
      autohomeworkEdges: null,
      recursiveListKey: 0,
      loading: false
    }
  },
  created () {
    this.loadRecursiveList(this.currentUserId, this.usergroupId)
  },
  computed: {},
  methods: {
    async loadRecursiveList (currentUserId, usergroupId) {
      this.loading = true
      try {
        this.autohomeworkEdges = await this.$apolloQuery(
          LoadRecursiveHomeworkListQuery,
          {
            currentUserId,
            usergroupId,
            first: config.app.maxNumAutohomework
          }
        ).then(res => res.data.usergroupAutohomeworkEdges)
        this.$emit('loaded', { numAutohomework: this.autohomeworkEdges.length })
      } catch (e) {
        this.$log(e)
        this.$emit('error')
      } finally {
        this.loading = false
      }
    },
    update () {
      this.loadRecursiveList(this.currentUserId, this.usergroupId)
    }
  }
}
</script>
<style scoped>
.recursive-homework-col {
  position: relative;
  margin-bottom: 20px;
}
</style>
