<template>
  <div class="mt-5">
    <div
      v-if="solutionData != null"
      v-for="s in solutionData"
      class="solution katex"
    >
      <p v-if="s.text != null" v-katex v-html="s.text" />
    </div>
  </div>
</template>
<script>
import VKatex from 'vue-katex-auto-render'
export default {
  directives: {
    katex: VKatex
  },
  components: {},
  props: {
    solutionEdgeKey: String
  },
  data () {
    return {
      solutionData: null
    }
  },
  computed: {},
  async created () {
    this.solutionData = await this.loadSolutionContent(this.solutionEdgeKey)
    if (this.solutionData != null) {
      this.loaded = true
    }
  },
  watch: {
    async solutionEdgeKey (newValue) {
      this.loaded = false
      this.solutionData = await this.loadSolutionContent(newValue)
      this.loaded = this.solutionData != null
    }
  },
  methods: {
    async loadSolutionContent (solutionEdgeKey) {
      return await this.$loadContent(solutionEdgeKey + '.json')
    }
  }
}
</script>
<style scoped>
.solution {
  padding-left: 20px;
}
</style>
