<template>
  <div>
    <div class="modal-section">
      <div class="modal-row">
        <div class="type-side">
          <p class="sm-title">
            {{ $t($interfaceLang, 'Type', '類型') }}
          </p>
          <SelectType v-model="selectedType" />
        </div>
        <div class="title-side">
          <p class="sm-title">
            {{ $t($interfaceLang, 'Assignment Title', '作業標題') }}
          </p>
          <input
            type="text"
            class="input-text"
            v-model="paperName"
            :placeholder="$t($interfaceLang, 'Assignment Title', '作業標題')"
          />
        </div>
      </div>
    </div>
    <div class="modal-section">
      <ToggleButton v-model="isScheduled" />
      <div class="modal-row">
        <template v-if="isScheduled && selectedType === 'homework'">
          <div class="scheduled-side">
            <p class="sm-title">
              {{ $t($interfaceLang, 'Type', '類型') }}
            </p>
            <SelectHomeworkType
              v-model="selectedHomeworkType"
              :enableRecursive="enableRecursive"
            />
            <p v-if="!enableRecursive" class="select-remark-text">
              {{ $t($interfaceLang, '* Too many recursive', '* 太多重覆作業') }}
            </p>
          </div>
          <div
            v-if="selectedHomeworkType === 'oneoff'"
            class="scheduled-right-side"
          >
            <div class="select-date-col">
              <p class="sm-title">
                {{ $t($interfaceLang, 'Release date', '發佈日期') }}
              </p>
              <HomeworkDatePicker
                v-model="homeworkReleaseDate"
                :minDate="new Date()"
              />
            </div>
            <div class="select-date-col">
              <p class="sm-title">
                {{ $t($interfaceLang, 'Due date', '遞交日期') }}
              </p>
              <HomeworkDatePicker
                v-model="homeworkDeadline"
                :minDate="minDate(new Date(), homeworkReleaseDate)"
              />
            </div>
          </div>
          <div
            v-else-if="selectedHomeworkType === 'recursive'"
            class="scheduled-right-side"
          >
            <div class="select-date-col">
              <p class="sm-title">
                {{ $t($interfaceLang, 'Start date', '開始日期') }}
              </p>
              <HomeworkDatePicker
                v-model="recursiveStartDate"
                :minDate="new Date(Date.now() + 3600 * 24 * 1000)"
              />
            </div>
            <div class="select-date-col">
              <p class="sm-title">
                {{ $t($interfaceLang, 'End date', '結束日期') }}
              </p>
              <HomeworkDatePicker
                v-model="recursiveEndDate"
                :minDate="minDate(new Date(), recursiveStartDate)"
              />
            </div>
          </div>
        </template>
      </div>
      <RecurrenceList
        v-if="
          isScheduled &&
          selectedType === 'homework' &&
          selectedHomeworkType === 'recursive'
        "
        v-model="recurrence"
      />
    </div>
    <div
      v-if="
        isScheduled &&
        selectedType === 'homework' &&
        selectedHomeworkType === 'recursive'
      "
      class="modal-section"
    >
      <div class="advanced-col">
        <p v-b-toggle.homework-recursive-setting class="advanced-collapse-btn">
          {{ $t($interfaceLang, 'Advanced Settings', '進階設定') }}
          <i class="icon-font icon-arrow_filled_down">&#xe802;</i>
          <i class="icon-font icon-arrow_filled_up">&#xe803;</i>
        </p>
        <b-collapse id="homework-recursive-setting" class="mt-2">
          <div class="modal-row2">
            <div class="schedule-repeat-col">
              <p class="sm-title">
                {{ $t($interfaceLang, 'Schedule Repeat Every', '重複安排每') }}
              </p>
              <SelectRecursiveScheduleRepeat v-model="selectedScheduleRepeat" />
              <span class="advanced-text">
                {{ $t($interfaceLang, 'Week', '星期') }}
              </span>
            </div>
            <div class="DFA-col">
              <p class="sm-title">
                {{ $t($interfaceLang, 'Assignment due after', '交功課時間') }}
              </p>
              <SelectDaysForAssignment v-model="selectedDFA" />
              <span class="advanced-text">
                {{ $t($interfaceLang, 'days', '日後') }}
              </span>
            </div>
          </div>
          <div class="modal-row2">
            <p class="sm-title">
              {{ $t($interfaceLang, 'Follow-up', 'Follow-up') }}
              <span class="remark-text">
                *{{
                  $t(
                    $interfaceLang,
                    "generated based on students' performance on recent recursive assignment",
                    '根據學生最近重覆作業的表現來生成'
                  )
                }}
              </span>
            </p>
            <div class="follow-up-col">
              <div class="follow-up-question-input">
                <InputQuestionQty
                  v-model="numFollowupQuestion"
                  :inputs="[1, 3, 5, 8, 10]"
                  :defaultInputIndex="1"
                  :advancedText="$t($interfaceLang, 'Question(s)', '題')"
                />
              </div>
              <div class="follow-up-recent-auto-input">
                <InputQuestionQty
                  v-model="numAutohomeworkPerFollowup"
                  :inputs="[1, 2, 3, 4, 5]"
                  :defaultInputIndex="2"
                  :behindText="$t($interfaceLang, 'Every', '每')"
                  :advancedText="
                    $t($interfaceLang, 'Recursive assignment(s)', '份重覆作業')
                  "
                />
              </div>
              <span class="recurrence-remark" v-if="nextAssignmentDate != null">
                {{
                  $t(
                    $interfaceLang,
                    'First release of recursive assignment',
                    '第一份自動作業日期'
                  )
                }}:
                {{ $displayDate(nextAssignmentDate) }}
                ({{
                  $t($interfaceLang, weekdayNamesEn, weekdayNamesZh)[
                    nextAssignmentDate.getDay()
                  ]
                }})
              </span>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
    <div class="modal-section section-row align-right">
      <button
        id="btn-continue"
        class="modal-btn right-item"
        @click="$emit('next', getData())"
      >
        <i class="icon-font icon-arrow_right">&#xe805;</i>
        {{ $t($interfaceLang, 'Continue', '下一步') }}
      </button>
    </div>
  </div>
</template>
<script>import { BCollapse, VBToggle } from 'bootstrap-vue'

import SelectType from './SelectType.vue'
import ToggleButton from './ToggleButton.vue'
import SelectHomeworkType from './SelectHomeworkType.vue'
import HomeworkDatePicker from '../../components/HomeworkDatePicker.vue'
import RecurrenceList from '../../components/RecurrenceList.vue'
import SelectRecursiveScheduleRepeat from '../../components/SelectRecursiveScheduleRepeat.vue'
import SelectDaysForAssignment from '../../components/SelectDaysForAssignment.vue'
import InputQuestionQty from '../../components/InputQuestionQty.vue'

export default {
  components: {
    SelectType,
    ToggleButton,
    SelectHomeworkType,
    HomeworkDatePicker,
    RecurrenceList,
    SelectRecursiveScheduleRepeat,
    SelectDaysForAssignment,
    InputQuestionQty,
    BCollapse
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    enableRecursive: Boolean
  },
  data () {
    return {
      selectedType: 'homework',
      paperName: null,
      isScheduled: false,
      selectedHomeworkType: 'oneoff',
      homeworkReleaseDate: new Date(),
      homeworkDeadline: new Date(Date.now() + 3600 * 24 * 1000),
      recursiveStartDate: new Date(Date.now() + 3600 * 24 * 1000),
      recursiveEndDate: new Date(Date.now() + 7 * 3600 * 24 * 1000),
      recurrence: new Array(7).fill(true),
      selectedScheduleRepeat: 'one_week',
      selectedDFA: '1',
      numFollowupQuestion: 3,
      numAutohomeworkPerFollowup: 3,
      nextAssignmentDate: new Date(),
      weekdayNamesEn: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      weekdayNamesZh: ['日', '一', '二', '三', '四', '五', '六']
    }
  },
  computed: {},
  methods: {
    minDate (d1, d2) {
      return Number(d1) < Number(d2) ? d2 : d1
    },
    getData () {
      return {
        selectedType: this.selectedType,
        paperName: this.paperName,
        isScheduled: this.isScheduled,
        selectedHomeworkType: this.selectedHomeworkType,
        homeworkReleaseDate: this.homeworkReleaseDate,
        homeworkDeadline: this.homeworkDeadline,
        recursiveStartDate: this.recursiveStartDate,
        recursiveEndDate: this.recursiveEndDate,
        recurrence: this.recurrence,
        selectedScheduleRepeat: this.selectedScheduleRepeat,
        selectedDFA: this.selectedDFA,
        numFollowupQuestion: this.numFollowupQuestion,
        numAutohomeworkPerFollowup: this.numAutohomeworkPerFollowup,
        nextAssignmentDate: this.nextAssignmentDate
      }
    }
  }
}
</script>

<style src="@/components/teacher/modal/modal.css" scoped></style>
<style scoped>
.topic-checkbox {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
}

.subtopic-checkbox {
  font-size: 14px;
  font-weight: normal;
  color: #1c2d60;
}

.align-right button {
  margin-left: 10px;
  display: flex !important;
  align-items: center;
}

.modal-row {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.modal-row2 {
  position: relative;
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.advanced-collapse-btn {
  font-size: 14px;
  font-weight: normal;
  color: #007fca;
  cursor: pointer;
}

.advanced-collapse-btn.icon-font {
  font-size: 12px;
}

[aria-expanded='false'] .icon-arrow_filled_down {
  display: inline;
}
[aria-expanded='true'] .icon-arrow_filled_down {
  display: none;
}

[aria-expanded='false'] .icon-arrow_filled_up {
  display: none;
}
[aria-expanded='true'] .icon-arrow_filled_up {
  display: inline;
}

.scheduled-side,
.type-side {
  position: relative;
  width: 20%;
}

.title-side {
  position: relative;
  width: 78%;
}

.normal-text {
  font-size: 14px;
  font-weight: normal;
  color: #1c2d60;
  margin: 0px;
}

.date-box {
  font-size: 14px;
  color: #1c2d60;
  border-radius: 6px;
  background-color: #f1f3f1;
  border: none;
  padding: 3px 10px;
  min-height: 26px;
  min-width: 110px;
  cursor: pointer;
}

.date-box i {
  color: #1c2d60;
  font-size: 14px;
  padding-right: 5px;
}

.scheduled-right-side {
  position: relative;
  display: flex;
  width: 75%;
  justify-content: flex-start;
}

.select-date-col {
  position: relative;
  padding-right: 10px;
}

.item-check label:before {
  border: 1.5px solid #969696;
}

.item-check input[type='checkbox']:checked + label:before {
  background-color: #ff881b;
  border-color: #ff881b;
}

.schedule-repeat-col {
  position: relative;
  width: 40%;
}

.DFA-col {
  position: relative;
  width: 50%;
}

.follow-up-col {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.remark-text {
  font-size: 12px;
  color: #ff6d6d;
  padding-left: 5px;
}

#homework-recursive-setting .select-option.input-text {
  width: 50px;
}

#homework-recursive-setting .advanced-text {
  font-size: 14px;
  font-weight: normal;
  color: #1c2d60;
  padding-left: 10px;
}

.follow-up-question-input {
  position: relative;
  width: 40%;
}

.follow-up-recent-auto-input {
  position: relative;
  width: 50%;
}

.recurrence-remark {
  position: relative;
  width: 100%;
  font-size: 12px;
  color: #42c9f1;
  margin-top: 10px;
  margin-bottom: 0px;
}

.test-date-col {
  position: relative;
  width: 40%;
}

.test-date-col .date-box-col {
  position: relative;
  width: 120px;
  display: block;
}

.start-time-col,
.end-time-col {
  position: relative;
  width: 25%;
}

.start-time-col .select-option.input-text,
.end-time-col .select-option.input-text {
  width: 80px;
}

#practice-setting {
  position: relative;
  display: flex;
}

#practice-setting .scheduled-side {
  width: 25%;
}

#practice-setting .scheduled-right-side {
  flex-direction: column;
}

.reveal-solution-option {
  position: relative;
  display: flex;
  width: 100%;
  flex-basis: 100%;
  align-items: center;
  margin-bottom: 10px;
}

.reveal-solution-option .item-radio-label span {
  color: #007fca;
}

.reveal-solution-option .test-date-col {
  width: 30%;
  margin-left: 20px;
}
/* .reveal-solution-option .start-time-col {
} */

.topics-collapse-btn {
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-size: 14px;
  color: #1c2d60;
  border-radius: 6px;
  background-color: #f1f3f1;
  border: none;
  padding: 3px 10px;
  min-height: 26px;
  cursor: pointer;
}

.topics-collapse-btn .icon-font {
  margin-left: auto;
}

.topic-item {
  position: relative;
  display: flex;
  align-items: center;
}

.subtopics-collapse-btn {
  font-size: 14px;
  color: #1c2d60;
  margin-left: 5px;
  cursor: pointer;
}

.subtopics {
  margin-bottom: 10px;
}

.topic-col {
  position: relative;
  max-height: 400px;
  overflow-y: scroll;
}

.topic-setting-col {
  position: relative;
  display: flex;
  align-items: center;
}

.topic-setting-text {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
  margin-right: 15px;
}

.diff-input {
  position: relative;
  display: flex;
  align-items: center;
}

.diff-star {
  font-size: 18px;
  margin-right: 5px;
  color: #1c2d60;
  cursor: pointer;
}

.no-of-question-input {
  position: relative;
  width: 40%;
}

.diff-input {
  position: relative;
  width: 40%;
}

.select-remark-text {
  position: relative;
  color: #ff6d6d;
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 12px;
}

.modal-btn {
  z-index: 0 !important;
}
</style>
