<template>
  <div>
    <DuplicatePaper v-if="name == 'duplicate'" :paperQuestionEdges="[{ question: {} }]" />
    <DuplicatePaperAdapter v-if="name == 'duplicate-adapter'" :currentUserId="'490'" :paperId="'183'" />
    <PaperRow v-if="name == 'paper-row'" paperType="homework" :isLocked="true" :numQuestion="10" name="test"
      :startTime="'' + Date.now()" />
    <SelectUsergroup v-if="name == 'select-usergroup'"
      :usergroups="[{ id: 'test', name: 'test-class', type: 'class' }, { id: 'test2', name: 'test-class-2', type: 'class' }, { id: 'test3', name: 'test-group', type: 'group' }, { id: 'test4', name: 'test-group-2', type: 'group' }]" />
  </div>
</template>
<script>
import DuplicatePaper from '@/pages/teacher/manage-assignment/modal/duplicate-paper/DuplicatePaper.vue'
import DuplicatePaperAdapter from '@/pages/teacher/manage-assignment/modal/duplicate-paper/DuplicatePaperAdapter.vue'
import PaperRow from '@/pages/teacher/manage-assignment/modal/duplicate-paper/PaperRow.vue'
import SelectUsergroup from '@/pages/teacher/manage-assignment/modal/duplicate-paper/SelectUsergroup.vue'
export default {
  components: {
    DuplicatePaper,
    DuplicatePaperAdapter,
    PaperRow,
    SelectUsergroup
  },
  data() {
    return {
      name: this.$route.query?.name
    }
  }
}
</script>
