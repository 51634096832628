<template>
  <div>
    <SetFavQgroup
      ref="save-fav-qgroup"
      :currentUserId="currentUserId"
      :usergroupId="usergroupId"
    />
    <FavQuestion
      :question="question"
      :permutation="permutation"
      :isQgroupFav="isQgroupFav"
      :displayAnswerIndex="displayAnswerIndex"
      :correctAnswerIndex="correctAnswerIndex"
      :solutionEdgeKey="solutionEdgeKey"
      @draw="$emit('draw', { ...$event, permutation })"
      @set-fav="setIsFav($event)"
      @template-mutation="templateMutation($event)"
      @choose-ans="$emit('choose-ans', { ...$event, permutation, question })"
    />
  </div>
</template>
<script>
import FavQuestion from './FavQuestion.vue'
import SetFavQgroup from '@/components/set-fav-qgroup/SetFavQgroup.vue'
import templateMutationQuery from './TemplateMutation.graphql'
export default {
  components: {
    FavQuestion,
    SetFavQgroup
  },
  props: {
    currentUserId: String,
    usergroupId: String,
    question: Object,
    displayAnswerIndex: Number,
    correctAnswerIndex: Number,
    solutionEdgeKey: String
  },
  data () {
    return {
      permutation: this.shuffleArray([0, 1, 2, 3]),
      isQgroupFav: true,
      showSolution: false
    }
  },
  computed: {},
  methods: {
    shuffleArray (x, random = Math.random) {
      for (let i = x.length - 1; i > 0; i--) {
        let j = Math.floor(random() * (i + 1))
        let tmp = x[i]
        x[i] = x[j]
        x[j] = tmp
      }
      return x
    },
    async setIsFav ({ question, isFav }) {
      await this.$refs['save-fav-qgroup'].save(
        question?.displayQuestion?.qgroup?.id,
        isFav
      )
    },
    async templateMutation ({ question }) {
      let questionId = question?.displayQuestion?.id
      if (questionId != null) {
        let question = await this.$apolloQuery(templateMutationQuery, {
          currentUserId: this.currentUserId,
          id: questionId,
          lang: this.$interfaceLang
        }).then(res => res.data?.question?.templateMutation)
        if (question != null) {
          this.$emit('done-template-mutation', { question })
        }
      }
    }
  }
}
</script>
<style src="" />
