<template>
  <DatePicker
    :locale="$t($interfaceLang, 'en', 'zh')"
    :firstDayOfWeek="0"
    :timezone="$appStore.appInfo.timezoneText"
    :modelConfig="{
      type: 'string',
      start: {
        timeAdjust: '00:00:00'
      }
    }"
    :min-date="new Date(Date.now())"
    :attributes="[
      {
        key: 'weekdays',
        highlight: {
          contentStyle: {
            color: '#f45e5c'
          }
        },
        dates: {
          weekdays: [1]
        }
      },
      {
        highlight: {
          color: 'yellow',
          fillMode: 'outline'
        },
        dates: new Date()
      }
    ]"
    class="inline-block date-box-col"
    v-model="startTime"
    @input="$emit('input', startTime == null ? null : '' + Number(startTime))"
  >
    <template v-slot="{ inputValue, togglePopover }">
      <div class="date-box" @click="togglePopover()">
        <i class="icon-title icon-font icon-calendar">&#xe823;</i>
        <span class="date-text">{{ $displayDate(new Date(startTime)) }}</span>
      </div>
    </template>
  </DatePicker>
</template>
<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
export default {
  components: {
    DatePicker
  },
  props: {
    value: String
  },
  data () {
    return {
      startTime: this.toDate(this.value)
    }
  },
  watch: {
    value (newValue) {
      this.startTime = this.toDate(newValue)
    }
  },
  methods: {
    toDate (x) {
      return x == null ? null : new Date(Number(x))
    }
  }
}
</script>
<style src="" />
