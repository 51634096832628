import { render, staticRenderFns } from "./FavQuestion.vue?vue&type=template&id=1ab42c74&scoped=true&"
import script from "./FavQuestion.vue?vue&type=script&lang=js&"
export * from "./FavQuestion.vue?vue&type=script&lang=js&"
import style0 from "./FavQuestion.vue?vue&type=style&index=0&id=1ab42c74&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ab42c74",
  null
  
)

export default component.exports