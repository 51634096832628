<template>
  <div class="item-row" :class="progressType">
    <div @click="$emit('goto', { paper })" class="click-item">
      <div class="item-name item-hightlight-text">
        <div class="type-new-label" v-if="isNew && !isExpired">
          {{ $t($interfaceLang, 'New', '新功課') }}
        </div>
        <span>{{ displayName(this.paper?.name) }}</span>
      </div>
      <div class="item-answered-questions">
        <p class="sm-title">
          {{ $t($interfaceLang, 'Answered questions', '已答問題') }}
        </p>
        <Progress :part="numSubmission" :total="numQuestion" />
      </div>
      <DaysToGo :expiryTime="paper.expiryTime" />
    </div>
  </div>
</template>
<script>
import config from '@/config.json'
import Progress from './Progress.vue'
import DaysToGo from './DaysToGo.vue'
export default {
  components: { Progress, DaysToGo },
  props: {
    paper: Object,
    numQuestion: Number,
    numSubmission: Number
  },
  data () {
    return {}
  },
  computed: {
    isExpired () {
      let expiryTime = this.paper.expiryTime
      return expiryTime == null
        ? false
        : new Date(Number(expiryTime)) < Date.now()
    },
    isNew () {
      return (this.numSubmission ?? 0) == 0
    },
    progressType () {
      return this.isExpired
        ? 'type-overdue'
        : this.isNew
        ? 'type-new'
        : 'type-progress'
    }
  },
  methods: {
    displayName (name) {
      if (name == null || name == '') {
        return config.app.defaultAssignmentName[this.lang]
      }
      return name
    }
  }
}
</script>
<style scoped>
.click-item {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
}

.click-item:hover,
.click-item:focus {
  text-decoration: none;
}

.click-item:hover .item-name,
.click-item:focus .item-name {
  color: #0c89d3;
}

.type-new-label {
  position: relative;
  padding: 2px 5px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: #f5a53d;
  border-radius: 4px;
  margin-right: 5px;
}

.type-new {
  border-left: 5px solid #ff881b;
  background-color: #fff5cb !important;
}

.type-progress {
  border-left: 5px solid #42c9f1;
  background-color: #d9f4ff !important;
}

.type-overdue {
  border-left: 5px solid #f65a58;
  background-color: #ffe7e8 !important;
}

.icon-font {
  color: #1c2d60;
  font-size: 14px;
  margin-left: auto;
  width: 2%;
}

.item-row {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
}

.item-row p {
  margin: 0px;
}

.sm-title {
  font-size: 11px;
  color: #1c2d60;
}

.sm-title2 {
  font-size: 11px;
  color: #f65a58;
}

.item-hightlight-text {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
}

.item-name {
  width: 45%;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.item-answered-questions {
  width: 25%;
  padding: 0px 25px;
  border-left: 1px solid #adadad;
}

.item-date {
  width: 17%;
  padding: 0px 10px;
  border-right: 1px solid #adadad;
}

::v-deep .progress-col {
  position: relative;
  display: flex;
  align-items: center;
}

::v-deep .progress {
  height: 0.5rem;
  width: 78%;
  background-color: #d0d0d0;
}

::v-deep .progress-bar {
  background-color: #ff881b;
}

::v-deep .progress-col .item-hightlight-text {
  margin-left: 5px;
}

@media only screen and (max-width: 767px) {
  .item-name {
    width: 100%;
    flex: 0 1 100%;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .item-answered-questions {
    width: 50%;
    padding: 0px;
    border: none;
  }

  .item-date {
    width: 48%;
    padding: 0px;
    padding-left: 20px;
    border: none;
  }
}
</style>
