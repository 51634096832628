<template>
  <div class="calendar-container">
    <Calendar
      is-expanded
      :attributes="attributes"
      :locale="$t($interfaceLang, 'en', 'zh')"
      :firstDayOfWeek="0"
      :timezone="timezone"
      @update:to-page="
        updateThisMonth($event)
        $emit('page-change', { thisMonth, nextMonth: nextMonth() })
      "
    />
    <LocalLoadingLayer v-if="loading" />
  </div>
</template>

<script>
import Calendar from 'v-calendar/lib/components/calendar.umd'
import LocalLoadingLayer from '@/components/LocalLoadingLayer.vue'

export default {
  components: {
    Calendar,
    LocalLoadingLayer
  },
  props: {
    papers: Array,
    loading: Boolean
  },
  data () {
    var defaultAttributes = [
      {
        key: 'weekdays',
        highlight: {
          contentStyle: {
            color: '#f45e5c'
          }
        },
        dates: {
          weekdays: [1, 7]
        }
      },
      {
        highlight: {
          color: 'yellow',
          fillMode: 'outline'
        },
        dates: new Date()
      }
    ]
    return {
      timezone: this.$appStore.appInfo.timezoneText,
      thisMonth: null,
      defaultAttributes,
      attributes: [...defaultAttributes]
    }
  },
  computed: {},
  created () {
    var currentYear = new Date(Date.now()).getFullYear()
    var currentMonth = new Date(Date.now()).getMonth() + 1
    this.thisMonth = Number(new Date(`${currentYear}-${currentMonth} GMT+8`))
    this.updateAttributes(this.papers)
  },
  watch: {
    papers (newValue) {
      this.updateAttributes(newValue)
    }
  },
  methods: {
    nextMonth () {
      return this.thisMonth + 32 * 3600 * 24 * 1000
    },
    updateThisMonth (e) {
      this.thisMonth = Number(new Date(`${e.year}-${e.month} GMT+8`))
    },
    updateAttributes (papers) {
      this.attributes = [
        ...this.defaultAttributes,
        ...papers
          //.filter(x => x.isDeleted == false)
          .map(x => ({
            key: 'calendar-' + x.id,
            highlight: {
              contentStyle: {
                color: 'white',
                backgroundColor: '#42c9f1'
              }
            },
            popover: {
              label: x.name ?? this.$t(this.$interfaceLang, 'Exercise', '練習'),
              visibility: 'hover',
              hideIndicator: true
            },
            dates: new Date(Number(x.expiryTime) - 3600 * 24 * 1000)
          }))
      ]
    }
  }
}
</script>
<style src="@/assets/css/teacherCalendar.css" scoped></style>
<style scoped>
.loading-gif {
  width: 50px;
  height: 50px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9996;
}
.calendar-container {
  position: relative;
}
</style>
