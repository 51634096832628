var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.name == 'teacher-edit-random-add')?_c('TeacherEditLibraryRandomAddModal',{attrs:{"topics":_vm.testTopics.topics}}):_vm._e(),(_vm.name == 'teacher-load-topics')?_c('TeacherLoadTopics',{attrs:{"currentUserId":'490',"tschemaId":'122',"usergroupId":'50'}}):_vm._e(),(_vm.name == 'teacher-add-assignment-modal')?_c('TeacherAddAssignmentModal',{attrs:{"topics":_vm.testTopics}}):_vm._e(),(_vm.name == 'teacher-add-assignment-adapter')?_c('TeacherAddAssignmentAdapter',{attrs:{"currentUserId":_vm.$appStore.userInfo?.userid,"usergroupId":_vm.$appStore.userInfo?.usergroup?.id,"topics":_vm.testTopics}}):_vm._e(),(_vm.name == 'teacher-recursive-list')?_c('TeacherRecursiveList',{attrs:{"autohomeworkEdges":[{ autohomework: { paperName: 'Test' } }]}}):_vm._e(),(_vm.name == 'teacher-recursive-list-adapter')?_c('TeacherRecursiveListAdapter',{attrs:{"currentUserId":_vm.$appStore.userInfo?.userid,"usergroupId":_vm.$appStore.userInfo?.usergroup?.id}}):_vm._e(),(_vm.name == 'teacher-add-assignment-topic-list')?_c('TeacherAddAssignmentTopicList',{attrs:{"topicData":_vm.testTopics}}):_vm._e(),(_vm.name == 'teacher-delete-recursive')?_c('TeacherDeleteResursiveModal',{attrs:{"autohomework":{ id: 'test', paperName: 'Test' }}}):_vm._e(),(_vm.name == 'teacher-delete-recursive-adapter')?_c('TeacherDeleteResursiveAdapter',{attrs:{"currentUserId":'490',"autohomework":{ id: 'test', paperName: 'Test' }}}):_vm._e(),(_vm.name == 'teacher-edit-recursive')?_c('TeacherEditRecursiveModal',{attrs:{"autohomework":{
      paperName: 'Test',
      scheduleWeekdays: [true, true, true, true, true, true, true],
      selectedScheduleRepeat: 5,
      scheduleRepeatFrequency: 'two_week',
      homeworkDuration: 3
    }}}):_vm._e(),(_vm.name == 'teacher-edit-recursive-adapter')?_c('TeacherEditRecursiveAdapter',{attrs:{"currentUserId":'582',"autohomework":{
      id: '4',
      paperName: 'Test',
      scheduleWeekdays: [true, true, true, true, true, true, true],
      selectedScheduleRepeat: 5,
      scheduleRepeatFrequency: 'two_week',
      homeworkDuration: 3
    }}}):_vm._e(),(_vm.name == 'teacher-calendar-adapter')?_c('TeacherCalendarAdapter',{attrs:{"currentUserId":'490',"usergroupId":'54'}}):_vm._e(),(_vm.name == 'teacher-manage-paper-list')?_c('TeacherManagePaperList',{attrs:{"papers":[
      {
        id: 'test',
        name: 'Test',
        type: 'homework',
        isDynamic: false,
        startTime: '' + Date.now(),
        expiryTime: '' + Date.now()
      },
      {
        id: 'test2',
        name: 'Test2',
        type: 'recursive',
        isDynamic: false,
        startTime: '' + Date.now(),
        expiryTime: '' + Date.now()
      },
      {
        id: 'test3',
        name: 'Test3',
        type: 'recursive_followup',
        isDynamic: false,
        startTime: '' + Date.now(),
        expiryTime: '' + Date.now()
      }
    ]}}):_vm._e(),(_vm.name == 'teacher-manage-paper-adapter')?_c('TeacherManagePaperAdapter',{attrs:{"currentUserId":'490',"usergroupId":'50',"readOnly":false}}):_vm._e(),(_vm.name == 'teacher-manage-paper-row')?_c('TeacherManagePaperRow',{attrs:{"paper":{
      id: 'test',
      name: 'Test',
      type: 'homework',
      isDynamic: false,
      startTime: '' + Date.now(),
      expiryTime: '' + Date.now()
    }}}):_vm._e(),(_vm.name == 'teacher-manage-delete-paper-modal')?_c('TeacherManageDeletePaperModal',{attrs:{"paper":{
      id: 'test',
      name: 'Test',
      type: 'homework',
      isDynamic: false,
      startTime: '' + Date.now(),
      expiryTime: '' + Date.now()
    }}}):_vm._e(),(_vm.name == 'teacher-manage-delete-paper-modal')?_c('TeacherManageDeletePaperAdapter',{attrs:{"currentUserId":'490',"paper":{
      id: 'test',
      name: 'Test',
      type: 'homework',
      isDynamic: false,
      startTime: '' + Date.now(),
      expiryTime: '' + Date.now()
    }}}):_vm._e(),(_vm.name == 'teacher-manage-bulk-select')?_c('TeacherManageBulkSelect',{attrs:{"papers":[
      {
        id: 'test',
        selected: true
      },
      {
        id: 'test2',
        selected: true
      },
      {
        id: 'test3',
        selected: false
      }
    ]}}):_vm._e(),(_vm.name == 'teacher-manage-bulk-delete-modal')?_c('TeacherManageDeletePaperBulkModal',{attrs:{"papers":[
      { id: '7', name: 'Test', selected: true },
      { id: '8', selected: true },
      { id: '9', selected: false }
    ]}}):_vm._e(),(_vm.name == 'teacher-manage-bulk-delete-adapter')?_c('TeacherManageDeletePaperBulkAdapter',{attrs:{"currentUserId":'490',"papers":[
      { id: '7', name: 'Test', selected: true },
      { id: '8', selected: true },
      { id: '9', selected: false }
    ]}}):_vm._e(),(_vm.name == 'teacher-manage-edit-paper-info-modal')?_c('TeacherManageEditPaperInfoModal',{attrs:{"paperName":'Test',"startTime":'1666385779690',"expiryTime":'1666585779690'}}):_vm._e(),(_vm.name == 'teacher-manage-edit-paper-info-adapter')?_c('TeacherManageEditPaperInfoAdapter',{attrs:{"currentUserId":'490',"paper":{
      id: '7',
      name: 'Test',
      type: 'homework',
      isDynamic: false,
      startTime: '' + Date.now(),
      expiryTime: '' + Date.now()
    }}}):_vm._e(),(_vm.name == 'teacher-edit-nav-row')?_c('TeacherEditNavRow',{attrs:{"numQuestion":10,"topics":_vm.testTopics.topics}}):_vm._e(),(_vm.name == 'teacher-edit-paper-row')?_c('TeacherEditPaperRow',{attrs:{"isPreview":true,"paper":{
      id: '7',
      name: 'Test',
      type: 'homework',
      isDynamic: false,
      startTime: '' + Date.now(),
      expiryTime: '' + (Date.now() + 3600 * 24 * 1000)
    }}}):_vm._e(),(_vm.name == 'teacher-edit-question-row')?_c('TeacherEditQuestionRow',{attrs:{"indexNo":0,"question":{
      isRetracted: false,
      hasInheritquestion: true,
      displayQuestion: {
        id: '22188',
        type: 'mc',
        sampleEdgeKey: '361b692d770811e3f49d0d1b8ecde4c4',
        sampleEdgePermutation: [2, 0, 3, 1],
        solutionEdgeKey: '89a811bbc1a7823612363569a25b9ac8',
        qgroup: { id: '152', myUserEdge: null, __typename: 'Qgroup' },
        __typename: 'Question'
      },
      __typename: 'Question'
    },"readOnly":false,"isNewQuestion":false,"isReordering":false}}):_vm._e(),(_vm.name == 'teacher-edit-question-list')?_c('TeacherEditQuestionList',{attrs:{"questions":[
      {
        isRetracted: false,
        hasInheritquestion: true,
        displayQuestion: {
          id: '22188',
          type: 'mc',
          sampleEdgeKey: '361b692d770811e3f49d0d1b8ecde4c4',
          sampleEdgePermutation: [2, 0, 3, 1],
          solutionEdgeKey: '89a811bbc1a7823612363569a25b9ac8',
          qgroup: { id: '152', myUserEdge: null, __typename: 'Qgroup' },
          __typename: 'Question'
        },
        __typename: 'Question'
      }
    ]}}):_vm._e(),(_vm.name == 'teacher-edit-question-list-adapter')?_c('TeacherEditQuestionListAdapter',{attrs:{"currentUserId":'490',"questions":[
      {
        isRetracted: false,
        hasInheritquestion: true,
        displayQuestion: {
          id: '22209',
          type: 'mc',
          sampleEdgeKey: '5fc10dcf7d857bb1de0f2a70507da82c',
          sampleEdgePermutation: [2, 0, 3, 1],
          solutionEdgeKey: 'e3db972482e29b1202b3fe2a8b031056',
          qgroup: { id: '153', myUserEdge: null, __typename: 'Qgroup' },
          __typename: 'Question'
        },
        __typename: 'Question'
      },
      {
        isRetracted: false,
        hasInheritquestion: true,
        displayQuestion: {
          id: '22188',
          type: 'mc',
          sampleEdgeKey: '361b692d770811e3f49d0d1b8ecde4c4',
          sampleEdgePermutation: [2, 0, 3, 1],
          solutionEdgeKey: '89a811bbc1a7823612363569a25b9ac8',
          qgroup: { id: '152', myUserEdge: null, __typename: 'Qgroup' },
          __typename: 'Question'
        },
        __typename: 'Question'
      }
    ]}}):_vm._e(),(_vm.name == 'teacher-edit-solution-modal')?_c('TeacherEditSolutionModal',{attrs:{"question":{
      isRetracted: false,
      hasInheritquestion: true,
      displayQuestion: {
        id: '22188',
        type: 'mc',
        sampleEdgeKey: '361b692d770811e3f49d0d1b8ecde4c4',
        sampleEdgePermutation: [2, 0, 3, 1],
        solutionEdgeKey: '89a811bbc1a7823612363569a25b9ac8',
        qgroup: { id: '152', myUserEdge: null, __typename: 'Qgroup' },
        __typename: 'Question'
      },
      __typename: 'Question'
    }}}):_vm._e(),(_vm.name == 'teacher-edit-duplicate-adapter')?_c('TeacherEditDuplicateAdapter',{attrs:{"currentUserId":'490',"lang":'en_hk',"question":{
      isRetracted: false,
      hasInheritquestion: true,
      displayQuestion: {
        id: '22188',
        type: 'mc',
        sampleEdgeKey: '361b692d770811e3f49d0d1b8ecde4c4',
        sampleEdgePermutation: [2, 0, 3, 1],
        solutionEdgeKey: '89a811bbc1a7823612363569a25b9ac8',
        qgroup: { id: '152', myUserEdge: null, __typename: 'Qgroup' },
        __typename: 'Question'
      },
      __typename: 'Question'
    },"questionList":[]}}):_vm._e(),(_vm.name == 'teacher-edit-library-row')?_c('TeacherEditLibraryRow',{attrs:{"topics":_vm.testTopics.topics,"yearTerm":[4, 1]}}):_vm._e(),(_vm.name == 'teacher-edit-library')?_c('TeacherEditLibrary',{attrs:{"topics":_vm.testTopics.topics,"yearTerm":[4, 1],"questions":[
      {
        isRetracted: false,
        hasInheritquestion: true,
        displayQuestion: {
          id: '22209',
          type: 'mc',
          sampleEdgeKey: '5fc10dcf7d857bb1de0f2a70507da82c',
          sampleEdgePermutation: [2, 0, 3, 1],
          solutionEdgeKey: 'e3db972482e29b1202b3fe2a8b031056',
          qgroup: { id: '153', myUserEdge: null, __typename: 'Qgroup' },
          __typename: 'Question'
        },
        __typename: 'Question'
      },
      {
        isRetracted: false,
        hasInheritquestion: true,
        displayQuestion: {
          id: '22188',
          type: 'mc',
          sampleEdgeKey: '361b692d770811e3f49d0d1b8ecde4c4',
          sampleEdgePermutation: [2, 0, 3, 1],
          solutionEdgeKey: '89a811bbc1a7823612363569a25b9ac8',
          qgroup: { id: '152', myUserEdge: null, __typename: 'Qgroup' },
          __typename: 'Question'
        },
        __typename: 'Question'
      }
    ]}}):_vm._e(),(_vm.name == 'teacher-edit-library-adapter')?_c('TeacherEditLibraryAdapter',{attrs:{"currentUserId":'490',"tschemaId":'122',"topics":_vm.testTopics.topics,"yearTerm":[4, 1],"paperQuestions":[],"questions":[
      {
        isRetracted: false,
        hasInheritquestion: true,
        displayQuestion: {
          id: '22209',
          type: 'mc',
          sampleEdgeKey: '5fc10dcf7d857bb1de0f2a70507da82c',
          sampleEdgePermutation: [2, 0, 3, 1],
          solutionEdgeKey: 'e3db972482e29b1202b3fe2a8b031056',
          qgroup: { id: '153', myUserEdge: null, __typename: 'Qgroup' },
          __typename: 'Question'
        },
        __typename: 'Question'
      },
      {
        isRetracted: false,
        hasInheritquestion: true,
        displayQuestion: {
          id: '22188',
          type: 'mc',
          sampleEdgeKey: '361b692d770811e3f49d0d1b8ecde4c4',
          sampleEdgePermutation: [2, 0, 3, 1],
          solutionEdgeKey: '89a811bbc1a7823612363569a25b9ac8',
          qgroup: { id: '152', myUserEdge: null, __typename: 'Qgroup' },
          __typename: 'Question'
        },
        __typename: 'Question'
      }
    ]}}):_vm._e(),(_vm.name == 'teacher-edit-library-question-row')?_c('TeacherEditLibraryQuestionRow',{attrs:{"question":{
      isRetracted: false,
      hasInheritquestion: true,
      displayQuestion: {
        id: '22188',
        type: 'mc',
        sampleEdgeKey: '361b692d770811e3f49d0d1b8ecde4c4',
        sampleEdgePermutation: [2, 0, 3, 1],
        solutionEdgeKey: '89a811bbc1a7823612363569a25b9ac8',
        qgroup: { id: '152', myUserEdge: null, __typename: 'Qgroup' },
        __typename: 'Question'
      },
      __typename: 'Question'
    }}}):_vm._e(),(_vm.name == 'teacher-edit-similar')?_c('TeacherEditSimilarQuestions',{attrs:{"questions":[
      {
        isRetracted: false,
        hasInheritquestion: true,
        displayQuestion: {
          id: '22188',
          type: 'mc',
          sampleEdgeKey: '361b692d770811e3f49d0d1b8ecde4c4',
          sampleEdgePermutation: [2, 0, 3, 1],
          solutionEdgeKey: '89a811bbc1a7823612363569a25b9ac8',
          qgroup: { id: '152', myUserEdge: null, __typename: 'Qgroup' },
          __typename: 'Question'
        },
        __typename: 'Question'
      }
    ]}}):_vm._e(),(_vm.name == 'teacher-edit-random-add-year-term')?_c('TeacherEditLibraryRandomAddYearTermDropdown',{attrs:{"topics":_vm.testTopics.topics}}):_vm._e(),(_vm.name == 'student-old-question-view')?_c('StudentOldQuestionView'):_vm._e(),(_vm.name == 'student-question-view')?_c('StudentQuestionView',{attrs:{"permutation":[0, 1, 2, 3],"question":{
      displayQuestion: {
        id: '1',
        type: 'mc',
        sampleEdgeKey:
          _vm.$route.query.sample ?? '840df09f17301e104f64d95b186144f0'
      }
    },"correctAnswerIndexNo":1}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }