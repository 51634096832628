<template>
  <div class="item-progress" v-if="numQuestion != null && numQuestion != 0">
    <div class="text-col">
      {{ topicName }}
      <p class="q-num">
        <template v-if="$interfaceLang == 'en_hk'">
          {{ numQuestion }} questions
        </template>
        <template v-else-if="$interfaceLang == 'zh_hk'">
          {{ numQuestion }} 條題目
        </template>
      </p>
    </div>
    <div class="progress-col">
      <div class="progress">
        <div
          class="progress-bar"
          :class="color"
          role="progressbar"
          v-bind:style="{ width: correctRate + '%' }"
          v-bind:aria-valuenow="correctRate"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
    <div class="text-col">
      <p class="correct-rate">
        <template v-if="$interfaceLang == 'en_hk'">
          Your correct rate is
          <span :class="{ wrong: correctRate <= 50 }">{{ correctRate }}%</span>
        </template>
        <template v-else-if="$interfaceLang == 'zh_hk'">
          你的答對率是
          <span :class="{ wrong: correctRate <= 50 }">{{ correctRate }}%</span>
        </template>
      </p>
      <p class="class-average">
        <template v-if="$interfaceLang == 'en_hk'">
          Class average is <span>{{ classCorrectRate }}%</span>
        </template>
        <template v-else-if="$interfaceLang == 'zh_hk'">
          班平均是 <span>{{ classCorrectRate }}%</span>
        </template>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    topicName: String,
    numQuestion: Number,
    color: String,
    correctRate: Number,
    classCorrectRate: Number
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>

<style scoped>
.item-progress {
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  clear: both;
}

.text-col {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

.title {
  font-size: 13px;
  color: #626262;
  text-decoration: none;
  float: left;
  margin: 0px;
}

.title:hover,
.title:focus {
  color: #353535;
}

.title i {
  font-size: 10px;
}

.q-num {
  font-size: 13px;
  float: right;
  margin: 0px;
}

.progress-col {
  clear: both;
  position: relative;
  display: flex;
  align-items: center;
}

.progress {
  height: 0.5rem;
  width: 100%;
  background-color: #d0d0d0;
  margin: 5px 0px;
}

.progress-text {
  width: 5%;
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
  margin-left: 5px;
}

.progress-bar.green {
  background-color: #3ccc89;
}

.progress-bar.org {
  background-color: #dadf37;
}

.progress-bar.purple {
  background-color: #a772ec;
}

.progress-bar.blue {
  background-color: #2fb1c8;
}

.progress-col .item-hightlight-text {
  margin-left: 5px;
}

.correct-rate {
  font-size: 13px;
  font-weight: 600;
  line-height: 1.15;
  color: #1c2d60;
  float: left;
}

.correct-rate span {
  color: #3ccc89;
}

.correct-rate span.wrong {
  color: red !important;
}

.class-average {
  font-size: 13px;
  font-weight: normal;
  line-height: 1.15;
  color: #626262;
  float: right;
}

.class-average span {
  font-size: 14px;
  font-weight: 600;
}
</style>
