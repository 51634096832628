<template>
  <div class="subtopic-col" :key="'subtopic-' + subtopic.id">
    <span>{{ $t($interfaceLang, subtopic.nameEn, subtopic.nameZh) }}</span>
    <div class="subtopic-setting">
      <SubtopicToggle
        v-if="!readOnly"
        v-model="subtopicAvailable"
        @input="updateToggle($event)"
      />
      <SubtopicDatePicker
        v-if="!subtopicAvailable"
        v-model="subtopic.startTime"
      />
    </div>
  </div>
</template>
<script>
import SubtopicToggle from './SubtopicToggle.vue'
import SubtopicDatePicker from './SubtopicDatePicker.vue'

export default {
  components: {
    SubtopicToggle,
    SubtopicDatePicker
  },
  props: {
    subtopic: Object,
    readOnly: Boolean
  },
  data () {
    return {
      subtopicAvailable:
        this.subtopic.startTime != null &&
        Number(this.subtopic.startTime) < Date.now()
    }
  },
  methods: {
    updateToggle (value) {
      if (value == true) {
        this.subtopic.startTime =
          '' + Math.min(this.subtopic.startTime ?? Date.now(), Date.now())
      } else {
        this.subtopic.startTime = Date.now() + 3600 * 24 * 1000
      }
    }
  }
}
</script>
<style src="../../TeachingSchedule.css" scoped />
