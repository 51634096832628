<template>
  <Calendar
    :papers="papers"
    @page-change="fetchPapers($event.thisMonth, $event.nextMonth)"
    :loading="loadingPapers"
  />
</template>
<script>
import LoadCalendarQuery from './LoadCalendar.graphql'
import Calendar from '../../components/Calendar.vue'
export default {
  components: {
    Calendar
  },
  props: {
    currentUserId: String
  },
  data () {
    return {
      papers: [],
      loadingPapers: false
    }
  },
  computed: {},
  methods: {
    async fetchPapers (thisMonth, nextMonth) {
      this.loadingPapers = true
      this.papers = await this.$graphqlUtil
        .graphqlFetchEdges(
          this.$apolloQuery,
          LoadCalendarQuery,
          {
            currentUserId: this.$appStore.userInfo.userid,
            first: 20,
            expiryFromTime: '' + thisMonth,
            expiryToTime: '' + nextMonth
          },
          x => x.paperUserEdges,
          null,
          3
        )
        .then(res => res.map(y => y.paper))

      // this.papers = await this.$apolloQuery(LoadCalendarQuery, {
      //   currentUserId: this.$appStore.userInfo.userid,
      //   first: 20,
      //   expiryFromTime: '' + thisMonth,
      //   expiryToTime: '' + nextMonth
      // }).then(res => res?.data?.paperUserEdges?.map(x => x.paper))
      this.loadingPapers = false
    }
  }
}
</script>
<style src="" />
