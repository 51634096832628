<template>
  <BarChart
    class="chart-container"
    :chart-data="dataCollection"
    :options="options"
    :chart-id="'question-time'"
  />
</template>
<script>
import BarChart from '@/components/teacher/report/ReportChartBar'
export default {
  components: {
    BarChart
  },
  props: {
    questions: Array
  },
  data () {
    return {
      dataCollection: null,
      options: null,
      hasNoData: false
    }
  },
  mounted () {
    this.renderChart()
  },
  computed: {
    labels () {
      return this.questions.map(q => 'Q' + (q.indexNo + 1))
    },
    times () {
      return this.questions.map(q => q.avgTimeSpent ?? 0)
    }
  },

  methods: {
    timeDisplay (x) {
      if (x < 60 * 1000) {
        return this.$t(
          this.$interfaceLang,
          (x / 1000).toFixed(2) + ' sec',
          (x / 1000).toFixed(2) + ' 秒'
        )
      } else {
        return this.$t(
          this.$interfaceLang,
          (x / 60 / 1000).toFixed(1) + ' min',
          (x / 60 / 1000).toFixed(1) + ' 分鐘'
        )
      }
    },
    renderChart () {
      var colors = this.labels.map(_ => '#adadad')
      this.dataCollection = {
        labels: this.labels,
        datasets: [
          {
            backgroundColor: colors,
            data: this.times,
            barThickness: 15
          }
        ]
      }
      var M = Math.ceil(Math.max(...this.times) / 1000) * 1000
      var stepSize = Math.max(M * 0.25, 500)
      if (M > 1 && M < 4) {
        stepSize = 0.5
      } else if (M >= 4) {
        stepSize = Math.floor(M / 4)
      }
      M = Math.ceil(Math.max(1, ...this.times) / stepSize + 1) * stepSize
      var vue = this
      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              align: 'start',
              paddingRight: 100,
              ticks: {
                min: 0,
                max: M,
                stepSize: stepSize,
                padding: 10,
                callback: function (value, index, values) {
                  return vue.timeDisplay(value)
                }
              }
            }
          ]
        },
        legend: {
          display: false
        },
        onClick: function (e, elementsAtEvent) {
          if (this.getElementsAtEvent(e)[0]) {
            vue.$emit('click-element', { index: elementsAtEvent[0]._index })
          }
        },
        onHover: function (e) {
          if (this.getElementsAtEvent(e)[0]) {
            e.target.style.cursor = 'pointer'
          } else {
            e.target.style.cursor = 'default'
          }
        }
      }
      $('.chart-container').width(
        Math.max(this.dataCollection.labels.length * 60, 200)
      )
    }
  }
}
</script>
