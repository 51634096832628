<template>
  <div>
    <TopicSelect
      v-if="name == 'topic-select'"
      :forms="forms"
      v-model="selectedForm"
    />
    <TopicList
      v-if="name == 'topic-list'"
      :yearTerm="yearTerm"
      :topics="topics"
    />
    <TopicListItem
      v-if="name == 'topic-list-item'"
      :nameEn="'Quadratic Equations'"
      :nameZh="'二次方程'"
      :numSubtopic="3"
      :imagePath="'/static/images/img_practise_Quadratic_Equations.png'"
      :color="'blue'"
    />

    <div id="createPractice-model" v-if="name == 'subtopic-check-boxes'">
      <div class="modal-section">
        <SubtopicCheckBoxes :subtopics="subtopics" v-model="checkedSubtopics" />
      </div>
    </div>
    <QtyInput
      v-if="name == 'qty-input'"
      :minNumQuestion="5"
      :maxNumQuestion="30"
      :increment="5"
      v-model="numQuestion"
    />
    <CreatePractice
      v-if="name == 'create-practice'"
      :topicName="createPracticeTopicName"
      :minNumQuestion="5"
      :maxNumQuestion="30"
      :increment="5"
      :subtopics="subtopics"
      @done="doneCreatePractice"
      @close="modalCreatePractice = false"
    />
    <!-- <LoadTopics v-if="name == 'load-topics'" ref="load-topics" :currentUserId="$appStore.userInfo.userid" :usergroupId="$appStore.userInfo?.usergroup?.id" 
      @loaded="loadedTopics = $event.topics"
    /> -->
    <DifficultyInput v-if="name == 'difficulty-input'" v-model="difficulty" />
    <SectionSelect v-if="name == 'selection-select'" v-model="currentSection" />
    <PracticeRow
      v-if="name == 'practice-row'"
      :paperId="'1'"
      :paperName="'Practice'"
      :numQuestion="10"
      :numCorrectQuestion="4"
      :numSubmission="1"
      :timeSpent=null
      :createdTime="1672732800000"
      :correctRate="40"
    />
    <SectionHistory v-if="name == 'section-history'" :currentUserId="currentUserId" />
  </div>
</template>
<script>
import TopicSelect from '@/pages/student/practice/components/topic-select/TopicSelect.vue'
import TopicList from '@/pages/student/practice/components/topic-list/TopicList.vue'
import TopicListItem from '@/pages/student/practice/components/topic-list/TopicListItem.vue'
import SubtopicCheckBoxes from '@/pages/student/practice/components/modal-create-practice/SubtopicCheckBoxes.vue'
import QtyInput from '@/pages/student/practice/components/modal-create-practice/QtyInput.vue'
import CreatePractice from '@/pages/student/practice/components//modal-create-practice/CreatePractice.vue'
import LoadTopics from '@/pages/student/practice/components/load-topics/LoadTopics.vue'
import DifficultyInput from '@/components/student/difficulty-input/DifficultyInput.vue'
import SectionSelect from '@/pages/student/practice/components/section-select/SectionSelect.vue'
import PracticeRow from '@/pages/student/practice/components/practice-row/PracticeRow.vue'
import SectionHistory from '@/pages/student/practice/components/section-history/SectionHistory.vue'

import { loadTopics, loadPracticeHistory } from '@/pages/student/practice/apollo-queries/apollo-queries.js'

export default {
  components: {
    TopicSelect,
    TopicList,
    TopicListItem,
    SubtopicCheckBoxes,
    QtyInput,
    CreatePractice,
    LoadTopics,
    DifficultyInput,
    SectionSelect,
    PracticeRow,
    SectionHistory
  },
  props: {},
  data () {
    return {
      currentUserId: this.$appStore.userInfo.userid,
      name: this.$route.query.name,
      yearTerm: null, //"2",
      topics: [
        {
          topicId: '1WHUHt',
          nameEn: 'Algebraic Equations in One Unknown',
          nameZh: '一元代數方程',
          numSubtopic: 1,
          imagePath: null,
          color: 'purple',
          yearTerm: '1'
        },
        {
          topicId: '6T1UU3',
          nameEn: 'Quadratic Equations',
          nameZh: '二次方程',
          numSubtopic: 3,
          imagePath: '/static/images/img_practise_Quadratic_Equations.png',
          color: 'blue',
          yearTerm: '2'
        }
      ],
      forms: [null, '1', '4', '5'],
      selectedForm: 1,
      subtopics: [
        {
          id: '_1qUya',
          nameEn: 'Laws of Positive Integral Indices',
          nameZh: '正整數指數定律'
        },
        {
          id: '_PkUMt',
          nameEn: 'Polynomials',
          nameZh: '多項式'
        },
        {
          id: '6PEU30',
          nameEn: 'Addition and Subtraction of Polynomials',
          nameZh: '多項式的加法和減法'
        },
        {
          id: '6LvUhs',
          nameEn: 'Multiplication of Polynomials',
          nameZh: '多項式的乘法'
        }
      ],
      checkedSubtopics: [
        {
          id: '6PEU30',
          nameEn: 'Addition and Subtraction of Polynomials',
          nameZh: '多項式的加法和減法'
        }
      ],
      numQuestion: 15,
      createPracticeTopicName: this.$t(
        this.$interfaceLang,
        'Manipulation of Simple Polynomials',
        '簡易多項式的運算'
      ),
      modalCreatePractice: true,

      doCreatePractice: false,
      createPracticeSubtopicIds: null,
      createPracticeNumQuestion: null,
      createPracticeDifficulty: null,
      loadedTopics: null,
      loadedPracticeRow: null,
      difficulty: 3,
      currentSection: 'freePractice'
    }
  },
  computed: {},
  async mounted () {
    if (this.name == 'load-topics') {
      let currentUserId = this.$appStore.userInfo.userid
      let usergroupId = this.$appStore.userInfo?.usergroup?.id
      this.loadedTopics = await loadTopics(
        this.$apolloQuery,
        currentUserId,
        usergroupId
      )
    } else if (this.name == 'load-practice-history') {
      let currentUserId = this.$appStore.userInfo.userid
      this.loadedPracticeRow = await loadPracticeHistory(
        this.$apolloQuery,
        currentUserId,
        20
      )
    }
  },
  methods: {
    doneCreatePractice (event) {
      this.createPracticeNumQuestion = event.numQuestion
      this.createPracticeSubtopicIds = event.subtopicIds
      this.createPracticeDifficulty = event.difficulty
      this.doCreatePractice = true
    }
  }
}
</script>

<style src="@/components/student/modal/modal.css" scoped></style>
