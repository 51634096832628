var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.yearTerm != null)?_c('div',{staticClass:"assignment-container",attrs:{"id":"library-container"}},[_c('LibraryRow',{ref:"library-row",attrs:{"topics":_vm.topics,"yearTerm":_vm.yearTerm,"showFav":_vm.showFav},on:{"input":function($event){_vm.$log('library-row-input', _vm.selector)
      _vm.$emit('select', _vm.selector)
      _vm.$nextTick(_ => _vm.resetQuestions())}},model:{value:(_vm.selector),callback:function ($$v) {_vm.selector=$$v},expression:"selector"}}),(_vm.selector?.topic != null || _vm.selector?.isFav)?_c('div',{staticClass:"add-questions-container"},_vm._l((_vm.questions),function(q){return _c('LibraryQuestionRow',{key:'library-question-' + q?.displayQuestion?.id,attrs:{"readOnly":_vm.readOnly,"question":q,"isQgroupFav":q.displayQuestion.qgroup.myUserEdge?.isQgroupFav,"showFav":_vm.showFav},on:{"add":function($event){return _vm.$emit('add', $event)},"similar":function($event){return _vm.$emit('similar', $event)},"set-isfav":function($event){_vm.setIsFav($event)
        _vm.$emit('set-isfav', $event)}}})}),1):_vm._e(),(_vm.selector?.topic != null || _vm.selector?.isFav)?_c('InfiniteLoading',{ref:"infinite-loading-paper",on:{"infinite":function($event){return _vm.atInfinity($event)}}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"},[_c('img',{staticClass:"loading-gif",attrs:{"src":require("@/assets/images/loading.gif")}})]),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'No more questions.', '無其他題目。'))+" ")]),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'No more questions.', '無其他題目。'))+" ")])]):_vm._e(),(_vm.selector?.topic == null && !_vm.selector?.isFav)?_c('div',{staticClass:"blank-msg"},[_c('img',{staticClass:"img-tip",attrs:{"src":require("@/assets/images/img_tips.png")}}),_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.$t(
          _vm.$interfaceLang,
          '<b>Tips</b><br />Select Term, Topic, Subtopic or tick \'Favourite\' from above to browse questions.',
          '<b>小題示</b><br />瀏覽題目前，請先選擇年級、主題及副題或者剔選「我的最愛」。'
        )
      )}})]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }