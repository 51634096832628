<template>
  <div class="homework-list">
    <div class="nav-row">
      <SelectTab
        v-model="selectedTab"
        @input="
          $emit('select-tab', selectedTab)
          $nextTick(_ => resetInfiniteLoading())
        "
      />
    </div>
    <HomeworkTodoList
      ref="todo-list"
      v-show="selectedTab === 'todo'"
      :todoPaperEdges="todoPaperEdges"
      @infinite="$emit('todo-infinite', $event)"
      @goto="$emit('goto', $event)"
    />
    <HomeworkPastList
      ref="past-list"
      v-show="selectedTab === 'past'"
      :pastPaperEdges="pastPaperEdges"
      @infinite="$emit('past-infinite', $event)"
      @goto="$emit('goto', $event)"
    />
    <!-- <template v-if="selectedTab === 'past'">
      <div id="homework-list-todo" class="homework-list-col">
        <div class="filter-col">
          <div class="homework-type-label">
            <div class="homework-type-legend type-submitted"></div>
            <span>{{ $t($interfaceLang, 'Tried', '有做') }}</span>
          </div>
          <div class="homework-type-label">
            <div class="homework-type-legend type-overdue"></div>
            <span>{{ $t($interfaceLang, 'Late', '遲交') }}</span>
          </div>
        </div>
        <HomeworkPastRow
          v-for="edge in pastPaperEdges"
          :paper="edge.paper"
          :numQuestion="edge.numQuestion"
          :numCorrect="edge.numCorrect"
          @goto="$emit('goto', $event)"
        />
      </div>
    </template> -->
    <!-- <InfiniteLoading
      ref="infinite-loading-paper"
      @infinite="$emit('infinite', $event)"
    >
      <div slot="spinner">
        <img class="loading-gif" src="@/assets/images/loading.gif" />
      </div>
      <div slot="no-more">
        {{ $t($interfaceLang, 'No more assignments.', '無其他作業。') }}
      </div>
      <div slot="no-results">
        {{ $t($interfaceLang, 'No more assignments.', '無其他作業。') }}
      </div>
    </InfiniteLoading> -->
  </div>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading'
import SelectTab from './SelectTab.vue'
import HomeworkTodoRow from './HomeworkTodoRow.vue'
import HomeworkPastRow from './HomeworkPastRow.vue'
import HomeworkTodoList from './HomeworkTodoList.vue'
import HomeworkPastList from './HomeworkPastList.vue'
export default {
  components: {
    InfiniteLoading,
    SelectTab,
    HomeworkTodoRow,
    HomeworkPastRow,
    HomeworkTodoList,
    HomeworkPastList
  },
  props: {
    todoPaperEdges: Array,
    pastPaperEdges: Array,
    tab: String
  },
  data () {
    return {
      selectedTab: this.tab
    }
  },
  computed: {},
  methods: {
    resetInfiniteLoading () {
      if (this.selectedTab === 'todo') {
        this.$refs['todo-list'].resetInfiniteLoading()
      } else if (this.selectedTab === 'past') {
        this.$refs['past-list'].resetInfiniteLoading()
      }
    }
  }
}
</script>
<style src="../../Homework.css" scoped></style>
