<template>
  <div>
    <div class="position-relative">
      <div class="page-info">
        <div class="page-title-desc">
          <div class="page-title" slot="reference">
            {{
              $t(
                $interfaceLang,
                'Student submission and performance',
                '學生提交和表現'
              )
            }}
          </div>
          <div class="page-desc">
            {{
              $t(
                $interfaceLang,
                'Overall assignment correct rate and time spent',
                '整體習作正確率及時間使用'
              )
            }}
          </div>
        </div>
        <div class="page-btn-group">
          <button
            @click="$emit('goto-details')"
            id="btn-question-detail"
            class="page-btn org-btn"
          >
            <i class="icon-font icon-inspect">&#xe815;</i>
            {{ $t($interfaceLang, 'Details', '題目細節') }}
          </button>
        </div>
      </div>
      <div class="nav-row">
        <div class="chart-label-col">
          <div class="label-item">
            <div class="label-block gray-block"></div>
            <div class="label-name">
              {{ $t($interfaceLang, 'Not submitted', '未提交') }}
            </div>
          </div>
          <div class="label-item">
            <div class="label-block red-block"></div>
            <div class="label-name">
              {{ $t($interfaceLang, 'Incorrect', '不正確') }}
            </div>
          </div>
          <div class="label-item">
            <div class="label-block green-block"></div>
            <div class="label-name">
              {{ $t($interfaceLang, 'Correct', '正確') }}
            </div>
          </div>
        </div>
        <!-- sort options -->
      </div>
      <div class="student-chart-container-col">
        <StudentChart
          v-if="users != null"
          :users="users"
          class="student-chart-container"
        />
        <div v-else>
          <p class="item-hightlight-text">
            {{ noData }}
          </p>
        </div>
      </div>
      <LocalLoadingLayer v-if="isLoading" />
    </div>
  </div>
</template>
<script>
import LocalLoadingLayer from '@/components/LocalLoadingLayer.vue'
import StudentChart from './StudentChart.vue'
export default {
  components: {
    StudentChart,
    LocalLoadingLayer
  },
  props: {
    users: Array,
    isLoading: Boolean
  },
  computed: {
    noData () {
      return this.$t(this.$interfaceLang, 'No data', '沒有資料')
    }
  },
  methods: {}
}
</script>
<style
  src="@/pages/teacher/report-n-statistics/ReportInside.css"
  scoped
></style>

<style scoped>
.page-info {
  margin-bottom: 20px;
}

.student-chart-container {
  position: relative;
}

.chart-label-col {
  position: relative;
  display: flex;
  align-items: center;
}

.label-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.label-block {
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.green-block {
  background-color: #3bcc89;
}

.red-block {
  background-color: #f55a58;
}

.gray-block {
  background-color: #f1f3f0;
  border: 1px solid black;
}

.label-name {
  font-size: 14px;
  color: #1c2d60;
  font-weight: 600;
}
</style>
