<template>
  <div>
    <div v-if="(questions?.length ?? 0) == 0" class="blank-msg">
      <p>
        {{
          $t(
            $interfaceLang,
            'No questions in this assignment yet.',
            '此作業未有題目。'
          )
        }}
      </p>
      <button id="btn-add" class="org-btn" @click="$emit('select-library')">
        <i class="icon-font icon-plus">&#xe817;</i>
        {{ $t($interfaceLang, 'Add Questions', '新增題目') }}
      </button>
    </div>
    <div v-else class="edit-questions-container">
      <div
        v-show="isReordering"
        @click="
          pasteQuestionAt(0)
          isReordering = false
          $emit('change')
        "
        class="reorder-line"
      >
        <span>{{ $t($interfaceLang, 'Paste here', '在此貼上') }}</span>
      </div>
      <div
        v-for="(q, i) in questions"
        v-show="isReordering == false || reorderingIndexNo != i"
      >
        <div class="question-container-col">
          <div v-show="isReordering" class="question-is-reordering" />
          <QuestionRow
            :indexNo="i"
            :question="q"
            :isNewQuestion="q.isNew"
            :readOnly="readOnly"
            :showFav="showFav"
            :isReordering="isReordering"
            :isQgroupFav="q.displayQuestion.qgroup.myUserEdge?.isQgroupFav"
            @reorder="
              isReordering = true
              reorderingIndexNo = $event.indexNo
            "
            @set-isfav="
              setIsFav($event)
              $emit('set-isfav', $event)
            "
            @drawing="$emit('drawing', $event)"
            @solution="$emit('solution', $event)"
            @template-mutation="$emit('template-mutation', $event)"
            @similar="$emit('similar', $event)"
            @duplicate="$emit('duplicate', $event)"
            @delete="$emit('delete', $event)"
          />
        </div>
        <div
          v-show="isReordering"
          :key="i + '_reorder'"
          @click="
            pasteQuestionAt(i + 1)
            isReordering = false
            $emit('change')
          "
          class="reorder-line"
        >
          <span>{{ $t($interfaceLang, 'Paste here', '在此貼上') }}</span>
        </div>
      </div>
    </div>
    <transition name="fade">
      <template v-if="isReordering">
        <div class="reorder-tips">
          <i class="icon-font icon-cut">&#xe809;</i>
          <span>
            {{
              $t(
                $interfaceLang,
                'Question cut, please paste at desired location.',
                '題目已經剪下，請在選擇貼上的位置。'
              )
            }}
          </span>
        </div>
      </template>
    </transition>
    <transition name="fade">
      <template v-if="isReordering">
        <div @click="isReordering = false" class="btn-cancal-reorder">
          <i class="icon-font icon-close">&#xe808;</i>
          <p v-html="$t($interfaceLang, 'Cancel<br />reorder', '取消調位')"></p>
        </div>
      </template>
    </transition>
  </div>
</template>
<script>
import QuestionRow from './QuestionRow.vue'
export default {
  components: {
    QuestionRow
  },
  props: {
    readOnly: Boolean,
    showFav: Boolean,
    questions: Array
  },
  data () {
    return {
      isReordering: false,
      reorderingIndexNo: null
    }
  },
  computed: {},
  methods: {
    pasteQuestionAt (index) {
      this.newQuestionIds = [
        this.questions[this.reorderingIndexNo].displayQuestion.id
      ]
      var i = this.reorderingIndexNo
      var q = this.questions
      if (index < i) {
        q = [
          ...q.slice(0, index),
          q[i],
          ...q.slice(index, i),
          ...q.slice(i + 1)
        ]
      } else if (i < index) {
        q = [
          ...q.slice(0, i),
          ...q.slice(i + 1, index),
          q[i],
          ...q.slice(index)
        ]
      }
      this.$emit('reorder', q)
      this.reorderingIndexNo = null
    },
    setIsFav ({ question, isFav }) {
      let qgroupId = question?.displayQuestion?.qgroup.id
      if (qgroupId != null) {
        if (question.displayQuestion.qgroup.myUserEdge != null) {
          Object.assign(question.displayQuestion.qgroup.myUserEdge, {
            isQgroupFav: isFav
          })
        } else {
          question.displayQuestion.qgroup.myUserEdge = { isQgroupFav: isFav }
        }
      }
    }
  }
}
</script>
<style src="@/pages/teacher/edit-assignment/EditAssignment.css" scoped />
<style scoped>
.question-container-col {
  position: relative;
}
.question-is-reordering {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 50;
  opacity: 0.5;
}
</style>
