<template>
  <Info
    :paperId="paperId"
    :paperName="paperName"
    :paperType="paperType"
    :paperExpiryTime="paperExpiryTime"
    :numQuestion="numQuestion"
    :numStudent="numStudent"
    :numSubmission="numSubmission"
    :numTopicThreshold="numTopic"
    :avgTimeSpent="avgTimeSpent"
    :avgCorrectRate="avgCorrectRate"
    :topics="topics"
    :isLoading="isLoading"
  />
</template>

<script>
import Info from './Info.vue'
import LoadAssignmentInfoQuery from './LoadAssignmentInfo.graphql'
export default {
  components: {
    Info
  },
  props: {
    currentUserId: String,
    paperId: String,
    usergroupId: String,
    numTopic: Number
  },
  data () {
    return {
      paperName: null,
      paperType: null,
      paperExpiryTime: null,
      numQuestion: null,
      numStudent: null,
      numSubmission: null,
      avgTimeSpent: null,
      avgCorrectRate: null,
      topics: null,
      isLoading: false
    }
  },
  async created () {
    this.isLoading = true
    let result = await this.fetchAssignmentInfo()
    this.parseAssignmentInfo(result)
    this.isLoading = false
  },
  methods: {
    async fetchAssignmentInfo () {
      return await this.$apolloQuery(LoadAssignmentInfoQuery, {
        currentUserId: this.currentUserId,
        id: this.paperId,
        usergroupId: this.usergroupId,
        numTopic: this.numTopic
      }).then(res => res.data)
    },
    parseAssignmentInfo (result) {
      if (!result.paper?.isDeleted) {
        this.numStudent = result.usergroup?.numStudent
        this.paperType = result.paper?.type
        this.paperName = result.paper?.name
        this.paperExpiryTime = result.paper?.expiryTime
        this.numQuestion =
          result.paper?.cache?.stats?.numQuestion ?? result.paper?.numQuestion
        this.numSubmission = result.paper?.cache?.stats?.numSubmission
        this.avgTimeSpent = result.paper?.cache?.stats?.usergroupAvgTimeSpent
        this.avgCorrectRate =
          result.paper?.cache?.stats?.usergroupAvgCorrectRate
        this.topics = result.paper?.topicEdges.map(x => ({
          name: this.$t(this.$interfaceLang, x.topic.nameEn, x.topic.nameZh),
          count: x.cache?.paperStats?.numTopicQuestion
        }))
      }
    }
  }
}
</script>

<style></style>
