<template>
  <header id="student-header" class="container-student">
    <nav class="navbar navbar-expand-md navbar-light">
      <div class="top-row">
        <div id="header-logo"><img src="@/assets/images/Logo_big.svg" /></div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fa fa-bars" />
        </button>
      </div>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <div class="overlay" />
        <div class="profile-nav">
          <div class="btn-group" role="group">
            <button
              id="btn-select-profile"
              type="button"
              class="btn dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="profile-pic">
                <img src="@/assets/images/avatar/profile_student_01.png" />
              </div>
              <span class="profile-name">{{ studentName }}</span>
            </button>
            <div
              class="dropdown-menu dropdown-menu-right btn-select-profile-group"
              aria-labelledby="btn-select-profile"
            >
              <router-link
                class="dropdown-item btn-select-profile-menu"
                :to="`/student/performance`"
              >
                <i class="icon-font icon-performance">&#xe826;</i>
                {{ $t($interfaceLang, 'My performance', '我的表現') }}
              </router-link>
              <router-link
                class="dropdown-item btn-select-profile-menu"
                :to="`/logout`"
                href="javascript:void(0)"
              >
                <i class="icon-font icon-logout">&#xe834;</i>
                {{ $t($interfaceLang, 'Logout', '登出') }}
              </router-link>
              <template v-if="userData.length > 1">
                <div class="white-line"></div>
                <p class="switch-txt">
                  {{ $t($interfaceLang, 'Switch Account(s)', '切換帳號') }}
                </p>
                <AccountSwitchAdapter />
              </template>
            </div>
          </div>
        </div>
        <LangSelector v-model="lang" @input="$emit('set-lang', lang)" />
        <ul class="menu-nav">
          <li
            v-for="m in menuData"
            class="select-menu"
            :class="{ active: $route.meta.active === m.active }"
          >
            <template v-if="!m.isComingSoon">
              <router-link :to="{ path: m.to }" exact>
                {{ $t($interfaceLang, m.captionEn, m.captionZh) }}
              </router-link>
              <span v-if="m.isNew" class="new ml-2">
                ({{ $t($interfaceLang, 'New!', '最新!') }})
              </span>
            </template>
            <template v-else>
              <a href="#" @click.prevent="">
                {{ $t($interfaceLang, m.captionEn, m.captionZh) }}
                <span class="coming-soon ml-2">
                  ({{ $t($interfaceLang, 'Coming soon!', '即將推出!') }})
                </span>
              </a>
            </template>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>
<script>
import LangSelector from './LangSelector.vue'
import menuData from './menu-data.json'
import AccountSwitchAdapter from './adapters/AccountSwitchAdapter.vue'
export default {
  components: {
    LangSelector,
    AccountSwitchAdapter
  },
  props: {
    studentName: String,
    lang: String
  },
  data () {
    return {
      menuData,
      userData: this.$appStore.userData
    }
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>
.white-line {
  position: relative;
  display: block;
  width: 80%;
  height: 0.5px;
  margin: 0 auto;
  background-color: #fff;
}
.switch-txt {
  position: relative;
  display: block;
  font-size: 12px;
  width: 80%;
  padding-top: 10px;
  margin: 0 auto;
  color: #ddc254;
}

#student-header {
  position: relative;
  min-height: 56px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

#header-logo img {
  width: 120px;
}

.top-row {
  background-color: white;
  z-index: 1060;
}

.navbar {
  width: 100%;
  padding: 0.5rem 0.5rem;
}

.navbar-toggler {
  border: none;
  color: #1c2d60;
}

button:focus,
button:hover,
button:active {
  box-shadow: none;
  outline: none;
}

.navbar-collapse {
  flex-direction: row-reverse;
}

#lang-selector {
  margin-left: auto;
}

.menu-nav {
  padding: 0px;
  margin: 0px;
  margin-left: 20px;
}

.menu-nav li {
  position: relative;
  float: left;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0px 25px;
}

.menu-nav li a:hover,
.menu-nav li a:focus,
.select-menu.active a {
  color: #0c89d3;
  text-decoration: none;
}

.select-menu a {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #1e2d60;
}

.profile-pic {
  cursor: pointer;
}

.profile-name {
  font-size: 14px;
  font-weight: 600;
  color: #1e2d60;
  padding: 0px 5px;
}

.profile-pic img {
  width: 32px;
}

#btn-select-profile {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
  height: 56px;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
}

#btn-select-profile:before {
  content: '';
  height: 25px;
  padding-right: 15px;
}

.btn-select-profile-group {
  padding: 0px;
  margin: 0 auto;
  min-width: 85%;
  background-color: #007fca;
  border: 0px;
  border-radius: 0px;
}

.btn-select-profile-menu {
  color: white;
  font-size: 13px;
  font-weight: normal;
  line-height: 2.3;
  align-items: center;
  display: flex;
}

.btn-select-profile-menu:hover,
.btn-select-profile-menu:active {
  background-color: #009af3;
}

.icon-font {
  font-size: 17px;
  color: #ffd32d;
  padding-right: 5px;
}

#btn-select-lang {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
  height: 56px;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
}

#btn-select-lang:before {
  content: '';
  height: 25px;
  border-left: 1.5px solid #bbbbbb;
  padding-right: 15px;
}

.btn-select-lang-group {
  padding: 0px;
  margin: 0 auto;
  min-width: 85%;
  background-color: #007fca;
  border: 0px;
  border-radius: 0px;
}

.btn-select-lang-menu {
  color: white;
  font-size: 13px;
  font-weight: normal;
  line-height: 2.3;
  align-items: center;
  display: flex;
}

.btn-select-lang-menu:hover,
.btn-select-lang-menu:active {
  background-color: #009af3;
}

.lang-short {
  display: block;
}

.lang-long {
  display: none;
}

.coming-soon {
  font-size: 8pt;
  font-weight: bold;
  color: #ff7000;
}

.new {
  font-size: 8pt;
  font-weight: bold;
  color: #3ccc89;
}


@media only screen and (max-width: 767px) {
  #student-header {
    padding: 0px;
  }

  .navbar {
    padding: 0px;
  }

  .top-row {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
  }

  #header-logo {
    position: relative;
  }

  .navbar-toggler {
    position: relative;
    margin-left: auto;
    padding-right: 0px;
  }

  .navbar-collapse {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    min-height: 100%;
    padding-top: 50px;
    z-index: 1050;
    background-color: #007fca;
  }

  .profile-nav {
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: #007fca;
  }

  .profile-nav .btn-group {
    display: block;
  }

  .btn-select-profile-group {
    position: relative;
    height: auto;
    opacity: 1;
    width: 100%;
    pointer-events: visible;
    border-bottom: 1px solid white;
  }

  #btn-select-profile:before {
    border-left: none;
  }

  #btn-select-profile:after {
    content: none;
  }

  .profile-name {
    color: white;
  }

  .btn-select-profile-menu {
    padding-left: 40px;
  }

  .menu-nav {
    margin-left: 0px;
    position: relative;
    display: block;
    background-color: #007fca;
  }

  .menu-nav li {
    position: relative;
    display: block;
    float: none;
    height: auto;
    padding: 0px;
    border-bottom: 1px solid white;
  }

  .menu-nav li:last-child {
    border-bottom: none;
  }

  .menu-nav li.active a,
  .menu-nav li a {
    position: relative;
    display: block;
    color: white;
    width: 100%;
    padding: 15px 20px;
  }

  .menu-nav li a::after {
    content: '';
    position: absolute;
    right: 20px;
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    /* speak: never; */
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
  }

  .menu-nav li a:hover,
  .menu-nav li a:focus {
    color: white;
    background-color: #009af3;
  }

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #ffffffa1;
  }

  #lang-selector {
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: #007fca;
  }

  #lang-selector .btn-group {
    display: block;
  }

  #btn-select-lang {
    color: white !important;
  }

  .btn-select-lang-group {
    position: relative;
    height: auto;
    opacity: 1;
    width: 100%;
    pointer-events: visible;
    border-bottom: 1px solid white;
  }

  .btn-select-lang-menu {
    padding-left: 40px;
  }

  #btn-select-lang:before {
    border-left: none;
  }

  #btn-select-lang:after {
    content: none;
  }

  .lang-short {
    display: none;
  }

  .lang-long {
    display: block;
  }
}
</style>
