<template>
  <div class="questions-list">
    <ul class="questions-col" v-if="useranswers != null">
      <li
        v-for="(x, i) in useranswers"
        :class="{
          correct: x.isCorrect,
          wrong: x.isWrong,
          current: value == i
        }"
        class="question-col"
        @click="$emit('input', i)"
      >
        {{ i + 1 }}{{ x.isLate ? '*' : '' }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    value: Number,
    useranswers: Array
  },
  data () {
    return {}
  }
}
</script>
<style src="../../Paper.css" scoped></style>
