import config from '@/config.json'
import log from '@/log.js'

async function getSignedUrl(user) {
  return user?.getIdToken()
    .then(
      token => fetch(
        config.server.signerUrl,
        {
          method: "GET",
          headers: {
            'content-type': 'application/json',
            authorization: 'Bearer ' + token,
            "cache-control": "max-age"
          }
        }
      ))
    .then(res => res?.json())
    .then(data => data?.signedUrl)
}

function isJSON(resName) {
  return resName.endsWith('.json')
}

function isJpeg(resName) {
  return resName.endsWith('.jpg') || resName.endsWith('.jpeg')
}

async function load(user, resName) {
  return await getSignedUrl(user)
    .then(x => {
      if (isJSON(resName)) {
        return fetch(x?.replace('*', resName),
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
            }
          }
        ).then(res => res?.json())
      } else if (isJpeg(resName)) {
        return fetch(x?.replace('*', resName),
          {
            method: "GET",
            headers: {
              "content-type": "image/jpeg"
            }
          }
        ).then(res => res?.blob())
          .then(blob => URL.createObjectURL(blob))
      }
    })
}

export default {
  url(user, resName) {
    return getSignedUrl(user).then(
      x => x?.replace('*', resName)
    )
  },
  async load(user, resName) {
    try {
      return await load(user, resName)
    } catch (e) {
      log(e.message)
      return await load(user, resName)
    }
  }
}