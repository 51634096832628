<template>
  <div class="report-info performance-chart">
    <div class="report-info-col-title">
      {{ $t($interfaceLang, 'Assignment Performance', '功課表現') }}
    </div>
    <template v-if="!isLoading">
      <template v-if="hasData">
        <div class="nav-row">
          <SelectTypes v-model="currentSection" @input="renderChart()" />
        </div>
        <div class="chart-legend-col">
          <div class="chart-legend org">
            <div class="chart-legend-box"></div>
            <div class="chart-legend-text">
              {{ $t($interfaceLang, 'You', '你') }}
            </div>
          </div>
          <div class="chart-legend blue">
            <div class="chart-legend-box"></div>
            <div class="chart-legend-text">
              {{ $t($interfaceLang, 'Class average', '班平均') }}
            </div>
          </div>
        </div>
        <div v-dragscroll class="chart-col">
          <PerformanceLines
            ref="performanceChart"
            :labels="paperNames"
            :correctRates="correctRates"
            :classCorrectRates="classCorrectRates"
            :showClassCorrectRates="true"
          />
        </div>
      </template>
      <div v-else class="no-data">
        {{ $t($interfaceLang, 'No Data', '沒有資料') }}
      </div>
    </template>
    <LocalLoadingLayer v-else />
  </div>
</template>
<script>
import SelectTypes from './SelectTypes.vue'
import PerformanceLines from './PerformanceLines.vue'
import LocalLoadingLayer from '@/components/LocalLoadingLayer.vue'

import { dragscroll } from 'vue-dragscroll'

export default {
  directives: {
    dragscroll
  },
  components: {
    SelectTypes,
    PerformanceLines,
    LocalLoadingLayer
  },
  props: {
    paperEdges: Array,
    isLoading: Boolean
  },
  data () {
    return {
      currentSection: 'all'
    }
  },
  computed: {
    hasData () {
      return (
        this.paperEdges.filter(
          x => x.paper?.cache?.userStudentStats?.correctRate != null
        ).length > 0
      )
    },
    showPaperEdges () {
      return this.paperEdges?.filter(this.paperEdgeFilter) ?? []
    },
    paperNames () {
      return this.showPaperEdges.map(x => x.paper.name)
    },
    correctRates () {
      return this.showPaperEdges.map(
        x => x.cache?.userStudentStats?.correctRate
      )
    },
    classCorrectRates () {
      return this.showPaperEdges.map(
        x => x.paper.cache?.stats?.usergroupAvgCorrectRate
      )
    }
  },
  methods: {
    paperEdgeFilter (edge) {
      return (
        this.currentSection === 'all' ||
        (this.currentSection === 'past1' &&
          Date.now() - Number(edge.paper.expiryTime) <
            (365.25 / 12) * 3600 * 24) ||
        (this.currentSection === 'past3' &&
          Date.now() - Number(edge.paper.expiryTime) < (365.25 / 4) * 3600 * 24)
      )
    },
    renderChart () {
      setTimeout(() => this.$refs.performanceChart.renderChart(), 500)
    }
  }
}
</script>
<style src="../../Performance.css" scoped></style>
<style scoped>
.no-data {
  color: darkgrey;
  font-size: 30px;
  font-weight: bold;
}

.nav-row {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.select-types {
  float: left;
}

.select-item {
  padding: 5px 20px;
  float: left;
  background-color: rgb(230, 230, 230);
  border-radius: 16px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #939393;
  position: relative;
  z-index: 50;
  cursor: pointer;
}

.select-item.selected {
  color: white;
  background-color: #007fca;
  z-index: 52;
}

#type-all {
  padding: 5px 40px;
}

#type-last {
  padding: 5px 40px;
  margin-left: -30px;
}

#type-past1 {
  padding: 5px 40px;
  margin-left: -30px;
}

#type-past3 {
  padding: 5px 40px;
  margin-left: -30px;
}
</style>
