<template>
  <div id="duplicateQuestion-model">
    <Modal @close="$emit('close')">
      <template v-slot:header>
        <i class="icon-title icon-font icon-duplicate_outline">
          &#xe80d;
        </i>
        <span class="title">
          {{ $t($interfaceLang, 'Duplicate Question', '複製題目') }}
        </span>
        <i @click="$emit('close')" class="icon-font icon-close">
          &#xe808;
        </i>
      </template>
      <template v-slot:body>
        <div class="modal-section">
          <p class="sm-title">
            {{
              $t(
                $interfaceLang,
                'Please select number of copies of the question to be added to the assignment. The system will add the questions with number shuffled.',
                '請選擇複製題目數量。系統會抽換新加題目的數字。'
              )
            }}
          </p>
          <div class="section-row">
            <p class="sm-title">
              {{ $t($interfaceLang, 'Amount', '數量') }}
            </p>
            <div class="add-question-qty">
              <img
                class="img-qty"
                src="@/assets/images/add.svg"
                @click="num = Math.min(num + 1, MAX_NUM)"
              />
              <div class="question-qty">{{ num }}</div>
              <img
                class="img-qty"
                src="@/assets/images/deduct.svg"
                @click="num = Math.max(num - 1, MIN_NUM)"
              />
            </div>
            <button
              id="btn-random"
              class="modal-btn right-item"
              @click="$emit('save', { num })"
            >
              <i class="icon-font icon-DUPLICATE">&#xe80d;</i>
              {{ $t($interfaceLang, 'Duplicate', '複製題目') }}
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/teacher/modal/Modal.vue'
export default {
  name: 'DuplicateQuestion',
  components: {
    Modal
  },
  data () {
    return {
      num: 1,
      MAX_NUM: 5,
      MIN_NUM: 1
    }
  }
}
</script>

<style src="@/components/teacher/modal/modal.css" scoped></style>
