<template>
  <div class="select-types">
    <!-- <div
      id="type-library"
      class="select-item"
      :class="{ selected: value === 'library' }"
      @click="$emit('input', 'library')"
    >
      {{ $t($interfaceLang, 'Question Library', '題目庫') }}
    </div> -->
    <div
      id="type-edit"
      class="select-item selected ml-2"
      @click="$emit('input', 'edit')"
    >
      {{ $t($interfaceLang, 'Edit Assignment', '修改作業') }}
      <span class="q-num">{{ numQuestion }}</span>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: String,
    numQuestion: Number
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="@/pages/teacher/edit-assignment/EditAssignment.css" scoped />
