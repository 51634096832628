<template>
  <LineChart
    class="chart-container"
    :chartData="dataCollection"
    :options="options"
    :chart-id="'student-performance-chart'"
  />
</template>
<script>
import LineChart from '@/components/student/LineChart'
export default {
  components: {
    LineChart
  },
  props: {
    labels: Array,
    correctRates: Array,
    classCorrectRates: Array,
    showClassCorrectRates: Boolean
  },
  data () {
    return {
      dataCollection: null,
      options: null
    }
  },
  mounted () {
    this.renderChart()
  },
  computed: {},
  methods: {
    renderChart () {
      // console.log('renderChart')
      if (this.labels == null) {
        return
      }
      var datasets = [
        {
          label: 'Student performance ',
          fill: false,
          borderColor: '#ff881b',
          backgroundColor: '#ff881b',
          data: this.correctRates,
          tension: 0,
          pointRadius: 6
        }
      ]
      if (
        this.classCorrectRates != null &&
        this.showClassCorrectRates === true
      ) {
        datasets.push({
          label: 'Class performance',
          fill: false,
          borderColor: '#42c9f1',
          backgroundColor: '#42c9f1',
          data: this.classCorrectRates,
          tension: 0,
          pointRadius: 6
        })
      }

      this.dataCollection = {
        labels: this.labels,
        datasets
      }
      $('.chart-container').width(this.dataCollection.labels.length * 120)

      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          callbacks: {
            title: function (tooltipItem, data) {
              return tooltipItem[0].label
            },
            label: function (tooltipItem, data) {
              return 'Correct rate: ' + tooltipItem.value + '%'
            }
          },
          displayColors: false,
          xPadding: 10,
          yPadding: 10,
          titleMarginBottom: 10,
          backgroundColor: '#1c2d60',
          footerFontColor: '#ff7977',
          footerFontStyle: 'bold',
          footerMarginTop: 10
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              align: 'start',
              gridLines: {
                drawTicks: false
              },
              ticks: {
                min: 0,
                max: 100,
                stepSize: 20,
                padding: 10,
                callback: function (value, index, values) {
                  return value + '%'
                }
              }
            }
          ]
        },
        legend: {
          display: false
        },
        onClick: function (e) {
          if (this.getElementsAtEvent(e)[0]) {
            // console.log(this.getElementsAtEvent(e)[0]._index)
            // var activePointLabel = this.getElementsAtEvent(e)[0]._model.label
            // console.log(activePointLabel)
            // router.push({ path: '/reportnstatistics/assignment' })
          }
        },
        onHover: function (e) {
          if (this.getElementsAtEvent(e)[0]) {
            e.target.style.cursor = 'pointer'
          } else {
            e.target.style.cursor = 'default'
          }
        }
      }
    }
  }
}
</script>
<style src="" />
