import config from '@/config.json'
import log from '@/log.js'

import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { RetryLink } from 'apollo-link-retry'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

import gql from 'graphql-tag'
import apolloStore from '@/store/apollo-store.js'

export default {
  async createClient(user) {
    const httpLink = new HttpLink({
      uri: config.server.graphql_uri,
      credentials: 'same-origin'
    })
    const authLink = await user?.getIdToken(true).then(
      token => setContext(
        (_, { headers }) => ({
          headers: {
            ...headers, authorization: 'Bearer ' + token
          }
        })
      )
    )
    const retryLink = new RetryLink({
      delay: {
        initial: 300, max: 1000, jitter: true
      },
      attempts: {
        max: 2,
        retryIf: (error, operation) => true
      }
    })
    const client = new ApolloClient({
      link: ApolloLink.from([retryLink, authLink, httpLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache'
        }
      }
    })
    return {
      query: async (query, variables) => {
        let result = null
        try {
          result = await client.query({ query: gql`${query}`, variables })
          return apolloStore.cache(result)
        } catch (e) {
          log(e.message)
        } finally {
          log('ApolloQuery', { query, variables, data: result?.data })
        }
      },
      mutate: async (query, variables) => {
        let result = null
        try {
          result = await client.mutate({ mutation: gql`${query}`, variables })
          return apolloStore.cache(result)
        } catch (e) {
          log(e.message)
        } finally {
          log('ApolloMutate', { query, variables, data: result?.data })
        }
      }
    }
  }
}