<template>
  <div>
    <div class="position-relative">
      <div class="report-info-col-title">
        {{ $t($interfaceLang, 'Topic Mastery', '主題熟習度') }}
        <span>
          *{{
            $t(
              $interfaceLang,
              'Counted on question correct rate',
              '以題目正確率計算'
            )
          }}
        </span>
      </div>
      <Progress
        v-for="(topic, i) in topics"
        :title="topic.name"
        :numQuestion="topic.numQuestion ?? 0"
        :rate="topic.avgCorrectRate ?? 0"
        :color="colors[i % 4]"
      />
      <div v-if="topics?.length == 0">
        <span class="item-hightlight-text">
          {{ noData }}
        </span>
      </div>
      <LocalLoadingLayer v-if="isLoading" />
    </div>
  </div>
</template>
<script>
import LocalLoadingLayer from '@/components/LocalLoadingLayer.vue'
import Progress from './Progress.vue'
export default {
  components: {
    Progress,
    LocalLoadingLayer
  },
  props: {
    topics: Array,
    isLoading: Boolean
  },
  data () {
    return {
      colors: ['#a772ec', '#3ccc89', '#dadf37', '#2fb1c8']
    }
  },
  computed: {
    noData () {
      return this.$t(this.$interfaceLang, 'No data', '沒有資料')
    }
  },
  methods: {}
}
</script>
<style src="../../ReportInside.css" scoped></style>
