<template>
  <div v-if="loaded">
    <div v-if="!hasError">
      <QViewMc
        ref="question-view-mc"
        v-if="question.displayQuestion?.type == 'mc'"
        :questionId="question.displayQuestion?.id"
        :indexNo="indexNo"
        :userMarkerIndex="userMarkerIndex"
        :questionData="questionData"
        :permutation="permutation"
        :correctAnswerIndex="correctAnswerIndex"
        @choose-ans="$emit('choose-ans', $event)"
      />
    </div>
    <div v-else>
      {{
        $t(
          $interfaceLang,
          'Loading error. Please try again later.',
          '讀取錯誤，請後再試。'
        )
      }}
      <button class="retry-btn" @click="load(question)">
        {{ $t($interfaceLang, 'Retry', '重試') }}
      </button>
    </div>
  </div>
  <div v-else>
    <img class="loading-gif" src="@/assets/images/loading.gif" />
  </div>
</template>

<script>
import QViewMc from './QViewMc.vue'
export default {
  components: {
    QViewMc
  },
  props: {
    indexNo: Number,
    question: Object,
    userMarkerIndex: Number, //the index of user answer
    permutation: Array,
    correctAnswerIndex: Number //the correct answer index
  },
  data () {
    return {
      questionData: null,
      loaded: false,
      hasError: false
    }
  },
  created () {
    this.load(this.question)
  },
  watch: {
    async question (newValue) {
      this.load(newValue)
    }
  },
  methods: {
    async load (question) {
      this.loaded = false
      try {
        this.questionData = await this.loadQuestionData(question)
      } catch (e) {
        this.hasError = true
      } finally {
        this.loaded = true
      }
    },
    async loadQuestionContent (question) {
      return await this.$loadContent(
        question?.displayQuestion?.sampleEdgeKey + '.json'
      )
    },
    async loadImageContent (imageName) {
      return await this.$loadContent(imageName)
    },
    async loadMcData (questionData) {
      let result = Object.assign({}, questionData)
      if (questionData.contentImage != null) {
        result.contentImageData = await this.loadImageContent(
          questionData.contentImage
        )
      }
      result.answers = await Promise.all(
        questionData.answers.map(answer => {
          if (answer.endsWith('.jpg')) {
            result.isAnswerImage = true
            return this.loadImageContent(answer)
          }
          return answer
        })
      )
      return result
    },
    async loadQuestionData (question) {
      let questionData = await this.loadQuestionContent(question)
      if (questionData != null) {
        questionData = Object.assign({}, questionData)
        if (question.displayQuestion.type == 'mc') {
          return this.loadMcData(questionData)
        }
      }
    }
  }
}
</script>

<style scoped>
.loading-gif {
  width: 50px;
  height: 50px;
}
</style>
