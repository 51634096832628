<template>
  <popper
    class="table-title-container"
    trigger="hover"
    :options="{ placement: 'bottom' }"
  >
    <div class="popper">
      {{ paperName }}
    </div>
    <div class="col-content" slot="reference">
      <i class="icon-font icon-assignment">&#xe807;</i>
      {{ displayExpiryDate(paperExpiryTime) }}
    </div>
  </popper>
</template>
<script>
import dateFormat from 'dateformat'
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
export default {
  components: {
    popper: Popper
  },
  props: {
    paperName: String,
    paperExpiryTime: String
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    displayExpiryDate (value) {
      if (value == null) {
        return '-'
      }
      let localTimeValue = new Date(
        Number(value) +
          this.$appStore.appInfo.timezoneOffset * 3600 * 1000 -
          3600 * 24 * 1000
      )
      return this.$t(
        this.$interfaceLang,
        dateFormat(localTimeValue, 'GMT:dd mmm'),
        dateFormat(localTimeValue, 'GMT:dd-mm')
      )
    }
  }
}
</script>

<style
  src="@/pages/teacher/report-n-statistics/ReportnStatistics.css"
  scoped
></style>

<style scoped>
.table-title-container {
  position: relative;
  display: flex;
  align-items: center;
}

.popper {
  color: white;
  background-color: #626262;
  border: 1px #626262 solid;
  box-shadow: none;
  padding: 5px 10px;
}

#overview-container >>> .popper__arrow {
  border-color: transparent transparent #626262 transparent !important;
}

.overview-chart-col {
  position: relative;
  margin-top: 30px;
  overflow-x: auto;
  max-width: 952px;
}

.overview-chart {
  position: relative;
  background-color: white;
}

.overview-chart th {
  font-size: 12px;
  font-weight: 600;
  color: #ccc;
  border-bottom: 0.8px solid #ccc;
  height: 30px;
  padding: 5px 10px;
}

.overview-chart td {
  font-size: 14px;
  color: #192c64;
  height: 40px;
}

.table-id {
  min-width: 30px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
}

.table-student {
  min-width: 130px;
  width: 130px;
  text-align: right;
  vertical-align: middle;
  padding-right: 10px;
}

.table-assignment {
  min-width: 80px;
  width: 80px;
  text-align: center;
  vertical-align: middle;
}

.table-overdue-title {
  min-width: 40px;
  width: 40px;
  color: #2b95d3 !important;
}

.table-overdue {
  min-width: 40px;
  text-align: center;
  vertical-align: middle;
}

.color-bar {
  width: 85%;
  height: 85%;
}

.color-on_time {
  background-color: #3bcc89;
}

.color-late {
  background-color: #9ee5c5;
}

.color-overdue {
  background-color: #f55a58;
}

.color-partial {
  background-color: #f1f3f0;
}

.table-overdue-td {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gray-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #192c64;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #f1f3f0;
}

.red-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #f55a58;
}

.col-content {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.hidden-text {
  position: absolute;
  display: block;
  opacity: 0;
}
</style>
