<template>
  <div class="add-question-qty">
    <img
      class="img-qty"
      src="@/assets/images/deduct.svg"
      @click="numQuestion = Math.max(numQuestion - increment, minNumQuestion)"
    />
    <div class="question-qty">{{ numQuestion }}</div>
    <img
      class="img-qty"
      src="@/assets/images/add.svg"
      @click="numQuestion = Math.min(numQuestion + increment, maxNumQuestion)"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    minNumQuestion: Number,
    maxNumQuestion: Number,
    increment: Number,
    value: Number
  },
  data () {
    return {
      numQuestion: this.value
    }
  },
  watch: {
    numQuestion (newValue, oldValue) {
      this.$emit('input', newValue)
    }
  },
  computed: {},
  methods: {}
}
</script>

<style src="@/components/student/modal/modal.css" scoped></style>
