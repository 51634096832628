<template>
  <div id="select-isFav">
    <input
      type="checkbox"
      class="form-check-isFav"
      id="selectIsFav"
      v-model="selectIsFav"
      @input="$emit('input', !selectIsFav)"
    />
    <label class="form-check-label" for="selectIsFav">
      {{ $t($interfaceLang, 'Favourite', '我的最愛') }}
    </label>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: Boolean
  },
  data () {
    return {
      selectIsFav: !!this.value
    }
  },
  computed: {},
  methods: {}
}
</script>
<style src="@/pages/teacher/edit-assignment/EditAssignment.css" scoped />
