import config from '@/config'

const performance = _ => import('@/pages/student/performance/Performance.vue')
const homework = _ => import('@/pages/student/homework/Homework.vue')
const paper = _ => import('@/pages/student/paper/Paper.vue')
const fav = _ => import('@/pages/student/favourite/Favourite.vue')
const practice = _ => import('@/pages/student/practice/Practice.vue')

export default [
  {
    path: '/student',
    redirect: config.route.studentHome,
  },
  {
    path: '/student/homework',
    name: 'StudentHomework',
    component: homework,
    meta: {
      requireAuth: true,
      active: '/student/homework',
      pageRole: 'student'
    }
  },
  {
    path: '/student/paper',
    name: 'StudentPaper',
    component: paper,
    meta: {
      requireAuth: true,
      active: '/student/homework',
      pageRole: 'student'
    }
  },
  // {
  //   path: '/student/home',
  //   name: 'StudentHome',
  //   component: home,
  //   meta: {
  //     requireAuth: true,
  //     active: '/student/home',
  //     pageRole: 'student'
  //   }
  // },
  {
    path: '/student/performance',
    name: 'StudentPerformance',
    component: performance,
    meta: {
      requireAuth: true,
      active: '/student/performance',
      pageRole: 'student'
    }
  },
  {
    path: '/student/practice',
    name: 'StudentPractice',
    component: practice,
    meta: {
      requireAuth: true,
      active: '/student/practice',
      pageRole: 'student'
    }
  },
  // {
  //   path: '/student/dopractice',
  //   name: 'StudentDoPractice',
  //   component: dopractice,
  //   meta: {
  //     requireAuth: true,
  //     active: '/student/practice',
  //     pageRole: 'student'
  //   }
  // },
  {
    path: '/student/favourite',
    name: 'StudentFavourite',
    component: fav,
    meta: {
      requireAuth: true,
      active: '/student/favourite',
      pageRole: 'student'
    }
  },
]