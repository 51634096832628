<template>
  <div>
    <div class="position-relative">
      <div :class="paperTypeClass" class="report-title">
        <div class="item-name report-info-item">
          <a href="#" @click.prevent="$emit('goto', getPaperData())" exact>
            <span>
              {{ paperName ?? untitled }}
            </span>
          </a>
        </div>
      </div>
      <div class="report-info">
        <div class="report-info-group">
          <div class="item-date report-info-item">
            <p class="sm-title">{{ $t($interfaceLang, 'Due', '遞交日期') }}</p>
            <p class="item-hightlight-text">
              {{ $displayDate(paperExpiryTime) }}
            </p>
          </div>
          <div class="item-submission report-info-item">
            <p class="sm-title">
              {{ $t($interfaceLang, 'Student submission', '已提交學生數量') }}
            </p>
            <div v-if="numStudent != null" class="progress-col">
              <Progress :rate="submissionRate" />
              <span class="item-hightlight-text">
                {{ numSubmission ?? 0 }}/{{ numStudent }}
              </span>
            </div>
            <div v-else class="progress-col">
              <span class="item-hightlight-text">
                {{ noData }}
              </span>
            </div>
          </div>
        </div>
        <div class="report-info-group">
          <div class="chart-doughnut report-info-item" v-if="topics != null">
            <p class="sm-title">
              <template v-if="$interfaceLang == 'en_hk'">
                <span class="item-hightlight-text"> {{ numTopicDesc }} </span>
                Topics Covered
              </template>
              <template v-else-if="$interfaceLang == 'zh_hk'">
                包含<span class="item-hightlight-text">
                  {{ numTopicDesc }}
                </span>
                個主題
              </template>
            </p>
            <QuestionsChart
              v-if="topics != null"
              :numQuestion="numQuestion"
              :topics="topics"
              @set-legend="doughnutLegend = $event"
            />
            <div class="doughnut-legend" v-html="doughnutLegend" />
          </div>
          <div v-else class="chart-doughnut report-info-item">
            <p class="sm-title">{{ $t($interfaceLang, 'Topics', '主題') }}</p>
            <p class="item-hightlight-text">
              {{ noData }}
            </p>
          </div>
        </div>
        <div class="report-info-group">
          <div class="item-time report-info-item">
            <p class="sm-title">
              {{ $t($interfaceLang, 'Average time spent', '平均作答時間') }}
            </p>
            <p class="item-hightlight-text">
              <template v-if="avgTimeSpent != null">
                {{ displayTimeSpent(avgTimeSpent) }}
              </template>
              <template v-else>
                {{ noData }}
              </template>
            </p>
          </div>
          <div class="item-average-rate">
            <template v-if="avgCorrectRate != null">
              <CProgress :rate="avgCorrectRate" :color="avgCorrectColor" />
              <p class="sm-title">
                <template v-if="$interfaceLang == 'en_hk'">
                  Average <br />
                  correct rate
                </template>
                <template v-else-if="$interfaceLang == 'en_hk'">
                  平均<br />正確率
                </template>
              </p>
            </template>
            <div v-else class="report-info-item">
              <p class="sm-title">
                {{ $t($interfaceLang, 'Average correct rate', '平均確率') }}
              </p>
              <p class="item-hightlight-text">{{ noData }}</p>
            </div>
          </div>
        </div>
      </div>
      <LocalLoadingLayer v-if="isLoading" />
    </div>
  </div>
</template>
<script>
import LocalLoadingLayer from '@/components/LocalLoadingLayer.vue'
import CProgress from '../c-progress/CProgress.vue'
import Progress from '../progress/Progress.vue'
import QuestionsChart from './QuestionsChart.vue'

export default {
  components: {
    CProgress,
    Progress,
    QuestionsChart,
    LocalLoadingLayer
  },
  props: {
    paperId: String,
    paperName: String,
    paperType: String,
    paperExpiryTime: String,
    numSubmission: Number,
    numStudent: Number,
    numTopicThreshold: Number,
    topics: Array,
    avgTimeSpent: Number,
    avgCorrectRate: Number,
    isLoading: Boolean,
    numQuestion: Number
  },
  data () {
    return {
      doughnutLegend: ''
    }
  },
  computed: {
    untitled () {
      return this.$t(this.$interfaceLang, 'Untitled', '無題')
    },
    numTopicDesc () {
      if (this.numTopicThreshold != null) {
        return this.topics?.length > this.numTopicThreshold
          ? this.numTopicThreshold + '+'
          : this.topics?.length
      }
    },
    paperTypeClass () {
      return {
        'type-homework': this.paperType == 'homework',
        'type-auto': ['recursive', 'recursive_followup'].includes(
          this.paperType
        ),
        'type-test': this.paperType == 'test'
      }
    },
    submissionRate () {
      return (this.numStudent ?? 0) != 0
        ? Math.floor(((this.numSubmission ?? 0) / this.numStudent) * 100)
        : 0
    },
    topicChartLabels () {
      return this.topics?.slice(0, this.numTopicThreshold)?.map(x => x.name)
    },
    topicChartData () {
      return this.topics
        ?.slice(0, this.numTopicThreshold)
        ?.map(x => x.count ?? 0)
    },
    avgCorrectColor () {
      return 'red'
    },
    noData () {
      return this.$t(this.$interfaceLang, 'No data', '沒有資料')
    }
  },
  methods: {
    getPaperData () {
      return {
        id: this.paperId,
        name: this.paperName
      }
    },
    displayTimeSpent (value) {
      if (value == null) {
        return '-'
      } else {
        var time = (value / 1000 / 60).toFixed(2)
        if (time > 1) {
          return this.$t(this.$interfaceLang, time + ' min', time + '分鐘')
        } else {
          return this.$t(this.$interfaceLang, '< 1 min', '< 1 分鐘')
        }
      }
    }
  }
}
</script>
<style src="../../ReportInside.css" scoped></style>

<style scoped>
.report-info-item a {
  color: #1c2d60;
  text-decoration: none;
}

.report-info-item a:hover {
  color: #017fcc;
}
</style>
