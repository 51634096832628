<template>
  <div class="question-container">
    <div class="question-info">
      <div class="question-info-left">
        <FavButton
          v-model="isFav"
          @input="$emit('set-fav', { question: useranswer.question, isFav })"
        />
        <div class="question-num">{{ questionName }}</div>
      </div>
      <div class="question-info-right">
        <a @click="$emit('draw', { useranswer })" class="btn-draw question-btn">
          <i class="icon-font icon-draw" @click="$emit('draw')">&#xe825;</i>
        </a>
      </div>
    </div>
    <QuestionView
      ref="question-view"
      :key="questionViewKey"
      :indexNo="indexNo"
      :isShuffled="true"
      :question="useranswer.question"
      :userMarkerIndex="useranswer.data.displayAnswerIndexNo"
      :permutation="useranswer?.data?.permutation"
      :correctAnswerIndex="useranswer?.correctAnsData?.correctAnswerIndexNo"
      @choose-ans="chooseAnswer($event)"
    />
  </div>
</template>
<script>
import FavButton from '@/components/FavButton.vue'
import QuestionView from '@/components/student/question-view/QuestionView.vue'
export default {
  components: {
    QuestionView,
    FavButton
  },
  props: {
    indexNo: Number,
    useranswer: Object,
    isLate: Boolean,
    isQgroupFav: Boolean
  },
  data () {
    return {
      questionViewKey: Math.random(),
      isFav: this.isQgroupFav
    }
  },
  computed: {
    questionName () {
      return 'Q' + (this.indexNo + 1) + (this.isLate ? '*' : '')
    }
  },
  methods: {
    updateAnswers () {
      this.questionViewKey += 1
    },
    chooseAnswer (event) {
      if (this.useranswer?.data?.displayAnswerIndexNo == null) {
        this.$emit('choose-ans', { ...event, useranswer: this.useranswer })
      }
    }
  }
}
</script>
<style scoped>
.question-container {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 10px;
  border-radius: 4px;
  background-color: white;
}

.question-info {
  position: relative;
  display: flex;
}

.question-info-left {
  position: relative;
  display: flex;
  align-items: center;
}

.question-info-right {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.btn-favourite {
  padding-right: 5px !important;
  border-right: 1px solid #f1f3f1 !important;
}

.question-num {
  font-size: 22px;
  font-weight: bold;
  color: #1c2d60;
}
.question-btn {
  border: none;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  color: #1c2d60;
  font-size: 24px;
  margin-right: 10px;
}

.question-btn:focus,
.question-btn:hover,
.question-btn:active {
  box-shadow: none;
  outline: none;
}

.question-dropdown {
  color: #1c2d60;
  width: 100%;
}

.question-dropdown:focus,
.question-dropdown:hover,
.question-dropdown:active {
  box-shadow: none;
  outline: none;
}

.question-dropdown-group {
  padding: 20px;
  box-shadow: 0 2px 6px 0 rgba(70, 70, 70, 0.4);
  border-radius: 10px;
  background-color: #1c2d60;
  min-width: 100%;
  margin-top: 10px;
  left: 30px !important;
  margin-left: -100px;
}

.question-dropdown-group:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #1c2d60 transparent;
  border-width: 0 8px 8px;
  top: -8px;
  left: 74%;
  margin-left: -10px;
}

.question-dropdown-item {
  padding: 5px 0px;
  font-size: 13px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
}

.question-dropdown-item i {
  font-size: 20px;
  margin-right: 5px;
}

.question-dropdown-item:focus,
.question-dropdown-item:hover,
.question-dropdown-item:active {
  color: #febd47;
  background-color: transparent;
}

.btn-draw {
  margin-right: 0px;
}

.btn-draw:hover,
.btn-draw:focus {
  text-decoration: none;
}

::v-deep .question-solution {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #f1f3f0;
}

::v-deep .question-solution-title {
  padding: 10px 20px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border-radius: 4px 4px 0px 0px;
  background-color: #42c9f1;
}

::v-deep .question-solution-info {
  padding: 5px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  background-color: #42c9f1;
}

::v-deep .question-solution-msg img {
  max-width: 40px;
  margin-top: -15px;
}

::v-deep .question-solution-msg span {
  margin-left: 10px;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.59;
  letter-spacing: 0.3px;
  color: white;
  text-transform: uppercase;
}

::v-deep .question-solution-info.correct {
  background-color: #00d083;
}

::v-deep .question-solution-info.wrong {
  background-color: #f65a58;
}

.btn-notes {
  border-radius: 20px;
  background-color: #3ccc89;
  font-size: 13px;
  font-weight: bold;
  color: var(--white);
  text-transform: uppercase;
  padding: 5px 15px;
  margin-right: 0px;
  margin-left: auto;
  height: fit-content;
}

.btn-notes .icon-font {
  font-size: 16px;
  margin-right: 5px;
}

.btn-notes:hover,
.btn-notes:focus {
  background-color: #039e65;
}

.solution {
  padding: 20px;
}

.btn-group {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.btn-group a:hover {
  text-decoration: none;
}

.gary-btn.org-btn {
  color: #1c2d60;
  background-color: #f1f3f1;
}

.org-btn {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: bold;
  color: #f5f5f5;
  background-color: #ff881b;
  padding: 5px 15px;
  text-transform: uppercase;
  border: none;
}

.org-btn .icon {
  margin-right: 5px;
}

.gary-btn.org-btn:hover,
.gary-btn.org-btn:focus {
  background-color: #ababab;
}

.org-btn:hover,
.org-btn:focus {
  background-color: #bb6616;
}

.org-btn .icon-font {
  padding-right: 5px;
}

.org-btn:focus,
.org-btn:hover,
.org-btn:active {
  box-shadow: none;
  outline: none;
}

#btn-next {
}

.icon-draw_pad {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .question-solution-msg {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .btn-notes {
    margin-left: 0px;
  }

  .btn-draw {
    display: none;
  }
}
</style>
