import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=6130f356&scoped=true&"
import script from "./Modal.vue?vue&type=script&lang=js&"
export * from "./Modal.vue?vue&type=script&lang=js&"
import style0 from "./modal.css?vue&type=style&index=0&id=6130f356&prod&scoped=true&lang=css&"
import style1 from "./Modal.vue?vue&type=style&index=1&id=6130f356&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6130f356",
  null
  
)

export default component.exports