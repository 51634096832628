<template>
  <div class="question-container">
    <div class="question-info">
      <div class="question-info-left">
        <div class="question-num" v-if="indexNo != null">
          Q{{ indexNo + 1 }}
        </div>
      </div>
    </div>
    <QuestionView :question="question" :isShuffled="false" :permutation="[0, 1, 2, 3]"
      :mcAnswerPermutation="question?.displayQuestion?.sampleEdgePermutation" />
  </div>
</template>
<script>
import QuestionView from '@/components/teacher/new-question-view/QuestionView.vue'
export default {
  components: {
    QuestionView
  },
  props: {
    indexNo: Number,
    question: Object,
  }
}
</script>

<style scoped>
.question-container {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-right: 20px;
  border-radius: 4px;
  background-color: white;
  border: 0.5px solid #a9a9a9;
  margin-top: 20px;
}

.question-info {
  position: relative;
  display: flex;
}

.question-info-left {
  position: relative;
  display: flex;
  align-items: center;
}

.question-num {
  font-size: 22px;
  font-weight: bold;
  color: #1c2d60;
}
</style>