<template>
  <div class="filter-col">
    <div class="btn-group select-topic" role="group">
      <button type="button" class="btn select-topic-dropdown dropdown-toggle" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        {{ displayMenuForm(selectedForm) }}
      </button>
      <div class="dropdown-menu select-topic-dropdown-group btn-select-topic-group">
        <a v-for="f in forms" v-if="f != selectedForm" @click="selectedForm = f"
          class="dropdown-item select-topic-dropdown-item" href="#">
          {{ displayMenuForm(f) }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    forms: Array,
    value: String
  },
  data() {
    return {
      selectedForm: this.value
    }
  },
  watch:{
    selectedForm(newValue, oldValue){
      this.$emit('input', newValue)
    },
    //note: this is now to do two way binding
    value(newValue){
      this.selectedForm = newValue
    }
  },
  computed: {},
  methods: {
    displayMenuForm (x) {
      const chineseNumber = {
        '1': '一',
        '2': '二',
        '3': '三',
        '4': '四',
        '5': '五',
        '6': '六'
      }
      if (x != null) {
        return this.$t(
          this.$interfaceLang,
          'All form ' + x + ' topics',
          '所有中' + chineseNumber['' + x] + '主題'
        )
      } else {
        return this.$t(this.$interfaceLang, 'All topics', '所有主題')
      }
    }
  }
}
</script>
<style src="../../Practice.css" scoped>

</style>