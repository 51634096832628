<template>
  <div>
    <template v-if="solutionData != null" v-for="s in solutionData">
      <p v-if="s.text != null" v-katex v-html="s.text" />
    </template>
  </div>
</template>
<script>
import VKatex from 'vue-katex-auto-render'
export default {
  directives: {
    katex: VKatex
  },
  components: {},
  props: {
    question: Object
  },
  data () {
    return {
      solutionData: null
    }
  },
  computed: {},
  async created () {
    this.solutionData = await this.loadSolutionContent(this.question)
    if (this.solutionData != null) {
      this.loaded = true
    }
  },
  watch: {
    async question (newValue) {
      this.loaded = false
      this.solutionData = await this.loadSolutionContent(newValue)
      this.loaded = this.solutionData != null
    }
  },
  methods: {
    async loadSolutionContent (question) {
      return await this.$loadContent(
        question?.displayQuestion?.solutionEdgeKey + '.json'
      )
    }
  }
}
</script>
<style src="" />
