<template>
  <div>
    <div class="col-title">
      {{ $t($interfaceLang, 'Recursive assignment', '重覆作業') }}
    </div>
    <div class="recursive-list-col">
      <template v-if="autohomeworkEdges != null">
        <template v-for="(edge, index) in autohomeworkEdges">
          <div
            v-if="!edge.autohomework.isDeleted"
            class="recursive-homework-item"
            :key="'item-' + index"
          >
            <span class="dot blue"></span>
            <span class="recursive-homework-name">{{
              edge.autohomework.paperName ??
                $t($interfaceLang, 'Exercise', '練習')
            }}</span>
            <div class="recursive-btn-group">
              <i
                @click="$emit('edit-recursive', edge.autohomework)"
                :class="{ disabled: readOnly }"
                class="icon-font icon-edit"
              >
                &#xe80f;
              </i>
              <i
                @click="$emit('delete-recursive', edge.autohomework)"
                :class="{ disabled: readOnly }"
                class="icon-font icon-delete"
              >
                &#xe80a;
              </i>
            </div>
          </div>
        </template>
      </template>
      <div
        v-else-if="autohomeworkEdges?.length == 0"
        class="recursive-homework-item"
      >
        {{ $t($interfaceLang, 'No recursive assignment.', '沒有重覆作業') }}
      </div>
      <div v-else class="recursive-homework-item">
        ...
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    readOnly: Boolean,
    autohomeworkEdges: Array
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    update () {
      this.$forceUpdate()
    }
  }
}
</script>
<style src="@/pages/teacher/manage-assignment/ManageAssignment.css" scoped />
<style scoped>
.recursive-homework-col {
  position: relative;
  margin-bottom: 20px;
}

.col-title {
  position: relative;
  font-size: 13px;
  font-weight: 600;
  background-color: #007fca;
  color: white;
  align-items: center;
  padding: 20px 15px 10px 15px;
  border-radius: 4px 4px 0px 0px;
}

.recursive-list-col {
  position: relative;
  background-color: white;
  border-radius: 0px 0px 4px 4px;
}

.recursive-homework-item {
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 15px;
  color: #1e2d60;
  font-size: 13px;
  font-weight: 600;
}

.recursive-homework-item .dot {
  width: 12px;
  height: 12px;
}

.recursive-homework-name {
  width: 76%;
}

.recursive-btn-group {
  position: relative;
  display: flex;
  margin-left: auto;
  text-align: right;
  font-size: 14px;
  width: 17%;
}

.recursive-btn-group .icon-font {
  margin-left: 5px;
  cursor: pointer;
}

.icon-delete {
  color: rgb(255, 86, 86);
}

.disabled {
  pointer-events: none !important;
  opacity: 0.67 !important;
}
</style>
