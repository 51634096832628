<template>
  <button
    @click="$emit('input', !value)"
    class="btn-favourite"
  >
    <i
      :class="{
        'icon-font': true,
        'icon-favourite': true,
        'icon-favourite_filled': true,
        active: value
      }"
    >
      <template v-if="value"> &#xe810; </template>
      <template v-else> &#xe811; </template>
    </i>
  </button>
</template>
<script>
export default {
  components: {},
  props: {
    value: Boolean
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>
.btn-favourite {
  border: none;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  color: #1c2d60;
  font-size: 24px;
  margin-right: 20px;
}
</style>