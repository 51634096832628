<template>
  <div class="item-row" :class="typeClass">
    <a href="#" @click.prevent="$emit('goto')" class="click-item">
      <div class="item-name item-hightlight-text">
        {{
          (paper.name ?? '') == ''
            ? defaultAssignmentName[$interfaceLang]
            : paper.name
        }}
      </div>
      <div class="item-date">
        <p class="sm-title">{{ $t($interfaceLang, 'Due', '遞交日期') }}</p>
        <p class="item-hightlight-text">
          {{
            $displayDate(
              paper.expiryTime != null
                ? Number(paper.expiryTime) - 3600 * 24 * 1000
                : null
            )
          }}
        </p>
      </div>
      <div class="item-submission">
        <p class="sm-title">
          {{ $t($interfaceLang, 'Assignment submission', '作業提交數量') }}
        </p>
        <div class="progress-col">
          <div v-if="numStudent != null" class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{ width: submissionRate + '%' }"
            />
          </div>
          <span v-if="numStudent != null" class="item-hightlight-text">
            {{ paper.numSubmission ?? 0 }}/{{ numStudent }}
          </span>
          <span v-else class="item-hightlight-text"> {{ noData }} </span>
        </div>
      </div>
      <div class="item-average-rate">
        <p
          class="sm-title"
          v-html="
            $t(
              $interfaceLang,
              ' Average <br /> correct rate',
              '平均<br />正確率'
            )
          "
        />
        <div
          :class="`average-rate-c c100 p${paper.usergroupAvgCorrectRate} ${correctRateColor}`"
        >
          <span v-if="paper.usergroupAvgCorrectRate != null">
            {{ paper.usergroupAvgCorrectRate }} %</span
          >
          <span v-else>-</span>
          <div class="slice">
            <div class="bar"></div>
            <div class="fill"></div>
          </div>
        </div>
      </div>
      <div class="item-qty">
        <p class="sm-title">
          {{ $t($interfaceLang, 'No. of questions', '問題數量') }}
        </p>
        <p class="item-hightlight-text">{{ paper.numQuestion ?? noData }}</p>
      </div>
      <div class="item-time">
        <p class="sm-title">
          {{ $t($interfaceLang, 'Average time spent', '平均作答時間') }}
        </p>
        <p class="item-hightlight-text">
          {{ avgTimeSpent ?? noData }}
        </p>
      </div>
    </a>
    <button
      v-if="false && hasPaperExpired"
      @click="$emit('followup', { paper })"
      class="btn-follow_up item-btn"
    >
      <i class="icon-font icon-follow_up"> &#xe813; </i>
    </button>
  </div>
</template>
<script>
import config from '@/config.json'
export default {
  components: {},
  props: {
    paper: Object,
    numStudent: Number
  },
  data () {
    return {
      typeOptions: {
        homework: {
          typeClass: 'type-homework',
          caption: this.$t(this.$interfaceLang, 'Homework', '功課')
        },
        recursive: {
          typeClass: 'type-auto',
          caption: this.$t(this.$interfaceLang, 'Auto', '自動')
        },
        recursive_followup: {
          typeClass: 'type-auto',
          caption: this.$t(this.$interfaceLang, 'Auto', '自動')
        },
        test: {
          typeClass: 'type-homework',
          caption: this.$t(this.$interfaceLang, 'Test', '測試')
        }
      },
      defaultAssignmentName: config.app.defaultAssignmentName
    }
  },
  computed: {
    typeClass () {
      return this.typeOptions[this.paper.type]?.typeClass
    },
    avgTimeSpent () {
      let time = this.paper.usergroupAvgTimeSpent
      if (time != null) {
        time = (time / 1000 / 60).toFixed(2)
        if (time > 1) {
          return this.$t(this.$interfaceLang, time + ' min', time + ' 分鐘')
        } else {
          return this.$t(this.$interfaceLang, '< 1 min', '< 1 分鐘')
        }
      }
    },
    submissionRate () {
      if (this.numStudent != null) {
        return ((this.paper.numSubmission ?? 0) / this.numStudent) * 100
      }
    },
    correctRateColor () {
      let avgCorrectRate = this.paper.usergroupAvgCorrectRate
      if (avgCorrectRate != null) {
        return avgCorrectRate < 60 ? 'red' : 'green'
      }
    },
    hasPaperExpired () {
      return (
        this.paper?.expiryTime != null &&
        Number(this.paper?.expiryTime) < Date.now()
      )
    },
    noData () {
      return this.$t(this.$interfaceLang, 'No data', '沒有資料')
    }
  },
  methods: {}
}
</script>
<style scoped>
.click-item {
  position: relative;
  display: flex;
  width: 100%;
}

.click-item:hover,
.click-item:focus {
  text-decoration: none;
}

.click-item:hover .item-name,
.click-item:focus .item-name {
  color: #0c89d3;
}

.type-homework {
  border-left: 5px solid #42c9f1;
}

.type-practice {
  border-left: 5px solid #ffd32d;
}

.type-auto {
  border-left: 5px solid #d0d0d0;
}

.item-btn {
  border: none;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  color: #1c2d60;
  font-size: 26px;
  margin-left: auto;
}

.item-btn:focus,
.item-btn:hover,
.item-btn:active {
  box-shadow: none;
  outline: none;
}

.item-row {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
}

.item-row p {
  margin: 0px;
}

.sm-title {
  font-size: 11px;
  color: #adadad;
}

.item-hightlight-text {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
}

.item-name {
  width: 27%;
  padding-right: 10px;
}

.item-date {
  width: 12%;
  padding-left: 10px;
  border-left: 1px solid #adadad;
}

.item-submission {
  width: 18%;
  padding-right: 10px;
  border-right: 1px solid #adadad;
}

.item-average-rate {
  width: 17%;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.item-qty {
  width: 12%;
}

.item-time {
  width: 13%;
}

.progress-col {
  position: relative;
  display: flex;
  align-items: center;
}

.progress {
  height: 0.5rem;
  width: 78%;
  background-color: #d0d0d0;
}

.progress-bar {
  background-color: #ffd32d;
}

.progress-col .item-hightlight-text {
  margin-left: 5px;
}

.average-rate-c {
  font-size: 40px;
  margin-left: 5px;
}

.average-rate-c span {
  font-weight: bold;
  color: #1c2d60;
}

.average-rate-c.red .bar,
.average-rate-c.red .fill {
  border-color: #f65a58 !important;
}

.average-rate-c.green .bar,
.average-rate-c.green .fill {
  border-color: #3ccc89 !important;
}

@media only screen and (max-width: 991px) {
  .item-date {
    width: 14%;
  }

  .item-submission {
    width: 15%;
  }

  .item-time {
    width: 14%;
  }
}
</style>
