<template>
  <div>
    <LoadingLayer v-if="isLoading || isDuplicating" />
    <DuplicatePaper
      v-if="isLoading == false"
      :paper="paper"
      :paperQuestionEdges="paperQuestionEdges"
      :usergroups="usergroups"
      @duplicate="duplicatePaper($event)"
      @close="$emit('close')"
    />
  </div>
</template>
<script>
import CreatePaperGraphql from './CreatePaper.graphql'
import LoadPaperGrapqhl from './LoadPaper.graphql'
import LoadPaperQuestionsGraphql from './LoadPaperQuestions.graphql'
import LoadPaperCompressContentGraphql from './LoadPaperCompressContent.graphql'

import LoadingLayer from '@/components/LoadingLayer.vue'
import DuplicatePaper from './DuplicatePaper.vue'

import config from '@/config.json'

export default {
  components: {
    LoadingLayer,
    DuplicatePaper
  },
  props: {
    currentUserId: String,
    paperId: String
  },
  data () {
    return {
      isLoading: true,
      isDuplicating: false,
      paper: null,
      paperQuestionEdges: [],
      usergroups: this.$appStore.usergroupData
        ?.filter(x => (x.isUserTeacherOwner = true))
        ?.map(x => x.usergroup)
    }
  },
  async created () {
    var [paper, paperQuestionEdges] = await Promise.all([
      this.loadPaper(this.currentUserId, this.paperId),
      this.loadPaperQuestions(
        this.currentUserId,
        this.paperId,
        this.$interfaceLang,
        20
      )
    ])
    this.isLoading = false
    if (paper != null && paperQuestionEdges != null) {
      this.paper = paper
      this.paperQuestionEdges = paperQuestionEdges
    } else {
      this.$emit('error')
    }
  },
  methods: {
    async loadPaper (currentUserId, paperId) {
      let params = { currentUserId, paperId }
      let result = await this.$apolloQuery(LoadPaperGrapqhl, params)
      return result.data.paper
    },
    async loadPaperQuestions (currentUserId, paperId, lang, first) {
      return await this.$graphqlUtil.graphqlFetchEdges(
        this.$apolloQuery,
        LoadPaperQuestionsGraphql,
        { currentUserId, paperId, lang, first },
        x => x.paperQuestionEdges,
        null,
        6
      )
    },
    async loadPaperCompressContent (currentUserId, paperId, paperName) {
      let result = await this.$apolloQuery(LoadPaperCompressContentGraphql, {
        currentUserId,
        id: paperId,
        paperName
      })
      return result.data.paper?.compressContent
    },
    async createPaper (
      currentUserId,
      usergroupId,
      paperType,
      paperName,
      questionIds
    ) {
      let params = {
        currentUserId,
        usergroupId,
        paperType,
        paperName,
        questionIds
      }
      let result = await this.$apolloQuery(CreatePaperGraphql, params)
      return result.data.createMyPaperWithQuestions
    },
    async duplicatePaper ({ paperId, paperName, usergroupIds }) {
      this.isDuplicating = true
      try {
        if (usergroupIds.includes('other')) {
          await this.copyLinkToClipboard(paperId, paperName)
        }
        this.$toast_success(
          this.$t(this.$interfaceLang, 'Copied link.', '成功複製連結')
        )
      } catch (e) {
        this.$toast_error(
          this.$t(this.$interfaceLang, 'Failed copy link.', '複製連結失敗')
        )
      }
      usergroupIds = usergroupIds.filter(x => x != 'other')
      for (var usergroupId of usergroupIds) {
        try {
          await this.createPaper(
            this.currentUserId,
            usergroupId,
            'homework',
            paperName,
            this.paperQuestionEdges.map(x => x.question.displayQuestion.id)
          )
          let usergroupName = this.$appStore.usergroupData
            .map(x => x.usergroup)
            .find(x => x.id == usergroupId)?.name
          this.$toast_success(
            this.$t(
              this.$interfaceLang,
              `Duplicate successfully to ${usergroupName}.`,
              `成功複製連結至 ${usergroupName}`
            )
          )
        } catch (e) {
          this.$toast_error(
            this.$t(
              this.$interfaceLang,
              `Fail duplicate to ${usergroupName}.`,
              `複製連結至 ${usergroupName} 失敗`
            )
          )
          console.log(e)
        }
      }
      this.isDuplicating = false
      this.$emit('close')
    },
    async copyLinkToClipboard (paperId, paperName) {
      if (config.app.siteUrl != null) {
        let compressContent = await this.loadPaperCompressContent(
          this.currentUserId,
          paperId,
          paperName
        )
        await this.copyToClipboard(
          config.app.siteUrl +
            'teacher/editassignment?content=' +
            compressContent
        )
      }
    },
    async copyToClipboard (text) {
      await navigator.clipboard.writeText(text)
    }
  }
}
</script>
<style src=""></style>
