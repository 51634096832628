<template>
  <div>
    <div class="homework-list">
      <div id="homework-list-todo" class="homework-list-col">
        <div class="filter-col">
          <div class="homework-type-label">
            <div class="homework-type-legend type-new" />
            <span>{{ $t($interfaceLang, 'New', '新功課') }}</span>
          </div>
          <div class="homework-type-label">
            <div class="homework-type-legend type-progress" />
            <span>{{ $t($interfaceLang, 'In Progress', '進行中') }}</span>
          </div>
          <div class="homework-type-label">
            <div class="homework-type-legend type-overdue" />
            <span>{{ $t($interfaceLang, 'Overdue', '逾期') }}</span>
          </div>
        </div>
        <HomeworkTodoRow
          v-for="edge in todoPaperEdges"
          :key="edge.id"
          :paper="edge.paper"
          :numQuestion="edge.numQuestion"
          :numSubmission="edge.numSubmission"
          @goto="$emit('goto', $event)"
        />
      </div>
    </div>
    <InfiniteLoading
      ref="infinite-loading-paper-todo"
      @infinite="$emit('infinite', $event)"
    >
      <div slot="spinner">
        <img class="loading-gif" src="@/assets/images/loading.gif" />
      </div>
      <div slot="no-more">
        {{ $t($interfaceLang, 'No more assignments.', '無其他作業。') }}
      </div>
      <div slot="no-results">
        {{ $t($interfaceLang, 'No more assignments.', '無其他作業。') }}
      </div>
    </InfiniteLoading>
  </div>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading'
import HomeworkTodoRow from './HomeworkTodoRow.vue'

export default {
  components: {
    InfiniteLoading,
    HomeworkTodoRow
  },
  props: {
    todoPaperEdges: Array
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    resetInfiniteLoading () {
      this.$refs['infinite-loading-paper-todo'].stateChanger.reset()
    }
  }
}
</script>
<style src="../../Homework.css" scoped></style>
