<template>
  <div id="deleteRecursiveHomework-model">
    <Modal @close="$emit('close')">
      <template v-slot:header>
        <i class="icon-title icon-font icon-delete">
          &#xe80a;
        </i>
        <span class="title">
          {{
            $t(
              $interfaceLang,
              'Remove recursive assignment',
              '移除重覆作業'
            )
          }}
        </span>
        <i @click="$emit('close')" class="icon-font icon-close">
          &#xe808;
        </i>
      </template>
      <template v-slot:body>
        <div class="modal-section">
          <p class="sm-title">
            {{
              $t(
                $interfaceLang,
                'Do you want to remove the following recursive assignment?',
                '是否要移除以下重覆作業?'
              )
            }}
          </p>
          <ul class="recursive-homework">
            <li>{{ autohomework.paperName ??
              $t($interfaceLang, 'Exercise', '練習') }}</li>
          </ul>
          <div class="section-row align-right">
            <button
              id="btn-cancel"
              class="modal-btn modal-btn-white"
              @click="$emit('close')"
            >
              <i class="icon-font icon-delete">&#xe808;</i>
              {{ $t($interfaceLang, 'Cancel', '取消') }}
            </button>
            <button
              id="btn-delete"
              class="modal-btn modal-btn-red"
              @click="$emit('delete')"
            >
              <i class="icon-font icon-close">&#xe80a;</i>
              {{ $t($interfaceLang, 'Delete', '刪除') }}
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/teacher/modal/Modal.vue'
export default {
  name: 'DeleteRecursiveHomework',
  components: {
    Modal
  },
  props: {
    autohomework: Object
  }
}
</script>

<style src="@/components/teacher/modal/modal.css" scoped></style>
<style scoped>
.recursive-homework {
  font-size: 14px;
  font-weight: normal;
  color: #1c2d60;
  padding-left: 15px;
}
</style>
