<template>
  <div class="report-info topics-mastery">
    <div class="nav-row">
      <div class="report-info-col-title">
        {{ title }}
      </div>
    </div>
    <template v-if="topics != null && topics.length > 0">
      <TopicProgressbar
        v-for="(t, i) in topics"
        :topicName="$t($interfaceLang, t.topic.nameEn, t.topic.nameZh)"
        :numQuestion="t.cache.userStudentStats.numQuestionDone"
        :color="colors[i % colors.length]"
        :correctRate="t.cache.userStudentStats.paperQgroupCorrectRate"
        :classCorrectRate="t.topic.usergroupEdges[0].cache.usergroupStats.paperQgroupCorrectRate"
        :key="'topic-progress-bar-' + i"
      />
    </template>
    <div v-else class="no-data">
      {{ $t($interfaceLang, 'No Data', '沒有資料') }}
    </div>
  </div>
</template>
<script>
import TopicProgressbar from './TopicProgressbar'
export default {
  components: {
    TopicProgressbar
  },
  props: {
    topics: Array,
    title: String
  },
  data () {
    return {
      colors: ['green', 'org', 'purple', 'blue']
    }
  },
  computed: {},
  methods: {}
}
</script>
<style src="../../Performance.css" scoped></style>
<style scoped>
.no-data {
  color: darkgrey;
  font-size: 30px;
  font-weight: bold;
}
</style>
