<template>
  <div class="nav-row">
    <div id="btn-back" @click="$emit('back')">
      <i class="icon-font icon-arrow_left">&#xe804;</i>
    </div>
    <SectionSelect
      v-model="section"
      :numQuestion="numQuestion"
      @input="$emit('section', { section })"
    />
    <YearTermDropdown
      v-show="section == 'library'"
      v-model="yearTerm"
      :yearTerms="getYearTerms(topics)"
      @input="$emit('year-term', { yearTerm })"
    />
    <div v-show="section == 'library'" class="random-col">
      <button
        v-if="!readOnly"
        id="btn-random"
        class="org-btn"
        @click="$emit('random')"
      >
        <i class="icon-font icon-plus">&#xe817;</i>
        {{ $t($interfaceLang, 'Random', '隨機') }}
      </button>
    </div>
  </div>
</template>
<script>
import SectionSelect from './SectionSelect.vue'
import YearTermDropdown from './YearTermDropdown.vue'
export default {
  components: {
    SectionSelect,
    YearTermDropdown
  },
  props: {
    yearTermPrefrence: Array,
    usergroupSchoolYear: Number,
    numQuestion: Number,
    topics: Array,
    readOnly: Boolean
  },
  data () {
    return {
      section: 'edit',
      yearTerm: this.getDefaultYearTerm(this.topics, this.yearTermPrefrence)
    }
  },
  created () {
    this.$emit('year-term', { yearTerm: this.yearTerm })
  },
  computed: {},
  methods: {
    getYearTerm (topic) {
      return [topic.schoolYear, topic.term]
    },
    getYearTerms (topics) {
      let yearTerms = topics.map(this.getYearTerm).map(x => `${x[0]}-${x[1]}`)
      return [...new Set(yearTerms)]
        .map(x => x.split('-').map(Number))
        .sort(this.compareYearTerm)
    },
    getDefaultYearTerm (topics, pref) {
      let topic = null
      if (pref != null) {
        try {
          topic = topics.find(x => x.schoolYear == pref[0] && x.term == pref[1])
        } catch (e) {}
      }
      if (topic == null) {
        if (topics.length > 0 && this.usergroupSchoolYear != null) {
          let maxSchoolYear = Math.max(
            ...topics
              .filter(x => x.schoolYear <= this.usergroupSchoolYear)
              .map(x => x.schoolYear)
          )
          let minTerm = Math.min(
            ...topics
              .filter(x => x.schoolYear == maxSchoolYear)
              .map(x => x.term)
          )
          topic =
            topics.find(
              x => x.schoolYear == maxSchoolYear && x.term == minTerm
            ) ?? topics[0]
        } else {
          topic = topics[0]
        }
      }
      return this.getYearTerm(topic)
    },
    compareYearTerm (yt1, yt2) {
      if (yt1[0] == yt2[0]) {
        return yt1[1] - yt2[1]
      } else {
        return yt1[0] - yt2[0]
      }
    },
    setSection (x) {
      this.section = x
      this.$emit('section', { section: x })
    }
  }
}
</script>

<style src="@/pages/teacher/edit-assignment/EditAssignment.css" scoped />
