<template></template>
<script>
import LoadScheduleSubtopicsQuery from './LoadScheduleSubtopics.graphql'
import LoadTopicsQuery from './LoadTopics.graphql'

export default {
  components: {},
  props: {
    currentUserId: String,
    tschemaId: String,
    usergroupId: String
  },
  data () {
    return {}
  },
  computed: {},
  async created () {
    let topics = this.$appStore.currentTopics
    if (topics == null || topics?.usergroupId != this.usergroupId) {
      let subtopicEdges = await this.fetchSubtopics(
        this.currentUserId,
        this.tschemaId,
        25
      )
      // TODO: sort subtopicEdges by schoolYear, term, displayOrder
      let topicEdges = await this.fetchTopics(
        this.currentUserId,
        this.usergroupId,
        25
      )
      topics = this.getTopics(subtopicEdges, topicEdges)
      this.$appStore.updateCurrentTopics({
        usergroupId: this.usergroupId,
        topics
      })
    }
    this.$emit('loaded')
  },
  methods: {
    compareSchoolYearTerm (x1, x2) {
      if (x1.schoolYear < x2.schoolYear) {
        return -1
      } else if (x1.schoolYear == x2.schoolYear) {
        return x1.term < x2.term ? -1 : x1.term > x2.term ? 1 : 0
      } else {
        return 1
      }
    },
    getTopics (subtopicEdges, topicEdges) {
      return topicEdges
        .map(topicEdge => {
          let topic = topicEdge.topic
          topic.subtopics = subtopicEdges
            .map(x => ({
              ...(x.subtopic ?? {}),
              schoolYear: x.schoolYear,
              term: x.term,
              startTime: x.startTime,
              displayOrder: x.defaultOrder
            }))
            .filter(x => x.topicEdges[0].topic.id == topic.id)
            .sort((st1, st2) => {
              if (st1.startTime == st2.startTime) {
                return (st1.displayOrder ?? -1) - (st2.displayOrder ?? -1)
              } else {
                return Number(st1.startTime ?? -1) - (st2.startTime ?? -1)
              }
            })
          if (topic.subtopics.length != 0) {
            topic.schoolYear = Infinity
            topic.term = Infinity
            topic.displayOrder = Infinity
            for (var i = 0; i < topic.subtopics.length; ++i) {
              if (this.compareSchoolYearTerm(topic.subtopics[i], topic) < 0) {
                topic.schoolYear = topic.subtopics[i].schoolYear
                topic.term = topic.subtopics[i].term
              }
              if (topic.subtopics[i].displayOrder < topic.displayOrder) {
                topic.displayOrder = topic.subtopics[i].displayOrder
              }
            }
          } else {
            topic = null
          }
          return topic
        })
        .filter(x => x != null)
        .sort((t1, t2) => {
          if (t1.schoolYear == t2.schoolYear) {
            if (t1.term == t2.term) {
              return (t1.displayOrder ?? -1) - (t2.displayOrder ?? -1)
            } else {
              return (t1.term ?? -1) - (t2.term ?? -1)
            }
          } else {
            return (t1.schoolYear ?? -1) - (t2.schoolYear ?? -1)
          }
        })
    },
    async fetchSubtopics (userId, tschemaId, batchSize) {
      const variables = { currentUserId: userId, tschemaId, first: batchSize }
      return await this.$graphqlUtil.graphqlFetchEdges(
        this.$apolloQuery,
        LoadScheduleSubtopicsQuery,
        variables,
        x => x.tschemaSubtopicEdges
      )
    },
    async fetchTopics (userId, usergroupId, batchSize) {
      const variables = {
        currentUserId: userId,
        id: usergroupId,
        first: batchSize
      }
      return await this.$graphqlUtil.graphqlFetchEdges(
        this.$apolloQuery,
        LoadTopicsQuery,
        variables,
        x => x.usergroup.topicEdges
      )
    }
  }
}
</script>
<style src="" />
