<template>
  <div>
    <FavQuestion
      v-if="name == 'fav-question'"
      :question="question"
      :permutation="[0, 2, 1, 3]"
      :displayAnswerIndex="2"
      :correctAnswerIndex="0"
      :showSolution="true"
      :solutionEdgeKey="question.displayQuestion.solutionEdgeKey"
    />
    <FavQuestionAdapter
      v-if="name == 'fav-question-adapter'"
      :question="question"
      :currentUserId="'786'"
      :usergroupId="'60'"
    />
  </div>
</template>
<script>
import questionData from './question.js'
import FavQuestion from '@/pages/student/favourite/components/fav-question/FavQuestion.vue'
import FavQuestionAdapter from '@/pages/student/favourite/components/fav-question/FavQuestionAdapter.vue'
export default {
  components: {
    FavQuestion,
    FavQuestionAdapter
  },
  props: {},
  data () {
    return {
      name: this.$route?.query?.name,
      question: questionData
    }
  },
  computed: {},
  methods: {}
}
</script>
<style src="" />
