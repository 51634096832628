<template>
  <div class="btn-group select-more" role="group">
    <button
      type="button"
      class="btn assisgnment-dropdown"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="icon-font icon-more">&#xe816;</i>
    </button>
    <div
      class="dropdown-menu dropdown-menu-right assisgnment-dropdown-group btn-select-more-group"
    >
      <a
        v-if="false"
        @click.prevent="$emit('share', { paper })"
        class="dropdown-item assisgnment-dropdown-item"
        href="#"
      >
        <i class="icon-font icon-share">&#xe81f;</i>
        {{ $t($interfaceLang, 'Share', '分享') }}
      </a>
      <a
        v-if="readOnly !== true"
        @click.prevent="$emit('duplicate', { paper })"
        class="dropdown-item assisgnment-dropdown-item"
        href="#"
      >
        <i class="icon-font icon-duplicate">&#xe80e;</i>
        {{ $t($interfaceLang, 'Duplicate', '複製') }}
      </a>
      <a
        v-if="readOnly != true"
        @click.prevent="$emit('delete', { paper })"
        class="dropdown-item assisgnment-dropdown-item"
        href="#"
      >
        <i class="icon-font icon-Delete">&#xe80a;</i>
        {{ $t($interfaceLang, 'Delete', '刪除') }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    paper: Object,
    readOnly: Boolean
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="" />
