<template>
  <div>
    <OverviewChart
      ref="overview"
      :key="Object.keys(tileLookup).length"
      :students="students"
      :papers="papers"
      :numPaper="numPaper"
      :tileLookup="tileLookup"
      :isLoadingPapers="isLoadingPapers"
      :isLoading="isLoading"
      :isLoadingStudents="isLoadingStudents"
    />
  </div>
</template>
<script>
import LoadStudentsQuery from './LoadStudents.graphql'
import LoadPapersQuery from './LoadPapers.graphql'
import LoadDisplayDataQuery from './LoadDisplayData.graphql'
import OverviewChart from '../../components/overview-chart/OverviewChart.vue'
export default {
  components: {
    OverviewChart
  },
  props: {
    currentUserId: String,
    usergroupId: String
  },
  data () {
    return {
      papers: [],
      students: [],
      tileLookup: {},
      numPaper: 8,
      studentBatchSize: 20,
      isLoadingStudents: true,
      isLoadingPapers: true,
      isLoading: true
    }
  },
  computed: {},
  async created () {
    this.students = await this.fetchStudents()
    this.isLoadingStudents = false
    this.papers = await this.fetchPapers()
    this.isLoadingPapers = false
    this.$nextTick(_ => this.$forceUpdate())
    await this.loadDisplayData()
    this.isLoading = false
  },
  methods: {
    async fetchStudents () {
      let variables = {
        currentUserId: this.currentUserId,
        usergroupId: this.usergroupId,
        first: this.studentBatchSize
      }
      return await this.$graphqlUtil
        .graphqlFetchEdges(
          this.$apolloQuery,
          LoadStudentsQuery,
          variables,
          x => x.usergroupUserEdges
        )
        .then(res => res?.map(x => x.user))
    },
    async fetchPapers () {
      let variables = {
        currentUserId: this.currentUserId,
        usergroupId: this.usergroupId,
        expiryToTime: '' + (Date.now() + 7 * 3600 * 24 * 1000),
        first: this.numPaper
      }
      return await this.$apolloQuery(LoadPapersQuery, variables).then(res =>
        res?.data?.usergroupPaperEdges.map(x => x.paper)
      )
    },
    async fetchDisplayData (batchSize, displayDataEndCursor) {
      let variables = {
        currentUserId: this.currentUserId,
        paperIds: this.papers.map(x => x.id),
        first: batchSize,
        after: displayDataEndCursor
      }
      return await this.$apolloQuery(LoadDisplayDataQuery, variables).then(
        res => res?.data?.paperUserEdgesByPapers
      )
    },
    async loadDisplayData () {
      let batchSize = 20
      let MAX_IT = (this.numPaper * 50) / batchSize
      let displayDataEndCursor = null
      for (var i = 0; i < MAX_IT; ++i) {
        let data = await this.fetchDisplayData(batchSize, displayDataEndCursor)
        data.forEach(d => {
          this.updateTileLookup(d)
        })
        this.$nextTick(_ => {
          this.$refs['overview']?.$forceUpdate()
        })
        if (data.length < batchSize) {
          break
        } else {
          displayDataEndCursor = data[batchSize - 1]?.id
        }
      }
      this.fillEmptyTile()
      this.updateOverduePaper()
    },
    getDisplaySubmitStatus (submitStatus, isPaperExpired) {
      if (isPaperExpired) {
        if (submitStatus == 'submitted' || submitStatus == 'late') {
          return submitStatus
        } else {
          return 'overdue'
        }
      } else {
        return submitStatus ?? 'new'
      }
    },
    tileDataMap (data) {
      return {
        studentId: data.user.id,
        paperId: data.paper.id,
        isPaperExpired: this.isPaperExpired(data.paper),
        numCorrectQuestion: data.cache?.userStudentStats?.numCorrectQuestion,
        numSubmittedQuestion:
          data.cache?.userStudentStats?.numSubmittedQuestion,
        numQuestion:
          data.paper?.cache?.stats?.numQuestion ?? data.paper.numQuestion,
        submitStatus: this.getDisplaySubmitStatus(
          data.cache?.userStudentSubmitStatus,
          this.isPaperExpired(data.paper)
        ),
        submitTime: data?.cache?.userStudentSubmitTime
      }
    },
    updateTileLookup (data) {
      let index = this.tileIndex(data.user.id, data.paper.id)
      this.tileLookup[index] = this.tileDataMap(data)
    },
    fillEmptyTile () {
      for (var student of this.students) {
        for (var paper of this.papers) {
          let index = this.tileIndex(student.id, paper.id)
          if (this.tileLookup[index] == null) {
            this.tileLookup[index] = this.tileDataMap({
              user: student,
              paper,
              cache: {
                userStudentSubmitStatus: 'new'
              }
            })
          }
        }
      }
    },
    tileIndex (studentId, paperId) {
      return (studentId ?? 'null') + '-' + (paperId ?? 'null')
    },
    updateOverduePaper () {
      for (const key in this.tileLookup) {
        let tile = this.tileLookup[key]
        if (tile.submitStatus == 'overdue') {
          let studentId = tile.studentId
          let paperId = tile.paperId
          let student = this.students.find(x => x.id == studentId)
          if (student != null) {
            student.overduePapers = student.overduePapers ?? []
            let paper = this.papers.find(x => x.id == paperId)
            if (paper != null) {
              student.overduePapers.push(paper)
            }
          }
        }
      }
    },
    isPaperExpired (paper) {
      return paper.expiryTime != null && Number(paper.expiryTime) < Date.now()
    }
  }
}
</script>
<style src="" />
