<template>
  <div>
    <div class="homework-list">
      <div id="homework-list-past" class="homework-list-col">
        <div class="filter-col">
          <div class="homework-type-label">
            <div class="homework-type-legend type-submitted"></div>
            <span>{{ $t($interfaceLang, 'On Time', '準時') }}</span>
          </div>
          <div class="homework-type-label">
            <div class="homework-type-legend type-late"></div>
            <span>{{ $t($interfaceLang, 'Late', '遲交') }}</span>
          </div>
        </div>
        <HomeworkPastRow
          v-for="edge in pastPaperEdges"
          :key="edge.id"
          :paper="edge.paper"
          :numQuestion="edge.numQuestion"
          :numCorrect="edge.numCorrect"
          @goto="$emit('goto', $event)"
        />
      </div>
    </div>
    <InfiniteLoading
      ref="infinite-loading-paper-past"
      @infinite="$emit('infinite', $event)"
    >
      <div slot="spinner">
        <img class="loading-gif" src="@/assets/images/loading.gif" />
      </div>
      <div slot="no-more">
        {{ $t($interfaceLang, 'No more assignments.', '無其他作業。') }}
      </div>
      <div slot="no-results">
        {{ $t($interfaceLang, 'No more assignments.', '無其他作業。') }}
      </div>
    </InfiniteLoading>
  </div>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading'
import HomeworkPastRow from './HomeworkPastRow.vue'

export default {
  components: {
    InfiniteLoading,
    HomeworkPastRow
  },
  props: {
    pastPaperEdges: Array
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    resetInfiniteLoading () {
      this.$refs['infinite-loading-paper-past'].stateChanger.reset()
    }
  }
}
</script>
<style src="../../Homework.css" scoped></style>
