<template>
  <div class="btn-group" role="group">
    <button
      id="btn-select-profile"
      type="button"
      class="btn dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <div class="profile-pic">
        <img :src="profileImage(avatarName)" />
      </div>
      <span class="profile-name">
        <p class="profile-text">
          {{ displayName ?? roleName($interfaceLang, userRole) }}
        </p>
        <p class="profile-text role-name">
          {{ roleName($interfaceLang, userRole) }}
        </p>
      </span>
    </button>
    <div
      class="dropdown-menu dropdown-menu-right btn-select-profile-group"
      aria-labelledby="btn-select-profile"
    >
      <a
        v-if="false"
        @click.prevent="$emit('editProfile')"
        class="dropdown-item btn-select-profile-menu"
        href="#"
      >
        <i class="icon-font icon-profile">&#xe818;</i>
        {{ $t($interfaceLang, 'Edit Profile', '個人化設定') }}
      </a>
      <a
        v-if="false"
        @click.prevent="$router.push({ path: '/teacher/teachingschedule' })"
        class="dropdown-item btn-select-profile-menu"
        href="#"
      >
        <i class="icon-font icon-calendar">&#xe823;</i>
        {{ $t($interfaceLang, 'Teaching Schedule', '教學時間表') }}
      </a>
      <a
        v-if="false"
        class="dropdown-item btn-select-profile-menu disabled"
        href="#"
      >
        <i class="icon-font icon-favourite">&#xe811;</i>
        {{ $t($interfaceLang, 'Favourite', '我的最愛') }}
        <span class="coming-soon"
          >({{ $t($interfaceLang, 'Coming soon!', '即將推出!') }})</span
        >
      </a>
      <a v-if="false" class="dropdown-item btn-select-profile-menu" href="#">
        <i class="icon-font icon-settings">&#xe81e;</i>
        {{ $t($interfaceLang, 'Settings', 'Settings') }}
      </a>
      <a
        @click.prevent="$router.push({ path: '/logout' })"
        class="dropdown-item btn-select-profile-menu"
        href="#"
      >
        <i class="icon-font icon-logout">&#xe835;</i>
        {{ $t($interfaceLang, 'Logout', '登出') }}
      </a>
      <template v-if="identities.length > 1">
        <div class="white-line"></div>
        <p class="switch-txt">
          {{ $t($interfaceLang, 'Switch Account(s)', '切換帳號') }}
        </p>
        <AccountSwitchAdapter />
      </template>
    </div>
  </div>
</template>
<script>
import AccountSwitchAdapter from '../adapters/AccountSwitchAdapter.vue'
export default {
  components: {
    AccountSwitchAdapter
  },
  props: {
    avatarName: String,
    displayName: String,
    userRole: String,
    identities: Array
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    profileImage (val) {
      var avatarName
      if (val == null || val == 'undefined' || val == '') {
        avatarName = 'profile_teacher_01'
      } else {
        avatarName = val
      }
      return require('@/assets/images/avatar/' + avatarName + '.png')
    },
    roleName (lang, val) {
      let names = {
        'en_hk-teacher': 'Teacher',
        'en_hk-super_teacher': 'Super Teacher',
        'en_hk-student': 'Student',
        'en_hk-inspector': 'Inspector',
        'zh_hk-teacher': '教師',
        'zh_hk-super_teacher': '超級教師',
        'zh_hk-student': '學生',
        'zh_hk-inspector': '監督'
      }
      return names[`${lang}-${val}`]
    }
  }
}
</script>

<style scoped>
.switch-txt {
  position: relative;
  display: block;
  font-size: 12px;
  width: 80%;
  padding-top: 10px;
  margin: 0 auto;
  color: #ddc254;
}

.white-line {
  position: relative;
  display: block;
  width: 80%;
  height: 0.5px;
  margin: 0 auto;
  background-color: #fff;
}

.btn-switch-acc {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  margin: 5px 0px;
}

.btn-switch-acc:hover .profile-name {
  color: #ddc254;
}

.btn-switch-acc .profile-pic {
  margin-right: 5px;
}

.btn-switch-acc .profile-name {
  color: #fff;
  line-height: 1.1em;
}

#profile {
  padding-left: 20px;
}

.profile-pic {
  cursor: pointer;
}

.profile-name {
  font-size: 14px;
  color: #1e2d60;
  padding: 0px 5px;
  text-align: left;
}

.profile-text {
  padding: 0px;
  margin: 0px;
}

.role-name {
  font-size: 10px;
}

.profile-pic img {
  width: 32px;
}

#btn-select-profile {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
  height: 56px;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
}

#btn-select-profile:before {
  content: '';
  height: 25px;
  border-left: 1.5px solid #bbbbbb;
  padding-right: 15px;
}

.btn-select-profile-group {
  padding: 0px;
  margin: 0 auto;
  min-width: 85%;
  background-color: #192c64;
  border: 0px;
  border-radius: 0px;
}

.btn-select-profile-menu {
  color: white;
  font-size: 13px;
  font-weight: normal;
  line-height: 2.3;
}

.btn-select-profile-menu:hover,
.btn-select-profile-menu:active {
  color: #ddc254;
  background-color: #000;
}

.icon-font {
  font-size: 17px;
  color: #ffd32d;
  padding-right: 5px;
}

.coming-soon {
  font-size: 8pt;
  font-weight: bold;
  color: lightblue;
}

.disabled {
  opacity: 0.67;
  pointer-events: none;
}

@media only screen and (max-width: 991px) {
  #btn-select-profile:before {
    content: none;
  }
}
</style>
