<template>
  <Settings
    :key="settingKey"
    :topics="editTopics"
    :readOnly="readOnly"
    :hasChanged="hasChanged"
    @save="saveSchedule($event)"
    @overview="$emit('overview')"
    @reset="
      resetSchedule()
      $emit('reset')
    "
  />
</template>
<script>
import UpdateTeachingScheduleQuery from './UpdateTeachingSchedule.graphql'
import Settings from './Settings.vue'
export default {
  components: {
    Settings
  },
  props: {
    currentUserId: String,
    tschemaId: String,
    topics: Array,
    readOnly: Boolean
  },
  data () {
    return {
      editTopics: this.getTopics(this.topics),
      settingKey: 0
    }
  },
  computed: {
    hasChanged () {
      return (
        this.editTopics
          .map(x => x.subtopics)
          .reduce((a, b) => [...a, ...b])
          .filter(x => x.oldStartTime != x.startTime).length > 0
      )
    }
  },
  methods: {
    getTopics (topics) {
      let newTopics = JSON.parse(JSON.stringify(topics))
      newTopics.forEach(t =>
        t.subtopics.forEach(st => (st.oldStartTime = st.startTime))
      )
      return newTopics
    },
    async saveSchedule ({ topics }) {
      let subtopics = topics
        .map(x => x.subtopics)
        .reduce((a, b) => [...a, ...b])
        .filter(x => x.oldStartTime != x.startTime)
      let inputs = subtopics.map(x => ({
        subtopicId: x.id,
        startTime: x.startTime
      }))
      this.$emit('save-mutation-start')
      try {
        let result = await this.$apolloMutation(UpdateTeachingScheduleQuery, {
          currentUserId: this.currentUserId,
          tschemaId: this.tschemaId,
          inputs
        }).then(res => res?.data?.updateMyTschemaSchedule)
        result.success
          ? this.$emit('save-mutation-done', {
              tschemaSubtopicEdges: result.tschemaSubtopicEdges
            })
          : this.$emit('save-mutation-error')
      } catch (e) {
        this.$log(e.message)
        this.$emit('save-mutation-error')
      }
    },
    resetSchedule () {
      this.editTopics.forEach(t =>
        t.subtopics.forEach(st => {
          st.startTime = st.oldStartTime
        })
      )
      this.settingKey++
    }
  }
}
</script>
<style src="" />
