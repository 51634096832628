<template>
  <div class="assignment-row" :class="rowClass" :key="paperRowKey">
    <div class="assignment-type">
      <p>{{ $t(lang, 'Type', '種類') }}</p>
      <div class="btn assignment-dropdown">
        {{ $t(lang, paperTypeNameEn, paperTypeNameZh) }}
      </div>
    </div>
    <div class="assignment-format">
      <p>{{ $t(lang, 'Format', '格式') }}</p>
      <IsDynamicDropdown
        v-model="isDynamic"
        :readOnly="readOnly || locked"
        @input="$emit('isdynamic', { paper, isDynamic })"
      />
    </div>
    <div class="assignment-info">
      <div class="assignment-name">
        <span v-if="paper.name != null && paper.name != ''">
          {{ paper.name }}
        </span>
        <span class="name-placeholder" v-else>
          {{ $t($interfaceLang, '(Default Title)', '(預設標題)') }}
        </span>
        <div class="auto-followup-info">
          <span v-if="paper.type == 'recursive_followup'">
            ({{ $t(lang, 'Follow up', '跟進') }})
          </span>
        </div>
      </div>
      <div class="assignment-lock">
        <i class="icon-font icon-locked" v-if="locked">
          &#xe814;
        </i>
      </div>
    </div>
    <div id="assignment-start" class="assignment-date">
      <p>{{ $t(lang, 'Release on', '發佈日期') }}</p>
      <p class="assignment-start-date">
        {{ $displayDate(paper.startTime) }}
      </p>
    </div>
    <div id="assignment-due" class="assignment-date">
      <p>{{ $t(lang, 'Deadline', '遞交日期') }}</p>
      <p class="assignment-due-date">
        {{
          $displayDate(
            paper.expiryTime != null
              ? Number(paper.expiryTime) - 3600 * 24 * 1000
              : null
          )
        }}
      </p>
    </div>
    <div class="assignment-btn">
      <template v-if="!readOnly">
        <button @click="$emit('edit')" id="btn-edit">
          <i class="icon-font icon-edit">&#xe80f;</i>
        </button>
        <button v-if="isPreview" @click="$emit('create')" id="btn-save">
          <i class="icon-font icon-save">&#xe81d;</i>
          {{ $t(lang, 'Create', '建立') }}
        </button>
        <button
          v-else
          @click="$emit('save')"
          :class="{ disabled: hasChanged === false }"
          id="btn-save"
        >
          <i class="icon-font icon-save">&#xe81d;</i>
          {{ $t(lang, 'Save', '儲存') }}
        </button>
      </template>
    </div>
  </div>
</template>
<script>
import config from '@/config.json'
import IsDynamicDropdown from './IsDynamicDropdown.vue'
export default {
  components: {
    IsDynamicDropdown
  },
  props: {
    lang: String,
    paper: Object,
    readOnly: Boolean,
    locked: Boolean,
    hasChanged: Boolean,
    isPreview: Boolean
  },
  data () {
    return {
      isDynamic: this.paper.isDynamic,
      paperRowKey: 0
    }
  },
  computed: {
    rowClass () {
      return {
        'type-homework': this.paper?.type == 'homework',
        'type-auto': ['recursive', 'recursive_followup'].includes(
          this.paper?.type
        ),
        'type-test': this.paper?.type == 'test',
        selected: this.selected
      }
    },
    typeName () {
      let types = {
        homework: this.$t(this.lang, 'Homework', '功課'),
        recursive: this.$t(this.lang, 'Recurisve', '重覆'),
        recursive_followup: this.$t(this.lang, 'Recurisve', '重覆')
      }
      return types[this.paper?.type]
    },
    paperTypeNameEn () {
      if (this.paper.type == 'homework') {
        return 'Homework'
      } else if (
        this.paper.type == 'recursive' ||
        this.paper.type == 'recursive_followup'
      ) {
        return 'Recursive'
      } else if (this.paper.type == 'freepractice') {
        return 'Practice'
      } else if (this.paper.type == 'test') {
        return 'Test'
      }
    },
    paperTypeNameZh () {
      if (this.paper.type == 'homework') {
        return '家課'
      } else if (
        this.paper.type == 'recursive' ||
        this.paper.type == 'recursive_followup'
      ) {
        return '重覆'
      }
    }
  },
  methods: {
    displayName (name) {
      if (name == null || name == '') {
        return null
      }
      return name
    },
    update () {
      this.paperRowKey++
    }
  }
}
</script>

<style scoped>
.assignment-row {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
}

.assignment-row >>> p {
  margin: 0px;
}

.assignment-row .btn-group {
  width: 100%;
}

.type-auto {
  border-left: 5px solid #d0d0d0;
}

.type-homework {
  border-left: 5px solid #42c9f1;
}

.type-test {
  border-left: 5px solid #ffd32d;
}

.type-practice {
  border-left: 5px solid #d0d0d0;
}

.assignment-type {
  font-size: 11px;
  color: #adadad;
  width: 15%;
}

.assignment-format {
  font-size: 11px;
  color: #adadad;
  width: 14%;
  padding-left: 10px;
  border-right: 1px solid #adadad;
  border-left: 1px solid #adadad;
}

::v-deep .assignment-dropdown {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
  margin: 0px;
  padding: 0px;
  width: 100%;
  text-align: left;
}

::v-deep .assignment-dropdown:after {
  position: absolute;
  right: 13px;
  top: 9px;
}

::v-deep .assignment-dropdown:focus,
::v-deep .assignment-dropdown:hover,
::v-deep .assignment-dropdown:active {
  box-shadow: none;
  outline: none;
}

::v-deep .assignment-dropdown-group {
  padding: 20px;
  box-shadow: 0 2px 6px 0 rgba(70, 70, 70, 0.4);
  border-radius: 10px;
  background-color: #1c2d60;
  min-width: 100%;
  margin-top: 10px;
}

::v-deep .assignment-dropdown-group:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #1c2d60 transparent;
  border-width: 0 8px 8px;
  top: -8px;
  left: 74%;
  margin-left: -10px;
}

::v-deep .assignment-dropdown-item {
  padding: 5px 0px;
  font-size: 13px;
  font-weight: 600;
  color: white;
}

::v-deep .assignment-dropdown-item i {
  font-size: 20px;
  margin-right: 5px;
}

::v-deep .assignment-dropdown-item:focus,
::v-deep .assignment-dropdown-item:hover,
::v-deep .assignment-dropdown-item:active {
  color: #febd47;
  background-color: transparent;
}

.assignment-info {
  position: relative;
  width: 35%;
  display: flex;
  align-items: center;
  padding: 0px 15px;
}

.assignment-name {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
  width: 100%;
}

.assignment-date {
  width: 13%;
  font-size: 11px;
  color: #adadad;
  padding: 0px 10px;
}

#assignment-start {
  border-left: 1px solid #adadad;
}

.assignment-start-date,
.assignment-due-date {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
}

.assignment-btn {
  display: flex;
  justify-content: flex-end;
  width: 15%;
  align-items: center;
}

.assignment-btn button {
  border: none;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  color: #1c2d60;
  font-size: 26px;
}

.assignment-btn button:focus,
.assignment-btn button:hover,
.assignment-btn button:active {
  box-shadow: none;
  outline: none;
}

.assignment-btn .select-more {
  width: auto;
}

.assignment-btn #btn-edit {
  margin-right: 10px;
}

.btn-select-more-group {
  margin-right: -20px;
}

#btn-save {
  position: relative;
  display: block;
  border-radius: 20px;
  background-color: #ff881b;
  border-style: none;
  font-size: 13px;
  font-weight: 600;
  color: white;
  padding: 0px 15px;
  text-transform: uppercase;
  height: 30px;
  width: max-content;
}

#btn-save:focus,
#btn-save:hover,
#btn-save:active {
  box-shadow: none;
  outline: none;
  background-color: #ffa856;
}

#btn-save i {
  font-size: 16px;
  padding-right: 5px;
}
.disabled {
  opacity: 0.67;
  pointer-events: none;
}
.assignment-lock {
  font-size: 24px;
  color: #f65a58;
}

.name-placeholder {
  color: #999999;
}
</style>
