<template>
  <ReportAssignmentList
    @goto="
      $router.push({
        path: '/teacher/reportnstatistics/assignment',
        query: { id: $event.id }
      })
    "
    @followup="$emit('followup', $event)"
    :numStudent="numStudent"
    :isShowDueOnly="isShowDueOnly"
    @infinite="atInfinity($event)"
    :papers="papers.map(parseReportListPaper)"
  />
</template>
<script>
import LoadAssignmentListQuery from './LoadAssignmentList.graphql'
import ReportAssignmentList from '../../components/report-assignment-list/ReportAssignmentList.vue'
export default {
  components: {
    ReportAssignmentList
  },
  props: {
    currentUserId: String,
    usergroupId: String,
    numStudent: Number,
    isShowDueOnly: Boolean
  },
  data () {
    return {
      batchSize: 10,
      upToExpiryDays: 7,
      endPaperCursor: null,
      sortBy: 'EXPIRY_TIME',
      sortOrder: 'DESC',
      papers: [],
      isLoading: false
    }
  },
  computed: {},
  methods: {
    parseReportListPaper (paper) {
      if (paper != null) {
        return {
          id: paper.id,
          type: paper.type,
          expiryTime: paper.expiryTime,
          name: paper.name,
          numQuestion: paper.cache?.stats?.numQuestion ?? paper.numQuestion,
          numSubmission: paper.cache?.stats?.numSubmission,
          usergroupAvgTimeSpent: paper.cache?.stats?.usergroupAvgTimeSpent,
          usergroupAvgCorrectRate: paper.cache?.stats?.usergroupAvgCorrectRate
        }
      }
    },
    async fetchMoreAssignments () {
      return await this.$apolloQuery(LoadAssignmentListQuery, {
        currentUserId: this.currentUserId,
        creatorId: this.currentUserId,
        usergroupId: this.usergroupId,
        startToTime: '' + Date.now(),
        expiryToTime: null,
        first: this.batchSize,
        after: this.endPaperCursor,
        sortBy: this.sortBy,
        sortOrder: this.sortOrder
      }).then(res => res?.data?.usergroupPaperEdges ?? [])
    },
    async atInfinity (state) {
      if (!this.isLoading) {
        this.isLoading = true
        try {
          let edges = await this.fetchMoreAssignments()
          if (edges.length > 0) {
            this.endPaperCursor = edges[edges.length - 1]?.id
          }
          if (edges.length < this.batchSize) {
            state.complete()
          } else {
            state.loaded()
          }
          this.papers = [
            ...this.papers,
            ...edges.map(x => JSON.parse(JSON.stringify(x.paper)))
          ]
        } finally {
          this.isLoading = false
        }
      }
    }
  }
}
</script>
<style src="" />
