<!-- <<<ref>>>
  branch: dev
  file: src\pages\student\practice\Practice.vue
  code: <div class="select-types">...</div> 
-->
<template>
  <div class="nav-row">
    <div class="select-types">
      <div
        :class="{ selected: currentSection === 'freePractice' }"
        @click="currentSection = 'freePractice'"
        id="type-freePractice"
        class="select-item"
      >
        {{ $t($interfaceLang, 'Practices', '所有練習') }}
      </div>
      <div
        :class="{ selected: currentSection === 'history' }"
        @click="currentSection = 'history'"
        id="type-history"
        class="select-item"
      >
        {{ $t($interfaceLang, 'History', '練習記錄') }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: String
  },
  data () {
    return {
      currentSection: this.value
    }
  },
  watch:{
    currentSection(newValue, oldValue){
      this.$emit('input', newValue)
    }
  },
  computed: {},
  methods: {}
}
</script>
<style src="../../Practice.css" scoped></style>
