import { render, staticRenderFns } from "./ChartPerformance.vue?vue&type=template&id=0f88f83c&scoped=true&"
import script from "./ChartPerformance.vue?vue&type=script&lang=js&"
export * from "./ChartPerformance.vue?vue&type=script&lang=js&"
import style0 from "../../Performance.css?vue&type=style&index=0&id=0f88f83c&prod&scoped=true&lang=css&"
import style1 from "./ChartPerformance.vue?vue&type=style&index=1&id=0f88f83c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f88f83c",
  null
  
)

export default component.exports