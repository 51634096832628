<template>
  <div id="select-topic" class="btn-group select-dropdown" role="group">
    <button
      type="button"
      class="btn select-questions-dropdown dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span class="select-content">
        <template v-if="value == null">
          {{ $t($interfaceLang, 'Please select', '請選擇') }}
        </template>
        <template v-for="t in topics" v-if="value?.id == t.id">
          {{ $t($interfaceLang, t.nameEn, t.nameZh) }}
        </template>
      </span>
    </button>
    <div
      class="dropdown-menu select-topic-dropdown-group select-questions-dropdown-group  btn-select-topic-group"
    >
      <a
        v-for="t in topics"
        v-if="t.id != value?.id"
        class="dropdown-item select-topic-dropdown-item select-questions-dropdown-item"
        href="#"
        @click="$emit('input', t)"
      >
        {{ $t($interfaceLang, t.nameEn, t.nameZh) }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    topics: Array,
    value: Object
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>
