import LoadTopicsQuery from './LoadTopics.graphql'
import CreatePracticeQuery from './CreatePractice.graphql'
import loadPracticeHistoryQuery from './LoadPracticeHistory.graphql'

export async function loadTopics (
  apolloQuery,
  currentUserId,
  usergroupId,
  after
) {
  let colors = [
    'purple',
    'green',
    'pink',
    'blue',
    'pink2',
    'green2',
    'green3',
    'blue2'
  ]
  var result = await apolloQuery(LoadTopicsQuery, {
    currentUserId,
    usergroupId
  })
  var topics = []
  var topicEdges = result.data.topicUsergroupEdges
  for (var i = 0; i < topicEdges.length; i++) {
    var subtopics = []
    var subtopicEdges = topicEdges[i].topic.subtopicEdges
    for (var j = 0; j < subtopicEdges.length; j++) {
      subtopics.push({
        id: subtopicEdges[j].subtopic.id,
        nameEn: subtopicEdges[j].subtopic.nameEn,
        nameZh: subtopicEdges[j].subtopic.nameZh
      })
    }
    topics.push({
      topicId: topicEdges[i].topic.id,
      nameEn: topicEdges[i].topic.nameEn,
      nameZh: topicEdges[i].topic.nameZh,
      numSubtopic: topicEdges[i].topic.numSubtopic,
      imagePath: null, // TODO '../static/images/' + topicFigure[t.id] + '.png'
      color: colors[i % colors.length], // TODO
      yearTerm: topicEdges[i].cache.topicYear,
      subtopics: subtopics
    })
  }
  return topics
}

export async function createPractice (
  apolloQuery,
  currentUserId,
  subtopicIds,
  numQuestion,
  difficulty
) {
  var result = await apolloQuery(CreatePracticeQuery, {
    currentUserId,
    currentUserId,
    subtopicIds,
    numQuestion,
    difficulty
  })
  return result
}

export async function loadPracticeHistory (apolloQuery, currentUserId, first) {
  var result = await apolloQuery(loadPracticeHistoryQuery, {
    currentUserId,
    userId: currentUserId,
    paperType: 'freepractice',
    first,
    after: null,
    sortBy: 'LAST_MODIFY_TIME',
    sortOrder: 'DESC'
  })
  var history = []
  var paperUserEdges = result.data.paperUserEdges
  for (const edge of paperUserEdges) {
    let numCorrectQuestion = edge.cache?.userStudentStats?.numCorrectQuestion
    let numQuestion = edge.paper.questionEdgeCount
    history.push({
      paperId: edge.paper.id,
      paperName: edge.paper.name,
      topicEdges: edge.paper.topicEdges,
      numQuestion,
      numCorrectQuestion,
      timeSpent:
        edge.cache?.userStudentStats?.timeSpent == null
          ? null
          : Number(edge.cache?.userStudentStats?.timeSpent),
      createdTime: Number(edge.paper.createTime),
      correctRate:
        numQuestion == null || numCorrectQuestion == null
          ? 0
          : (numCorrectQuestion / numQuestion) * 100
    })
  }
  return history
}
