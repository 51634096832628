<template>
  <div
    :class="{
      'question-container': true,
      'question-container-new': isNewQuestion
    }"
  >
    <template v-if="question != null">
      <div class="question-info">
        <div class="question-info-left">
          <div class="question-num" v-if="indexNo != null">
            Q{{ indexNo + 1 }}
          </div>
          <button
            v-show="!isReordering && !readOnly"
            @click="$emit('reorder', { indexNo })"
            class="btn-reorder edit-question-btn"
          >
            <i class="icon-font icon-reorder">&#xe81c;</i>
            {{ $t($interfaceLang, 'Reorder', '更改次序') }}
          </button>
        </div>
        <div class="question-info-right">
          <button
            @click="$emit('drawing', { question, indexNo })"
            class="btn-draw_pad edit-question-btn"
          >
            <i class="icon-font icon-draw_pad">&#xe80c;</i>
          </button>
          <button
            @click="$emit('solution', { question })"
            class="btn-solutions edit-question-btn"
          >
            <i class="icon-font icon-solutions">&#xe834;</i>
          </button>
          <FavButton
            v-if="showFav"
            v-model="isFav"
            @input="$emit('set-isfav', { question, isFav })"
          />
          <SelectMoreDropdown
            :readOnly="readOnly"
            @template-mutation="
              $emit('template-mutation', { question, indexNo })
            "
            @similar="$emit('similar', { question, indexNo })"
            @duplicate="$emit('duplicate', { question, indexNo })"
            @delete="$emit('delete', { question, indexNo })"
          />
        </div>
      </div>
      <QuestionView
        :question="question"
        :isShuffled="false"
        :permutation="[0, 1, 2, 3]"
        :mcAnswerPermutation="question?.displayQuestion?.sampleEdgePermutation"
      />
    </template>
  </div>
</template>
<script>
import QuestionView from '@/components/teacher/new-question-view/QuestionView.vue'
import FavButton from '@/components/FavButton.vue'
import SelectMoreDropdown from './SelectMoreDropdown.vue'

export default {
  components: {
    QuestionView,
    FavButton,
    SelectMoreDropdown
  },
  props: {
    indexNo: Number,
    question: Object,
    isNewQuestion: Boolean,
    readOnly: Boolean,
    isReordering: Boolean,
    isQgroupFav: Boolean,
    showFav: Boolean
  },
  data () {
    return {
      isFav: this.isQgroupFav
    }
  },
  watch: {
    isQgroupFav (newValue) {
      this.isFav = newValue
    }
  }
}
</script>
<style scoped>
/* 1ed: edited the color */
.question-container {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-right: 20px;
  border-radius: 4px;
  background-color: white;
  border-left: 5px solid #a9a9a9;
  margin-top: 20px;
}

/* 1ed: added this class */
.question-container-new {
  border-left: 5px solid #ec72c6;
}

.question-info {
  position: relative;
  display: flex;
}

.question-info-left {
  position: relative;
  display: flex;
  align-items: center;
}

.btn-reorder.edit-question-btn {
  display: flex;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 0px 10px;
  margin-left: 10px;
  border-radius: 12px;
  background-color: #d0d0d0;
}

.btn-reorder.edit-question-btn i {
  font-size: 16px;
  margin-right: 5px;
}

.btn-reorder.edit-question-btn:focus,
.btn-reorder.edit-question-btn:hover {
  background-color: #a3a3a3;
}

.question-info-right {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.question-num {
  font-size: 22px;
  font-weight: bold;
  color: #1c2d60;
}

.edit-question-btn {
  border: none;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  color: #1c2d60;
  font-size: 24px;
  margin-right: 20px;
}

.edit-question-btn:focus,
.edit-question-btn:hover,
.edit-question-btn.active {
  box-shadow: none;
  outline: none;
}

::v-deep .edit-question-dropdown {
  color: #1c2d60;
  width: 100%;
}

::v-deep .edit-question-dropdown:focus,
::v-deep .edit-question-dropdown:hover,
::v-deep .edit-question-dropdown.active {
  box-shadow: none;
  outline: none;
}

::v-deep .edit-question-dropdown-group {
  padding: 20px;
  box-shadow: 0 2px 6px 0 rgba(70, 70, 70, 0.4);
  border-radius: 10px;
  background-color: #1c2d60;
  min-width: 100%;
  margin-top: 10px;
  left: 30px !important;
  margin-left: -100px;
}

::v-deep .edit-question-dropdown-group:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #1c2d60 transparent;
  border-width: 0 8px 8px;
  top: -8px;
  left: 74%;
  margin-left: -10px;
}

::v-deep .edit-question-dropdown-item {
  padding: 5px 0px;
  font-size: 13px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
}

::v-deep .edit-question-dropdown-item i {
  font-size: 20px;
  margin-right: 5px;
}

::v-deep .edit-question-dropdown-item:focus,
::v-deep .edit-question-dropdown-item:hover,
::v-deep .edit-question-dropdown-item.active {
  color: #febd47;
  background-color: transparent;
}
</style>
