<template>
  <div
    class="question-solution-info"
    :class="{
      correct: isCorrect,
      wrong: isWrong
    }"
  >
    <div class="question-solution-msg">
      <template v-if="type == 'mc' && correctAnswerIndexNo != null">
        <template v-if="isCorrect">
          <img src="/static/images/img_answer_correct.png" />
          <span v-if="$interfaceLang == 'en_hk'" class="correct">
            You have selected the correct answer
            {{ displayAlphabet(displayAnswerIndexNo) }}.
          </span>
          <span v-else-if="$interfaceLang == 'zh_hk'" class="correct">
            你選了正確答案
            {{ displayAlphabet(displayAnswerIndexNo) }}.
          </span>
        </template>
        <template v-else-if="isWrong">
          <img src="/static/images/img_answer_wrong.png" />
          <span v-if="$interfaceLang == 'en_hk'" class="wrong">
            The correct answer is
            {{ displayAlphabet(correctAnswerIndexNo) }}, but you’ve answered
            {{ displayAlphabet(displayAnswerIndexNo) }}.
          </span>
          <span v-else-if="$interfaceLang == 'zh_hk'" class="wrong">
            正確答案是
            {{ displayAlphabet(correctAnswerIndexNo) }}，不過你選了
            {{ displayAlphabet(displayAnswerIndexNo) }}。
          </span>
        </template>
        <template v-else>
          <img src="/static/images/img_answer_wrong.png" />
          <span v-if="$interfaceLang == 'en_hk'" class="wrong">
            The correct answer is
            {{ displayAlphabet(correctAnswerIndex) }}, but you didn't answer.
          </span>
          <span v-else-if="$interfaceLang == 'zh_hk'" class="wrong">
            正確答案是
            {{ displayAlphabet(correctAnswerIndex) }}， 但是你沒有回答。
          </span>
        </template>
      </template>
      <template v-else> </template>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    type: String,
    displayAnswerIndexNo: Number,
    correctAnswerIndexNo: Number
  },
  data () {
    return {}
  },
  computed: {
    isCorrect () {
      return (
        this.displayAnswerIndexNo != null &&
        this.correctAnswerIndexNo == this.displayAnswerIndexNo
      )
    },
    isWrong () {
      return this.displayAnswerIndexNo != null &&
        this.correctAnswerIndexNo != this.displayAnswerIndexNo
    }
  },
  methods: {
    displayAlphabet (i) {
      var alphabet = ['A', 'B', 'C', 'D']
      return alphabet[i]
    }
  }
}
</script>