<template>
  <div>
    <Modal @close="$emit('close')">
      <template v-slot:header>
        <i class="icon-title icon-font icon-duplicate_outline">
          &#xe80d;
        </i>
        <span class="title">
          {{ $t($interfaceLang, 'DELETE ASSIGNMENT', '刪除習作') }}
        </span>
        <i @click="$emit('close')" class="icon-font icon-close">
          &#xe808;
        </i>
      </template>
      <template v-slot:body>
        <div class="modal-section">
          <p class="sm-title">
            {{
              $t(
                $interfaceLang,
                'Delete the following assignment?',
                '刪除以下習作?'
              )
            }}
          </p>
          <div :class="rowClass" class="assignment-row">
            <div class="assignment-info">
              <div class="assignment-name">
                {{ paper.name }}
              </div>
            </div>
          </div>
          <div class="section-row">
            <button
              id="btn-random"
              class="modal-btn right-item"
              @click="$emit('delete', paper)"
            >
              {{ $t($interfaceLang, 'Delete', '刪除') }}
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/teacher/modal/Modal.vue'
export default {
  name: 'DeletePaper',
  components: {
    Modal
  },
  props: {
    paper: Object
  },
  computed: {
    rowClass () {
      return {
        'type-homework': this.paper?.type == 'homework',
        'type-auto': ['recursive', 'recursive_followup'].includes(
          this.paper?.type
        ),
        'type-test': this.paper?.type == 'test',
        selected: this.selected
      }
    }
  }
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>
<style scoped>
.assignment-row {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
}

.assignment-row p {
  margin: 0px;
}

.assignment-row .btn-group {
  width: 100%;
}

.type-homework {
  border-left: 5px solid #42c9f1;
}

.type-practice {
  border-left: 5px solid #ffd32d;
}

.type-auto {
  border-left: 5px solid #d0d0d0;
}
</style>
