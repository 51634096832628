<template>
  <div
    class="btn-group select-order select-dropdown"
    role="group"
    v-if="value != null"
  >
    <button
      type="button"
      class="btn select-order-dropdown dropdown-toggle select-dropdown-btn-nobg"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="icon-font icon-sort">&#xe821;</i>
      <span>
        {{ $t($interfaceLang, value.nameEn, value.nameZh) }}
      </span>
    </button>
    <div
      class="dropdown-menu select-order-dropdown-group select-dropdown-group"
    >
      <a
        v-for="(s, i) in sortByOptions"
        v-if="s.nameEn != value.nameEn"
        @click.prevent="$emit('input', s)"
        class="dropdown-item select-order-dropdown-item select-dropdown-item"
        href="#"
        :key="'sort-by-' + i"
      >
        {{ $t($interfaceLang, s.nameEn, s.nameZh) }}
      </a>
    </div>
  </div>
</template>
<script>
import sortByOptions from './sortBy.json'

export default {
  components: {},
  props: {
    value: Object
  },
  data () {
    return {
      sortByOptions
    }
  },
  computed: {},
  created () {
    this.$emit('input', sortByOptions[0])
  },
  methods: {}
}
</script>
<style src="@/pages/teacher/manage-assignment/ManageAssignment.css" scoped />
