<template>
  <div class="topic-list-col">
    <p v-b-toggle="'topics'" class="topics-collapse-btn">
      <span v-html="selectedTopicName" />
      <i class="icon-font icon-arrow_filled_down">&#xe802;</i>
      <i class="icon-font icon-arrow_filled_up">&#xe803;</i>
    </p>
    <b-collapse id="topics" class="mt-2 mb-3">
      <div class="topic-col">
        <template v-for="(t, i) in topics">
          <div class="topic-item" :key="'topic-' + t.id">
            <b-form-checkbox
              class="topic-checkbox"
              v-model="t.allSelected"
              :indeterminate="t.indeterminate"
              @change="
                toggleAll(t)
                $emit('input', getSelectedSubtopicIds(topics))
              "
            >
              {{ $t($interfaceLang, t.nameEn, t.nameZh) }}
            </b-form-checkbox>
            <span v-b-toggle="'topic-' + t.id" class="subtopics-collapse-btn">
              <i class="icon-font icon-arrow_filled_down">&#xe802;</i>
              <i class="icon-font icon-arrow_filled_up">&#xe803;</i>
            </span>
          </div>
          <b-collapse
            :id="'topic-' + t.id"
            class="mt-2 subtopics"
            :key="'topic-collapse-' + t.id"
          >
            <template v-for="(subt, j) in t.subtopics">
              <b-form-checkbox
                v-model="subt.selected"
                @change="
                  selectSubtopic(t)
                  $emit('input', getSelectedSubtopicIds(topics))
                "
                :id="subt.id"
                :name="subt.id"
                :key="'subtopic-' + subt.id"
                class="ml-4 subtopic-checkbox"
              >
                {{ $t($interfaceLang, subt.nameEn, subt.nameZh) }}
              </b-form-checkbox>
            </template>
          </b-collapse>
        </template>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse, BFormCheckbox, VBToggle } from 'bootstrap-vue'
export default {
  components: {
    BCollapse,
    BFormCheckbox
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    topicData: Array,
    value: Array
  },
  data () {
    return {
      topics: null
    }
  },
  computed: {
    selectedTopicName () {
      const maxShowTopics = 3
      let text = ''
      let visibleTopicNum = this.topics.filter(
        x => x.allSelected || x.indeterminate
      ).length
      let showedTopicNum = 0
      let extraSubtopicCount = 0
      for (var i = 0; i < this.topics.length; ++i) {
        if (this.topics[i].allSelected) {
          text +=
            this.$t(
              this.$interfaceLang,
              this.topics[i].nameEn + '(All)',
              this.topics[i].nameZh + '(全部)'
            ) + '<br/>'
          showedTopicNum++
        } else if (this.topics[i].indeterminate) {
          text +=
            this.$t(
              this.$interfaceLang,
              this.topics[i].nameEn +
                ` (${
                  this.topics[i].subtopics.filter(x => x.selected).length
                } subtopics)`,
              this.topics[i].nameZh +
                ` (${
                  this.topics[i].subtopics.filter(x => x.selected).length
                } 個副題)`
            ) + '<br/>'
          showedTopicNum++
        }
        if (showedTopicNum == maxShowTopics) {
          break
        }
      }
      for (var j = i + 1; j < this.topics.length; ++j) {
        extraSubtopicCount += this.topics[j].subtopics.filter(
          x => x.selected == true
        ).length
      }

      if (visibleTopicNum > maxShowTopics) {
        text += this.$t(
          this.$interfaceLang,
          `... and ${
            visibleTopicNum - maxShowTopics
          } more topics, ${extraSubtopicCount} more subtopics`,
          `... 及額外 ${
            visibleTopicNum - maxShowTopics
          } 個題目, ${extraSubtopicCount} 個副題目`
        )
      }
      return text
    }
  },
  created () {
    this.topics = this.getTopicFromData(this.topicData, this.value)
    this.topics.forEach(t => {
      this.selectSubtopic(t)
    })
  },
  methods: {
    getSelectedSubtopicIds (topics) {
      return topics
        .map(x => x.subtopics)
        .reduce((a, b) => [...a, ...b], [])
        .filter(x => x.selected == true)
        .map(x => x.id)
    },
    getTopicFromData (topicData, subtopicIds) {
      return topicData?.map(x => ({
        ...x,
        allSelected: false,
        indeterminate: false,
        subtopics: x.subtopics.map(y => ({
          ...y,
          selected: subtopicIds.includes(y.id)
        }))
      }))
    },
    toggleAll (topic) {
      if (topic.allSelected) {
        topic.subtopics.forEach(st => {
          st.selected = true
        })
        topic.indeterminate = false
      } else {
        topic.subtopics.forEach(st => {
          st.selected = false
        })
        topic.indeterminate = false
      }

      // if (topic.allSelected) {
      //   this.topicsData[index].subtopics = topic.subtopics.map(x => {
      //     x.selected = true
      //     return x
      //   })
      //   this.topicsData[index].indeterminate = false
      // } else {
      //   this.topicsData[index].subtopics = topic.subtopics.map(x => {
      //     x.selected = false
      //     return x
      //   })
      //   this.topicsData[index].indeterminate = false
      // }
    },
    selectSubtopic (topic) {
      if (!topic.subtopics.map(x => x.selected).includes(false)) {
        topic.allSelected = true
        topic.indeterminate = false
      } else if (topic.subtopics.map(x => x.selected).includes(true)) {
        topic.allSelected = false
        topic.indeterminate = true
      } else {
        topic.allSelected = false
        topic.indeterminate = false
      }
    }
  }
}
</script>

<style scoped>
[aria-expanded='false'] .icon-arrow_filled_down {
  display: inline;
}
[aria-expanded='true'] .icon-arrow_filled_down {
  display: none;
}

[aria-expanded='false'] .icon-arrow_filled_up {
  display: none;
}
[aria-expanded='true'] .icon-arrow_filled_up {
  display: inline;
}
.topics-collapse-btn {
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-size: 14px;
  color: #1c2d60;
  border-radius: 6px;
  background-color: #f1f3f1;
  border: none;
  padding: 3px 10px;
  min-height: 26px;
  cursor: pointer;
}

.topics-collapse-btn .icon-font {
  margin-left: auto;
}

.topic-item {
  position: relative;
  display: flex;
  align-items: center;
}

.subtopics-collapse-btn {
  font-size: 14px;
  color: #1c2d60;
  margin-left: 5px;
  cursor: pointer;
}

.subtopics {
  margin-bottom: 10px;
}

.topic-col {
  position: relative;
  max-height: 200px;
  overflow-y: scroll;
}
</style>
