<template>
  <div class="library-select-questions">
    <div id="select-topic-col">
      <TopicSelect
        v-model="inputTopic"
        :topics="topics"
        :yearTerm="yearTerm"
        @input="
          inputSubtopic = null
          $emit('input', getData())
        "
      />
    </div>
    <div id="select-subtopic-col">
      <SubtopicSelect
        v-model="inputSubtopic"
        :subtopics="inputTopic?.subtopics"
        @input="$emit('input', getData())"
      />
    </div>
    <div id="select-diff-col">
      <DifficultySelect
        v-model="inputDifficulty"
        @input="$emit('input', getData())"
      />
      <div class="line" />
      <FavSelect
        :class="{ disabled: !showFav }"
        v-model="inputFav"
        @input="$emit('input', getData())"
      />
    </div>
  </div>
</template>
<script>
import TopicSelect from './TopicSelect.vue'
import SubtopicSelect from './SubtopicSelect.vue'
import DifficultySelect from './DifficultySelect.vue'
import FavSelect from './FavSelect.vue'
export default {
  components: {
    TopicSelect,
    SubtopicSelect,
    DifficultySelect,
    FavSelect
  },
  props: {
    yearTerm: Array,
    topics: Array,
    value: Object,
    showFav: Boolean
  },
  data () {
    return {
      inputTopic: null,
      inputSubtopic: null,
      inputDifficulty: null,
      inputFav: false
    }
  },
  computed: {},
  methods: {
    getData () {
      return {
        topic: this.inputTopic,
        subtopic: this.inputSubtopic,
        difficulty: this.inputDifficulty,
        isFav: this.inputFav
      }
    },
    reset () {
      this.inputTopic = null
      this.inputSubtopic = null
      this.inputDifficulty = null
      this.inputFav = null
      this.$emit('input', this.getData())
    }
  }
}
</script>
<style src="@/pages/teacher/edit-assignment/EditAssignment.css" scoped />
<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.67;
}
</style>
