<template>
  <div class="add-question-qty">
    <span class="desc-text">
      {{ descText }}
    </span>
    <img
      class="img-qty"
      src="@/assets/images/deduct.svg"
      @click="
        prev()
        $emit('input', inputs[index])
      "
    />
    <div class="question-qty">{{ inputs[index] }}</div>
    <img
      class="img-qty"
      src="@/assets/images/add.svg"
      @click="
        next()
        $emit('input', inputs[index])
      "
    />
    <span class="advanced-text">
      {{ advancedText }}
    </span>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    inputs: Array,
    defaultInputIndex: Number,
    descText: String,
    advancedText: String
  },
  data () {
    return {
      index: this.defaultInputIndex ?? 0
    }
  },
  computed: {},
  methods: {
    next () {
      this.index = Math.min(this.index + 1, this.inputs.length - 1)
    },
    prev () {
      this.index = Math.max(this.index - 1, 0)
    }
  }
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>
<style scoped>
.advanced-text {
  font-size: 14px;
  font-weight: normal;
  color: #1c2d60;
  padding-left: 10px;
}
.desc-text {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
  margin-right: 15px;
}
</style>
