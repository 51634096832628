const md5 = require('md5')
const apolloStore = require("./apollo-store.js")

function tryParse(JSONstring) {
  try {
    return JSON.parse(JSONstring)
  } catch (e) { }
}

module.exports = {
  appInfo: {
    timezoneText: "Asia/Hong_Kong",
    timezoneOffset: 8
  },
  userPreferences: {
    interfaceLang: 'en_hk',
    libraryNavRow: null
  },
  userInfo: {
    email: null,
    userid: null,
    role: 'guest',
    name: null,
    displayName: null,
    avatarName: null,
    agreeTermVersion: null,
    usergroup: null,
    tschema: null
  },
  currentTopics: {
    usergroupId: null,
    topics: null
  },
  userData: null,
  usergroupData: null,
  previewSetting: {
    name: "",
    startTime: null,
    expiryTime: null,
    numQuestion: null,
    difficulty: null,
    subtopicIds: []
  },
  redirectTo: sessionStorage.redirectTo != null ? JSON.parse(sessionStorage.redirectTo) : null,
  setRedirectTo(x) {
    if (x != null) {
      sessionStorage.redirectTo = JSON.stringify({
        path: x.path,
        query: x.query
      })
    } else {
      sessionStorage.removeItem('redirectTo')
    }
  },
  load() {
    this.userInfo = tryParse(sessionStorage.userInfo) ?? {}
    this.userData = tryParse(sessionStorage.userData)?.filter(x => x.type === 'teacher')
    this.usergroupData = tryParse(sessionStorage.usergroupData)
    this.currentTopics = tryParse(sessionStorage.currentTopics) ?? {}
    apolloStore.cache(this.currentTopics)
    this.previewSetting = tryParse(sessionStorage.previewSetting)
    this.userPreferences = tryParse(localStorage[this.localUserKey()])?.userPreferences ?? this.userPreferences
  },
  localUserKey() {
    if (this.userInfo.userid) {
      return 'Storage-' + md5(this.userInfo.userid)
    }
  },
  saveLocalStore(itemName, item) {
    let localStore = tryParse(localStorage[this.localUserKey()]) ?? {}
    localStore[itemName] = item
    localStorage[this.localUserKey()] = JSON.stringify(localStore)
  },
  updateUserPrefrences(updateData) {
    this.userPreferences = Object.assign(this.userPreferences ?? {}, updateData)
    this.saveLocalStore('userPreferences', this.userPreferences)
  },
  clearPreviewSetting() {
    this.previewSetting = null
    sessionStorage.previewSetting = null
  },
  updatePreviewSetting(updateData) {
    this.previewSetting = Object.assign({}, updateData)
    sessionStorage.previewSetting = JSON.stringify(this.previewSetting)
  },
  updateUserInfo(updateData) {
    this.userInfo = Object.assign(this.userInfo ?? {}, updateData)
    sessionStorage.userInfo = JSON.stringify(this.userInfo)
  },
  clearUserInfo() {
    sessionStorage.userInfo = null
    this.userInfo = {}
  },
  updateUserData(newUserData) {
    this.userData = newUserData
    sessionStorage.userData = JSON.stringify(newUserData)
  },
  updateUsergroupData(newUsergroupData) {
    this.usergroupData = newUsergroupData
    sessionStorage.usergroupData = JSON.stringify(newUsergroupData)
  },
  updateCurrentTopics(newTopics) {
    newTopics?.topics?.forEach(x => {
      x.subtopics?.forEach(y => {
        y.topicEdges = null
      })
    })
    this.currentTopics = newTopics
    apolloStore.cache(newTopics)
    sessionStorage.currentTopics = JSON.stringify(newTopics)
  },
  switchIdentity(identity) {
    let userData = [...this.userData]
    sessionStorage.clear()
    this.updateUserData(userData);
    this.updateUserInfo({
      userid: identity.id,
      role: identity.type,
      name: identity.name,
      displayName: identity.displayName,
      avatarName: identity.avatarName,
      agreeTermVersion: identity.agreeTermVersion
    })
  }
}