<!-- <<<ref>>>
  branch: dev
  file: src\pages\student\practice\components\PracticeHistoryRow.vue
-->
<template>
  <div
    :id="'practiceHistory-row-' + paperId"
    class="item-row"
  >
    <a class="click-item" @click="onClickRow">
      <div class="item-name item-hightlight-text">
        {{ paperName }}
      </div>
      <div class="item-qty">
        <p class="sm-title">
          {{ $t($interfaceLang, 'No. of questions', '問題數量') }}
        </p>
        <p class="item-hightlight-text">{{ numQuestion }}</p>
      </div>
      <div class="item-time">
        <p class="sm-title">
          {{ $t($interfaceLang, 'Time spent', '作答時間') }}
        </p>
        <p class="item-hightlight-text">
          {{ displayTimeSpent(timeSpent) }}
        </p>
      </div>
      <div class="item-score">
        <p class="sm-title">
          {{ $t($interfaceLang, 'Score', '分數') }}
        </p>
        <div class="progress-col">
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              :style="{ width: correctRate + '%' }"
            />
          </div>
          <span
            class="item-hightlight-text"
            v-if="numCorrectQuestion == null || numQuestion == null"
          >
            -
          </span>
          <span class="item-hightlight-text" v-else>
            {{ numCorrectQuestion }}/{{ numQuestion }}
          </span>
        </div>
      </div>
      <div class="item-date">
        <p class="sm-title">
          {{ $t($interfaceLang, 'Created On', '建立於') }}
        </p>
        <p class="item-hightlight-text">
          {{ displayDate(createdTime) }}
        </p>
      </div>
    </a>
  </div>
</template>
<script>
import dateFormat from 'dateformat'
export default {
  components: {},
  props: {
    paperId: String,
    paperName: String,
    numQuestion: Number,
    numCorrectQuestion: Number,
    timeSpent: Number,
    createdTime: Number,
    correctRate: Number
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    displayDate (value, offset = 0) {
      if (value == null) return '-'
      return dateFormat(
        new Date(Number(value) + 8 * 3600 * 1000 + offset),
        'UTC:yyyy-mm-dd'
      )
    },
    displayTimeSpent (timeSpent) {
      if (timeSpent == null) {
        return this.$t(this.$interfaceLang, 'No data', '沒有資料')
      } else {
        var time = (timeSpent / 1000 / 60).toFixed(2)
        if (time > 1) {
          return this.$t(this.$interfaceLang, time + ' min', time + ' 分鐘')
        } else {
          return this.$t(this.$interfaceLang, '< 1 min', '< 1 分鐘')
        }
      }
    },
    onClickRow () {
      this.$emit('click', { paperId: this.paperId })
    }
  }
}
</script>
<style scoped>
.click-item {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.click-item:hover,
.click-item:focus {
  text-decoration: none;
}

.click-item:hover .item-name,
.click-item:focus .item-name {
  color: #0c89d3;
}

.item-btn {
  border: none;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  color: #1c2d60;
  font-size: 26px;
  margin-left: auto;
}

.item-btn:focus,
.item-btn:hover,
.item-btn:active {
  box-shadow: none;
  outline: none;
}

.item-row {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
}

.item-row p {
  margin: 0px;
}

.sm-title {
  font-size: 11px;
  color: #adadad;
}

.item-hightlight-text {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
}

.item-name {
  width: 30%;
  padding-right: 10px;
}

.item-qty {
  width: 15%;
  padding-left: 10px;
  border-left: 1px solid #adadad;
}

.item-time {
  width: 15%;
  padding-left: 10px;
  border-left: 1px solid #adadad;
}

.item-date {
  width: 20%;
  padding-left: 10px;
  border-left: 1px solid #adadad;
}

.item-score {
  width: 20%;
}

.progress-col {
  position: relative;
  display: flex;
  align-items: center;
}

.progress {
  height: 0.5rem;
  width: 78%;
  background-color: #d0d0d0;
}

.progress-bar {
  background-color: #ffd32d;
}

.progress-col .item-hightlight-text {
  margin-left: 5px;
}

.average-rate-c {
  font-size: 40px;
  margin-left: 5px;
}

.average-rate-c span {
  font-weight: bold;
  color: #1c2d60;
}

.average-rate-c.red .bar,
.average-rate-c.red .fill {
  border-color: #f65a58 !important;
}

.average-rate-c.green .bar,
.average-rate-c.green .fill {
  border-color: #3ccc89 !important;
}

@media only screen and (max-width: 991px) {
  .item-date {
    width: 14%;
  }

  .item-submission {
    width: 15%;
  }

  .item-time {
    width: 14%;
  }
}
</style>
