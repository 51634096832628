import log from '@/log.js'

export default {
  async graphqlFetchEdges(apolloQuery, query, variables, edgesMap, after = null, maxNumQuery = 10) {
    let edges = []
    try {
      let first = variables.first == null ? 1 : Math.max(variables.first, 1)
      let result = await apolloQuery(query, { ...variables, after })
      edges = edgesMap(result.data)
      if ((edges ?? []).length < first) {
        return edges ?? []
      }
      after = edges[edges.length - 1].id
      for (var i = 1; i < maxNumQuery; ++i) {
        result = await apolloQuery(query, { ...variables, after })
        edges = [...edges, ...edgesMap(result.data)]
        after = edges[edges.length - 1].id
        if (edgesMap(result.data).length < first) {
          return edges
        }
      }
      return edges
    } catch (e) {
      log(e.message)
      return edges
    }
  }
}