<template>
  <div class="d-flex">
    <div v-for="i in 5" class="diff-star">
      <i
        @click="
          difficulty = i
          $emit('input', difficulty)
        "
        :class="{
          'icon-star_filled': i <= difficulty
        }"
        class="star1 icon-font icon-diff-star"
      >
        <template v-if="i <= difficulty">
          &#xe82e;
        </template>
        <template v-else>
          &#xe82f;
        </template>
      </i>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: Number
  },
  data () {
    return {
      difficulty: this.value
    }
  },
  computed: {},
  methods: {}
}
</script>

<style scoped>
.diff-star {
  font-size: 18px;
  margin-right: 5px;
  color: #1c2d60;
  cursor: pointer;
}
</style>
