<template>
  <Questions
    :questions="displayQuestionEdges"
    :isLoading="isLoading"
    @show-question="
      $emit('show-question', { questionEdges })
    "
    @click-question="
      $emit('click-question', { questionEdges, indexNo: $event })
    "
  />
</template>
<script>
import LoadQuestionEdgesQuery from './LoadQuestionEdges.graphql'
import Questions from './Questions.vue'

const MAX_NUM_QUESTION = 60

export default {
  components: {
    Questions
  },
  props: {
    currentUserId: String,
    paperId: String,
    batchSize: Number
  },
  data () {
    return {
      questionEdges: null,
      isLoading: false
    }
  },
  computed: {
    displayQuestionEdges () {
      return this.questionEdges
    }
  },
  async created () {
    this.isLoading = true
    let questionEdges = await this.fetchQuestionEdges()
    this.questionEdges = this.parseQuestionEdges(questionEdges)
    this.isLoading = false
  },
  methods: {
    async fetchQuestionEdges () {
      let first = Math.max(this.batchSize ?? 0, 10)
      return await this.$graphqlUtil.graphqlFetchEdges(
        this.$apolloQuery,
        LoadQuestionEdgesQuery,
        {
          currentUserId: this.currentUserId,
          paperId: this.paperId,
          lang: this.$interfaceLang,
          first
        },
        x => x.paperQuestionEdges,
        null,
        Math.ceil(MAX_NUM_QUESTION / first) + 1
      )
    },
    parseQuestionEdges (edges) {
      let resEdges = edges.map(_ => ({}))
      edges.forEach(edge => {
        if (edge?.questionIndexNo != null) {
          resEdges[edge?.questionIndexNo] = {
            correctRate: edge?.cache?.paperStats?.usergroupAvgCorrectRate ?? 0,
            avgTimeSpent: edge?.cache?.paperStats?.usergroupAvgTimeSpent ?? 0,
            indexNo: edge?.questionIndexNo,
            question: edge?.question
          }
        }
      })
      return resEdges
    }
  }
}
</script>
<style src="" />
