<template>
  <FavQuestionLayout>
    <template v-slot:info-left>
      <FavButton
        v-model="isFav"
        @input="$emit('set-fav', { question, isFav })"
      />
      <button class="btn-shuffle question-btn">
        <i
          class="icon-font icon-shuffle"
          @click="$emit('template-mutation', { question })"
        >
          &#xe820;
        </i>
      </button>
    </template>
    <template v-slot:info-right>
      <a
        @click.prevent="$emit('draw', { question })"
        href="#"
        class="btn-draw question-btn"
      >
        <i class="icon-font icon-draw">&#xe825;</i>
      </a>
    </template>
    <template v-slot:question>
      <QuestionView
        :question="question"
        :isShuffled="true"
        :permutation="permutation"
        :userMarkerIndex="displayAnswerIndex"
        :correctAnswerIndex="correctAnswerIndex"
        @choose-ans="
          displayAnswerIndex == null ? $emit('choose-ans', $event) : null
        "
      />
    </template>
    <template v-slot:solution>
      <PaperSolution
        v-if="solutionEdgeKey != null"
        :type="'mc'"
        :solutionEdgeKey="solutionEdgeKey"
        :displayAnswerIndexNo="displayAnswerIndex"
        :correctAnswerIndexNo="correctAnswerIndex"
        :showNextButton="false"
      />
    </template>
  </FavQuestionLayout>
</template>
<script>
import FavQuestionLayout from './FavQuestionLayout.vue'
import FavButton from '@/components/FavButton.vue'
import QuestionView from '@/components/student/question-view/QuestionView.vue'
import PaperSolution from '@/components/student/paper-solution/PaperSolution.vue'
export default {
  components: {
    FavQuestionLayout,
    FavButton,
    QuestionView,
    PaperSolution
  },
  props: {
    question: Object,
    permutation: Array,
    displayAnswerIndex: Number,
    correctAnswerIndex: Number,
    isQgroupFav: Boolean,
    solutionEdgeKey: String
  },
  data () {
    return {
      isFav: this.isQgroupFav
    }
  },
  watch: {
    isQgroupFav (newValue) {
      this.isFav = newValue
    }
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>
.question-container-col {
  position: relative;
}

.question-container {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-right: 20px;
  border-radius: 4px;
  background-color: white;
  margin-top: 20px;
}

.question-info {
  position: relative;
  display: flex;
}

.question-info-left {
  position: relative;
  display: flex;
  align-items: center;
}

.question-info-right {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.btn-favourite {
  padding-right: 5px !important;
  border-right: 1px solid #f1f3f1 !important;
}

.question-num {
  font-size: 22px;
  font-weight: bold;
  color: #1c2d60;
}

.question-btn {
  border: none;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  color: #1c2d60;
  font-size: 24px;
  margin-right: 10px;
}

.question-btn:focus,
.question-btn:hover,
.question-btn:active {
  box-shadow: none;
  outline: none;
}

.question-dropdown {
  color: #1c2d60;
  width: 100%;
}

.question-dropdown:focus,
.question-dropdown:hover,
.question-dropdown:active {
  box-shadow: none;
  outline: none;
}

.question-dropdown-group {
  padding: 20px;
  box-shadow: 0 2px 6px 0 rgba(70, 70, 70, 0.4);
  border-radius: 10px;
  background-color: #1c2d60;
  min-width: 100%;
  margin-top: 10px;
  left: 30px !important;
  margin-left: -100px;
}

.question-dropdown-group:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #1c2d60 transparent;
  border-width: 0 8px 8px;
  top: -8px;
  left: 74%;
  margin-left: -10px;
}

.question-dropdown-item {
  padding: 5px 0px;
  font-size: 13px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
}

.question-dropdown-item i {
  font-size: 20px;
  margin-right: 5px;
}

.question-dropdown-item:focus,
.question-dropdown-item:hover,
.question-dropdown-item:active {
  color: #febd47;
  background-color: transparent;
}

.btn-draw {
  margin-right: 0px;
}

.btn-draw:hover,
.btn-draw:focus {
  text-decoration: none;
}

.question-solution {
  position: relative;
  margin-top: 20px;
  border-radius: 4px;
  background-color: white;
}

.question-solution-title {
  padding: 10px 20px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border-radius: 4px 4px 0px 0px;
  background-color: #42c9f1;
}

.question-solution-info {
  padding: 10px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.question-solution-msg img {
  max-width: 70px;
}

.question-solution-msg span {
  margin-left: 10px;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.59;
  letter-spacing: 0.3px;
}

.question-solution-msg span.correct {
  color: #00d083;
}

.question-solution-msg span.wrong {
  color: #f65a58;
}

.btn-notes {
  border-radius: 20px;
  background-color: #3ccc89;
  font-size: 13px;
  font-weight: bold;
  color: var(--white);
  text-transform: uppercase;
  padding: 5px 15px;
  margin-right: 0px;
  margin-left: auto;
  height: fit-content;
}

.btn-notes .icon-font {
  font-size: 16px;
  margin-right: 5px;
}

.btn-notes:hover,
.btn-notes:focus {
  background-color: #039e65;
}

.solution {
  padding: 20px;
}

@media only screen and (max-width: 767px) {
  .question-solution-msg {
    width: 100%;
    margin-bottom: 10px;
  }

  .btn-notes {
    margin-left: 0px;
  }

  .btn-draw {
    display: none;
  }
}
</style>
