<template>
  <div>
    <div class="modal-section">
      <div class="select-topic-col">
        <p class="sm-title">
          {{ $t($interfaceLang, 'Topics', '題目') }}
        </p>
        <div class="topic-list-col">
          <p v-b-toggle="'topics'" class="topics-collapse-btn">
            <span
              v-html="
                $t(
                  $interfaceLang,
                  'Auto selected by teacher schedule',
                  '自動參考教學日程'
                )
              "
            />
          </p>
        </div>
        <div class="topic-setting-col">
          <div class="no-of-question-input">
            <InputQuestionQty
              v-model="numQuestion"
              :inputs="[5, 6, 7, 8, 9, 10, 15, 20, 30, 40]"
              :defaultInputIndex="5"
              :descText="$t($interfaceLang, 'No of Question', '題目數量')"
              :advancedText="''"
            />
          </div>
          <div class="diff-input">
            <span class="topic-setting-text">
              {{ $t($interfaceLang, 'Difficulty', '難度') }}
            </span>
            <DifficultyInput v-model="difficulty" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-section section-row align-right">
      <button
        id="btn-back"
        class="modal-btn modal-btn-white"
        @click="$emit('prev')"
      >
        <i class="icon-font icon-arrow_left"> &#xe804; </i>
        {{ $t($interfaceLang, 'Back', '上一步') }}
      </button>
      <button
        @click="$emit('next', getData())"
        id="btn-preview"
        class="btn-preview modal-btn2 right-item"
      >
        <i class="icon-font icon-inspect">&#xe815;</i>
        {{ $t($interfaceLang, 'Create', '創建') }}
      </button>
    </div>
  </div>
</template>
<script>
import { VBToggle } from 'bootstrap-vue'
import InputQuestionQty from '../../components/InputQuestionQty.vue'
import DifficultyInput from './DifficultyInput.vue'

export default {
  components: {
    InputQuestionQty,
    DifficultyInput
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {},
  data () {
    return {
      numQuestion: 10,
      difficulty: 3
    }
  },
  computed: {},
  methods: {
    getData () {
      return {
        numQuestion: this.numQuestion,
        difficulty: this.difficulty
      }
    }
  }
}
</script>

<style src="@/components/teacher/modal/modal.css" scoped></style>
<style scoped>
.topic-checkbox {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
}

.subtopic-checkbox {
  font-size: 14px;
  font-weight: normal;
  color: #1c2d60;
}

.align-right button {
  margin-left: 10px;
  display: flex !important;
  align-items: center;
}

.modal-row {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.modal-row2 {
  position: relative;
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.advanced-collapse-btn {
  font-size: 14px;
  font-weight: normal;
  color: #007fca;
  cursor: pointer;
}

.advanced-collapse-btn.icon-font {
  font-size: 12px;
}

[aria-expanded='false'] .icon-arrow_filled_down {
  display: inline;
}
[aria-expanded='true'] .icon-arrow_filled_down {
  display: none;
}

[aria-expanded='false'] .icon-arrow_filled_up {
  display: none;
}
[aria-expanded='true'] .icon-arrow_filled_up {
  display: inline;
}

.scheduled-side,
.type-side {
  position: relative;
  width: 20%;
}

.title-side {
  position: relative;
  width: 78%;
}

.normal-text {
  font-size: 14px;
  font-weight: normal;
  color: #1c2d60;
  margin: 0px;
}

.date-box {
  font-size: 14px;
  color: #1c2d60;
  border-radius: 6px;
  background-color: #f1f3f1;
  border: none;
  padding: 3px 10px;
  min-height: 26px;
  min-width: 110px;
  cursor: pointer;
}

.date-box i {
  color: #1c2d60;
  font-size: 14px;
  padding-right: 5px;
}

.scheduled-right-side {
  position: relative;
  display: flex;
  width: 75%;
  justify-content: flex-start;
}

.select-date-col {
  position: relative;
  padding-right: 10px;
}

.item-check label:before {
  border: 1.5px solid #969696;
}

.item-check input[type='checkbox']:checked + label:before {
  background-color: #ff881b;
  border-color: #ff881b;
}

.schedule-repeat-col {
  position: relative;
  width: 40%;
}

.DFA-col {
  position: relative;
  width: 50%;
}

.follow-up-col {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.remark-text {
  font-size: 12px;
  color: #ff6d6d;
  padding-left: 5px;
}

#homework-recursive-setting .select-option.input-text {
  width: 50px;
}

#homework-recursive-setting .advanced-text {
  font-size: 14px;
  font-weight: normal;
  color: #1c2d60;
  padding-left: 10px;
}

.follow-up-question-input {
  position: relative;
  width: 40%;
}

.follow-up-recent-auto-input {
  position: relative;
  width: 50%;
}

.recurrence-remark {
  position: relative;
  width: 100%;
  font-size: 12px;
  color: #42c9f1;
  margin-top: 10px;
  margin-bottom: 0px;
}

.test-date-col {
  position: relative;
  width: 40%;
}

.test-date-col .date-box-col {
  position: relative;
  width: 120px;
  display: block;
}

.start-time-col,
.end-time-col {
  position: relative;
  width: 25%;
}

.start-time-col .select-option.input-text,
.end-time-col .select-option.input-text {
  width: 80px;
}

#practice-setting {
  position: relative;
  display: flex;
}

#practice-setting .scheduled-side {
  width: 25%;
}

#practice-setting .scheduled-right-side {
  flex-direction: column;
}

.reveal-solution-option {
  position: relative;
  display: flex;
  width: 100%;
  flex-basis: 100%;
  align-items: center;
  margin-bottom: 10px;
}

.reveal-solution-option .item-radio-label span {
  color: #007fca;
}

.reveal-solution-option .test-date-col {
  width: 30%;
  margin-left: 20px;
}
/* .reveal-solution-option .start-time-col {
} */

.topics-collapse-btn {
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-size: 14px;
  color: #1c2d60;
  border-radius: 6px;
  background-color: #f1f3f1;
  border: none;
  padding: 3px 10px;
  min-height: 26px;
  cursor: pointer;
}

.topics-collapse-btn .icon-font {
  margin-left: auto;
}

.topic-item {
  position: relative;
  display: flex;
  align-items: center;
}

.subtopics-collapse-btn {
  font-size: 14px;
  color: #1c2d60;
  margin-left: 5px;
  cursor: pointer;
}

.subtopics {
  margin-bottom: 10px;
}

.topic-col {
  position: relative;
  max-height: 400px;
  overflow-y: scroll;
}

.topic-setting-col {
  position: relative;
  display: flex;
  align-items: center;
}

.topic-setting-text {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
  margin-right: 15px;
}

.diff-input {
  position: relative;
  display: flex;
  align-items: center;
}

.diff-star {
  font-size: 18px;
  margin-right: 5px;
  color: #1c2d60;
  cursor: pointer;
}

.no-of-question-input {
  position: relative;
  width: 40%;
}

.diff-input {
  position: relative;
  width: 40%;
}

.select-remark-text {
  position: relative;
  color: #ff6d6d;
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 12px;
}
</style>
