var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"randomlyAddQuestions-model"}},[_c('Modal',{on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('i',{staticClass:"icon-title icon-font icon-random"},[_vm._v("")]),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'RANDOMLY ADD QUESTIONS', '隨機新增題目'))+" ")]),_c('i',{staticClass:"icon-font icon-close",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("  ")])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"modal-section"},[_c('div',{staticClass:"section-row select-row"},[_c('p',{staticClass:"sm-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Term', '學期'))+" ")]),_c('div',{staticClass:"topic-list"},[_c('div',{attrs:{"id":"select-topic-col"}},[_c('YearTermDropdown',{attrs:{"topics":_vm.topics},on:{"input":function($event){_vm.selectedTopic = null
                  _vm.selectedSubtopic = null}},model:{value:(_vm.selectedYearTerm),callback:function ($$v) {_vm.selectedYearTerm=$$v},expression:"selectedYearTerm"}})],1)])]),_c('div',{staticClass:"section-row select-row"},[_c('p',{staticClass:"sm-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Topic', '題目'))+" ")]),_c('div',{staticClass:"topic-list"},[_c('div',{attrs:{"id":"select-topic-col"}},[_c('TopicDropdown',{attrs:{"topics":_vm.topics.filter(
                    x =>
                      x.schoolYear == _vm.selectedYearTerm[0] &&
                      x.term == _vm.selectedYearTerm[1]
                  )},on:{"input":function($event){_vm.selectedSubtopic = null}},model:{value:(_vm.selectedTopic),callback:function ($$v) {_vm.selectedTopic=$$v},expression:"selectedTopic"}})],1)])]),_c('div',{staticClass:"section-row select-row"},[_c('p',{staticClass:"sm-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Subtopic', '副題'))+" ")]),_c('div',{staticClass:"topic-list"},[_c('div',{attrs:{"id":"select-topic-col"}},[_c('SubtopicDropdown',{class:{ disabled: _vm.selectedTopic == null },attrs:{"subtopics":_vm.selectedTopic?.subtopics},model:{value:(_vm.selectedSubtopic),callback:function ($$v) {_vm.selectedSubtopic=$$v},expression:"selectedSubtopic"}})],1)])]),_c('div',{staticClass:"section-row"},[_c('p',{staticClass:"sm-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Amount', '數量'))+" ")]),_c('InputQuestionQty',{attrs:{"inputs":[1, 2, 3, 4, 5],"defaultInputIndex":0},on:{"input":function($event){_vm.numQuestion = $event}}})],1),_c('button',{staticClass:"modal-btn right-item",class:{ disabled: _vm.selectedTopic == null },attrs:{"id":"btn-random"},on:{"click":function($event){return _vm.$emit('add', {
              topic: _vm.selectedTopic,
              subtopic: _vm.selectedSubtopic,
              num: _vm.numQuestion
            })}}},[_c('i',{staticClass:"icon-font icon-plus"},[_vm._v("")]),_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Add', '加入'))+" ")])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }