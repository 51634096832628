<template>
  <Modal>
    <template v-slot:header>
      <span class="title">
        <template v-if="$interfaceLang == 'en_hk'">
          {{ numQuestion }} Questions
        </template>
        <template v-else> {{ numQuestion }} 條題目 </template>
      </span>
      <i @click="$emit('close')" class="icon-font icon-close">
        &#xe808;
      </i>
    </template>
    <template v-slot:body>
      <div class="modal-section">
        <div class="section-row">
          <Labels class="question-label-list" />
        </div>
        <div class="section-row">
          <ul class="questions-col">
            <li
              v-for="(x, i) in useranswers"
              @click="$emit('input', i)"
              :class="{
                correct: x.isCorrect,
                wrong: x.isWrong,
                current: value == i
              }"
              class="question-col"
            >
              {{ i + 1 }}{{ x.isLate ? '*' : '' }}
            </li>
          </ul>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from '@/components/teacher/modal/Modal.vue'
import Labels from './Labels.vue'
export default {
  components: {
    Modal,
    Labels
  },
  props: {
    value: Number,
    useranswers: Array
  },
  data () {
    return {}
  },
  computed: {
    numQuestion () {
      return this.useranswers?.length
    }
  },
  methods: {}
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>
<style scoped>
::v-deep .modal-container {
  width: 600px;
  max-width: 90vw;
}
.questions-col {
  position: relative;
  display: inline-block;
  list-style: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
}

.question-col {
  position: relative;
  display: block;
  float: left;
  font-size: 13px;
  font-weight: bold;
  color: #d0d0d0;
  width: 32px;
  height: 32px;
  padding: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 15px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #d0d0d0;
  background-color: white;
  cursor: pointer;
}

.question-col.correct {
  color: white;
  background-color: #3ccc89;
  border: none !important;
}

.question-col.wrong {
  color: white;
  background-color: #f65a58;
  border: none !important;
}

.question-col.current {
  padding: 5px;
  border: 2px solid #42c9f1;
}

.question-col:hover,
.question-col:focus {
  padding: 5px;
  border: 2px solid #42c9f1;
}
</style>
