var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.name == 'report-overview')?_c('ReportOverview'):_vm._e(),(_vm.name == 'report-overview-chart')?_c('ReportOverviewChart',{attrs:{"students":[
      {
        id: 'test',
        userIdentifier: 'test',
        name: 'Student',
        overduePapers: [
          { id: 'test', name: 'Paper', expiryTime: '' + Date.now() }
        ]
      }
    ],"papers":[{ id: 'test', name: 'Paper', expiryTime: '' + Date.now() }],"tileLookup":{
      'test-test': {
        numCorrectQuestion: 9,
        numQuestion: 10,
        submitStatus: 'on_time',
        submitTime: '' + Date.now()
      }
    }}}):_vm._e(),(_vm.name == 'report-overview-adapter')?_c('ReportOverviewChartAdapter',{attrs:{"currentUserId":'672',"usergroupId":'57'}}):_vm._e(),(_vm.name == 'report-assignment-row')?_c('ReportAssignmentRow',{attrs:{"paper":{
      id: 'test',
      name: '',
      expiryTime: '' + Date.now()
    }}}):_vm._e(),(_vm.name == 'report-assignment-list')?_c('ReportAssignmentList',{attrs:{"papers":[
      {
        id: 'test',
        type: 'homework',
        name: 'Test',
        expiryTime: '' + Date.now(),
        numSubmission: 2,
        numQuestion: 10,
        usergroupAvgCorrectRate: 59,
        usergroupAvgTimeSpent: 1000000
      }
    ],"numStudent":10}}):_vm._e(),(_vm.name == 'report-assignment-list-adapter')?_c('ReportAssignmentListAdapter',{attrs:{"currentUserId":'672',"usergroupId":'57'}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }