<template>
  <div class="btn-group" role="group">
    <button
      id="btn-select-lang"
      type="button"
      class="btn dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      🌐
      <template v-if="value == 'en_hk'"> En </template>
      <template v-else-if="value == 'zh_hk'"> 繁 </template>
    </button>
    <div
      class="dropdown-menu btn-select-classes-group"
      aria-labelledby="btn-select-lang"
    >
      <a
        v-if="value != 'en_hk'"
        @click.prevent="$emit('input', 'en_hk')"
        class="dropdown-item btn-select-class active"
        href="#"
      >
        En - English
      </a>
      <a
        v-if="value != 'zh_hk'"
        @click.prevent="$emit('input', 'zh_hk')"
        class="dropdown-item btn-select-class"
        href="#"
      >
        繁 - 繁體中文
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: String
  }
}
</script>
<style scoped>
#btn-select-lang {
  display: flex;
  align-items: center;
  margin: 0px;
  padding-right: 15px;
  height: 56px;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
}

#btn-select-lang:before {
  content: '';
  height: 25px;
  border-left: 1.5px solid #bbbbbb;
  padding-right: 15px;
}

.btn-select-lang-group {
  padding: 0px;
  margin: 0 auto;
  min-width: 85%;
  background-color: #007fca;
  border: 0px;
  border-radius: 0px;
}

.btn-select-lang-menu {
  color: white;
  font-size: 13px;
  font-weight: normal;
  line-height: 2.3;
}

.btn-select-lang-menu:hover,
.btn-select-lang-menu:active {
  background-color: #009af3;
}

.dropdown-menu {
  max-height: 80vh;
  /*overflow-y: auto;*/
}
</style>
