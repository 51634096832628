<template>
  <select
    class="select-option input-text select-homeworktype"
    @input="$emit('input', $event.target[$event.target.selectedIndex].value)"
  >
    <option value="oneoff" :selected="value == 'oneoff'">{{
      $t($interfaceLang, 'One-off', '一次性')
    }}</option>
    <option
      :disabled="!enableRecursive"
      value="recursive"
      :selected="value == 'recursive'"
    >
      {{ $t($interfaceLang, 'Recursive', '重覆') }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    value: String,
    enableRecursive: Boolean
  }
}
</script>
