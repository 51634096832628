import { render, staticRenderFns } from "./Info.vue?vue&type=template&id=546b72d7&scoped=true&"
import script from "./Info.vue?vue&type=script&lang=js&"
export * from "./Info.vue?vue&type=script&lang=js&"
import style0 from "../../Homework.css?vue&type=style&index=0&id=546b72d7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546b72d7",
  null
  
)

export default component.exports