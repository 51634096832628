<template>
  <div id="select-accounts">
    <div id="accounts-container">
      <div id="accounts-container-title">
        {{
          $t($interfaceLang ?? 'en_hk', 'Select Your Account', '選擇你的帳號')
        }}
      </div>
      <div id="accounts-container-content">
        <template v-for="(user, i) in userData">
          <div :key="i" class="account-item" @click="selectAcc(user)">
            <div class="profile-pic">
              <img :src="profileImage(user.avatarName)" />
            </div>
            <p class="profile-name">
              {{ user.displayName ?? roleName($interfaceLang, user.type) }}
            </p>
            <p class="profile-role">
              {{ roleName($interfaceLang, user.type) }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SelectAccounts',
  data () {
    return {
      userData: this.$appStore.userData
    }
  },
  computed: {},
  methods: {
    roleName (lang, val) {
      let names = {
        'en_hk-teacher': 'Teacher',
        'en_hk-super_teacher': 'Super Teacher',
        'en_hk-student': 'Student',
        'en_hk-inspector': 'Inspector',
        'zh_hk-teacher': '教師',
        'zh_hk-super_teacher': '超級教師',
        'zh_hk-student': '學生',
        'zh_hk-inspector': '監督'
      }
      return names[`${lang}-${val}`]
    },
    selectAcc (user) {
      if (user.id != this.$appStore.userInfo.userid) {
        this.$appStore.switchIdentity(user)
        //   let userData = Object.assign({}, this.$appStore.userData)
        //   sessionStorage.clear()
        //   this.$appStore.updateUserData(userData);
        //   this.$appStore.updateUserInfo({
        //     userid: user.id,
        //     role: user.type,
        //     name: user.name,
        //     displayName: user.displayName,
        //     avatarName: user.avatarName
        //   })
      }
      this.$router.push('/')
    },
    profileImage (val) {
      var avatarName
      if (val == null || val == 'undefined' || val == '') {
        avatarName = 'profile_teacher_01'
      } else {
        avatarName = val
      }
      return require('@/assets/images/avatar/' + avatarName + '.png')
    }
  }
}
</script>

<style scoped>
#select-accounts {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #192c64;
}

#accounts-container {
  position: relative;
  min-width: 500px;
  background-color: #fff;
}

#accounts-container-title {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  background-color: #007fca;
}

#accounts-container-content {
  position: relative;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.account-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.profile-pic {
  transform: scale(1);
  transition: 0.5s;
}

.account-item:hover .profile-pic {
  transform: scale(1.1);
}

.profile-pic img {
  position: relative;
  width: 80px;
}

.profile-name {
  font-size: 18px;
  font-weight: 600;
  color: #192c64;
  margin-top: 10px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.profile-role {
  font-size: 14px;
  font-weight: 600;
  color: #007fca;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
