<template>
  <div class="btn-group select-term" role="group">
    <button
      type="button"
      class="btn select-term-dropdown dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ displayYearTerm(value) }}
    </button>
    <div class="dropdown-menu select-term-dropdown-group btn-select-term-group">
      <a
        v-for="(yt, i) in yearTerms"
        v-if="compareYearTerm(value, yt) != 0"
        @click="$emit('input', yt)"
        :key="'topicCategoryMenuItem-' + i"
        class="dropdown-item select-term-dropdown-item"
        href="#"
      >
        {{ displayYearTerm(yt) }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    yearTerms: { type: Array, default: _ => [] },
    value: Array
  },
  data () {
    let zhNumber = ['一', '二', '三', '四', '五', '六']
    let yearTermLookup = {}
    for (let year of [1, 2, 3, 4, 5, 6]) {
      yearTermLookup[`en_hk-${year}-${1}`] = `Form ${year} First Term`
      yearTermLookup[`en_hk-${year}-${2}`] = `Form ${year} Second Term`
      yearTermLookup[`zh_hk-${year}-${1}`] = `中${zhNumber[year - 1]}上學期`
      yearTermLookup[`zh_hk-${year}-${2}`] = `中${zhNumber[year - 1]}下學期`
    }
    return {
      yearTermLookup
    }
  },
  computed: {},
  methods: {
    displayYearTerm (yearTerm) {
      return this.yearTermLookup[`${this.$interfaceLang}-${yearTerm.join('-')}`]
    },
    compareYearTerm (yt1, yt2) {
      if (yt1[0] == yt2[0]) {
        return yt1[1] - yt2[1]
      } else {
        return yt1[0] - yt2[0]
      }
    }
  }
}
</script>
<style src="@/pages/teacher/edit-assignment/EditAssignment.css" scoped />
