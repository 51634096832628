<template>
  <div id="overview-container" class="report-container">
    <div class="page-info">
      <div class="page-title-desc">
        <div class="page-title" slot="reference">
          {{
            $t(
              $interfaceLang,
              'Recent assignment submission',
              '最近習作繳交情況'
            )
          }}
        </div>
        <div class="page-desc">
          {{
            $t(
              $interfaceLang,
              'Hover on tiles and icons to know more details. Only assignment that has started, has expired or will expire within a week are listed.',
              '移動鼠標至顏色方塊及圖示以了解更多詳細信息。這裏只顯示已經開始及未來一星期前到期的習作。'
            )
          }}
        </div>
      </div>
      <div class="page-btn-group">
        <!-- Skipped: assignment filter -->
        <button v-if="false" id="btn-csv" class="page-btn org-btn">
          <i class="icon-font icon-undo">&#xe835;</i>
          {{ $t($interfaceLang, 'Export CSV', '匯出 CSV') }}
        </button>
      </div>
    </div>
    <div class="page-content-col">
      <div class="overview-chart-col">
        <div class="filter-col">
          <div class="overview-type-label">
            <div class="overview-type-legend type-new" />
            <span>{{ $t($interfaceLang, 'Not yet started', '未做') }}</span>
          </div>
          <div class="overview-type-label">
            <div class="overview-type-legend type-partial" />
            <span>{{ $t($interfaceLang, 'In progress', '在做') }}</span>
          </div>
          <div class="overview-type-label">
            <div class="overview-type-legend type-submitted" />
            <span>{{ $t($interfaceLang, 'Completed on time', '準時完成') }}</span>
          </div>
          <div class="overview-type-label">
            <div class="overview-type-legend type-late" />
            <span>{{ $t($interfaceLang, 'Completed late', '逾期完成') }}</span>
          </div>
          <div class="overview-type-label">
            <div class="overview-type-legend type-overdue" />
            <span>{{ $t($interfaceLang, 'Overdue', '逾期') }}</span>
          </div>
        </div>
        <OverviewChartAdapter
          :currentUserId="currentUserId"
          :usergroupId="usergroupId"
        />
      </div>
    </div>
  </div>
</template>
<script>
import OverviewChartAdapter from './adapters/overview-chart/OverviewChartAdapter.vue'
export default {
  components: {
    OverviewChartAdapter
  },
  props: {
    currentUserId: String,
    usergroupId: String
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="@/pages/teacher/report-n-statistics/ReportnStatistics.css" scoped />

<style scoped>
.filter-col {
  display: flex;
  width: 100%;
  vertical-align: middle;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.overview-type-label {
  position: relative;
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.overview-type-legend {
  position: relative;
  width: 12px;
  height: 12px;
}

.overview-type-label span {
  font-size: 13px;
  line-height: 1.54;
  color: #626262;
  padding-left: 5px;
}

.type-new {
  border: 1px solid black;
  background-color: #f1f3f0;
}

.type-partial {
  background-color: #9ee5c5;
}

.type-submitted {
  background-color: #3ccc89;
}

.type-overdue {
  background-color: #f65a58;
}

.type-late {
  background-color: #ff881b;
}
</style>
