var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DatePicker',{staticClass:"inline-block date-box-col",attrs:{"locale":_vm.$t(_vm.$interfaceLang, 'en', 'zh'),"firstDayOfWeek":0,"timezone":_vm.$appStore.appInfo.timezoneText,"modelConfig":{
    type: 'string',
    start: {
      timeAdjust: '00:00:00'
    }
  },"min-date":_vm.minDate,"attributes":[
    {
      key: 'weekdays',
      highlight: {
        contentStyle: {
          color: '#f45e5c'
        }
      },
      dates: {
        weekdays: [1]
      }
    },
    {
      highlight: {
        color: 'yellow',
        fillMode: 'outline'
      },
      dates: new Date()
    }
  ],"is-required":""},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, togglePopover }){return [_c('div',{staticClass:"date-box",on:{"click":function($event){return togglePopover()}}},[_c('i',{staticClass:"icon-title icon-font icon-calendar"},[_vm._v("")]),_c('span',{staticClass:"date-text"},[_vm._v(_vm._s(_vm.$displayDate(_vm.date)))])])]}}]),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})
}
var staticRenderFns = []

export { render, staticRenderFns }