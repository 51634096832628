<template></template>
<script>
import LoadLibrarySamplesQuery from './LoadLibrarySamples.graphql'

export default {
  components: {},
  props: {
    currentUserId: String,
    tschemaId: String,
    selector: Object,
    loadSize: Number,
    endCursor: String,
    favQgroups: Array,
    yearTerm: Array
  },
  // computed: {},
  created () {
    this.loadSamples()
  },
  methods: {
    async fetchTschemaQgroupEdgesByTopic (topicId, difficulty) {
      return await this.$apolloQuery(LoadLibrarySamplesQuery, {
        currentUserId: this.currentUserId,
        tschemaId: this.tschemaId,
        lang: this.$interfaceLang,
        topicId,
        difficulty,
        first: this.loadSize,
        after: this.endCursor
      }).then(res => res?.data?.tschemaQgroupEdges ?? [])
    },
    async fetchTschemaQgroupEdgesBySubtopic (subtopicId, difficulty) {
      return await this.$apolloQuery(LoadLibrarySamplesQuery, {
        currentUserId: this.currentUserId,
        tschemaId: this.tschemaId,
        lang: this.$interfaceLang,
        subtopicId,
        difficulty,
        first: this.loadSize,
        after: this.endCursor
      }).then(res => res?.data?.tschemaQgroupEdges ?? [])
    },
    async loadSamples () {
      let { topic, subtopic, difficulty, isFav } = this.selector
      if (!isFav) {
        let tschemaQgroupEdges = []
        if (topic != null && subtopic == null) {
          tschemaQgroupEdges = await this.fetchTschemaQgroupEdgesByTopic(
            topic.id,
            difficulty
          )
        } else if (subtopic != null) {
          tschemaQgroupEdges = await this.fetchTschemaQgroupEdgesBySubtopic(
            subtopic.id,
            difficulty
          )
        }
        let newQuestions = tschemaQgroupEdges.map(
          x => x?.qgroup?.questionSample
        )
        if (newQuestions.length < this.loadSize) {
          this.$emit('loaded', {
            questions: newQuestions,
            endCursor:
              tschemaQgroupEdges.length > 0
                ? tschemaQgroupEdges[tschemaQgroupEdges.length - 1].id
                : null
          })
          this.$emit('complete')
        } else {
          this.$emit('loaded', {
            questions: newQuestions.filter(
              x => x.qgroup?.questionSample?.qgroup?.difficulty ?? 0 < 3
            ),
            endCursor:
              tschemaQgroupEdges.length > 0
                ? tschemaQgroupEdges[tschemaQgroupEdges.length - 1].id
                : null
          })
        }
      } else {
        let newQuestions = this.favQgroups
          ?.filter(
            x =>
              x?.myUserEdge?.isQgroupFav == true &&
              0 ==
                this.compareYearTerm(
                  [x?.myUsergroupTopic?.schoolYear, x?.myUsergroupTopic?.term],
                  this.yearTerm
                ) &&
              (topic == null || x?.myUsergroupTopic?.id == topic.id) &&
              (subtopic == null || x?.myUsergroupSubtopic.id == subtopic.id) &&
              (difficulty == null || x.difficulty == difficulty)
          )
          .filter(x => x.qgroup?.questionSample?.qgroup?.difficulty ?? 0 < 3)
          .map(x => x.questionSample)
        this.$emit('loaded', {
          questions: newQuestions ?? [],
          endCursor: null
        })
        this.$emit('complete')
      }
    },
    compareYearTerm (yt1, yt2) {
      if (yt1[0] == yt2[0]) {
        return yt1[1] - yt2[1]
      } else {
        return yt1[0] - yt2[0]
      }
    }
  }
}
</script>
<style src="" />
