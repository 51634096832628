<template>
  <TopicMastery :topics="topicsData" :isLoading="isLoading" />
</template>
<script>
import LoadTopicMasteryQuery from './LoadTopicMastery.graphql'
import TopicMastery from './TopicMastery.vue'
export default {
  components: {
    TopicMastery
  },
  props: {
    currentUserId: String,
    paperId: String,
    topics: Array,
    numTopicThreshold: Number
  },
  data () {
    return {
      topicsData: null,
      loadBatchSize: 10
    }
  },
  async created () {
    this.isLoading = true
    let paperTopicEdges = await this.fetchTopicMastery()
    this.topicsData = this.parseTopicData(paperTopicEdges, this.topics)
    this.isLoading = false
  },
  computed: {},
  methods: {
    async fetchTopicMastery () {
      return await this.$graphqlUtil.graphqlFetchEdges(
        this.$apolloQuery,
        LoadTopicMasteryQuery,
        {
          currentUserId: this.currentUserId,
          paperId: this.paperId,
          first: this.loadBatchSize
        },
        x => x.paperTopicEdges
      )
    },
    parseTopicData (paperTopicEdges, topics) {
      return paperTopicEdges
        .map(edge => {
          let t = topics?.find(x => x.id == edge?.topic?.id && x.id != null)
          if (t != null) {
            return {
              name: this.$t(this.$interfaceLang, t.nameEn, t.nameZh),
              numQuestion: edge?.cache?.paperStats?.numTopicQuestion,
              avgCorrectRate: edge?.cache?.paperStats?.usergroupAvgCorrectRate
            }
          }
        })
        .filter(x => x != null)
    }
  }
}
</script>
<style src="" />
