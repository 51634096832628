<template>
  <div class="questions-list-col">
    <button class="btn-question" @click="$emit('click-question')">
      <i class="icon-font icon-list">&#xe82c;</i>
      <span v-if="$interfaceLang == 'en_hk'">
        {{ useranswers?.length }} Questions
      </span>
      <span v-else-if="$interfaceLang == 'zh_hk'">
        {{ useranswers?.length }} 條題目
      </span>
    </button>
    <QuestionList
      v-model="currentIndexNo"
      :useranswers="useranswers"
      @input="$emit('input', currentIndexNo)"
    />
  </div>
</template>
<script>
import QuestionList from './QuestionList.vue'
export default {
  components: {
    QuestionList
  },
  props: {
    useranswers: Array,
    value: Number
  },
  watch: {
    value () {
      this.currentIndexNo = this.value
    }
  },
  data () {
    return {
      currentIndexNo: this.value
    }
  },
  computed: {},
  methods: {}
}
</script>
<style src="../../Paper.css" scoped></style>
