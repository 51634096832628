<template>
  <div class="component-wrapper">
    <template v-if="status == 'not-yet-started'">
      <p>
        {{ $t($interfaceLang, 'Release on', '發佈日期') }}
      </p>
      <p class="assignment-start-date gray-color">
        {{ $displayDate(startTime != null ? Number(startTime) : null) }}
      </p>
    </template>
    <template v-else-if="status == 'fresh'">
      <p>{{ $t($interfaceLang, 'Deadline', '遞交日期') }}</p>
      <p class="assignment-due-date">
        {{
          $displayDate(
            expiryTime != null ? Number(expiryTime) - 3600 * 24 * 1000 : null
          )
        }}
      </p>
    </template>
    <template v-else-if="status == 'expired'">
      <p>{{ $t($interfaceLang, 'Deadline', '遞交日期') }}</p>
      <p class="assignment-due-date red-color">
        {{ $displayDate(Number(expiryTime) - 3600 * 24 * 1000) }}
      </p>
    </template>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    startTime: String,
    expiryTime: String
  },
  computed: {
    status () {
      if (this.startTime === null || Number(this.startTime) > Date.now()) {
        return 'not-yet-started'
      } else if (
        this.startTime != null &&
        Number(this.startTime) < Date.now() &&
        Number(this.expiryTime) > Date.now()
      ) {
        return 'fresh'
      } else {
        return 'expired'
      }
    }
  },
  methods: {}
}
</script>

<style scoped>
.component-wrapper {
  width: 100%;
}
</style>
