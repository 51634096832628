var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',[(_vm.isLoading == false)?[_c('thead',[_c('tr',{staticClass:"questions-header"},[_c('th',{staticClass:"table-id"},[_vm._v(_vm._s(_vm.$t(_vm.$interfaceLang, 'ID', 'ID')))]),_c('th',{staticClass:"table-student"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Student', '學生'))+" ")]),_c('th',{staticClass:"table-score"},[_vm._v(_vm._s(_vm.$t(_vm.$interfaceLang, 'Score', '分數')))]),_vm._l((_vm.paper.numQuestion),function(i){return _c('th',{staticClass:"table-assignment"},[_c('i',{staticClass:"icon-font icon-assignment"},[_vm._v("")]),_c('span',[_vm._v("Q"+_vm._s(i))])])})],2)]),_c('tbody',_vm._l((_vm.users),function(user,i){return _c('tr',[_c('td',{staticClass:"table-id"},[_vm._v("#"+_vm._s(user.identifier))]),_c('td',{staticClass:"table-student"},[_c('router-link',{attrs:{"to":{
              path: `/teacher/reportnstatistics/student`,
              query: { id: user.id }
            },"exact":""}},[_vm._v(" "+_vm._s(String(user.name).replace(/(.{14})..+/, '$1…'))+" ")])],1),_c('td',{staticClass:"table-score"},[_vm._v(_vm._s(user.score)+"/"+_vm._s(_vm.paper.numQuestion))]),_vm._l((_vm.paper.numQuestion),function(j){return [_vm._l(([_vm.useranswerLookup[user.id + '@' + (j - 1)]]),function(x){return [_c('td',{staticClass:"table-assignment",on:{"click":function($event){return _vm.$emit('click-useranswer', x)}}},[_c('span',{directives:[{name:"katex",rawName:"v-katex"}],staticClass:"ans-col",class:{
                  'correct-ans': x?.answerIndexNo == 0,
                  'wrong-ans': x?.answerIndexNo > 0 && x?.answerIndexNo <= 3,
                  'empty-ans': x?.answerIndexNo == null,
                  'late-ans': x?.isLate
                }},[_vm._v(" "+_vm._s(_vm.displayAnswerVX(x?.answerIndexNo))+" ")])])]})]})],2)}),0)]:_c('img',{staticClass:"loading-gif",attrs:{"src":require("@/assets/images/loading.gif")}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }