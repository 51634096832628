<template>
  <div class="practice-topics-col">
    <TopicListItem
      v-for="x in topics"
      v-if="yearTerm == null || x.yearTerm == yearTerm"
      :nameEn="x.nameEn"
      :nameZh="x.nameZh"
      :numSubtopic="x.numSubtopic"
      :imagePath="x.imagePath"
      :color="x.color"
      @do-practice="$emit('do-practice', { selectedTopic: x })"
    />
  </div>
</template>
<script>
import TopicListItem from './TopicListItem.vue'

export default {
  components: {
    TopicListItem
  },
  props: {
    yearTerm: String,
    topics: Array
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="../../Practice.css" scoped></style>
