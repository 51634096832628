<template>
  <div class="calendar-container picker-calendar">
    <DatePicker
      ref="date-picker"
      v-model="inputTimes"
      :locale="$t($interfaceLang, 'en', 'zh')"
      :firstDayOfWeek="0"
      :timezone="$appStore.appInfo.timezoneText"
      :model-config="datePickerConfig.modelConfig"
      :select-attribute="datePickerConfig.selectAttribute"
      :drag-attribute="datePickerConfig.dragAttribute"
      :attributes="[
        {
          key: 'weekdays',
          highlight: {
            contentStyle: {
              color: '#f45e5c'
            }
          },
          dates: {
            weekdays: [1]
          }
        },
        {
          highlight: {
            color: 'yellow',
            fillMode: 'outline'
          },
          dates: new Date()
        }
      ]"
      :min-date="new Date()"
      is-range
      is-expanded
      @dayclick="$emit('dayclick', $event)"
      @input="$emit('input', inputTimes)"
    />
  </div>
</template>
<script>
import datePickerConfig from './date-picker-config.json'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  components: {
    DatePicker
  },
  props: {
    value: Object
  },
  data () {
    return {
      datePickerConfig,
      inputTimes: this.value
    }
  }
}
</script>
<style src="@/assets/css/teacherCalendar.css" scoped></style>
<style scoped>
.picker-calendar >>> .vc-highlight-base-start,
.picker-calendar >>> .vc-highlight-base-middle,
.picker-calendar >>> .vc-highlight-base-end {
  background-color: #ffd32d !important;
}
</style>
