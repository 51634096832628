<template>
  <HomeworkList
    ref="homework-list"
    :tab="tab"
    :todoPaperEdges="todoPaperEdges"
    :pastPaperEdges="pastPaperEdges"
    @select-tab="
      tab = $event
      $log(todoEndCursor, pastEndCursor)
    "
    @goto="
      $router.push({ path: '/student/paper', query: { id: $event.paper.id } })
    "
    @todo-infinite="atTodoInfinity($event)"
    @past-infinite="atPastInfinity($event)"
  />
</template>

<script>
import HomeworkList from '../../components/homework-list/HomeworkList.vue'
import LoadPaperTodoListQuery from './LoadPaperTodoList.graphql'
import LoadPaperPastListQuery from './LoadPaperPastList.graphql'

export default {
  components: {
    HomeworkList
  },
  props: {
    currentUserId: String
  },
  data () {
    return {
      tab: 'todo',
      todoPaperEdges: [],
      pastPaperEdges: [],
      todoEndCursor: null,
      pastEndCursor: null,
      loadSize: 50,
      isLoading: false
    }
  },
  methods: {
    async fetchMoreTodoPaperEdges () {
      let edges = await this.$apolloQuery(LoadPaperTodoListQuery, {
        currentUserId: this.currentUserId,
        sortBy: 'EXPIRY_TIME',
        sortOrder: 'ASC',
        first: this.loadSize,
        after: this.todoEndCursor
      }).then(res => {
        let edges = res?.data?.paperUserEdges
        return edges.filter(x => x.paper.type != 'freepractice' && x.paper.type != 'test')
      })
      return edges
    },
    async fetchMorePastPaperEdges () {
      let edges = await this.$apolloQuery(LoadPaperPastListQuery, {
        currentUserId: this.currentUserId,
        sortBy: 'EXPIRY_TIME',
        sortOrder: 'ASC',
        first: this.loadSize,
        after: this.pastEndCursor
      }).then(res => {
        let edges = res?.data?.paperUserEdges
        return edges.filter(x => x.paper.type != 'freepractice' && x.paper.type != 'test')
      })
      return edges
    },
    parsePaperEdge (edge) {
      return {
        id: edge.id,
        paper: edge.paper,
        stats: edge.paper?.cache?.userStudentSubmitStatus,
        numQuestion:
          edge.paper?.cache?.stats?.numQuestion ??
          edge.paper?.questionEdgeCount,
        numSubmission: edge?.cache?.userStudentStats?.numSubmittedQuestion ?? 0,
        numCorrect: edge?.cache?.userStudentStats?.numCorrectQuestion ?? 0
      }
    },
    atTodoInfinity (state) {
      setTimeout(
        (async _ => {
          if (!this.isLoading) {
            this.isLoading = true
            try {
              let edges = await this.fetchMoreTodoPaperEdges(state)
              edges = edges.map(this.parsePaperEdge)
              if (edges.length > 0) {
                this.todoEndCursor = edges[edges.length - 1]?.id
              }
              if (edges.length < this.loadSize) {
                state.complete()
              } else {
                state.loaded()
              }
              this.todoPaperEdges = [...this.todoPaperEdges, ...edges]
            } finally {
              this.isLoading = false
            }
          }
        }).bind(this),
        200
      )
    },
    atPastInfinity (state) {
      setTimeout(
        (async _ => {
          if (!this.isLoading) {
            this.isLoading = true
            try {
              let edges = await this.fetchMorePastPaperEdges(state)
              edges = edges.map(this.parsePaperEdge)
              if (edges.length > 0) {
                this.pastEndCursor = edges[edges.length - 1]?.id
              }
              if (edges.length < this.loadSize) {
                state.complete()
              } else {
                state.loaded()
              }
              this.pastPaperEdges = [...this.pastPaperEdges, ...edges]
            } finally {
              this.isLoading = false
            }
          }
        }).bind(this),
        200
      )
    }
  }
}
</script>

<style></style>
