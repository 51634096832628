<template>
  <div id="editAssignmentInfo-modal">
    <Modal v-if="hasStarted(unsavedStartTime)" @close="$emit('close')">
      <template v-slot:header>
        <i class="icon-title icon-font icon-edit">&#xe80f;</i>
        <span class="title">
          {{ $t($interfaceLang, 'Edit Assignment Info', '編輯作業資訊') }}
        </span>
        <i @click="$emit('close')" class="icon-font icon-close">
          &#xe808;
        </i>
      </template>
      <template v-slot:body>
        <div class="modal-section">
          <p class="sm-title">{{ $t($interfaceLang, 'Title', '標題') }}</p>
          <input
            type="text"
            :placeholder="$t($interfaceLang, '(Default Title)', '(預設題目)')"
            class="input-text"
            v-model="inputName"
          />
        </div>
        <div class="modal-section">
          <div class="date-container">
            <div
              :class="{ hide: scheduleActive }"
              class="date-container-mask"
            />
            <ExtendDatePickerRow
              v-model="inputExpiryDate"
              :startTime="startTime"
              :expiryTime="unsavedExpiryTime"
            />
            <button
              id="btn-save"
              class="modal-btn"
              @click="
                $emit('extend', {
                  inputName,
                  inputExpiryDate: getResultExpiryDate(inputExpiryDate)
                })
              "
            >
              <i class="icon-font icon-save">&#xe81d;</i>
              {{ $t($interfaceLang, 'Save', '儲存') }}
            </button>
          </div>
        </div>
      </template>
    </Modal>
    <Modal v-else @close="$emit('close')">
      <template v-slot:header>
        <i class="icon-title icon-font icon-edit">&#xe80f;</i>
        <span class="title">
          {{ $t($interfaceLang, 'Edit Assignment Info', '編輯作業資訊') }}
        </span>
        <i @click="$emit('close')" class="icon-font icon-close">
          &#xe808;
        </i>
      </template>
      <template v-slot:body>
        <div class="modal-section">
          <p class="sm-title">{{ $t($interfaceLang, 'Title', '標題') }}</p>
          <input
            type="text"
            :placeholder="$t($interfaceLang, '(Default Title)', '(預設標題)')"
            class="input-text"
            v-model="inputName"
          />
        </div>
        <div v-if="numQuestion ?? 0 != 0" class="modal-section">
          <ToggleButton v-model="scheduleActive" />
          <div class="date-container">
            <div
              :class="{ hide: scheduleActive }"
              class="date-container-mask"
            />
            <DatePickerRow v-model="inputDateRanges" />
            <button
              id="btn-save"
              class="modal-btn"
              @click="
                $emit('save', {
                  inputName,
                  inputDateRanges: getResultDateRanges(inputDateRanges),
                  scheduleActive
                })
              "
            >
              <i class="icon-font icon-save">&#xe81d;</i>
              {{ $t($interfaceLang, 'Save', '儲存') }}
            </button>
          </div>
        </div>
        <div v-else class="modal-section">
          <div class="remark">
            {{ $t($interfaceLang, noQuestionWarningEn, noQuestionWarningZh) }}
          </div>
          <button
            id="btn-save"
            class="modal-btn"
            @click="
              $emit('save', {
                inputName,
                inputDateRanges: { start: null, end: null }
              })
            "
          >
            <i class="icon-font icon-save">&#xe81d;</i>
            {{ $t($interfaceLang, 'Save', '儲存') }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import config from '@/config.json'
import Modal from '@/components/teacher/modal/Modal.vue'
import ExtendDatePickerRow from './ExtendDatePickerRow.vue'
import DatePickerRow from './DatePickerRow.vue'
import ToggleButton from './ToggleButton.vue'
export default {
  components: {
    Modal,
    DatePickerRow,
    ExtendDatePickerRow,
    ToggleButton
  },
  props: {
    paperName: String,
    startTime: String,
    expiryTime: String,
    numQuestion: Number,
    unsavedStartTime: String,
    unsavedExpiryTime: String
  },
  data () {
    return {
      scheduleActive: this.startTime != null,
      inputName: this.paperName,
      inputExpiryDate: this.getInputExpiryDate(this.expiryTime),
      inputDateRanges: this.getInputDateRanges(this.startTime, this.expiryTime),
      noQuestionWarningEn:
        'There is no question in the assignment, it can be only saved as a draft.',
      noQuestionWarningZh: '作業沒有題目，只能儲存為草稿。'
    }
  },
  computed: {},
  methods: {
    getInputDateRanges (startTime, expiryTime) {
      let result = { start: null, end: null }
      if (startTime != null) {
        result.start = new Date(Number(startTime))
      }
      if (expiryTime != null) {
        result.end = new Date(Number(expiryTime) - 3600 * 24 * 1000)
      }
      if (Number(result.end) < Number(result.start)) {
        return { start: null, end: null }
      } else {
        return result
      }
    },
    getResultDateRanges (inputDateRanges) {
      let result = { start: null, end: null }
      if (inputDateRanges.start != null) {
        result.start = '' + Number(inputDateRanges.start)
      }
      if (inputDateRanges.end != null) {
        result.end = '' + (Number(inputDateRanges.end) + 3600 * 24 * 1000)
      }
      return result
    },
    getInputExpiryDate (inputExpiryDate) {
      return new Date(Number(inputExpiryDate) - 3600 * 24 * 1000)
    },
    getResultExpiryDate (inputExpiryDate) {
      return '' + (Number(inputExpiryDate) + 3600 * 24 * 1000)
    },
    hasStarted (startTime) {
      return startTime != null && Number(startTime) < Date.now()
    }
  }
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>
<style scoped>
#editAssignmentInfo-modal >>> .modal-container {
  width: 596px;
}
.hide {
  display: none;
}
.remark {
  width: 100%;
  font-size: 14px;
  color: #007fca;
}
.disabled {
  pointer-events: none;
  opacity: 0.67;
}
</style>
