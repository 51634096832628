<template>
  <DoughnutChart
    id="doughnut-chart"
    @sendLegend="$emit('update-legend', $event)"
    :chart-data="chartData"
    :options="chartOptions"
  />
</template>
<script>
import DoughnutChart from '@/components/student/ReportChartDoughnut.vue'
export default {
  components: { DoughnutChart },
  props: {
    numNew: Number,
    numProgress: Number,
    numOverdue: Number
  },
  data () {
    return {
      legend: null,
      chartData: null,
      chartOptions: null
    }
  },
  mounted () {
    this.render()
  },
  watch: {
    numNew () {
      this.render()
    },
    numProgress () {
      this.render()
    },
    numOverdue () {
      this.render()
    }
  },
  methods: {
    render () {
      let thisComponent = this
      this.chartData = {
        labels: [
          this.$t(this.$interfaceLang, 'New', '新功課'),
          this.$t(this.$interfaceLang, 'In Progress', '進行中'),
          this.$t(this.$interfaceLang, 'Overdue', '逾期')
        ],
        datasets: [
          {
            backgroundColor: ['#ff7000', '#42c8f1', '#f65a58'],
            data: [this.numNew, this.numProgress, this.numOverdue],
            cssClass: ['todo', 'progress', 'overdue']
          }
        ]
      }
      this.chartOptions = {
        cutoutPercentage: 80,
        center: this.numNew + this.numProgress + this.numOverdue,
        tooltips: {
          enabled: false
        },
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        legendCallback: function (chart) {
          // console.log('legendCallback')
          var legendHtml = []
          legendHtml.push('<ul class="chart-doughnut-legend-list">')
          var item = chart.data.datasets[0]
          for (var i = 0; i < item.data.length; i++) {
            // console.log(item);
            legendHtml.push('<li>')
            legendHtml.push('<div class="chart-doughnut-label-list">')
            if (item.cssClass[i] == 'todo') {
              legendHtml.push(
                '<span class="chart-doughnut-legend todo"><i class="icon-font icon-star">&#xe82f;</i></span>'
              )
            } else if (item.cssClass[i] == 'progress') {
              legendHtml.push(
                '<span class="chart-doughnut-legend inprogress"><i class="icon-font icon-in_progress">&#xe82b;</i></span>'
              )
            } else if (item.cssClass[i] == 'overdue') {
              legendHtml.push(
                '<span class="chart-doughnut-legend overdue"><i class="icon-font icon-expire">&#xe82a;</i></span>'
              )
            }
            legendHtml.push(
              '<span class="chart-doughnut-legend-label-text">' +
                chart.data.labels[i] +
                '</span>'
            )
            legendHtml.push(
              '<span class="chart-doughnut-legend-label-num">' +
                item.data[i] +
                '</span>'
            )
            legendHtml.push('</div>')
            legendHtml.push('</li>')
          }
          legendHtml.push('</ul>')
          return legendHtml.join('')
        }
      }
      this.$nextTick(_ => {
        this.$forceUpdate()
      })
    }
  }
}
</script>
<style src="../../Homework.css" scoped />

<style scoped>
.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-leave-active {
  transition: opacity 0s;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

.fade-leave {
  /* .fade-leave-active below version 2.1.8 */
  display: none;
}
</style>
