<template>
  <DeletePaperBulkModal
    :papers="papers"
    @delete="deletePapers(papers)"
    @close="$emit('close')"
  />
</template>
<script>
import DeletePapersQuery from '../../adapters/delete-paper-bulk/DeletePapers.graphql'
import DeletePaperBulkModal from '../../modal/DeletePaperBulk.vue'
export default {
  components: {
    DeletePaperBulkModal
  },
  props: {
    currentUserId: String,
    papers: Array
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    async deletePapers (papers) {
      this.$emit('mutate-start')
      try {
        var success = await this.$apolloMutation(DeletePapersQuery, {
          currentUserId: this.currentUserId,
          paperIds: papers.map(x => x.id)
        }).then(res => res?.data?.deleteMyPapers?.success)
        success ? this.$emit('mutate-done') : this.$emit('mutate-error')
      } catch (e) {
        this.$log(e)
        this.$emit('mutate-error')
      }
    }
  }
}
</script>
<style src="" />
