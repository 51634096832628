<template>
  <div id="select-diff" class="btn-group select-dropdown" role="group">
    <button
      type="button"
      class="btn select-diff-dropdown select-questions-dropdown dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <p class="select-title">
        {{ $t($interfaceLang, 'Difficulty', '難度') }}
      </p>
      <span class="select-content">
        <template v-if="value == null">
          {{ $t($interfaceLang, 'All difficulties', '所有難度') }}
        </template>
        <template v-else>
          {{ displayDifficulty(value) }}
        </template>
      </span>
    </button>
    <div
      class="dropdown-menu select-diff-dropdown-group select-questions-dropdown-group  btn-select-topic-group"
    >
      <a
        :key="'difficultyMenuItem-all'"
        v-if="null != value"
        @click="$emit('input', null)"
        class="dropdown-item select-diff-dropdown-item select-questions-dropdown-item"
        href="#"
      >
        {{ $t($interfaceLang, 'All difficulties', '所有難度') }}
      </a>
      <a
        v-for="level in [0, 1]"
        :key="'difficultyMenuItem-' + level"
        v-show="level != value"
        @click="$emit('input', level)"
        class="dropdown-item select-diff-dropdown-item select-questions-dropdown-item"
        href="#"
      >
        {{ displayDifficulty(level) }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: Number
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    displayDifficulty (x) {
      if (x == 0) {
        return this.$t(this.$interfaceLang, 'Easy', '簡單')
      } else if (x == 1) {
        return this.$t(this.$interfaceLang, 'Medium', '普通')
      } else if (x == 2) {
        return this.$t(this.$interfaceLang, 'Hard', '困難')
      }
    }
  }
}
</script>
<style src="@/pages/teacher/edit-assignment/EditAssignment.css" scoped />
