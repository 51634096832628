<template>
  <select
    class="select-option input-text select-schedule-repeat"
    @input="$emit('input', $event.target[$event.target.selectedIndex].value)"
  >
    <option value="1" :selected="value == '1'">1</option>
    <option value="2" :selected="value == '2'">2</option>
    <option value="3" :selected="value == '3'">3</option>
    <option value="4" :selected="value == '4'">4</option>
    <option value="5" :selected="value == '5'">5</option>
  </select>
</template>

<script>
export default {
  props: {
    value: String
  }
}
</script>
