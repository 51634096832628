<template>
  <div>
    <Header v-if="name == 'header'" />
    <HeaderAdapter v-if="name == 'header-adapter'" :currentUserId="'491'" />
    <HomeworkInfo
      v-if="name == 'homework-info'"
      :numNew="10"
      :numProgress="20"
    />
    <HomeworkInfoAdapter
      v-if="name == 'homework-info-adapter'"
      :currentUserId="'591'"
    />
    <HomeworkCalendar
      v-if="name == 'homework-calendar'"
      :papers="[
        {
          id: 'test',
          name: 'Test',
          type: 'homework',
          isDynamic: false,
          startTime: '' + Date.now(),
          expiryTime: '' + Date.now()
        },
        {
          id: 'test2',
          name: 'Test2',
          type: 'recursive',
          isDynamic: false,
          startTime: '' + Date.now(),
          expiryTime: '' + Date.now()
        },
        {
          id: 'test3',
          name: 'Test3',
          type: 'recursive_followup',
          isDynamic: false,
          startTime: '' + Date.now(),
          expiryTime: '' + Date.now()
        }
      ]"
    />
    <HomeworkCalendarAdpater
      v-if="name == 'homework-calendar-adapter'"
      :currentUserId="'591'"
    />
    <HomeworkTodoRow
      v-if="name == 'homework-todo-row'"
      :numSubmssion="5"
      :numQuestion="10"
      :paper="{
        id: 'test',
        name: 'Test',
        type: 'homework',
        isDynamic: false,
        startTime: '' + Date.now(),
        expiryTime: '' + Date.now()
      }"
    />
    <HomeworkList
      v-if="name == 'homework-list'"
      :papers="[
        {
          id: 'test',
          name: 'Test',
          type: 'homework',
          isDynamic: false,
          startTime: '' + Date.now(),
          expiryTime: '' + Date.now()
        },
        {
          id: 'test2',
          name: 'Test2',
          type: 'homework',
          isDynamic: false,
          startTime: '' + Date.now(),
          expiryTime: '' + Date.now()
        }
      ]"
    />
    <HomeworkListAdapter
      v-if="name == 'homework-list-adapter'"
      :currentUserId="'684'"
    />
    <PaperQuestionNav
      v-if="name == 'paper-question-nav'"
      :status="['correct', 'wrong', '', '', '']"
      v-model="paperQuestionListNumber"
    />
    <PaperQuestionList
      v-if="name == 'paper-question-list'"
      :status="['correct', 'wrong', '', '', '']"
      v-model="paperQuestionListNumber"
    />
    <PaperQuestion
      v-if="name == 'paper-question'"
      :indexNo="4"
      :useranswer="{
        id: 'test',
        type: 'homework',
        question: {
          displayQuestion: {
            id: '22188',
            type: 'mc',
            sampleEdgeKey: '361b692d770811e3f49d0d1b8ecde4c4',
            qgroup: { id: '152', myUserEdge: null, __typename: 'Qgroup' }
          }
        },
        data: {
          permutation: [0, 2, 1, 3],
          displayAnswerIndexNo: 2
        }
      }"
      @choose-ans="$log('choose-ans', $event)"
    />

    <PaperSolution
      v-if="name == 'paper-solution'"
      solutionEdgeKey="89a811bbc1a7823612363569a25b9ac8"
      :useranswer="{
        id: 'test',
        type: 'homework',
        question: {
          displayQuestion: {
            id: '22188',
            type: 'mc',
            sampleEdgeKey: '361b692d770811e3f49d0d1b8ecde4c4',
            qgroup: { id: '152', myUserEdge: null, __typename: 'Qgroup' }
          }
        },
        data: {
          permutation: [0, 2, 1, 3]
        }
      }"
    />
    <img v-if="name == 'test-image'" class="question-image" :src="testImage" />

    <ChartPerformance
      v-if="name == 'chart-performance'"
      :paperEdges="[
        {
          paper: {
            name: 'Assignment 1',
            expiryTime: '' + Date.now(),
            cache: { stats: { usergroupAvgCorrectRate: 30 } }
          },
          cache: {
            userStudentStats: {
              correctRate: 40
            }
          }
        },
        {
          paper: {
            name: 'Assignment 2',
            expiryTime: '' + Date.now(),
            cache: { stats: { usergroupAvgCorrectRate: 40 } }
          },
          cache: {
            userStudentStats: {
              correctRate: 50
            }
          }
        },
        {
          paper: {
            name: 'Assignment 3',
            expiryTime: '' + Date.now(),
            cache: { stats: { usergroupAvgCorrectRate: 80 } }
          },
          cache: {
            userStudentStats: {
              correctRate: 70
            }
          }
        },
        {
          paper: {
            name: 'Assignment 1',
            expiryTime: '' + Date.now(),
            cache: { stats: { usergroupAvgCorrectRate: 30 } }
          },
          cache: {
            userStudentStats: {
              correctRate: 40
            }
          }
        },
        {
          paper: {
            name: 'Assignment 2',
            expiryTime: '' + Date.now(),
            cache: { stats: { usergroupAvgCorrectRate: 40 } }
          },
          cache: {
            userStudentStats: {
              correctRate: 50
            }
          }
        },
        {
          paper: {
            name: 'Assignment 3',
            expiryTime: '' + Date.now(),
            cache: { stats: { usergroupAvgCorrectRate: 80 } }
          },
          cache: {
            userStudentStats: {
              correctRate: 70
            }
          }
        }
      ]"
    />
    <ChartPerformanceAdapter
      v-if="name == 'chart-performance-adapter'"
      :currentUserId="'684'"
    />
    <TopicPerformanceAdapter
      v-if="name == 'topic-performance-adapter'"
      :currentUserId="'684'"
      :usergroupId="'57'"
    />
  </div>
</template>
<script>
import Header from '../components/student/header/Header.vue'
import HeaderAdapter from '../components/student/header/HeaderAdapter.vue'
import HomeworkInfo from '../pages/student/homework/components/info/Info.vue'
import HomeworkInfoAdapter from '../pages/student/homework/adapters/info/InfoAdapter.vue'
import HomeworkCalendar from '../pages/student/homework/components/Calendar.vue'
import HomeworkCalendarAdpater from '../pages/student/homework/adapters/calendar/CalendarAdapter.vue'
import HomeworkTodoRow from '../pages/student/homework/components/homework-list/HomeworkTodoRow.vue'
import HomeworkList from '../pages/student/homework/components/homework-list/HomeworkList.vue'
import HomeworkListAdapter from '../pages/student/homework/adapters/homework-list/HomeworkListAdapter.vue'

import PaperQuestionNav from '../pages/student/paper/components/question-nav/QuestionNav.vue'

import PaperQuestionList from '../pages/student/paper/components/question-nav/QuestionList.vue'
import PaperQuestion from '../pages/student/paper/components/PaperQuestion.vue'

import PaperSolution from '@/components/student/paper-solution/PaperSolution.vue'
import ChartPerformance from '@/pages/student/performance/components/chart-performance/ChartPerformance.vue'
import ChartPerformanceAdapter from '@/pages/student/performance/adapters/chart-performance/ChartPerformanceAdapter.vue'
import TopicPerformanceAdapter from '@/pages/student/performance/adapters/topic-performance/TopicPerformanceAdapter.vue'

export default {
  components: {
    Header,
    HeaderAdapter,
    HomeworkInfo,
    HomeworkInfoAdapter,
    HomeworkCalendar,
    HomeworkCalendarAdpater,
    HomeworkTodoRow,
    HomeworkList,

    HomeworkListAdapter,
    PaperQuestionNav,
    PaperQuestionList,
    PaperQuestion,
    PaperSolution,
    ChartPerformance,
    ChartPerformanceAdapter,
    TopicPerformanceAdapter
  },
  props: {},
  data () {
    return {
      name: this.$route.query.name,
      paperQuestionListNumber: 4,
      testImage: null
    }
  },
  computed: {},
  async created () {
    this.testImage = await this.$loadContent(
      '632397e68e542a5bdb929a23792a1145.jpg'
    )
  },
  methods: {
    imgSrc (base64) {
      if (base64 != null) {
        return 'data:image/jpeg;base64,' + base64
      }
    }
  }
}
</script>
<style src="" />
