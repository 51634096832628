<script>
import { Bar } from 'vue-chartjs'
import zoom from 'chartjs-plugin-zoom';

export default {
  extends: Bar,
  props: ['chartData', 'options'],
  mounted () {
    this.addPlugin(zoom);
    this.render()
  },
  watch: {
    'chartData' (to, from) {
      this.render()
    }
  },
  methods: {
    render () {
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>
