<template>
  <div class="item-date">
    <p v-if="daysToGo > 1" class="sm-title">
      <template v-if="$interfaceLang == 'en_hk'">
        Due <span class="sm-title2">{{ daysToGo }} days later</span>
      </template>
      <template v-else-if="$interfaceLang == 'zh_hk'">
        <span class="sm-title2">{{ daysToGo }} 日後到期</span>
      </template>
    </p>
    <p v-else-if="daysToGo > 0" class="sm-title">
      <template v-if="$interfaceLang == 'en_hk'">
        Due <span class="sm-title2"> Tomorrow</span>
      </template>
      <template v-else-if="$interfaceLang == 'zh_hk'">
        <span class="sm-title2">明天</span>到期
      </template>
    </p>

    <p v-else-if="daysToGo == 0" class="sm-title">
      <template v-if="$interfaceLang == 'en_hk'">
        Due <span class="sm-title2"> Today</span>
      </template>
      <template v-else-if="$interfaceLang == 'zh_hk'">
        <span class="sm-title2">今天</span>到期
      </template>
    </p>

    <p v-else class="sm-title">
      <template v-if="$interfaceLang == 'en_hk'">
        <span class="sm-title2">Overdue</span>
      </template>
      <template v-else-if="$interfaceLang == 'zh_hk'">
        <span class="sm-title2">逾期</span>
      </template>
    </p>
    <p class="item-hightlight-text">
      {{ $displayDate(Number(expiryTime) - 3600 * 24 * 1000) }}
    </p>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    expiryTime: String
  },
  data () {
    return {}
  },
  computed: {
    daysToGo () {
      return Math.floor(
        (Number(this.expiryTime) - Date.now()) / 3600 / 24 / 1000
      )
    }
  },
  methods: {}
}
</script>
<style scoped>
.click-item {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
}

.click-item:hover,
.click-item:focus {
  text-decoration: none;
}

.click-item:hover .item-name,
.click-item:focus .item-name {
  color: #0c89d3;
}

.type-new-label {
  position: relative;
  padding: 2px 5px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  background-color: #f5a53d;
  border-radius: 4px;
  margin-right: 5px;
}

.type-new {
  border-left: 5px solid #ff881b;
  background-color: #fff5cb !important;
}

.type-progress {
  border-left: 5px solid #42c9f1;
  background-color: #d9f4ff !important;
}

.icon-font {
  color: #1c2d60;
  font-size: 14px;
  margin-left: auto;
  width: 2%;
}

.item-row {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
}

.item-row p {
  margin: 0px;
}

.sm-title {
  font-size: 11px;
  color: #1c2d60;
}

.sm-title2 {
  font-size: 11px;
  color: #f65a58;
}

.item-hightlight-text {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
}

.item-name {
  width: 45%;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.item-answered-questions {
  width: 25%;
  padding: 0px 25px;
  border-left: 1px solid #adadad;
}

.item-date {
  width: 17%;
  padding: 0px 10px;
  border-right: 1px solid #adadad;
}

::v-deep .progress-col {
  position: relative;
  display: flex;
  align-items: center;
}

::v-deep .progress {
  height: 0.5rem;
  width: 78%;
  background-color: #d0d0d0;
}

::v-deep .progress-bar {
  background-color: #ff881b;
}

::v-deep .progress-col .item-hightlight-text {
  margin-left: 5px;
}

@media only screen and (max-width: 767px) {
  .item-name {
    width: 100%;
    flex: 0 1 100%;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .item-answered-questions {
    width: 50%;
    padding: 0px;
    border: none;
  }

  .item-date {
    width: 48%;
    padding: 0px;
    padding-left: 20px;
    border: none;
  }
}
</style>
