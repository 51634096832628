<template>
  <div class="question-content" v-if="questionData != null">
    <div
      v-if="questionData.contentImageData == null"
      v-katex
      class="question-title katex"
      v-html="questionData.content"
    />
    <div v-else v-katex class="question-title katex">
      <p v-html="questionData.content" />
      <img class="question-image" :src="questionData.contentImageData" />
    </div>
    <ul class="question-ans-list" v-if="answers != null">
      <li
        v-for="(a, i) in answers"
        class="question-ans"
        :key="'ans-' + i"
        :id="'ans-choice-' + indexNo+'-'+ questionId + '-' + i"
        :class="{
          'correct-ans': isCorrectAnswerAt(i) && correctAnswerIndex != null,
          'wrong-ans': !isCorrectAnswerAt(i) && correctAnswerIndex != null
        }"
        
        @click="
          $emit('choose-ans', {
            indexNo: i,
            ansHTML: $(
              '#ans-choice-' + indexNo + '-' + questionId.replace(/\./g, '\\.') + '-' + i
            )[0].innerHTML
          })
        "
      >
        <i class="icon-font icon-profile" v-if="isShowUserMarkerAt(i)">
          &#xe818;
        </i>
        <span class="mr-2"> {{ displayAnswerAlphabet(i) }}. </span>
        <img v-if="questionData.isAnswerImage" class="ans-image" :src="a" />
        <span v-else="questionData.isAnswerImage" v-katex> {{ a }} </span>
      </li>
    </ul>
  </div>
</template>
<script>
import VKatex from 'vue-katex-auto-render'
import jQuery from 'jquery'
let $ = jQuery

export default {
  directives: {
    katex: VKatex
  },
  components: {},
  props: {
    indexNo: Number,
    questionId: String,
    questionData: Object,
    userMarkerIndex: Number,
    permutation: Array,
    correctAnswerIndex: Number
  },
  computed: {
    answers () {
      if (this.permutation != null) {
        return this.permute(this.questionData.answers, this.permutation)
      }
    }
  },
  methods: {
    isShowUserMarkerAt (i) {
      return i == this.userMarkerIndex
    },
    isCorrectAnswerAt (i) {
      return i == this.correctAnswerIndex
    },
    displayAnswerAlphabet (i) {
      var alphabet = ['A', 'B', 'C', 'D']
      return alphabet[i]
    },
    permute (x, permutation) {
      if (
        permutation != null &&
        permutation.length > 0 &&
        x.length == permutation.length &&
        x.length == [...new Set(permutation)].length &&
        Math.max(...permutation) < x.length &&
        Math.min(...permutation) >= 0
      ) {
        return permutation.map(p => x[p])
      }
    },
    ipermute (x, permutation) {
      if (
        permutation != null &&
        permutation.length > 0 &&
        x.length == permutation.length &&
        x.length == [...new Set(permutation)].length &&
        Math.max(...permutation) < x.length &&
        Math.min(...permutation) >= 0
      ) {
        let z = new Array(x.length).fill(0)
        x.forEach((y, i) => {
          z[permutation[i]] = y
        })
        return z
      }
    },
    $
  }
}
</script>

<style scoped>
.question-title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}

.question-image {
  position: relative;
  max-width: 350px;
  width: 100%;
  margin: 0px auto;
  display: block;
  padding: 10px 0px;
}

.question-ans-list {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.question-ans-list li {
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 500;
  padding: 3px 20px;
  margin-bottom: 20px;
  border: 1px solid black;
  border-radius: 20px;
  width: 49%;
}

.image-image li,
.text-image li {
  width: 48%;
}

.question-ans {
  cursor: pointer;
}

.question-ans:hover,
.question-ans:focus {
  background-color: #6d6d6d;
  color: white;
}

.question-ans img {
  width: 100%;
  margin-bottom: 20px;
}

.correct-ans {
  color: white;
  background-color: #3ccc89;
  border: none !important;
  cursor: auto !important;
}

.wrong-ans {
  color: white;
  background-color: #f65a58;
  border: none !important;
  cursor: auto !important;
}

.correct-ans:hover,
.correct-ans:focus {
  color: white;
  background-color: #3ccc89;
}

.wrong-ans:hover,
.wrong-ans:focus {
  color: white;
  background-color: #f65a58;
}

.ans-image {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .question-ans-list li {
    width: 100%;
  }

  .image-image li,
  .text-image li {
    width: 100%;
  }
}
</style>
