<template>
  <div>
    <div class="question-info">
      <div class="question-info-left">
        <!-- fav button -->
        <FavButton
          v-if="showFav"
          v-model="isFav"
          @input="$emit('set-isfav', { question, isFav })"
          class="btn-favourite"
        />
        <div v-if="indexNo != null" class="question-num">
          Q{{ indexNo + 1 }}
        </div>
        <button
          @click="$emit('show-similar')"
          class="btn-relate add-question-btn"
        >
          <i class="icon-font icon-relate">&#xe81a;</i>
          {{ $t($interfaceLang, 'View Similar', '檢視相似題目') }}
        </button>
        <div v-if="correctRate != null" class="correct-rate">
          {{
            $t(
              $interfaceLang,
              `${correctRate}% Correct`,
              `${correctRate}% 答對`
            )
          }}
        </div>
      </div>
    </div>
    <!-- question view -->
    <QuestionView
      :question="question"
      :isShuffled="false"
      :permutation="[0, 1, 2, 3]"
      :mcAnswerPermutation="question?.displayQuestion?.sampleEdgePermutation"
    />
  </div>
</template>
<script>
import QuestionView from '@/components/teacher/new-question-view/QuestionView.vue'
import FavButton from '@/components/FavButton.vue'
export default {
  components: {
    QuestionView,
    FavButton
  },
  props: {
    indexNo: Number,
    correctRate: Number,
    question: Object,
    showFav: Boolean,
    isQgroupFav: Boolean
  },
  watch: {
    isQgroupFav (newValue) {
      this.isFav = newValue
    }
  },
  data () {
    return {
      isFav: this.isQgroupFav
    }
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>
.question-container {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  background-color: white;
  margin-top: 20px;
  border-bottom: 1px solid #d0d0d0;
}

.question-info {
  position: relative;
  display: flex;
}

.question-info-left {
  position: relative;
  display: flex;
  align-items: center;
}

.btn-relate.add-question-btn {
  display: flex;
  align-items: center;
  color: #1c2d60;
  font-size: 12px;
  font-weight: 600;
  padding: 0px 10px;
  margin-left: 10px;
  border-radius: 12px;
  background-color: #f1f3f1;
}

.btn-relate.add-question-btn i {
  color: #007fca;
  font-size: 16px;
  margin-right: 5px;
}

.btn-relate.add-question-btn:focus,
.btn-relate.add-question-btn:hover {
  background-color: #a3a3a3;
}

.question-info-right {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
}

.add-question-btn {
  border: none;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  color: #1c2d60;
  font-size: 24px;
}

.add-question-btn:focus,
.add-question-btn:hover,
.add-question-btn:active {
  box-shadow: none;
  outline: none;
}

.btn-favourite {
  padding-right: 5px;
  border-right: 1px solid #f1f3f1;
}

.add-question-qty {
  display: flex;
  align-items: center;
}

.question-qty {
  font-size: 13px;
  font-weight: 600;
  color: #1c2d60;
  padding: 0px 10px;
}

.img-qty {
  width: 20px;
  cursor: pointer;
}

.btn-add {
  font-size: 13px;
  font-weight: 600;
  color: white;
  padding: 2px 10px;
  border-radius: 12px;
  background-color: #1c2d60;
  margin-left: 10px;
}

.btn-add:hover,
.btn-add:focus {
  background-color: #2a4186;
}

.high-incorrect {
  display: flex;
  align-items: center;
  padding: 2px 10px;
  font-size: 13px;
  font-weight: bold;
  color: #f65a58;
  border: 2px solid #f65a58;
  border-radius: 6px;
}

.high-incorrect i {
  font-size: 18px;
  margin-right: 5px;
}

.remarkable {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  font-size: 13px;
  font-weight: bold;
  color: #1c2d60;
  background-color: #ffd32d;
  border-radius: 6px;
  height: 30px;
}

.remarkable i {
  font-size: 25px;
  margin-right: 0px;
}
.question-num {
  font-size: 22px;
  font-weight: bold;
  color: #1c2d60;
}
.correct-rate {
  display: flex;
  align-items: center;
  color: #1c2d60;
  font-size: 16px;
  font-weight: 600;
  padding: 0px 10px;
  margin-left: 10px;
  border-radius: 12px;
}

</style>
