<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['chartData', 'options'],
  data: () => ({
    htmlLegend: null
  }),
  mounted () {
    this.addPlugin({
      id: 'my-plugin',
      beforeDraw: function (chart) {
        var width = chart.chart.width
        var height = chart.chart.height
        var ctx = chart.chart.ctx

        ctx.restore()
        var fontSize = (height / 50).toFixed(2)
        ctx.font = fontSize + 'em sans-serif'
        ctx.textBaseline = 'middle'

        var text = chart.config.options.center
        var textX = Math.round((width - ctx.measureText(text).width) / 2)
        var textY = height / 2
        ctx.fillStyle = '#1c2d60'
        ctx.fillText(text, textX, textY)

        ctx.save()
      }
    })
    this.render()
  },
  watch: {
    chartData (to, from) {
      this.render()
    }
  },
  methods: {
    render () {
      this.renderChart(this.chartData, this.options)
      this.htmlLegend = this.generateLegend()
      this.$emit('sendLegend', this.htmlLegend)
    }
  }
}
</script>
