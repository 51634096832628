<template>
  <div>
    <TeacherUsergroupMenu
      v-if="userRole == 'teacher' || userRole == 'inspector'"
      v-model="selectedUsergroup"
      :usergroupEdges="usergroupEdges"
      :userRole="userRole"
      :isLoading="isLoading"
      @input="$emit('input', selectedUsergroup)"
    />
  </div>
</template>
<script>
import TeacherUsergroupMenu from './TeacherUsergroupMenu.vue'
export default {
  components: {
    TeacherUsergroupMenu
  },
  props: {
    usergroupEdges: Array,
    userRole: String,
    isLoading: Boolean,
    value: Object
  },
  data () {
    return {
      selectedUsergroup: this.value
    }
  },
  computed: {},
  watch: {
    value (newValue) {
      this.selectedUsergroup = newValue
    }
  },
  methods: {
    showInClass (usergroupEdge) {
      return (
        usergroupEdge.usergroup.type == 'class' &&
        (usergroupEdge.isUserTeacherOwner || this.userRole == 'inspector')
      )
    },
    showInUsergroup (usergroupEdge) {
      return (
        usergroupEdge.usergroup.type == 'usergroup' &&
        (usergroupEdge.isUserTeacherOwner || this.userRole == 'inspector')
      )
    },
    showInShared (usergroupEdge) {
      return (
        usergroupEdge.usergroup.type == 'usergroup' &&
        !usergroupEdge.isUserTeacherOwner
      )
    }
  }
}
</script>
<style scoped>
.menu-super-teacher {
  position: relative;
  padding: 15px;
  background-color: #192c64;
}

.menu-super-teacher .btn-select-class {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 10px 0px 10px 0px;
}

.menu-super-teacher .btn-select-class span {
  position: relative;
  margin-right: 10px;
  min-width: 80px;
}

.menu-super-teacher .btn-select-class .icon-font {
  margin-left: auto;
}

.menu-super-teacher a:hover {
  color: white !important;
  background-color: #192c64 !important;
}

.menu-super-teacher .btn-select-class .icon-font:hover,
.menu-super-teacher .btn-select-class span:hover,
.menu-super-teacher .btn-add-group:hover {
  color: #ddc254 !important;
}

.dropdown-submenu {
  position: relative;
  padding: 0px;
  width: 175px;
}

.dropdown-submenu-item {
  position: relative;
  display: block;
  color: white !important;
  font-weight: bold !important;
  padding: 10px 11px 10px 20px !important;
  background-color: #192c64 !important;
}

.dropdown-submenu-item:hover,
.dropdown-submenu-item:active {
  color: #ddc254 !important;
  background-color: #000 !important;
}

.dropdown-submenu > .dropdown-submenu-menu {
  top: 0;
  left: 99%;
  margin-top: -1px;
  border: none;
}

.dropdown-submenu:hover > .dropdown-submenu-menu {
  display: block;
  opacity: 1;
  height: auto;
  pointer-events: visible;
}

.dropdown-submenu > a:after {
  display: block;
  content: ' ';
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: 5px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

#teacher-header {
  position: relative;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#header-logo img {
  width: 120px;
}

.teacher-menu ul {
  padding: 0px;
  margin: 0px;
}

.teacher-menu .teacher-menu-item {
  position: relative;
  float: left;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0px 25px;
}

.teacher-menu .teacher-menu-item a {
  color: #1e2d60;
  text-decoration: none;
}

.teacher-menu .teacher-menu-item a:hover,
.teacher-menu .teacher-menu-item a:focus,
.select-menu.active a {
  color: #0c89d3;
}

.select-menu {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #1e2d60;
}

#select-classes #btn-select-classes {
  width: 111px;
  height: 56px;
  padding: 15px 11px 18px 20px;
  background-color: #007fca;
  border: 0px;
  border-radius: 0px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  box-shadow: none;
}

#btn-select-classes::after {
  content: '';
  border-top: 0.25em solid;
  border-right: 0.25em solid transparent;
  border-bottom: 0;
  border-left: 0.25em solid transparent;
  position: absolute;
  right: 10px;
  top: 26px;
}

.btn-select-classes-group {
  min-width: 100%;
  padding: 0px;
  margin: 0px;
  border: 0px;
  opacity: 0;
  height: 0;
  transition: 0.5s;
  display: block !important;
}

.btn-select-classes-group.show {
  height: auto;
  opacity: 1;
}

.btn-select-class {
  color: white !important;
  font-weight: bold;
  padding: 10px 11px 10px 20px;
  background-color: #192c64;
}

.btn-select-class:hover,
.btn-select-class:active {
  color: #ddc254 !important;
  background-color: #000 !important;
}

#lang-selector {
  margin-left: auto;
}

#btn-select-lang {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
  height: 56px;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
}

#btn-select-lang:before {
  content: '';
  height: 25px;
  border-left: 1.5px solid #bbbbbb;
  padding-right: 15px;
}

.btn-select-lang-group {
  padding: 0px;
  margin: 0 auto;
  min-width: 85%;
  background-color: #007fca;
  border: 0px;
  border-radius: 0px;
}

.btn-select-lang-menu {
  color: white;
  font-size: 13px;
  font-weight: normal;
  line-height: 2.3;
}

.btn-select-lang-menu:hover,
.btn-select-lang-menu:active {
  background-color: #009af3;
}
#header-left-wrapper {
  display: flex;
  align-items: center;
}
#header-right-wrapper {
  display: flex;
  margin-right: 25px;
}
.dropdown-menu {
  max-height: 80vh;
  /*overflow-y: auto;*/
}

.btn-add-group-li {
  padding-bottom: 0px !important;
}

.submenu-sharegroup li:first-child,
.submenu-group li:first-child {
  padding-top: 10px;
}

.submenu-sharegroup li:last-child,
.submenu-group li:last-child {
  padding-bottom: 10px;
}

.submenu-sharegroup li,
.submenu-group li {
  padding: 5px 15px 5px 15px;
  background-color: #192c64;
}

.submenu-sharegroup li a,
.submenu-group li a {
  padding: 5px 0px 5px 0px;
}

.submenu-sharegroup li a:hover,
.submenu-sharegroup li a:focus,
.submenu-group li a:hover,
.submenu-group li a:focus {
  background-color: #192c64 !important;
  color: #fff !important;
}

.submenu-sharegroup li a .icon-font:hover,
.submenu-sharegroup li a .icon-font:focus,
.submenu-sharegroup li a span:hover,
.submenu-sharegroup li a span:focus,
.submenu-group li a .icon-font:hover,
.submenu-group li a .icon-font:focus,
.submenu-group li a span:hover,
.submenu-group li a span:focus {
  background-color: #192c64 !important;
  color: #ddc254 !important;
}

.submenu-sharegroup li a .icon-font,
.submenu-group li a .icon-font {
  float: right;
  margin-right: 5px;
}

.btn-add-group {
  padding: 5px 0px 10px 0px !important;
  border-bottom: 1px solid #fff;
}

.btn-add-group .icon-font {
  float: left !important;
  margin-right: 10px !important;
}

.btn-sharegroup {
  display: flex;
  align-items: center;
}

.btn-sharegroup .icon-font {
  margin-left: auto;
  margin-right: 0px !important;
}

.sharegroup-profile {
  position: relative;
  width: 32px;
  margin-right: 10px;
}

.sharegroup-name {
  margin-right: 10px;
}

.archived .sharegroup-profile,
.archived .sharegroup-name {
  opacity: 0.4;
}

@media only screen and (max-width: 991px) {
  .teacher-menu .teacher-menu-item {
    padding: 0px 8px;
  }
}

@media only screen and (max-width: 810px) {
  .teacher-menu .teacher-menu-item {
    padding: 0px 4px;
  }
}
</style>
