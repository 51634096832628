<template>
  <div class="item-progress">
    <div class="text-col">
      <a href="#" @click.prevent="" class="title">{{ title }}</a>
      <p class="q-num">
        {{
          $t(
            $interfaceLang,
            `${numQuestion} Question(s)`,
            `${numQuestion} 條題目`
          )
        }}
      </p>
    </div>
    <div class="progress-col">
      <div class="progress">
        <div
          class="progress-bar"
          :style="{ 'background-color': color, width: rate + '%' }"
          role="progressbar"
          v-bind:aria-valuenow="rate"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <span class="progress-text">{{ rate }}%</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    numQuestion: Number,
    color: String,
    rate: Number
  }
}
</script>

<style scoped>
.item-progress {
  margin-bottom: 20px;
}

.text-col {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

.title {
  font-size: 13px;
  color: #626262;
  text-decoration: underline;
  float: left;
  margin: 0px;
}

.title:hover,
.title:focus {
  color: #353535;
}

.q-num {
  font-size: 13px;
  float: right;
  margin: 0px;
  padding-right: 6%;
}

.progress-col {
  clear: both;
  position: relative;
  display: flex;
  align-items: center;
}

.progress {
  height: 0.5rem;
  width: 95%;
  background-color: #d0d0d0;
}

.progress-text {
  width: 5%;
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
  margin-left: 5px;
}

/* .progress-bar.green{
    background-color: #3ccc89;
  }

  .progress-bar.org{
    background-color: #dadf37;
  }

  .progress-bar.purple{
    background-color: #a772ec;
  }

  .progress-bar.blue{
    background-color: #2fb1c8;
  } */

.progress-col .item-hightlight-text {
  margin-left: 5px;
}
</style>
