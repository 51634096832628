<template>
  <div class="topic-col">
    <p v-b-toggle="'topic' + topic.id" class="topic-collapse-btn">
      {{ $t($interfaceLang, topic.nameEn, topic.nameZh) }}
      <i class="icon-font icon-arrow_filled_down">&#xe802;</i>
      <i class="icon-font icon-arrow_filled_up">&#xe803;</i>
    </p>
    <b-collapse :id="'topic' + topic.id" class="subtopics-col">
      <SubtopicRow
        :key="subtopic.id"
        v-for="subtopic in topic.subtopics"
        :readOnly="readOnly"
        :subtopic="subtopic"
      />
    </b-collapse>
  </div>
</template>
<script>
import { BCollapse, BFormCheckbox, VBToggle } from 'bootstrap-vue'
import SubtopicRow from './SubtopicRow.vue'
export default {
  components: {
    BCollapse,
    SubtopicRow
  },  
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    topic: Object,
    readOnly: Boolean
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="../../TeachingSchedule.css" scoped />
