import { render, staticRenderFns } from "./TopicMastery.vue?vue&type=template&id=3c3dead8&scoped=true&"
import script from "./TopicMastery.vue?vue&type=script&lang=js&"
export * from "./TopicMastery.vue?vue&type=script&lang=js&"
import style0 from "../../ReportInside.css?vue&type=style&index=0&id=3c3dead8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c3dead8",
  null
  
)

export default component.exports