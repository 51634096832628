<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: {
    chartData: Object,
    options: Object
  },
  data: () => ({
    htmlLegend: null
  }),
  mounted () {
    var text = this.$t(this.$interfaceLang, 'No of questions', '題目數量')
    this.addPlugin({
      id: 'my-plugin',
      beforeDraw: function (chart) {
        var width = chart.chart.width
        var height = chart.chart.height
        var ctx = chart.chart.ctx

        ctx.restore()
        var fontSize = Math.max((height / 270).toFixed(2), 0.8)
        ctx.font = fontSize + 'em sans-serif'
        ctx.textBaseline = 'top'

        var textX = Math.round((width - ctx.measureText(text).width) / 2)
        var textY = height / 2 - 10
        ctx.fillText(text, textX, textY)

        fontSize = (height / 150).toFixed(2)
        ctx.font = fontSize + 'em sans-serif'
        var text2 = chart.config.options.center
        var textX2 = Math.round((width - ctx.measureText(text2).width) / 2)
        var textY2 = height / 2 + 15
        ctx.fillText(text2, textX2, textY2)

        ctx.save()
      }
    })
    this.render()
  },
  watch: {
    chartData (to, from) {
      this.render()
    }
  },
  methods: {
    render () {
      this.renderChart(this.chartData, this.options)
      this.htmlLegend = this.generateLegend()
      this.$emit('sendLegend', this.htmlLegend)
    }
  }
}
</script>
