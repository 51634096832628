<template>
  <div class="btn-group select-order select-dropdown" role="group">
    <button
      class="btn select-order-dropdown dropdown-toggle select-dropdown-btn-nobg"
      data-toggle="dropdown"
    >
      <i class="icon-font icon-filter">&#xe812;</i>
      <span>
        {{ $t($interfaceLang, 'Status Filter', '狀態篩選') }}
      </span>
    </button>
    <div
      onclick="event.stopPropagation()"
      class="dropdown-menu select-order-dropdown-group select-dropdown-group"
    >
      <div
        class="dropdown-item select-order-dropdown-item select-dropdown-item"
      >
        <div class="item-check item-filter">
          <input
            v-model="filterAllStatus"
            id="item-all-assignments"
            class="item-check-input"
            name="item-check"
            type="checkbox"
            @change="
              $nextTick(changeAllStatus)
              $emit('input', getFilters())
            "
          />
          <label class="item-check-label" for="item-all-assignments">
            {{ $t($interfaceLang, 'All Status', '全部狀態') }}
          </label>
        </div>
        <div class="line-gray" />
        <div class="item-check item-filter">
          <input
            v-model="filterExpired"
            id="item-expired"
            class="item-check-input"
            name="item-check"
            type="checkbox"
            @change="$nextTick(changeStatus)"
          />
          <label class="item-check-label" for="item-expired">
            {{ $t($interfaceLang, 'Expired', '到期') }}
          </label>
        </div>
        <div class="item-check item-filter">
          <input
            v-model="filterOngoing"
            id="item-ongoing"
            class="item-check-input"
            name="item-check"
            type="checkbox"
            @change="
              $nextTick(changeStatus)
              $emit('input', getFilters())
            "
          />
          <label class="item-check-label" for="item-ongoing">
            {{ $t($interfaceLang, 'Ongoing', '進行中') }}
          </label>
        </div>
        <div class="item-check item-filter">
          <input
            v-model="filterNotReleased"
            id="item-nyr"
            class="item-check-input"
            name="item-check"
            type="checkbox"
            @change="$nextTick(changeStatus)"
          />
          <label class="item-check-label" for="item-nyr">
            {{ $t($interfaceLang, 'Not Released', '未發佈') }}
          </label>
        </div>
        <div class="item-check item-filter">
          <input
            v-model="filterDraft"
            id="item-draft"
            class="item-check-input"
            name="item-check"
            type="checkbox"
            @change="$nextTick(changeStatus)"
          />
          <label class="item-check-label" for="item-draft">
            {{ $t($interfaceLang, 'Draft', '草稿') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: Object
  },
  data () {
    return {
      filterAllStatus: true,
      filterExpired: true,
      filterOngoing: true,
      filterNotReleased: true,
      filterDraft: true
    }
  },
  computed: {},
  created () {
    this.$emit('input', this.getFilters())
  },
  methods: {
    changeAllStatus () {
      this.filterExpired = this.filterAllStatus
      this.filterOngoing = this.filterAllStatus
      this.filterNotReleased = this.filterAllStatus
      this.filterDraft = this.filterAllStatus
      this.$emit('input', this.getFilters())
    },
    changeStatus () {
      this.filterAllStatus =
        this.filterExpired &&
        this.filterOngoing &&
        this.filterNotReleased &&
        this.filterDraft
      this.$emit('input', this.getFilters())
    },
    getFilters () {
      return {
        expired: this.filterExpired,
        ongoing: this.filterOngoing,
        notReleased: this.filterNotReleased,
        draft: this.filterDraft
      }
    }
  }
}
</script>
<style src="@/pages/teacher/manage-assignment/ManageAssignment.css" scoped />
