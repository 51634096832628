var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[((_vm.questions?.length ?? 0) == 0)?_c('div',{staticClass:"blank-msg"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t( _vm.$interfaceLang, 'No questions in this assignment yet.', '此作業未有題目。' ))+" ")]),_c('button',{staticClass:"org-btn",attrs:{"id":"btn-add"},on:{"click":function($event){return _vm.$emit('select-library')}}},[_c('i',{staticClass:"icon-font icon-plus"},[_vm._v("")]),_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Add Questions', '新增題目'))+" ")])]):_c('div',{staticClass:"edit-questions-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isReordering),expression:"isReordering"}],staticClass:"reorder-line",on:{"click":function($event){_vm.pasteQuestionAt(0)
        _vm.isReordering = false
        _vm.$emit('change')}}},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.$interfaceLang, 'Paste here', '在此貼上')))])]),_vm._l((_vm.questions),function(q,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isReordering == false || _vm.reorderingIndexNo != i),expression:"isReordering == false || reorderingIndexNo != i"}]},[_c('div',{staticClass:"question-container-col"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isReordering),expression:"isReordering"}],staticClass:"question-is-reordering"}),_c('QuestionRow',{attrs:{"indexNo":i,"question":q,"isNewQuestion":q.isNew,"readOnly":_vm.readOnly,"showFav":_vm.showFav,"isReordering":_vm.isReordering,"isQgroupFav":q.displayQuestion.qgroup.myUserEdge?.isQgroupFav},on:{"reorder":function($event){_vm.isReordering = true
            _vm.reorderingIndexNo = $event.indexNo},"set-isfav":function($event){_vm.setIsFav($event)
            _vm.$emit('set-isfav', $event)},"drawing":function($event){return _vm.$emit('drawing', $event)},"solution":function($event){return _vm.$emit('solution', $event)},"template-mutation":function($event){return _vm.$emit('template-mutation', $event)},"similar":function($event){return _vm.$emit('similar', $event)},"duplicate":function($event){return _vm.$emit('duplicate', $event)},"delete":function($event){return _vm.$emit('delete', $event)}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isReordering),expression:"isReordering"}],key:i + '_reorder',staticClass:"reorder-line",on:{"click":function($event){_vm.pasteQuestionAt(i + 1)
          _vm.isReordering = false
          _vm.$emit('change')}}},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.$interfaceLang, 'Paste here', '在此貼上')))])])])})],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.isReordering)?[_c('div',{staticClass:"reorder-tips"},[_c('i',{staticClass:"icon-font icon-cut"},[_vm._v("")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t( _vm.$interfaceLang, 'Question cut, please paste at desired location.', '題目已經剪下，請在選擇貼上的位置。' ))+" ")])])]:_vm._e()],2),_c('transition',{attrs:{"name":"fade"}},[(_vm.isReordering)?[_c('div',{staticClass:"btn-cancal-reorder",on:{"click":function($event){_vm.isReordering = false}}},[_c('i',{staticClass:"icon-font icon-close"},[_vm._v("")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(_vm.$interfaceLang, 'Cancel<br />reorder', '取消調位'))}})])]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }