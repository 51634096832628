<template>
  <div class="item-row" :class="submitType">
    <div @click="$emit('goto', { paper })" class="click-item">
      <div class="item-name item-hightlight-text">
        <span>{{ displayName }}</span>
      </div>
      <div class="item-average-rate">
        <p class="sm-title">{{ $t($interfaceLang, 'Score', '分數') }}</p>
        <div :class="'average-rate-c c100 p' + score + ' ' + scoreColor">
          <span>{{ score }}%</span>
          <div class="slice">
            <div class="bar"></div>
            <div class="fill"></div>
          </div>
        </div>
      </div>
      <div class="item-date">
        <p class="sm-title">{{ $t($interfaceLang, 'Due date', '到期日') }}</p>
        <p class="item-hightlight-text">
          {{ this.$displayDate(Number(paper.expiryTime) - 3600 * 24 * 1000) }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Progress from './Progress.vue'
import DaysToGo from './DaysToGo.vue'
export default {
  components: { Progress, DaysToGo },
  props: {
    paper: Object,
    status: String,
    numQuestion: Number,
    numCorrect: Number
  },
  data () {
    return {}
  },
  computed: {
    submitType () {
      if (this.status=='late') {
        return 'type-late'
      } else {
        return 'type-submitted'
      }
    },
    displayName () {
      return this.paper?.name
    },
    score () {
      return this.numQuestion > 0
        ? Math.ceil((this.numCorrect / this.numQuestion) * 100)
        : null
    },
    scoreColor () {
      if (this.score < 60) {
        return 'red'
      } else {
        return 'blue'
      }
    }
  },
  methods: {}
}
</script>
<style scoped>
.click-item {
  cursor: pointer;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}

.click-item:hover,
.click-item:focus {
  text-decoration: none;
}

.click-item:hover .item-name,
.click-item:focus .item-name {
  color: #0c89d3;
}

.type-submitted {
  border-left: 5px solid #3ccc89;
  background-color: #e7ffe8 !important;
}

.type-late {
  border-left: 5px solid #ff881b;
  background-color: #fff5cb !important;
}

.icon-font {
  color: #1c2d60;
  font-size: 14px;
  margin-left: auto;
  width: 2%;
}

.item-row {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
}

.item-row p {
  margin: 0px;
}

.sm-title {
  font-size: 11px;
  color: #1c2d60;
}

.item-hightlight-text {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
}

.item-name {
  width: 43%;
  padding-right: 10px;
}

.item-average-rate {
  width: 20%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  border-left: 1px solid #adadad;
}

.item-date {
  width: 15%;
  padding: 0px 10px;
  border-right: 1px solid #adadad;
}

.item-badge {
  width: 20%;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.item-badge img {
  width: 30px;
  margin-right: 5px;
}

.progress-col {
  position: relative;
  display: flex;
  align-items: center;
}

.progress {
  height: 0.5rem;
  width: 78%;
  background-color: #d0d0d0;
}

.progress-bar {
  background-color: #ffd32d;
}

.progress-col .item-hightlight-text {
  margin-left: 5px;
}

.average-rate-c {
  font-size: 40px;
  margin-left: 5px;
}

.average-rate-c span {
  font-weight: bold;
  color: #1c2d60;
}

.average-rate-c .bar,
.average-rate-c .fill {
  border-color: #42c9f1 !important;
}

.c100:after {
  background-color: white !important;
}

.row-type-overdue .c100:after {
  background-color: #ffe7e8 !important;
}

@media only screen and (max-width: 767px) {
  .click-item {
    flex-wrap: wrap;
  }

  .item-name {
    width: 100%;
    flex: 0 1 100%;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .item-average-rate {
    width: 50%;
    padding: 0px;
    border: none;
  }

  .item-date {
    width: 48%;
    padding: 0px;
    border: none;
  }

  .item-badge {
    margin-top: 10px;
    padding: 0px;
    width: 50%;
  }

  .icon-font {
    position: absolute;
    right: 0px;
  }
}
</style>
