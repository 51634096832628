<template>
  <EditPaperInfoModal
    :paperName="paper.name"
    :unsavedStartTime="paper.startTime"
    :unsavedExpiryTime="paper.expiryTime"
    :startTime="paper.startTime"
    :expiryTime="paper.expiryTime"
    :numQuestion="numQuestion"
    @close="$emit('close')"
    @extend="saveExtendPaperInfo(currentUserId, paper.id, $event)"
    @save="savePaperInfo(currentUserId, paper.id, $event)"
  />
</template>
<script>
import EditPaperInfoModal from '@/components/teacher/modal/edit-paper-info/EditPaperInfo.vue'
import SavePaperNameQuery from './SavePaperName.graphql'
import SavePaperStartAndExpiryTime from './SavePaperStartAndExpiryTime.graphql'
import ExtendPaperExpiryTime from './ExtendPaperExpiryTime.graphql'

export default {
  components: {
    EditPaperInfoModal
  },
  props: {
    currentUserId: String,
    paper: Object,
    numQuestion: Number
  },
  computed: {},
  methods: {
    async savePaperInfo (currentUserId, paperId, inputs) {
      let { inputName, inputDateRanges } = inputs
      this.$emit('mutate-start')
      try {
        let success = await this.$apolloMutation(SavePaperNameQuery, {
          currentUserId,
          paperId,
          name: inputName
        }).then(res => res?.data?.updateMyPaperName.success)
        if (success) {
          let res = await this.$apolloMutation(SavePaperStartAndExpiryTime, {
            currentUserId,
            paperId,
            startTime: inputDateRanges.start,
            expiryTime: inputDateRanges.end
          }).then(res => res?.data?.updateMyPaperStartAndExpiryTime)
          if (res.success == true) {
            this.unsavedStartTime = res.paper.startTime
            this.$emit('mutate-done', res.paper)
          } else {
            this.$emit(
              'mutate-error',
              this.$t(this.$interfaceLang, 'Edit dates failed.', '修改日期失敗')
            )
          }
        } else {
          this.$emit(
            'mutate-error',
            this.$t(this.$interfaceLang, 'Edit failed.', '修改失敗')
          )
        }
      } catch (e) {
        this.$emit(
          'mutate-error',
          this.$t(this.$interfaceLang, 'Edit failed.', '修改失敗')
        )
      }
    },
    async saveExtendPaperInfo (currentUserId, paperId, inputs) {
      let { inputName, inputExpiryDate } = inputs
      this.$emit('mutate-start')
      try {
        let success = await this.$apolloMutation(SavePaperNameQuery, {
          currentUserId,
          paperId,
          name: inputName
        }).then(res => res?.data?.updateMyPaperName.success)
        if (success) {
          success = await this.$apolloMutation(ExtendPaperExpiryTime, {
            currentUserId,
            paperId,
            expiryTime: inputExpiryDate
          }).then(res => res?.data?.extendMyPaperExpiryTime.success)
          if (success == true) {
            this.$emit('mutate-done')
          } else {
            this.$emit(
              'mutate-error',
              this.$t(this.$interfaceLang, 'Edit dates failed.', '修改日期失敗')
            )
          }
        } else {
          this.$emit(
            'mutate-error',
            this.$t(this.$interfaceLang, 'Edit failed.', '修改失敗')
          )
        }
      } catch (e) {
        this.$emit(
          'mutate-error',
          this.$t(this.$interfaceLang, 'Edit failed.', '修改失敗')
        )
      }
    }
  }
}
</script>
<style src="" />
