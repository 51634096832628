<template>
  <div>
    <div class="question-label">
      <span class="question-label-legend todo"></span>
      <span class="question-label-text">
        {{ $t($interfaceLang, 'Blank', '空白') }}
      </span>
    </div>
    <div class="question-label">
      <span class="question-label-legend correct"></span>
      <span class="question-label-text">
        {{ $t($interfaceLang, 'Correct', '答對') }}
      </span>
    </div>
    <div class="question-label">
      <span class="question-label-legend wrong"></span>
      <span class="question-label-text">
        {{ $t($interfaceLang, 'Wrong', '答錯') }}
      </span>
    </div>
    <div class="question-label">
      <span class="question-label-legend-round">*</span>
      <span class="question-label-text">
        {{ $t($interfaceLang, 'Late', '遲交') }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>
.question-label-list {
  margin-bottom: 10px;
}

.question-label {
  position: relative;
  display: flex;
  align-items: center;
  float: left;
  margin-right: 20px;
}

.question-label-legend {
  position: relative;
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
}

.question-label-legend.todo {
  border: 1px solid #d0d0d0;
  background-color: white;
}

.question-label-legend.correct {
  background-color: #3ccc89;
}

.question-label-legend.wrong {
  background-color: #f65a58;
}

.question-label-legend-round {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 16px;
  height: 16px;
  padding-bottom: 20px;
  margin-right: 3px;
}
</style>
