var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"student-chart",style:({ height: _vm.users?.length * 30 + 50 + 'px' })},[_c('div',{staticClass:"student-chart-left-info"},[_vm._l((_vm.users),function(user,i){return [_c('div',{staticClass:"student-identifier",style:({ top: i * 30 + 20 + 'px' })},[_vm._v(" #"+_vm._s(user.identifier)+" ")]),_c('div',{staticClass:"student-name",style:({ top: i * 30 + 20 + 'px', left: '50px' })},[_vm._v(" "+_vm._s(String(user.name).replace(/(.{14})..+/, '$1…'))+" ")])]})],2),_c('div',{staticClass:"chart-line",style:({
        height: _vm.users?.length * 30 + 'px',
        left: '170px'
      })}),_c('div',{staticClass:"student-chart-bar"},[_vm._l((_vm.users),function(user,i){return [_c('div',{staticClass:"student-bar",style:({ top: i * 30 + 'px' })},[_c('div',{staticClass:"correct-bar bar-col",style:({
              width: (user.correctRate ?? 0) + '%'
            })}),_c('div',{staticClass:"wrong-bar bar-col",style:({ width: (user.wrongRate ?? 0) + '%' })}),_c('div',{staticClass:"un-submit-bar bar-col",style:({
              width:
                100 - (user.correctRate ?? 0) - (user.wrongRate ?? 0) + '%'
            })})])]})],2),_c('div',{staticClass:"chart-line",style:({
        height: _vm.users?.length * 30 + 'px',
        left: '570px'
      })}),_c('div',{staticClass:"student-chart-right-info"},[_c('div',{staticClass:"student-chart-right-info-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Time spent', '作答時間'))+" ")]),_vm._l((_vm.users),function(user,i){return [_c('div',{staticClass:"student-time-spent",style:({ top: i * 30 + 26 + 'px' })},[_vm._v(" "+_vm._s(_vm.displayTimeSpent(user.timeSpent))+" ")])]})],2),_c('div',{staticClass:"student-chat-bottom-info",style:({
        top: _vm.users?.length * 30 + 20 + 'px',
        left: '150px'
      })},[_vm._l((11),function(i){return [_c('div',{staticClass:"student-percentage",style:({ left: (i - 1) * 40 + 'px' })},[_vm._v(" "+_vm._s((i - 1) * 10)+"% ")])]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }