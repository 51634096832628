<template>
  <div>
    <div class="position-relative">
      <div class="report-info-col-title">
        {{ $t($interfaceLang, 'Worst Performing Questions', '最差表現題目') }}
      </div>
      <div class="nav-row">
        <!-- topic filter -->
        <!-- toggle button -->
        <ToggleBtn
          v-model="showOnlyHighIncorrect"
          @input="$emit('select-high-incorrect', showOnlyHighIncorrect)"
          :title="
            $t(
              $interfaceLang,
              'Only show the high incorrect rates',
              '只顯示高錯誤率題目'
            )
          "
        />
      </div>
      <template v-if="!isLoading">
        <template v-if="(questionEdges?.length ?? 0) > 0">
          <QuestionRow
            v-for="(e, i) in questionEdges"
            :showFav="true"
            :question="e.question"
            :indexNo="e.indexNo"
            :correctRate="e.correctRate"
            :isQgroupFav="
              e.question.displayQuestion.qgroup?.myUserEdge?.isQgroupFav
            "
            @show-similar="$emit('show-similar', e.question)"
            @set-isfav="$emit('set-isfav', $event)"
            class="question-container"
          />
        </template>
        <template v-else>
          {{ noQuestion }}
        </template>
      </template>
      <LocalLoadingLayer v-else />
    </div>
  </div>
</template>
<script>
import LocalLoadingLayer from '@/components/LocalLoadingLayer.vue'
import ToggleBtn from '@/components/toggle-btn/ToggleBtn.vue'
import QuestionRow from './QuestionRow.vue'
export default {
  components: {
    QuestionRow,
    ToggleBtn,
    LocalLoadingLayer
  },
  props: {
    questionEdges: Array,
    isLoading: Boolean
  },
  data () {
    return {
      showOnlyHighIncorrect: true
    }
  },
  computed: {
    noQuestion () {
      return this.$t(this.$interfaceLang, 'No questions.', '沒有題目')
    }
  },
  methods: {}
}
</script>
<style src="../../ReportInside.css" scoped></style>

<style scoped>
.question-container {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  background-color: white;
  margin-top: 20px;
  border-bottom: 1px solid #d0d0d0;
}
</style>
