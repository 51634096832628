import { render, staticRenderFns } from "./FavSelect.vue?vue&type=template&id=5e4ff382&scoped=true&"
import script from "./FavSelect.vue?vue&type=script&lang=js&"
export * from "./FavSelect.vue?vue&type=script&lang=js&"
import style0 from "@/pages/teacher/edit-assignment/EditAssignment.css?vue&type=style&index=0&id=5e4ff382&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e4ff382",
  null
  
)

export default component.exports