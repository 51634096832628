<template>
  <BarChart
    class="chart-container"
    :chart-data="dataCollection"
    :options="options"
    :chart-id="'question-performance'"
  />
</template>
<script>
import BarChart from '@/components/teacher/report/ReportChartBar'
export default {
  components: {
    BarChart
  },
  props: {
    questions: Array
  },
  data () {
    return {
      dataCollection: null,
      options: null
    }
  },
  mounted () {
    this.renderChart()
  },
  computed: {
    labels () {
      return this.questions.map(q => 'Q' + (q.indexNo + 1))
    },
    correctRates () {
      return this.questions.map(q => q.correctRate)
    }
  },
  methods: {
    renderChart () {
      var colors = this.labels.map((x, i) => {
        if (this.correctRates[i] < 50) {
          return '#f65a58'
        } else {
          return '#007fca'
        }
      })
      this.dataCollection = {
        labels: this.labels,
        datasets: [
          {
            backgroundColor: colors,
            data: this.correctRates.map(x => {
              if (x == 0) {
                return 0.5
              }
              return x
            }),
            barThickness: 15
          }
        ]
      }
      var vue = this
      this.options = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              align: 'start',
              paddingRight: 100,
              ticks: {
                min: 0,
                max: 100,
                stepSize: 20,
                padding: 10,
                callback: function (value, index, values) {
                  return value + '%'
                }
              }
            }
          ]
        },
        legend: {
          display: false
        },
        onClick: function (e, elementsAtEvent) {
          if (this.getElementsAtEvent(e)[0]) {
            vue.$emit('click-element', { index: elementsAtEvent[0]._index })
          }
        },
        onHover: function (e) {
          if (this.getElementsAtEvent(e)[0]) {
            e.target.style.cursor = 'pointer'
          } else {
            e.target.style.cursor = 'default'
          }
        }
      }
      $('.chart-container').width(
        Math.max(this.dataCollection.labels.length * 60, 200)
      )
    }
  }
}
</script>
<style src="" />
