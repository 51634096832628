<template>
  <div id="questionSolution-model">
    <Modal @close="$emit('close')">
      <template v-slot:header>
        <span class="title">
          {{ $t($interfaceLang, 'Solution', '題解') }}
        </span>
        <i @click="$emit('close')" class="icon-font icon-close"> &#xe808;</i>
      </template>
      <template v-slot:body>
        <div class="solution-txt mt-3 katex">
          <SolutionView :question="question" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/teacher/modal/Modal.vue'
import SolutionView from '@/components/teacher/SolutionView.vue'
export default {
  components: {
    Modal,
    SolutionView
  },
  props: {
    question: Object
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>
