<template>
  <div class="component-wrapper">
    <button
      :class="{ active: value == 'ANY' }"
      class="filter-btn all-btn"
      @click="$emit('input', 'ANY')"
    >
      <div class="no-dot" />
      {{ $t($interfaceLang, 'All', '全部') }}
    </button>
    <button
      :class="{ active: value == 'HW' }"
      class="filter-btn"
      @click="$emit('input', 'HW')"
    >
      <div class="dot blue" />
      {{ $t($interfaceLang, 'Homework', '家課') }}
    </button>
    <button
      :class="{ active: value == 'AUTO' }"
      class="filter-btn"
      @click="$emit('input', 'AUTO')"
    >
      <div class="dot gray" />
      {{ $t($interfaceLang, 'Recursive', '重覆') }}
    </button>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: String
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="@/pages/teacher/manage-assignment/ManageAssignment.css" scoped />
<style scoped>
.component-wrapper {
  display: flex;
}
</style>
