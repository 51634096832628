<template>
  <div>
    <ReportOverview v-if="name == 'report-overview'" />
    <ReportOverviewChart
      v-if="name == 'report-overview-chart'"
      :students="[
        {
          id: 'test',
          userIdentifier: 'test',
          name: 'Student',
          overduePapers: [
            { id: 'test', name: 'Paper', expiryTime: '' + Date.now() }
          ]
        }
      ]"
      :papers="[{ id: 'test', name: 'Paper', expiryTime: '' + Date.now() }]"
      :tileLookup="{
        'test-test': {
          numCorrectQuestion: 9,
          numQuestion: 10,
          submitStatus: 'on_time',
          submitTime: '' + Date.now()
        }
      }"
    />
    <ReportOverviewChartAdapter
      v-if="name == 'report-overview-adapter'"
      :currentUserId="'672'"
      :usergroupId="'57'"
    />
    <ReportAssignmentRow
      v-if="name == 'report-assignment-row'"
      :paper="{
        id: 'test',
        name: '',
        expiryTime: '' + Date.now()
      }"
    />
    <ReportAssignmentList
      v-if="name == 'report-assignment-list'"
      :papers="[
        {
          id: 'test',
          type: 'homework',
          name: 'Test',
          expiryTime: '' + Date.now(),
          numSubmission: 2,
          numQuestion: 10,
          usergroupAvgCorrectRate: 59,
          usergroupAvgTimeSpent: 1000000
        }
      ]"
      :numStudent="10"
    />
    <ReportAssignmentListAdapter
      v-if="name == 'report-assignment-list-adapter'"
      :currentUserId="'672'"
      :usergroupId="'57'"
    />
  </div>
</template>
<script>
import ReportOverview from '@/pages/teacher/report-n-statistics/ReportOverview.vue'
import ReportOverviewChart from '@/pages/teacher/report-n-statistics/components/overview-chart/OverviewChart.vue'
import ReportOverviewChartAdapter from '@/pages/teacher/report-n-statistics/adapters/overview-chart/OverviewChartAdapter.vue'
import ReportAssignmentRow from '@/pages/teacher/report-n-statistics/components/report-assignment-list/AssignmentRow.vue'
import ReportAssignmentList from '@/pages/teacher/report-n-statistics/components/report-assignment-list/ReportAssignmentList.vue'
import ReportAssignmentListAdapter from '@/pages/teacher/report-n-statistics/adapters/report-assignment-list/ReportAssignmentListAdapter.vue'

export default {
  components: {
    ReportOverview,
    ReportOverviewChart,
    ReportOverviewChartAdapter,
    ReportAssignmentRow,
    ReportAssignmentList,
    ReportAssignmentListAdapter
  },
  props: {},
  data () {
    return {
      name: this.$route.query.name
    }
  },
  computed: {},
  methods: {}
}
</script>
<style
  src="@/pages/teacher/report-n-statistics/ReportnStatistics.css"
  scoped
></style>

<style scoped>
.table-title-container {
  position: relative;
  display: flex;
  align-items: center;
}

.popper {
  color: white;
  background-color: #626262;
  border: 1px #626262 solid;
  box-shadow: none;
  padding: 5px 10px;
}

#overview-container >>> .popper__arrow {
  border-color: transparent transparent #626262 transparent !important;
}

.overview-chart-col {
  position: relative;
  margin-top: 30px;
  overflow-x: auto;
  max-width: 952px;
}

.overview-chart {
  position: relative;
  background-color: white;
}

.overview-chart th {
  font-size: 12px;
  font-weight: 600;
  color: #ccc;
  border-bottom: 0.8px solid #ccc;
  height: 30px;
  padding: 5px 10px;
}

.overview-chart td {
  font-size: 14px;
  color: #192c64;
  height: 40px;
}

.table-id {
  min-width: 30px;
  width: 30px;
  text-align: center;
  vertical-align: middle;
}

.table-student {
  min-width: 130px;
  width: 130px;
  text-align: right;
  vertical-align: middle;
  padding-right: 10px;
}

.table-assignment {
  min-width: 80px;
  width: 80px;
  text-align: center;
  vertical-align: middle;
}

.table-overdue-title {
  min-width: 40px;
  width: 40px;
  color: #2b95d3 !important;
}

.table-overdue {
  min-width: 40px;
  text-align: center;
  vertical-align: middle;
}

.color-bar {
  width: 85%;
  height: 85%;
}

.color-on_time {
  background-color: #3bcc89;
}

.color-late {
  background-color: #9ee5c5;
}

.color-overdue {
  background-color: #f55a58;
}

.color-partial {
  background-color: #f1f3f0;
}

.table-overdue-td {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gray-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #192c64;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #f1f3f0;
}

.red-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #f55a58;
}

.col-content {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

.hidden-text {
  position: absolute;
  display: block;
  opacity: 0;
}
</style>
