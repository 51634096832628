<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header" />
          </div>
          <div class="modal-body">
            <slot name="body" />
          </div>
        </div>
      </div>
      <div class="modal-blackdrop"></div>
    </div>
  </transition>
</template>

<script>
import jquery from 'jquery'
const $ = jquery
export default {
  data () {
    return { $ }
  },
  created () {
    $('body').addClass('modal-open')
  },
  destroyed () {
    $('body').removeClass('modal-open')
  }
}
</script>

<style src="./modal.css" scoped></style>
<style scoped>
body.modal-open {
  overflow: hidden;
}
</style>
