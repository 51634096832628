<template>
  <div v-if="!paper?.isDeleted" :class="rowClass" class="assignment-row">
    <div v-if="!readOnly" class="assignment-checkbox item-check">
      <input
        v-model="selected"
        :id="'check-' + paper.id"
        :name="'item-check-' + paper.id"
        type="checkbox"
        class="item-check-input"
        @change="$emit('bulk-select', { paper, selected })"
      />
      <label :for="'check-' + paper.id" class="item-check-label"></label>
    </div>
    <div class="assignment-type">
      <p>{{ $t($interfaceLang, paperTypeNameEn, paperTypeNameZh) }}</p>
      <IsDynamicDropdown
        v-model="isDynamic"
        :readOnly="readOnly || locked"
        @input="$emit('isdynamic', { paper, isDynamic })"
      />
    </div>
    <div class="assignment-name-and-dates">
      <a
        class="assignment-info click-item"
        @click.prevent="$emit('goto', { paper })"
        href="#"
      >
        <div class="assignment-name">
          {{ paper.name }}
          <div class="auto-followup-info">
            <span v-if="paper.type == 'recursive_followup'">
              ({{ $t($interfaceLang, 'Follow up', '跟進') }})
            </span>
          </div>
        </div>
        <div class="assignment-lock">
          <i class="icon-font icon-locked" v-if="locked"> &#xe814; </i>
        </div>
        <div class="assignment-question-num" v-if="numQuestion != null">
          {{ numQuestion }}
        </div>
      </a>
      <div class="assignment-date">
        <AssignmentDate
          :startTime="paper.startTime"
          :expiryTime="paper.expiryTime"
        />
      </div>
      <div class="assignment-date">
        <p>{{ $t($interfaceLang, 'Last Modified', '最後修改') }}</p>
        <p class="assignment-start-date">
          {{ $displayDate(paper.lastModifyTime) }}
        </p>
      </div>
    </div>
    <div class="assignment-btn">
      <button
        v-if="!readOnly"
        @click="$emit('edit', { paper })"
        class="btn-edit"
      >
        <i class="icon-font icon-edit">&#xe80f;</i>
      </button>
      <button class="btn-share mt-2" @click="clickShare">
        <i class="icon-font icon-share">&#xe81f;</i>
      </button>
      <MoreDropdown
        v-if="!readOnly"
        :paper="paper"
        @share="$emit('share', $event)"
        @delete="$emit('delete', $event)"
        @duplicate="$emit('duplicate', $event)"
      />
    </div>
  </div>
</template>
<script>
import IsDynamicDropdown from './IsDynamicDropdown.vue'
import MoreDropdown from './MoreDropdown.vue'
import AssignmentDate from './AssignmentDate.vue'
export default {
  components: {
    IsDynamicDropdown,
    AssignmentDate,
    MoreDropdown
  },
  props: {
    paper: Object,
    numQuestion: Number,
    locked: Boolean,
    readOnly: Boolean
  },
  data () {
    return {
      selected: false,
      isDynamic: this.paper.isDynamic
    }
  },
  computed: {
    rowClass () {
      return {
        'type-homework': this.paper?.type == 'homework',
        'type-auto': ['recursive', 'recursive_followup'].includes(
          this.paper?.type
        ),
        'type-test': this.paper?.type == 'test',
        selected: this.selected
      }
    },
    paperTypeNameEn () {
      if (this.paper.type == 'homework') {
        return 'Homework'
      } else if (
        this.paper.type == 'recursive' ||
        this.paper.type == 'recursive_followup'
      ) {
        return 'Recursive'
      } else if (this.paper.type == 'freepractice') {
        return 'Practice'
      } else if (this.paper.type == 'test') {
        return 'Test'
      }
    },
    paperTypeNameZh () {
      if (this.paper.type == 'homework') {
        return '家課'
      } else if (
        this.paper.type == 'recursive' ||
        this.paper.type == 'recursive_followup'
      ) {
        return '重覆'
      }
    },
    isRecursive () {
      return (
        this.paper.type == 'recursive' ||
        this.paper.type == 'recursive_followup'
      )
    }
  },
  methods: {
    setSelected (value) {
      this.selected = value
    },
    clickShare () {
      const nameCode = this.paper.nameCode
      const questionsCode = this.paper.questionsCode
      const shareLink = `https://app.1ed.hk/teacher/editassignment?n=${nameCode}&q=${questionsCode}`
      window.open(shareLink, '_blank')
    }
  }
}
</script>
<style scoped>
.red-color {
  color: rgb(244, 94, 92) !important;
}

.gray-color {
  color: #adadad !important;
}

.type-homework {
  border-left: 5px solid #42c9f1;
}

.type-practice {
  border-left: 5px solid #ffd32d;
}

.type-auto {
  border-left: 5px solid #d0d0d0;
}

.assignment-row {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 4px;
}

.assignment-row.selected {
  background-color: #c7f2ff;
}

.assignment-row.selected .assignment-question-num {
  color: white;
  background-color: #6cd4f3;
}

.assignment-row >>> p {
  margin: 0px;
}

.assignment-row .btn-group {
  width: 100%;
}

.type-homework {
  border-left: 5px solid #42c9f1;
}

.type-test {
  border-left: 5px solid #ffd32d;
}

.type-practice {
  border-left: 5px solid #d0d0d0;
  display: none;
}

.type-auto {
  border-left: 5px solid #d0d0d0;
}

.assignment-checkbox {
  width: 4%;
}

.assignment-type {
  font-size: 11px;
  color: #adadad;
  width: 13%;
}

.type-txt {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
}

::v-deep .assignment-dropdown {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
  margin: 0px;
  padding: 0px;
  width: 100%;
  text-align: left;
}

::v-deep .assignment-dropdown:after {
  position: absolute;
  right: 13px;
  top: 9px;
}

::v-deep .assignment-dropdown:focus,
::v-deep .assignment-dropdown:hover,
::v-deep .assignment-dropdown:active {
  box-shadow: none;
  outline: none;
}

::v-deep .assignment-dropdown-group {
  padding: 20px;
  box-shadow: 0 2px 6px 0 rgba(70, 70, 70, 0.4);
  border-radius: 10px;
  background-color: #1c2d60;
  min-width: 100%;
  margin-top: 10px;
}

::v-deep .assignment-dropdown-group:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #1c2d60 transparent;
  border-width: 0 8px 8px;
  top: -8px;
  left: 74%;
  margin-left: -10px;
}

::v-deep .assignment-dropdown-item {
  padding: 5px 0px;
  font-size: 13px;
  font-weight: 600;
  color: white;
}

::v-deep .assignment-dropdown-item i {
  font-size: 20px;
  margin-right: 5px;
}

::v-deep .assignment-dropdown-item:focus,
::v-deep .assignment-dropdown-item:hover,
::v-deep .assignment-dropdown-item:active {
  color: #febd47;
  background-color: transparent;
}

.assignment-name-and-dates {
  position: relative;
  display: flex;
  width: 72%;
}

.click-item {
  width: 100%;
}
.click-item:hover,
.click-item:focus {
  text-decoration: none;
}

.assignment-info {
  position: relative;
  width: 60%;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  border-right: 1px solid #adadad;
  border-left: 1px solid #adadad;
}

.assignment-name {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
  width: 83%;
}

.click-item:hover .assignment-name,
.click-item:focus .assignment-name {
  color: #0c89d3;
}

.assignment-lock {
  font-size: 24px;
  color: #f65a58;
}

.assignment-question-num {
  font-size: 13px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  text-align: center;
  color: #1c2d60;
  background-color: #f1f3f1;
  border-radius: 100%;
}

.assignment-question-num::after {
  position: absolute;
  content: '';
  height: 20px;
  background-color: #f1f3f1;
}

.assignment-date {
  width: 20%;
  font-size: 11px;
  color: #adadad;
  padding: 0px 10px;
}

::v-deep .assignment-start-date,
::v-deep .assignment-due-date {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
}

.assignment-btn {
  display: flex;
  justify-content: flex-end;
  width: 10%;
}

.assignment-btn button {
  border: none;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  color: #1c2d60;
  font-size: 26px;
}

.assignment-btn button:focus,
.assignment-btn button:hover,
.assignment-btn button:active {
  box-shadow: none;
  outline: none;
}

.assignment-btn .select-more {
  width: auto;
}

.assignment-btn .btn-edit {
  margin-right: 10px;
}

.btn-share {
  margin-right: 10px;
  font-size: 20px !important;
}

::v-deep .btn-select-more-group {
  margin-right: -20px;
}
.auto-followup-info {
  color: #808080 !important;
}

.item-check {
  position: relative;
}

.item-check input[type='checkbox'] {
  display: none;
}

.item-check-label {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
  margin: 0px;
  cursor: pointer;
}

.item-check label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 15px;
  height: 15px;
  border-radius: 4px;
  border: 1.5px solid #969696;
  background-color: transparent;
}

.item-check input[type='checkbox']:checked + label:before {
  background-color: #ff881b;
  border-color: #ff881b;
}

.item-check input[type='checkbox']:checked + label:after {
  position: absolute;
  top: 5px;
  left: 1px;
  content: '';
  font-family: 'fontello';
  color: white;
  display: block;
  font-size: 14px;
}
</style>
