<template>
  <div class="toggle-btn">
    <button
      :class="{ active: isScheduled }"
      @click="
        isScheduled = !isScheduled
        $emit('input', isScheduled)
      "
      class="toggle-filter"
    />
    <span class="toggle-text" v-if="!isScheduled">
      {{ $t($interfaceLang, 'Draft', '草稿') }}
    </span>
    <span class="toggle-text" v-if="isScheduled">
      {{ $t($interfaceLang, 'Scheduled', '排程') }}
    </span>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      isScheduled: false
    }
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>
.toggle-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.toggle-filter {
  height: 12px;
  width: 30px;
  border-radius: 12px;
  border-style: none;
  background-color: #e4e4e4;
  margin-right: 5px;
}

.toggle-filter:focus,
.toggle-filter:hover,
.toggle-filter:active {
  box-shadow: none;
  outline: none;
}

.toggle-filter::before {
  content: '';
  position: absolute;
  top: 2px;
  left: auto;
  width: 16px;
  height: 16px;
  background-color: #adadad;
  border-radius: 50%;
}

.active.toggle-filter::before {
  left: 0px;
  background-color: #007fca !important;
}

.toggle-text {
  font-size: 13px;
  font-weight: 600;
  color: #1c2d60;
  padding-left: 5px;
}
</style>
