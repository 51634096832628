import Vue from 'vue'
import VueRouter from 'vue-router'
import config from '../config.json'
import contentClient from '../content-client'
import firebase from 'firebase/compat/app'
import apollo from '../apollo-client'
import log from '@/log.js'

import appStore from '../store/app-store'

import Login from '../pages/login/Login'
import SelectAccounts from '../pages/login/SelectAccounts'

import studentRoutes from './student-routes'
import teacherRoutes from './teacher-routes'
import testRoutes from './test-routes'

import myIdentitiesGraphql from './MyIdentities.graphql'

Vue.use(VueRouter)

var routes = [
  {
    path: '/', name: 'Home',
    meta: { requireAuth: true, isHome: true }
  },
  {
    path: '/login', name: 'login', component: Login,
    meta: { requireAuth: false },
  },
  {
    path: '/logout', name: 'logout',
    meta: { requireAuth: false },
    beforeEnter(to, from, next) {
      Vue.prototype.$firebaseApp.auth()
        .signOut()
        .then(() => {
          sessionStorage.clear()
          next('/login')
        })
    }
  },
  {
    path: '/selectaccounts', name: 'selectaccounts', component: SelectAccounts,
    meta: { requireAuth: true, pageRole: "*" }
  },
  ...studentRoutes,
  ...teacherRoutes,
]

if (process.env.NODE_ENV !== 'production') {
  routes = [...routes, ...testRoutes]
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

async function initializeClients(user) {
  Vue.prototype.$apolloQuery = async (...vars) => (await apollo.createClient(user)).query(...vars)
  Vue.prototype.$apolloMutation = async (...vars) => (await apollo.createClient(user)).mutate(...vars)
  Vue.prototype.$loadContent = resName => contentClient.load(user, resName)
  Vue.prototype.$contentUrl = resName => contentClient.url(user, resName)
}

async function getUserIdentities() {
  return Vue.prototype.$apolloQuery(myIdentitiesGraphql).then(res => res.data.myIdentities)
}

function chooseDefaultIdentity(identities) {
  if (identities.length == 1) {
    log('single identity')
    if (['teacher', 'super_teacher', 'inspector', 'student'].includes(identities[0].type)) {
      appStore.switchIdentity(identities[0])
    } else {
      throw Error('identity not allowed')
    }
  } else if (identities.length > 1) {
    let rolePriorities = ['teacher', 'super_teacher', 'inspector', 'student']
    let user = null
    for (var i = 0; i < rolePriorities.length; ++i) {
      let role = rolePriorities[i]
      let ids = identities.filter(x => x.type == role)
      if (ids.length > 0) {
        user = ids[0]
        break
      }
    }
    if (user != null) {
      appStore.switchIdentity(user)
    } else {
      log('identity not allowed')
      throw Error('identity not allowed')
    }
  } else {
    throw Error('user data not found')
  }
}

function checkPageRole(role, pageRole) {
  if (pageRole == '*') {
    return true
  } else if (['teacher', 'super_teacher', 'inspector'].includes(role)) {
    return pageRole == 'teacher'
  } else {
    return pageRole == role
  }
}

function getDefaultUserPageName(role) {
  if (['teacher', 'super_teacher', 'inspector'].includes(role)) {
    return config.route.teacherHome
  } else if (role == 'student') {
    return config.route.studentHome
  } else {
    log('unknown role')
    sessionStorage.clear()
  }
}


function updateRoute(to, from, next) {
  if (to.isHome || !checkPageRole(appStore.userInfo.role, to.meta.pageRole)) {
    next({ name: getDefaultUserPageName(appStore.userInfo.role) })
  } else {
    next()
  }
}

router.beforeEach((to, from, next) => {
  const firebaseApp = firebase.initializeApp(config.firebase)
  Vue.prototype.$firebaseApp = firebaseApp
  if (to.meta.requireAuth == true) {
    const auth = firebaseApp.auth()
    auth.onAuthStateChanged(async function (user) {
      if (user) {
        log('user logged in')
        await initializeClients(user)
        log('initialized clients')
        appStore.load()
        Vue.prototype.$interfaceLang = appStore.userPreferences.interfaceLang
        log('loaded app store')
        if (appStore.userInfo?.email == null || appStore.userInfo.email != user.email) {
          try {
            let identities = await getUserIdentities()
            appStore.updateUserInfo({ email: user.email })
            appStore.updateUserData(identities)
            chooseDefaultIdentity(appStore.userData)
          } catch (e) {
            log(e.message)
            sessionStorage.clear()
            next('/login')
          }
        }
        updateRoute(to, from, next)
      } else {
        sessionStorage.clear()
        next('/login')
      }
    })
  } else {
    next()
  }
})

export default router
