<template>
  <HomeworkInfo
    :loading="loadingInfo"
    :numNew="numNew"
    :numProgress="numProgress"
    :numOverdue="numOverdue"
  />
</template>
<script>
import HomeworkInfo from '../../components/info/Info.vue'
import LoadInfoQuery from './LoadInfo.graphql'
export default {
  components: {
    HomeworkInfo
  },
  props: {
    currentUserId: String
  },
  data () {
    return {
      numNew: 0,
      numProgress: 0,
      numOverdue: 0,
      loadingInfo: false
    }
  },
  created () {
    this.fetchHomeworkInfo()
  },
  methods: {
    async fetchHomeworkInfo () {
      this.loadingInfo = true
      let data = await this.$apolloQuery(LoadInfoQuery, {
        currentUserId: this.currentUserId
      }).then(res => res?.data)
      this.numNew = data.numNewPaper
      this.numProgress = data.numPartialPaper
      this.numOverdue = data.numExpiredNewPaper + data.numExpiredPartialPaper
      this.loadingInfo = false
    }
  }
}
</script>
<style src="" />
