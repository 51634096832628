<template>
  <div>
    <div v-if="loaded">
      <div v-if="!hasError">
        <QViewMc
          v-if="question.displayQuestion?.type == 'mc'"
          :isShuffled="isShuffled"
          :questionData="questionData"
          :userMarkerIndex="userMarkerIndex"
          :permutation="permutation"
          :correctAnswerIndex="correctAnswerIndex"
          :mcAnswerPermutation="mcAnswerPermutation"
        />
      </div>
      <div v-else>
        {{
          $t(
            $interfaceLang,
            'Loading error. Please try again later.',
            '讀取錯誤，請後再試。'
          )
        }}
      <button class="retry-btn" @click="load(question)">
        {{ $t($interfaceLang, 'Retry', '重試') }}
      </button>
      </div>
    </div>
    <div v-else>
      <img class="loading-gif" src="@/assets/images/loading.gif" />
    </div>
  </div>
</template>
<script>
import QViewMc from './QViewMc.vue'

export default {
  components: { QViewMc },
  props: {
    isShuffled: Boolean,
    question: Object,
    userMarkerIndex: Number, //the index of user answer for shuffled answers
    permutation: Array, //custom permutation, e.g. display student answers
    correctAnswerIndex: Number, //the correct answer index for shuffled answers
    mcAnswerPermutation: Array
  },
  data () {
    return {
      questionData: null,
      loaded: false,
      hasError: false
    }
  },
  computed: {},
  async created () {
    this.load(this.question)
  },
  watch: {
    async question (newValue) {
      this.load(newValue)
    }
  },
  methods: {
    async load (question) {
      this.loaded = false
      try {
        this.questionData = await this.loadQuestionData(question)
      } catch (e) {
        this.hasError = true
      } finally {
        this.loaded = true
      }
    },
    async loadQuestionContent (question) {
      return await this.$loadContent(
        question?.displayQuestion?.sampleEdgeKey + '.json'
      )
    },
    async loadImageContent (imageName) {
      return await this.$loadContent(imageName)
    },
    async loadMcData (questionData) {
      let result = Object.assign({}, questionData)
      if (questionData.contentImage != null) {
        result.contentImageData = await this.loadImageContent(
          questionData.contentImage
        )
      }
      result.answers = await Promise.all(
        questionData.answers.map(answer => {
          if (answer.endsWith('.jpg')) {
            result.isAnswerImage = true
            return this.loadImageContent(answer)
          }
          return answer
        })
      )
      return result
    },
    async loadQuestionData (question) {
      let questionData = await this.loadQuestionContent(question)
      if (questionData != null) {
        questionData = Object.assign({}, questionData)
        if (question.displayQuestion.type == 'mc') {
          return this.loadMcData(questionData)
        }
      }
    }
  }
}
</script>
<style scoped>
.loading-gif {
  width: 50px;
  height: 50px;
}
.retry-btn {
  align-items: center;
  color: #1c2d60;
  font-size: 12px;
  font-weight: 600;
  padding: 0px 10px;
  margin-left: 10px;
  border-radius: 12px;
  background-color: #f1f3f1;
  border: solid 1pt black;
}
</style>
