<template>
  <DeletePaperModal :paper="paper" @delete="deletePaper($event)" />
</template>
<script>
import DeletePaperQuery from './DeletePaper.graphql'

import DeletePaperModal from '../../modal/DeletePaper.vue'
export default {
  components: {
    DeletePaperModal
  },
  props: {
    currentUserId: String,
    paper: Object
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    async deletePaper (paper) {
      this.$emit('mutate-start')
      try {
        var success = await this.$apolloMutation(DeletePaperQuery, {
          currentUserId: this.currentUserId,
          paperId: paper.id
        }).then(res => res?.data?.deleteMyPaper?.success)
        success ? this.$emit('mutate-done') : this.$emit('mutate-error')
      } catch (e) {
        this.$log(e.message)
        this.$emit('mutate-error')
      }
    }
  }
}
</script>
<style src="" />
