<template>
  <div id="history-container">
    <div class="content-col">
      <div v-if="noData">
        {{ $t($interfaceLang, 'No history.', '沒有練習記錄。') }}
      </div>
      <div v-if="noData == false" class="ml-3 mb-3">{{ wordsInfo }}</div>
      <PracticeRow
        v-for="x in paperUserEdges"
        :paperId="x.paperId"
        :paperName="getTopicName(x)"
        :numQuestion="x.numQuestion"
        :numCorrectQuestion="x.numCorrectQuestion"
        :numSubmission="x.numSubmission"
        :timeSpent="x.timeSpent"
        :createdTime="x.createdTime"
        :correctRate="x.correctRate"
        @click="onClickPracticeHistory"
      />
    </div>
  </div>
</template>
<script>
import PracticeRow from '@/pages/student/practice/components/practice-row/PracticeRow.vue'

import { loadPracticeHistory } from '@/pages/student/practice/apollo-queries/apollo-queries.js'

const MAX_NUM_OF_ROW = 20

export default {
  components: {
    PracticeRow
  },
  props: {
    currentUserId: String
  },
  data () {
    return {
      paperUserEdges: [],
      noData: null
    }
  },
  async created () {
    this.paperUserEdges = await loadPracticeHistory(
      this.$apolloQuery,
      this.currentUserId,
      MAX_NUM_OF_ROW
    )
    this.noData = this.paperUserEdges.length == 0
  },
  computed: {
    wordsInfo () {
      return this.$t(
        this.$interfaceLang,
        'Here are the last 20 exercises.',
        '這是您最近的 20 次練習。'
      )
    }
  },
  methods: {
    onClickPracticeHistory (event) {
      console.log(event)
      this.$router.push({
        path: '/student/paper',
        query: { id: event.paperId }
      })
    },
    getTopicName (paperEdge) {
      console.log(paperEdge)
      let name = this.$t(
        this.$interfaceLang,
        paperEdge.topicEdges[0]?.topic?.nameEn,
        paperEdge.topicEdges[0]?.topic?.nameZh
      )
      return name ?? this.$t(this.$interfaceLang, 'Unknown', '未知')
    }
  }
}
</script>
<style scoped />
