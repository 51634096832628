<template>
  <div>
    <SetFavQgroup
      ref="save-fav-qgroup"
      :currentUserId="currentUserId"
      :usergroupId="usergroupId"
    />
    <QuestionList
      ref="question-list"
      :key="questionListKey"
      :readOnly="readOnly"
      :showFav="showFav"
      :questions="questions"
      @reorder="$emit('reorder', $event)"
      @change="$emit('change')"
      @drawing="$emit('drawing', $event)"
      @solution="$emit('solution', $event)"
      @template-mutation="templateMutation($event)"
      @similar="$emit('similar', $event)"
      @duplicate="$emit('duplicate', $event)"
      @delete="deleteQuestion($event)"
      @set-isfav="setIsFav($event)"
      @select-library="$emit('select-library')"
    />
  </div>
</template>
<script>
import SetFavQgroup from '@/components/set-fav-qgroup/SetFavQgroup.vue'
import LoadTemplateMutationQuery from './LoadTemplateMutation.graphql'
import QuestionList from '../../components/question-list/QuestionList.vue'
export default {
  components: {
    QuestionList,
    SetFavQgroup
  },
  props: {
    currentUserId: String,
    usergroupId: String,
    readOnly: Boolean,
    showFav: Boolean,
    questions: Array
  },
  data () {
    return {
      questionListKey: 0
    }
  },
  computed: {},
  methods: {
    updateNewQuestions (indices) {
      this.questions.forEach(x => {
        x.isNew = false
      })
      indices.forEach(i => {
        this.questions[i].isNew = true
      })
    },
    deleteQuestion ({ question }) {
      let questionIndex = this.questions.findIndex(
        x =>
          question.displayQuestion?.id != null &&
          x.displayQuestion?.id == question.displayQuestion?.id
      )
      if (questionIndex != -1) {
        this.updateNewQuestions([])
        this.questions.splice(questionIndex, 1)
        this.questionListKey++
      }
      this.$emit('change')
    },
    async templateMutation ({ question, indexNo }) {
      this.$emit('template-mutation-start')
      try {
        let templateMutation = await this.$apolloMutation(
          LoadTemplateMutationQuery,
          {
            currentUserId: this.currentUserId,
            id: question.displayQuestion.id,
            lang: this.$interfaceLang,
            exceptQuestionIds: this.questions.map(x => x.displayQuestion.id)
          }
        ).then(res => res?.data?.question?.templateMutation)
        if (templateMutation != null) {
          this.questions.splice(indexNo, 1, templateMutation)
          this.$emit('template-mutation-done')
          this.$emit('change')
          this.$refs['question-list'].$forceUpdate()
          this.updateNewQuestions([indexNo])
        } else {
          this.$emit('template-mutation-error')
        }
      } catch (e) {
        this.$log(e.message)
        this.$emit('template-mutation-error')
      }
    },
    addQuestions ({ questions, indexNo }) {
      if (indexNo == null) {
        indexNo = this.questions.length
      }
      this.updateNewQuestions([])
      questions.forEach(x => {
        x.isNew = true
      })
      this.questions.splice(indexNo + 1, 0, ...questions)
      this.$emit('change')
    },
    async setIsFav ({ question, isFav }) {
      await this.$refs['save-fav-qgroup'].save(
        question?.displayQuestion?.qgroup?.id,
        isFav
      )
      this.$emit('set-isfav', { question, isFav })
    }
  }
}
</script>
