<template>
  <div :class="{ active: value }" class="toggle-btn">
    <button @click="$emit('input', !value)" class="toggle-filter" />
    <span class="toggle-text">
      <template v-if="value">
        {{ $t($interfaceLang, 'Scheduled', '排程') }}
      </template>
      <template v-else>
        {{ $t($interfaceLang, 'Draft', '草稿') }}
      </template>
    </span>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: Boolean
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>
