<template>
  <Modal id="similarQuestions-model" @close="$emit('close')">
    <template v-slot:header>
      <span class="title">
        {{ $t($interfaceLang, 'Similar Questions', '相似題目') }}
      </span>
      <i @click="$emit('close')" class="icon-font icon-close">&#xe808;</i>
    </template>
    <template v-slot:body v-if="!isLoading">
      <template>
        <div class="question-container" v-for="question in questions">
          <div class="question-info">
            <div class="question-info-right">
              <button
                v-if="!readOnly"
                @click="$emit('add', { question })"
                class="add-question-btn btn-add"
              >
                <i class="icon-font icon-plus">&#xe817;</i> {{ $t('ADD') }}
              </button>
            </div>
          </div>
          <QuestionView
            :question="question"
            :isShuffled="false"
            :permutation="[0, 1, 2, 3]"
            :mcAnswerPermutation="
              question?.displayQuestion?.sampleEdgePermutation
            "
          />
        </div>
      </template>
    </template>
    <template v-slot:body v-else>
      <img class="loading-gif" src="@/assets/images/loading.gif" />
    </template>
  </Modal>
</template>
<script>
import Modal from '@/components/teacher/modal/Modal.vue'
import QuestionView from '@/components/teacher/new-question-view/QuestionView'

export default {
  components: {
    Modal,
    QuestionView
  },
  props: {
    questions: Array,
    readOnly: Boolean,
    isLoading: Boolean
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>
<style scoped>
.question-container {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  background-color: white;
  border-bottom: 1px solid #d0d0d0;
}

.question-info {
  position: relative;
  display: flex;
}

.question-info-left {
  position: relative;
  display: flex;
  align-items: center;
}

.question-info-right {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-right: 10px;
}

.add-question-btn {
  border: none;
  background-color: transparent;
  margin: 0px;
  padding: 0px;
  color: #1c2d60;
  font-size: 24px;
}

.add-question-btn:focus,
.add-question-btn:hover,
.add-question-btn:active {
  box-shadow: none;
  outline: none;
}

.btn-favourite {
  padding-right: 5px;
  border-right: 1px solid #f1f3f1;
}

.btn-add {
  font-size: 13px;
  font-weight: 600;
  color: white;
  padding: 2px 10px;
  border-radius: 12px;
  background-color: #1c2d60;
  margin-left: 10px;
}

.btn-add:hover,
.btn-add:focus {
  background-color: #2a4186;
}

.loading-gif {
  width: 50px;
  height: 50px;
}
</style>
