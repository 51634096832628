<template>
  <div class="calendar-container picker-calendar">
    <DatePicker
      ref="date-picker"
      v-model="inputTime"
      :locale="$t($interfaceLang, 'en', 'zh')"
      :firstDayOfWeek="0"
      :timezone="$appStore.appInfo.timezoneText"
      :model-config="datePickerConfig.modelConfig"
      :select-attribute="datePickerConfig.selectAttribute"
      :drag-attribute="datePickerConfig.dragAttribute"
      :attributes="attributes"
      :min-date="new Date()"
      is-expanded
      is-required
      @input="$emit('input', limitExpiryTime(inputTime))"
    />
  </div>
</template>
<script>
import datePickerConfig from './extend-date-picker-config.json'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  components: {
    DatePicker
  },
  props: {
    startTime: String,
    expiryTime: String,
    value: Date
  },
  data () {
    return {
      datePickerConfig,
      inputTime: this.value,
      constAttributes: [
        {
          key: 'weekdays',
          highlight: {
            contentStyle: {
              color: '#f45e5c'
            }
          },
          dates: {
            weekdays: [1]
          }
        },
        {
          highlight: {
            color: 'yellow',
            fillMode: 'outline'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    attributes () {
      let varAttributes = [
        {
          highlight: {
            fillMode: 'light',
            contentStyle: {
              backgroundColor: '#ffd32d'
            }
          },
          dates: {
            start: new Date(Number(this.startTime)),
            end: this.limitExpiryTime(this.inputTime)
          }
        }
      ]
      return [...this.constAttributes, ...varAttributes]
    }
  },
  methods: {
    maxDate (date1, date2) {
      return Number(date1) > Number(date2) ? date1 : date2
    },
    limitExpiryTime (date) {
      return this.maxDate(
        date,
        new Date(Number(this.expiryTime) - 3600 * 24 * 1000)
      )
    }
  }
}
</script>
<style src="@/assets/css/teacherCalendar.css" scoped></style>
<style scoped>
.picker-calendar >>> .vc-highlight-base-start,
.picker-calendar >>> .vc-highlight-base-middle,
.picker-calendar >>> .vc-highlight-base-end {
  background-color: #ffd32d !important;
}
</style>
