<template>
  <table>
    <template v-if="isLoading == false">
      <thead>
        <tr class="questions-header">
          <th class="table-id">{{ $t($interfaceLang, 'ID', 'ID') }}</th>
          <th class="table-student">
            {{ $t($interfaceLang, 'Student', '學生') }}
          </th>
          <th class="table-score">{{ $t($interfaceLang, 'Score', '分數') }}</th>
          <th class="table-assignment" v-for="i in paper.numQuestion">
            <i class="icon-font icon-assignment">&#xe807;</i>
            <span>Q{{ i }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, i) in users">
          <td class="table-id">#{{ user.identifier }}</td>
          <td class="table-student">
            <router-link
              :to="{
                path: `/teacher/reportnstatistics/student`,
                query: { id: user.id }
              }"
              exact
            >
              {{ String(user.name).replace(/(.{14})..+/, '$1…') }}
            </router-link>
          </td>
          <td class="table-score">{{ user.score }}/{{ paper.numQuestion }}</td>
          <template v-for="j in paper.numQuestion">
            <template v-for="x in [useranswerLookup[user.id + '@' + (j - 1)]]">
              <td
                @click="$emit('click-useranswer', x)"
                class="table-assignment"
              >
                <span
                  v-katex
                  class="ans-col"
                  :class="{
                    'correct-ans': x?.answerIndexNo == 0,
                    'wrong-ans': x?.answerIndexNo > 0 && x?.answerIndexNo <= 3,
                    'empty-ans': x?.answerIndexNo == null,
                    'late-ans': x?.isLate
                  }"
                >
                  {{ displayAnswerVX(x?.answerIndexNo) }}
                </span>
              </td>
            </template>
          </template>
        </tr>
      </tbody>
    </template>
    <img v-else class="loading-gif" src="@/assets/images/loading.gif" />
  </table>
</template>
<script>
import VKatex from 'vue-katex-auto-render'
export default {
  directives: {
    katex: VKatex
  },
  components: {},
  props: {
    numQuestion: Number,
    users: Array,
    paper: Object,
    useranswerLookup: { type: Object, default: _ => ({}) },
    isLoading: Boolean
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    displayAnswerVX (i) {
      if (i != null) {
        var VX = [
          '$\\checkmark$',
          '$\\char"2717_1$',
          '$\\char"2717_2$',
          '$\\char"2717_3$'
        ]
        return VX[i]
      } else {
        return '-'
      }
    }
  }
}
</script>
<style scoped>
#btn-back {
  align-items: flex-start;
}
#btn-back a {
  text-decoration: none;
}

.page-title-desc {
  width: 80%;
}

.page-btn-group {
  width: 140px;
}

.questions-table th {
  font-size: 12px;
  font-weight: 600;
  color: #ccc;
  border-bottom: 0.8px solid #ccc;
  height: 30px;
  padding: 5px 10px;
}

.questions-table td {
  font-size: 14px;
  color: #192c64;
  height: 40px;
}

.table-id {
  min-width: 60px;
  width: 60px;
  text-align: center;
  vertical-align: middle;
}

.table-student {
  min-width: 130px;
  width: 130px;
  text-align: right;
  vertical-align: middle;
  padding-right: 10px;
}

.table-student a {
  color: #192c64;
}

.table-score {
  min-width: 50px;
  width: 80px;
  text-align: center;
  vertical-align: middle;
}

.table-assignment {
  min-width: 70px;
  width: 70px;
  text-align: center;
  vertical-align: middle;
}

.questions-table-container {
  overflow-x: auto;
  margin-bottom: 20px;
}

.ans-col {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 85%;
  cursor: pointer;
}

.notice {
  display: flex;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid #f1f3f1;
}

.notice .img-notice {
  height: 60px;
  float: left;
}

.notice p {
  float: left;
  font-size: 13px;
  line-height: 1.23;
  color: #1c2d60;
  padding-left: 10px;
}

.correct-ans {
  color: green;
  background-color: #d9f5e7;
}

.wrong-ans {
  color: red;
  background-color: #feeeef;
}

.empty-ans {
  border: 0.7px solid #ccc;
}

.late-ans {
  border: 0.7px solid red;
}

.loading-gif {
  width: 50px;
  height: 50px;
}
</style>
