<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import './assets/css/global.css';
@import './assets/css/teacher.css';
@import './assets/css/student.css';
</style>
