<template>
  <div id="select-subtopic" class="btn-group select-dropdown" role="group">
    <button
      type="button"
      class="btn select-subtopic-dropdown select-questions-dropdown dropdown-toggle"
      :class="{
        disabled: subtopics == null
      }"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <p class="select-title">
        {{ $t($interfaceLang, 'Subtopic', '副題') }}
      </p>
      <span class="select-content">
        <template v-if="value == null">
          {{ $t($interfaceLang, 'All subtopics', '所有副題') }}
        </template>
        <template v-if="value != null">
          <template
            v-for="(s, i) in subtopics"
            v-if="value != null && s.id == value.id"
          >
            {{ $t($interfaceLang, s.nameEn, s.nameZh) }}
          </template>
        </template>
      </span>
    </button>
    <div
      class="dropdown-menu select-topic-dropdown-group select-questions-dropdown-group  btn-select-topic-group"
    >
      <a
        v-if="value != null"
        @click="$emit('input', null)"
        class="dropdown-item select-subtopic-dropdown-item select-questions-dropdown-item"
        href="#"
      >
        {{ $t($interfaceLang, 'All subtopics', '所有副題') }}
      </a>
      <a
        v-for="(st, i) in subtopics"
        v-show="value == null || value.id != st.id"
        class="dropdown-item select-topic-dropdown-item select-questions-dropdown-item"
        href="#"
        @click="$emit('input', st)"
      >
        {{ $t($interfaceLang, st.nameEn, st.nameZh) }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    subtopics: Array,
    value: Object
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="@/pages/teacher/edit-assignment/EditAssignment.css" scoped />
