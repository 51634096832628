<template>
  <div
    v-if="yearTerm != null"
    id="library-container"
    class="assignment-container"
  >
    <LibraryRow
      ref="library-row"
      v-model="selector"
      :topics="topics"
      :yearTerm="yearTerm"
      :showFav="showFav"
      @input="
        $log('library-row-input', selector)
        $emit('select', selector)
        $nextTick(_ => resetQuestions())
      "
    />
    <div
      v-if="selector?.topic != null || selector?.isFav"
      class="add-questions-container"
    >
      <LibraryQuestionRow
        :key="'library-question-' + q?.displayQuestion?.id"
        v-for="q in questions"
        :readOnly="readOnly"
        :question="q"
        :isQgroupFav="q.displayQuestion.qgroup.myUserEdge?.isQgroupFav"
        :showFav="showFav"
        @add="$emit('add', $event)"
        @similar="$emit('similar', $event)"
        @set-isfav="
          setIsFav($event)
          $emit('set-isfav', $event)
        "
      />
    </div>
    <InfiniteLoading
      v-if="selector?.topic != null || selector?.isFav"
      ref="infinite-loading-paper"
      @infinite="atInfinity($event)"
    >
      <div slot="spinner">
        <img class="loading-gif" src="@/assets/images/loading.gif" />
      </div>
      <div slot="no-more">
        {{ $t($interfaceLang, 'No more questions.', '無其他題目。') }}
      </div>
      <div slot="no-results">
        {{ $t($interfaceLang, 'No more questions.', '無其他題目。') }}
      </div>
    </InfiniteLoading>

    <div v-if="selector?.topic == null && !selector?.isFav" class="blank-msg">
      <img class="img-tip" src="@/assets/images/img_tips.png" />
      <p
        v-html="
          $t(
            $interfaceLang,
            '<b>Tips</b><br />Select Term, Topic, Subtopic or tick \'Favourite\' from above to browse questions.',
            '<b>小題示</b><br />瀏覽題目前，請先選擇年級、主題及副題或者剔選「我的最愛」。'
          )
        "
      />
    </div>
  </div>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading'

import LibraryRow from './LibraryRow.vue'
import LibraryQuestionRow from './LibraryQuestionRow.vue'
export default {
  components: {
    LibraryRow,
    LibraryQuestionRow,
    InfiniteLoading
  },
  props: {
    topics: Array,
    yearTerm: Array,
    questions: Array,
    readOnly: Boolean,
    showFav: Boolean
  },
  data () {
    return {
      selector: null
    }
  },
  watch: {
    yearTerm () {
      this.$nextTick(_ => {
        this.$refs['library-row'].reset()
      })
    }
  },
  computed: {},
  methods: {
    atInfinity (state) {
      setTimeout(
        (async _ => {
          this.$emit('infinite', state)
        }).bind(this),
        200
      )
    },
    resetQuestions () {
      this.$nextTick(_ =>
        this.$refs['infinite-loading-paper']?.stateChanger.reset()
      )
    },
    setIsFav ({ question, isFav }) {
      let qgroupId = question?.displayQuestion?.qgroup.id
      if (qgroupId != null) {
        if (question.displayQuestion.qgroup.myUserEdge != null) {
          Object.assign(question.displayQuestion.qgroup.myUserEdge, {
            isQgroupFav: isFav
          })
        } else {
          question.displayQuestion.qgroup.myUserEdge = { isQgroupFav: isFav }
        }
      }
    }
  }
}
</script>
<style src="@/pages/teacher/edit-assignment/EditAssignment.css" scoped />
