<template>
  <Calendar
    :papers="calendarPapers"
    :loading="loadingPapers"
    @page-change="loadCalendar($event)"
  />
</template>
<script>
import LoadCalendarAssignmentListQuery from './LoadCalendarAssignmentList.graphql'
import Calendar from '../../components/Calendar.vue'
export default {
  components: {
    Calendar
  },
  props: {
    currentUserId: String,
    usergroupId: String
  },
  data () {
    return {
      calendarPapers: [],
      loadingPapers: false
    }
  },
  computed: {},
  methods: {
    async loadCalendar (event) {
      this.loadingPapers = true
      try {
        let res = await this.$apolloQuery(LoadCalendarAssignmentListQuery, {
          currentUserId: this.currentUserId,
          usergroupId: this.usergroupId,
          first: 50,
          expiryToTime: event.nextMonth,
          expiryFromTime: event.thisMonth
        })
        this.calendarPapers = res.data?.usergroupPaperEdges.map(x => x.paper)
      } catch (e) {
        this.$log(e)
      } finally {
        this.loadingPapers = false
      }
    }
  }
}
</script>
<style src="" />
