<template>
  <div v-if="!readOnly" class="btn-group select-more" role="group">
    <button
      type="button"
      class="btn edit-question-dropdown edit-question-btn"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <i class="icon-font icon-more">&#xe816;</i>
    </button>
    <div
      class="dropdown-menu dropdown-menu-right edit-question-dropdown-group btn-select-more-group"
    >
      <a
        @click.prevent="$emit('template-mutation')"
        class="dropdown-item edit-question-dropdown-item"
        href="#"
      >
        <i class="icon-font icon-shuffle">&#xe820;</i>
        {{ $t($interfaceLang, 'Shuffle numbers', '抽換數字') }}
      </a>
      <a
        @click.prevent="$emit('similar')"
        class="dropdown-item edit-question-dropdown-item"
        href="#"
      >
        <i class="icon-font icon-relate">&#xe81a;</i>
        {{ $t($interfaceLang, 'Similar Questions', '相似題目') }}
      </a>
      <a
        @click.prevent="$emit('duplicate')"
        class="dropdown-item edit-question-dropdown-item"
        href="#"
        ><i class="icon-font icon-duplicate">&#xe80e;</i>
        {{ $t($interfaceLang, 'Duplicate', '複製題目') }}
      </a>
      <a
        @click.prevent="$emit('delete')"
        class="dropdown-item edit-question-dropdown-item"
        href="#"
      >
        <i class="icon-font icon-Delete">&#xe80a;</i>
        {{ $t($interfaceLang, 'Delete', '刪除題目') }}
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    readOnly: Boolean
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="" />
