<template>
  <DeleteRecursiveModal
    :autohomework="autohomework"
    @close="$emit('close')"
    @delete="
      deleteRecursive(autohomework)
      $emit('delete')
    "
  />
</template>
<script>
import DeleteRecursiveQuery from './DeleteRecursive.graphql'
import DeleteRecursiveModal from '../../modal/delete-recursive/DeleteRecursive.vue'

export default {
  components: {
    DeleteRecursiveModal
  },
  props: {
    currentUserId: String,
    autohomework: Object
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    async deleteRecursive (autohomework) {
      this.$emit('mutate-start')
      try {
        var success = await this.$apolloMutation(DeleteRecursiveQuery, {
          currentUserId: this.currentUserId,
          autohomeworkId: autohomework?.id
        }).then(res => res?.data?.deleteMyAutohomework?.success)
        success ? this.$emit('mutate-done') : this.$emit('mutate-error')
      } catch (e) {
        this.$log(e)
        this.$emit('mutate-error')
      }
    }
  }
}
</script>
<style src="" />
