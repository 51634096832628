<template>
  <ModalQuestionNav
    v-if="name == 'question-nav'"
    v-model="currentQusetionIndex"
    :useranswers="[
      {
        isCorrect: true
      }
    ]"
  />
</template>
<script>
import ModalQuestionNav from '@/pages/student/paper/components/modal-question-nav/ModalQuestionNav.vue'
export default {
  components: {
    ModalQuestionNav
  },
  props: {},
  data () {
    return {
      name: this.$route.query.name,
      currentQusetionIndex: 0
    }
  },
  computed: {},
  methods: {}
}
</script>
<style src="" />
