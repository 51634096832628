var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.name == 'question-chart')?_c('QuestionsChart',{attrs:{"numQuestion":40,"topics":[
      { name: 'Test', count: 10 },
      { name: 'Test2', count: 20 },
      { name: 'Test3', count: 5 }
    ]}}):_vm._e(),(_vm.name == 'info')?_c('ReportAssignmentInfo',{attrs:{"paperId":'1',"paperName":'Test',"paperType":'homework',"paperExpiryTime":'' + Date.now(),"numSubmission":10,"numStudent":20,"avgTimeSpent":3600000,"avgCorrectRate":30,"topics":[
      { name: 'test1', count: 10 },
      { name: 'test2', count: 10 },
      { name: 'test3', count: 10 },
      { name: 'test4', count: 10 }
    ]}}):_vm._e(),(_vm.name == 'questions')?_c('ReportAssignmentQuestions',{attrs:{"questions":[
      {
        indexNo: 0,
        correctRate: 10,
        avgTimeSpent: 6000
      },
      { indexNo: 1, correctRate: 20 },
      { indexNo: 2, correctRate: 30 },
      { indexNo: 3, correctRate: 40 },
      { indexNo: 4, correctRate: 50 },
      { indexNo: 5, correctRate: 60 },
      { indexNo: 6, correctRate: 70 },
      { indexNo: 7, correctRate: 80 },
      { indexNo: 8, correctRate: 90 },
      { indexNo: 9, correctRate: 100 }
    ]}}):_vm._e(),(_vm.name == 'topic-mastery')?_c('ReportAssignmentTopicMastery',{attrs:{"topics":[
      { nameEn: 'Test', nameZh: '測試', numQuestion: 10, avgCorrectRate: 25 },
      { nameEn: 'Test', nameZh: '測試', numQuestion: 10, avgCorrectRate: 60 },
      { nameEn: 'Test', nameZh: '測試', numQuestion: 10, avgCorrectRate: 25 }
    ]}}):_vm._e(),(_vm.name == 'students')?_c('ReportAssignmentStudents',{attrs:{"users":[
      {
        name: 'Test',
        identifier: '01',
        correctRate: 20,
        wrongRate: 30,
        timeSpent: 600000
      }
    ]}}):_vm._e(),(_vm.name == 'worst-questions')?_c('ReportAssignmentWostQuestions',{attrs:{"questionEdges":[
      { indexNo: 0, correctRate: 58 },
      { indexNo: 9 },
      { indexNo: 5 },
      { indexNo: 8 }
    ]}}):_vm._e(),(_vm.name == 'question-table')?_c('ReportAssignmentDetailQuestionTable',{attrs:{"numQuestion":10,"paper":{ id: '1', numQuestion: 15 },"users":[
      { id: '2', identifier: '4A 01', name: 'Chan Tai Man David', score: 8 }
    ],"useranswerLookup":{ '2@0': 1 }}}):_vm._e(),(_vm.name == 'modal-followup')?_c('ModalFollowup'):_vm._e(),(_vm.name == 'modal-followup-adapter')?_c('ModalFollowupAdapter',{attrs:{"currentUserId":'763',"usergroupId":'60',"paper":{ id: 169 }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }