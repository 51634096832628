<template>
  <div :class="`average-rate-c c100 p${rate} ${color}`">
    <span> {{ rate }}</span>
    <div class="slice">
      <div class="bar" />
      <div class="fill" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    rate: Number,
    color: String
  }
}
</script>
<style src="../../ReportInside.css" scoped />
