<template>
  <div>
    <div
      class="student-chart"
      v-bind:style="{ height: users?.length * 30 + 50 + 'px' }"
    >
      <div class="student-chart-left-info">
        <template v-for="(user, i) in users">
          <div
            class="student-identifier"
            v-bind:style="{ top: i * 30 + 20 + 'px' }"
          >
            #{{ user.identifier }}
          </div>
          <div
            class="student-name"
            v-bind:style="{ top: i * 30 + 20 + 'px', left: '50px' }"
          >
            {{ String(user.name).replace(/(.{14})..+/, '$1…') }}
          </div>
        </template>
      </div>
      <div
        class="chart-line"
        v-bind:style="{
          height: users?.length * 30 + 'px',
          left: '170px'
        }"
      />
      <div class="student-chart-bar">
        <template v-for="(user, i) in users">
          <div class="student-bar" v-bind:style="{ top: i * 30 + 'px' }">
            <div
              class="correct-bar bar-col"
              v-bind:style="{
                width: (user.correctRate ?? 0) + '%'
              }"
            ></div>
            <div
              class="wrong-bar bar-col"
              v-bind:style="{ width: (user.wrongRate ?? 0) + '%' }"
            ></div>
            <div
              class="un-submit-bar bar-col"
              v-bind:style="{
                width:
                  100 - (user.correctRate ?? 0) - (user.wrongRate ?? 0) + '%'
              }"
            ></div>
          </div>
        </template>
      </div>
      <div
        class="chart-line"
        v-bind:style="{
          height: users?.length * 30 + 'px',
          left: '570px'
        }"
      ></div>
      <div class="student-chart-right-info">
        <div class="student-chart-right-info-title">
          {{ $t($interfaceLang, 'Time spent', '作答時間') }}
        </div>
        <template v-for="(user, i) in users">
          <div
            class="student-time-spent"
            v-bind:style="{ top: i * 30 + 26 + 'px' }"
          >
            {{ displayTimeSpent(user.timeSpent) }}
          </div>
        </template>
      </div>
      <div
        class="student-chat-bottom-info"
        v-bind:style="{
          top: users?.length * 30 + 20 + 'px',
          left: '150px'
        }"
      >
        <template v-for="i in 11">
          <div
            class="student-percentage"
            v-bind:style="{ left: (i - 1) * 40 + 'px' }"
          >
            {{ (i - 1) * 10 }}%
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    users: Array
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    displayTimeSpent (value) {
      if (value == null) {
        return '-'
      } else {
        var time = (value / 1000 / 60).toFixed(1)
        if (time > 1) {
          return this.$t(this.$interfaceLang, time + ' min', time + '分鐘')
        } else {
          return this.$t(this.$interfaceLang, '< 1 min', '< 1 分鐘')
        }
      }
    }
  }
}
</script>
<style scoped>
.student-chart {
  position: relative;
  max-width: 700px;
  height: 700px;
  overflow-x: auto;
}

.student-chart-left-info {
  position: absolute;
  font-size: 12px;
  color: #1c2d60;
  font-weight: 600;
  top: 4px;
}

.student-identifier {
  position: absolute;
  width: 30px;
  text-align: center;
}

.student-name {
  position: absolute;
  width: 100px;
  text-align: right;
}

.student-chat-bottom-info {
  position: absolute;
  font-size: 12px;
  color: #959595;
  font-weight: 600;
}

.student-percentage {
  position: absolute;
  width: 50px;
  text-align: center;
}

.student-chart-right-info {
  position: absolute;
  font-size: 12px;
  color: #959595;
  font-weight: 600;
  top: -3px;
  left: 570px;
}

.student-chart-right-info-title {
  position: absolute;
  color: #c3c3c3;
  width: 80px;
  text-align: right;
}

.student-time-spent {
  position: absolute;
  width: 80px;
  text-align: right;
}

.student-chart-bar {
  position: absolute;
  top: 24px;
  left: 170px;
}

.student-bar {
  position: absolute;
  width: 400px;
  height: 18px;
}

.bar-col {
  height: 100%;
  float: left;
}

.chart-line {
  position: absolute;
  top: 20px;
  width: 0.7px;
  background-color: rgb(231, 231, 231);
}

.correct-bar {
  background-color: #3bcc89;
}
.wrong-bar {
  background-color: #f55a58;
}
.un-submit-bar {
  background-color: #f1f3f0;
}
.student-chart-student-container {
  max-height: 400px;
  overflow-y: auto;
}
</style>
