<template>
  <div id="editRecursiveHomework-model">
    <Modal @close="$emit('close')">
      <template v-slot:header>
        <i class="icon-title icon-font icon-edit">&#xe80f;</i>
        <span class="title">
          {{ $t($interfaceLang, 'Edit Recursive Assignment', '編輯重覆習作') }}
        </span>
        <i @click="$emit('close')" class="icon-font icon-close">
          &#xe808;
        </i>
      </template>
      <template v-slot:body>
        <div class="modal-section">
          <p class="sm-title">
            {{ $t($interfaceLang, 'Assignment Title', '作業標題') }}
          </p>
          <p class="assignment-title">
            {{ assignmentName ?? $t($interfaceLang, 'Exercise', '練習') }}
          </p>
        </div>
        <div class="modal-section">
          <p class="sm-title">
            {{ $t($interfaceLang, 'Schedule', '排程') }}
          </p>
          <div class="scheduled-right-side">
            <div class="select-date-col">
              <p class="sm-title">
                {{ $t($interfaceLang, 'Start date', '開始日期') }}
              </p>
              <div class="inline-block date-box-col">
                <div class="date-box">
                  <i class="icon-title icon-font icon-calendar">&#xe823;</i>
                  <span class="date-text">{{ $displayDate(startDate) }}</span>
                </div>
              </div>
            </div>
            <div class="select-date-col">
              <p class="sm-title">
                {{ $t($interfaceLang, 'End date', '結束日期') }}
              </p>
              <HomeworkDatePicker
                v-model="endDate"
                :minDate="minDate(new Date(), startDate)"
              />
            </div>
          </div>
          <RecurrenceList v-model="recurrence" />
        </div>
        <div class="modal-section">
          <div class="advanced-col">
            <p
              v-b-toggle.homework-recursive-setting
              class="advanced-collapse-btn"
            >
              {{ $t($interfaceLang, 'Advanced Settings', '進階設定') }}
              <i class="icon-font icon-arrow_filled_down">&#xe802;</i>
              <i class="icon-font icon-arrow_filled_up">&#xe803;</i>
            </p>
            <b-collapse visible id="homework-recursive-setting" class="mt-2">
              <div class="modal-row2">
                <div class="schedule-repeat-col">
                  <p class="sm-title">
                    {{
                      $t($interfaceLang, 'Schedule Repeat Every', '重複安排每')
                    }}
                  </p>
                  <SelectRecursiveScheduleRepeat
                    v-model="scheduleRepeatFrequency"
                  />
                  <span class="advanced-text">
                    {{ $t($interfaceLang, 'Week', '星期') }}
                  </span>
                </div>
                <div class="DFA-col">
                  <p class="sm-title">
                    {{
                      $t($interfaceLang, 'Assignment due after', '交功課時間')
                    }}
                  </p>
                  <SelectDaysForAssignment v-model="homeworkDuration" />
                  <span class="advanced-text">
                    {{ $t($interfaceLang, 'days', '日後') }}
                  </span>
                </div>
              </div>
              <div class="modal-row2">
                <p class="sm-title">
                  {{ $t($interfaceLang, 'Follow-up', 'Follow-up') }}
                  <span class="remark-text">
                    *{{
                      $t(
                        $interfaceLang,
                        "generated based on students' performance on recent recursive assignment",
                        '根據學生最近重覆作業的表現來生成'
                      )
                    }}
                  </span>
                </p>
                <div class="follow-up-col">
                  <div class="follow-up-question-input">
                    <InputQuestionQty
                      v-model="numFollowupQuestion"
                      :inputs="[1, 3, 5, 8, 10]"
                      :defaultInputIndex="1"
                      :advancedText="$t($interfaceLang, 'Question(s)', '題')"
                    />
                  </div>
                  <div class="follow-up-recent-auto-input">
                    <InputQuestionQty
                      v-model="numAutohomeworkPerFollowup"
                      :inputs="[1, 2, 3, 4, 5]"
                      :defaultInputIndex="2"
                      :behindText="$t($interfaceLang, 'Every', '每')"
                      :advancedText="
                        $t(
                          $interfaceLang,
                          'Recursive assignment(s)',
                          '份重覆作業'
                        )
                      "
                    />
                  </div>
                  <span
                    class="recurrence-remark"
                    v-if="nextAssignmentDate != null"
                  >
                    {{
                      $t(
                        $interfaceLang,
                        'First release of recursive assignment',
                        '第一份自動作業日期'
                      )
                    }}:
                    {{ $displayDate(nextAssignmentDate) }}
                    ({{
                      $t($interfaceLang, weekdayNamesEn, weekdayNamesZh)[
                        nextAssignmentDate.getDay()
                      ]
                    }})
                  </span>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
        <div class="modal-section section-row align-right">
          <button
            @click="$emit('save', getData())"
            id="btn-save"
            class="modal-btn right-item"
          >
            <i class="icon-font icon-inspect">&#xe815;</i>
            {{ $t($interfaceLang, 'Save', '儲存') }}
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { BCollapse, BFormCheckbox, VBToggle } from 'bootstrap-vue'

import Modal from '@/components/teacher/modal/Modal.vue'
import RecurrenceList from '../components/RecurrenceList.vue'
import HomeworkDatePicker from '../components/HomeworkDatePicker.vue'
import SelectRecursiveScheduleRepeat from '../components/SelectRecursiveScheduleRepeat.vue'
import SelectDaysForAssignment from '../components/SelectDaysForAssignment.vue'
import InputQuestionQty from '../components/InputQuestionQty.vue'

export default {
  components: {
    Modal,
    RecurrenceList,
    HomeworkDatePicker,
    SelectRecursiveScheduleRepeat,
    SelectDaysForAssignment,
    InputQuestionQty,
    BCollapse,
    BFormCheckbox
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    autohomework: Object
  },
  created () {},
  data () {
    return {
      assignmentName: this.autohomework.paperName,
      recurrence:
        this.autohomework.scheduleWeekdays ?? new Array(7).fill(false),
      startDate:
        this.autohomework.startTime != null
          ? new Date(Number(this.autohomework.startTime))
          : new Date(),
      endDate:
        this.autohomework.expiryTime != null
          ? new Date(Number(this.autohomework.expiryTime) - 3600 * 24 * 1000)
          : new Date(Date.now() + 2 * 3600 * 24 * 1000),
      scheduleRepeatFrequency: this.autohomework.scheduleRepeatFrequency,
      homeworkDuration: '' + this.autohomework.homeworkDuration,
      numFollowupQuestion: this.autohomework.numFollowupQuestion,
      numAutohomeworkPerFollowup: this.autohomework.numAutohomeworkPerFollowup,
      nextAssignmentDate: null,
      weekdayNamesEn: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      weekdayNamesZh: ['日', '一', '二', '三', '四', '五', '六']
    }
  },
  computed: {},
  methods: {
    minDate (d1, d2) {
      return Number(d1) < Number(d2) ? d2 : d1
    },
    getData () {
      return {
        id: this.autohomework?.id,
        paperName: this.assignmentName,
        scheduleWeekdays: this.recurrence,
        startTime: this.startDate != null ? '' + Number(this.startDate) : null,
        expiryTime: this.startDate != null ? '' + Number(this.endDate) : null,
        scheduleRepeatFrequency: this.scheduleRepeatFrequency,
        homeworkDuration: this.homeworkDuration,
        numFollowupQuestion: this.numFollowupQuestion,
        numAutohomeworkPerFollowup: this.numAutohomeworkPerFollowup
      }
    }
  }
}
</script>

<style src="@/components/teacher/modal/modal.css" scoped></style>
<style scoped>
.assignment-title {
  font-weight: normal;
}

.topic-checkbox {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
}

.subtopic-checkbox {
  font-size: 14px;
  font-weight: normal;
  color: #1c2d60;
}

.align-right button {
  margin-left: 10px;
  display: flex !important;
  align-items: center;
}

.modal-row {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.modal-row2 {
  position: relative;
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.advanced-collapse-btn {
  font-size: 14px;
  font-weight: normal;
  color: #007fca;
  cursor: pointer;
}

.advanced-collapse-btn.icon-font {
  font-size: 12px;
}

[aria-expanded='false'] .icon-arrow_filled_down {
  display: inline;
}
[aria-expanded='true'] .icon-arrow_filled_down {
  display: none;
}

[aria-expanded='false'] .icon-arrow_filled_up {
  display: none;
}
[aria-expanded='true'] .icon-arrow_filled_up {
  display: inline;
}

.scheduled-side,
.type-side {
  position: relative;
  width: 20%;
}

.title-side {
  position: relative;
  width: 78%;
}

.toggle-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.toggle-filter {
  height: 12px;
  width: 30px;
  border-radius: 12px;
  border-style: none;
  background-color: #e4e4e4;
  margin-right: 5px;
}

.toggle-filter:focus,
.toggle-filter:hover,
.toggle-filter:active {
  box-shadow: none;
  outline: none;
}

.toggle-filter::before {
  content: '';
  position: absolute;
  top: 2px;
  left: auto;
  width: 16px;
  height: 16px;
  background-color: #adadad;
  border-radius: 50%;
}

.active.toggle-filter::before {
  left: 0px;
  background-color: #007fca !important;
}

.toggle-text {
  font-size: 13px;
  font-weight: 600;
  color: #1c2d60;
  padding-left: 5px;
}

.normal-text {
  font-size: 14px;
  font-weight: normal;
  color: #1c2d60;
  margin: 0px;
}

.date-box {
  font-size: 14px;
  color: #1c2d60;
  border-radius: 6px;
  background-color: #f1f3f1;
  border: none;
  padding: 3px 10px;
  min-height: 26px;
  min-width: 110px;
}

.date-box i {
  color: #1c2d60;
  font-size: 14px;
  padding-right: 5px;
}

.scheduled-right-side {
  position: relative;
  display: flex;
  width: 75%;
  justify-content: flex-start;
}

.select-date-col {
  position: relative;
  padding-right: 10px;
}

.item-check label:before {
  border: 1.5px solid #969696;
}

.item-check input[type='checkbox']:checked + label:before {
  background-color: #ff881b;
  border-color: #ff881b;
}

.schedule-repeat-col {
  position: relative;
  width: 40%;
}

.DFA-col {
  position: relative;
  width: 50%;
}

.follow-up-col {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.remark-text {
  font-size: 12px;
  color: #ff6d6d;
  padding-left: 5px;
}

#homework-recursive-setting .select-option.input-text {
  width: 50px;
}

#homework-recursive-setting .advanced-text {
  font-size: 14px;
  font-weight: normal;
  color: #1c2d60;
  padding-left: 10px;
}

.follow-up-question-input {
  position: relative;
  width: 40%;
}

.follow-up-recent-auto-input {
  position: relative;
  width: 50%;
}

.recurrence-remark {
  position: relative;
  width: 100%;
  font-size: 12px;
  color: #42c9f1;
  margin-top: 10px;
  margin-bottom: 0px;
}

.test-date-col {
  position: relative;
  width: 40%;
}

.test-date-col .date-box-col {
  position: relative;
  width: 120px;
  display: block;
}

.start-time-col,
.end-time-col {
  position: relative;
  width: 25%;
}

.start-time-col .select-option.input-text,
.end-time-col .select-option.input-text {
  width: 80px;
}

#practice-setting {
  position: relative;
  display: flex;
}

#practice-setting .scheduled-side {
  width: 25%;
}

#practice-setting .scheduled-right-side {
  flex-direction: column;
}

.reveal-solution-option {
  position: relative;
  display: flex;
  width: 100%;
  flex-basis: 100%;
  align-items: center;
  margin-bottom: 10px;
}

.reveal-solution-option .item-radio-label span {
  color: #007fca;
}

.reveal-solution-option .test-date-col {
  width: 30%;
  margin-left: 20px;
}

.topics-collapse-btn {
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-size: 14px;
  color: #1c2d60;
  border-radius: 6px;
  background-color: #f1f3f1;
  border: none;
  padding: 3px 10px;
  min-height: 26px;
  cursor: pointer;
}

.topics-collapse-btn .icon-font {
  margin-left: auto;
}

.topic-item {
  position: relative;
  display: flex;
  align-items: center;
}

.subtopics-collapse-btn {
  font-size: 14px;
  color: #1c2d60;
  margin-left: 5px;
  cursor: pointer;
}

.subtopics {
  margin-bottom: 10px;
}

.topic-col {
  position: relative;
  max-height: 400px;
  overflow-y: scroll;
}

.topic-setting-col {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 15px;
  width: 100%;
}

.topic-setting-text {
  font-size: 14px;
  font-weight: 600;
  color: #1c2d60;
  margin-right: 15px;
}

.diff-input {
  position: relative;
  display: flex;
  align-items: center;
}

.diff-star {
  font-size: 18px;
  margin-right: 5px;
  color: #1c2d60;
  cursor: pointer;
}

.no-of-question-input {
  position: relative;
  width: 40%;
}

.diff-input {
  position: relative;
  width: 40%;
}

.date-box {
  font-size: 14px;
  color: #1c2d60;
  border-radius: 6px;
  background-color: white;
  border: none;
  padding: 3px 10px;
  min-height: 26px;
  min-width: 110px;
}

.date-box i {
  color: #1c2d60;
  font-size: 14px;
  padding-right: 5px;
}

.date-box-col {
  position: relative;
  width: 120px;
  display: block;
}
</style>
