<template>
  <div class="toggle-btn">
    <button
      :class="{ active: value }"
      class="toggle-filter"
      @click="$emit('input', !value)"
    />
    <span class="toggle-text" v-if="value == true">
      {{ $t($interfaceLang, 'Available', '可用') }}
    </span>
    <span class="toggle-text" v-else>
      {{ $t($interfaceLang, 'Scheduled', '排程') }}
    </span>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: Boolean
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style src="../../TeachingSchedule.css" scoped />
