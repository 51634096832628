import config from '@/config'

const manageAssignment = _ => import('@/pages/teacher/manage-assignment/ManageAssignment.vue')
const teachingSchedule = _ => import('@/pages/teacher/teaching-schedule/TeachingSchedule.vue')
const editAssignment = _ => import('@/pages/teacher/edit-assignment/EditAssignment.vue')
const reportnStatistics = _ => import('@/pages/teacher/report-n-statistics/ReportnStatistics.vue')
const reportAssignment = _ => import('@/pages/teacher/report-assignment/ReportAssignment.vue')
const reportAssignmentDetails = _ => import('@/pages/teacher/report-assignment-details/ReportAssignmentDetails.vue')

export default [
  {
    path: '/teacher',
    redirect: config.route.teacherHome,
  },
  {
    path: '/teacher/manageassignment',
    name: 'TeacherManageAssignment',
    component: manageAssignment,
    meta: {
      requireAuth: true,
      active: '/teacher/manageassignment',
      pageRole: 'teacher'
    }
  },
  {
    path: '/teacher/teachingschedule',
    name: 'TeachingSchedule',
    component: teachingSchedule,
    meta: {
      requireAuth: true,
      active: '/teacher/teachingschedule',
      pageRole: 'teacher'
    }
  },
  {
    path: '/teacher/editassignment/:id?',
    name: 'TeacherEditAssignment',
    component: editAssignment,
    meta: {
      requireAuth: true,
      active: '/teacher/manageassignment',
      pageRole: 'teacher'
    }
  },
  {
    path: '/teacher/reportnstatistics',
    name: 'TeacherReportnStatistics',
    component: reportnStatistics,
    meta: {
      requireAuth: true,
      active: '/teacher/reportnstatistics',
      pageRole: 'teacher'
    }
  },
  {
    path: '/teacher/reportnstatistics/assignment',
    name: 'TeacherReportAssignment',
    component: reportAssignment,
    meta: {
      requireAuth: true,
      active: '/teacher/reportnstatistics',
      pageRole: 'teacher'
    }
  },
  {
    path: '/teacher/reportnstatistics/assignment/questionsdetails',
    name: 'TeacherReportAssignmentDetails',
    component: reportAssignmentDetails,
    meta: {
      requireAuth: true,
      active: '/teacher/reportnstatistics',
      pageRole: 'teacher'
    }
  }
]