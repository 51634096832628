<template>
  <div class="select-types">
    <div
      @click="$emit('input', 'todo')"
      id="type-todo"
      class="select-item"
      :class="{ selected: value == 'todo' }"
    >
      {{ $t($interfaceLang, 'To do', '未到期') }}
    </div>
    <div
      @click="$emit('input', 'past')"
      id="type-past"
      class="select-item"
      :class="{ selected: value == 'past' }"
    >
      {{ $t($interfaceLang, 'Past', '已過期') }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: String
  }
}
</script>
<style src="../../Homework.css" scoped></style>
