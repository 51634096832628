<template>
  <div id="lang-selector">
    <div class="btn-group" role="group">
      <button
        id="btn-select-lang"
        type="button"
        class="btn dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        🌐
        <span class="lang-short" v-if="value == 'en_hk'">
          En
        </span>
        <span class="lang-short" v-else-if="value == 'zh_hk'">
          繁
        </span>
        <span class="lang-long" v-if="value == 'en_hk'">
          En - English
        </span>
        <span class="lang-long" v-else-if="value == 'zh_hk'">
          繁 - 繁體中文
        </span>
      </button>
      <div
        class="dropdown-menu dropdown-menu-right btn-select-lang-group"
        aria-labelledby="btn-select-lang"
      >
        <a
          v-if="value != 'en_hk'"
          @click.prevent="$emit('input', 'en_hk')"
          class="dropdown-item btn-select-lang-menu"
          href="#"
        >
          En - English
        </a>
        <a
          v-if="value != 'zh_hk'"
          @click.prevent="$emit('input', 'zh_hk')"
          class="dropdown-item btn-select-lang-menu"
          href="#"
        >
          繁 - 繁體中文
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: String
  },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>
#student-header {
  position: relative;
  min-height: 56px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

#header-logo img {
  width: 120px;
}

.top-row {
  background-color: white;
  z-index: 1060;
}

.navbar {
  width: 100%;
  padding: 0.5rem 0.5rem;
}

.navbar-toggler {
  border: none;
  color: #1c2d60;
}

button:focus,
button:hover,
button:active {
  box-shadow: none;
  outline: none;
}

.navbar-collapse {
  flex-direction: row-reverse;
}

#lang-selector {
  margin-left: auto;
}

.menu-nav {
  padding: 0px;
  margin: 0px;
  margin-left: 20px;
}

.menu-nav li {
  position: relative;
  float: left;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 0px 25px;
}

.menu-nav li a:hover,
.menu-nav li a:focus,
.select-menu.active a {
  color: #0c89d3;
  text-decoration: none;
}

.select-menu a {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #1e2d60;
}

.profile-pic {
  cursor: pointer;
}

.profile-name {
  font-size: 14px;
  font-weight: 600;
  color: #1e2d60;
  padding: 0px 5px;
}

.profile-pic img {
  width: 32px;
}

#btn-select-profile {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
  height: 56px;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
}

#btn-select-profile:before {
  content: '';
  height: 25px;
  padding-right: 15px;
}

.btn-select-profile-group {
  padding: 0px;
  margin: 0 auto;
  min-width: 85%;
  background-color: #007fca;
  border: 0px;
  border-radius: 0px;
}

.btn-select-profile-menu {
  color: white;
  font-size: 13px;
  font-weight: normal;
  line-height: 2.3;
  align-items: center;
  display: flex;
}

.btn-select-profile-menu:hover,
.btn-select-profile-menu:active {
  background-color: #009af3;
}

.icon-font {
  font-size: 17px;
  color: #ffd32d;
  padding-right: 5px;
}

#btn-select-lang {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
  height: 56px;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
}

#btn-select-lang:before {
  content: '';
  height: 25px;
  border-left: 1.5px solid #bbbbbb;
  padding-right: 15px;
}

.btn-select-lang-group {
  padding: 0px;
  margin: 0 auto;
  min-width: 85%;
  background-color: #007fca;
  border: 0px;
  border-radius: 0px;
}

.btn-select-lang-menu {
  color: white;
  font-size: 13px;
  font-weight: normal;
  line-height: 2.3;
  align-items: center;
  display: flex;
}

.btn-select-lang-menu:hover,
.btn-select-lang-menu:active {
  background-color: #009af3;
}

.lang-short {
  display: block;
}

.lang-long {
  display: none;
}

@media only screen and (max-width: 767px) {
  #student-header {
    padding: 0px;
  }

  .navbar {
    padding: 0px;
  }

  .top-row {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
  }

  #header-logo {
    position: relative;
  }

  .navbar-toggler {
    position: relative;
    margin-left: auto;
    padding-right: 0px;
  }

  .navbar-collapse {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    min-height: 100%;
    padding-top: 50px;
    z-index: 1050;
    background-color: #007fca;
  }

  .profile-nav {
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: #007fca;
  }

  .profile-nav .btn-group {
    display: block;
  }

  .btn-select-profile-group {
    position: relative;
    height: auto;
    opacity: 1;
    width: 100%;
    pointer-events: visible;
    border-bottom: 1px solid white;
  }

  #btn-select-profile:before {
    border-left: none;
  }

  #btn-select-profile:after {
    content: none;
  }

  .profile-name {
    color: white;
  }

  .btn-select-profile-menu {
    padding-left: 40px;
  }

  .menu-nav {
    margin-left: 0px;
    position: relative;
    display: block;
    background-color: #007fca;
  }

  .menu-nav li {
    position: relative;
    display: block;
    float: none;
    height: auto;
    padding: 0px;
    border-bottom: 1px solid white;
  }

  .menu-nav li:last-child {
    border-bottom: none;
  }

  .menu-nav li.active a,
  .menu-nav li a {
    position: relative;
    display: block;
    color: white;
    width: 100%;
    padding: 15px 20px;
  }

  .menu-nav li a::after {
    content: '';
    position: absolute;
    right: 20px;
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    /* speak: never; */
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
  }

  .menu-nav li a:hover,
  .menu-nav li a:focus {
    color: white;
    background-color: #009af3;
  }

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #ffffffa1;
  }

  #lang-selector {
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: #007fca;
  }

  #lang-selector .btn-group {
    display: block;
  }

  #btn-select-lang {
    color: white !important;
  }

  .btn-select-lang-group {
    position: relative;
    height: auto;
    opacity: 1;
    width: 100%;
    pointer-events: visible;
    border-bottom: 1px solid white;
  }

  .btn-select-lang-menu {
    padding-left: 40px;
  }

  #btn-select-lang:before {
    border-left: none;
  }

  #btn-select-lang:after {
    content: none;
  }

  .lang-short {
    display: none;
  }

  .lang-long {
    display: block;
  }
}
</style>
