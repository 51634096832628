<template>
  <Students
    :users="users"
    :isLoading="isLoading"
    @goto-details="
      $router.push({
        path: '/teacher/reportnstatistics/assignment/questionsdetails',
        query: { id: paperId }
      })
    "
  />
</template>
<script>
import LoadPaperQuery from './LoadPaper.graphql'
import LoadStudentsQuery from './LoadStudents.graphql'
import LoadAssignmentStudentsQuery from './LoadAssignmentStudents.graphql'
import Students from './Students.vue'

const MAX_STUDENTS = 250

export default {
  components: {
    Students
  },
  props: {
    currentUserId: String,
    paperId: String,
    usergroupId: String,
    batchSize: Number
  },
  data () {
    return {
      users: null,
      numQuestion: null,
      isLoading: false
    }
  },
  computed: {},
  async created () {
    this.isLoading = true
    let paper = await this.fetchPaper()
    let usergroupUserEdges = await this.fetchStudents()
    let paperUserEdges = await this.fetchAssignmentStudents()
    this.parseStudentsData(paper, usergroupUserEdges, paperUserEdges)
    this.isLoading = false
  },
  methods: {
    async fetchPaper () {
      return this.$apolloQuery(LoadPaperQuery, {
        currentUserId: this.currentUserId,
        paperId: this.paperId
      }).then(res => res.data.paper)
    },
    async fetchStudents () {
      return await this.$graphqlUtil.graphqlFetchEdges(
        this.$apolloQuery,
        LoadStudentsQuery,
        {
          currentUserId: this.currentUserId,
          usergroupId: this.usergroupId,
          first: Math.max(this.batchSize ?? 0, 10)
        },
        x => x.usergroupUserEdges,
        null,
        MAX_STUDENTS / Math.max(this.batchSize ?? 0, 10)
      )
    },
    async fetchAssignmentStudents () {
      return await this.$graphqlUtil.graphqlFetchEdges(
        this.$apolloQuery,
        LoadAssignmentStudentsQuery,
        {
          currentUserId: this.currentUserId,
          paperId: this.paperId,
          first: Math.max(this.batchSize ?? 0, 10)
        },
        x => x.paperUserEdges,
        null,
        MAX_STUDENTS / Math.max(this.batchSize ?? 0, 10)
      )
    },
    parseStudentsData (paper, usergroupUserEdges, paperUserEdges) {
      this.numQuestion = paper?.cache?.stats?.numQuestion ?? paper?.numQuestion
      this.users = usergroupUserEdges.map(x => ({
        id: x.user.id,
        name: x.user.name,
        identifier: x.user.userIdentifier
      }))
      paperUserEdges.forEach(edge => {
        let user = this.users.find(x => x.id == edge.user.id)
        if (this.numQuestion ?? 0 > 0) {
          let numCorrectQuestion =
            edge.cache?.userStudentStats?.numCorrectQuestion ?? 0
          let numSubmittedQuestion =
            edge.cache?.userStudentStats?.numSubmittedQuestion ?? 0
          let numWrongQuestion = numSubmittedQuestion - numCorrectQuestion
          user.correctRate = Math.floor(
            (numCorrectQuestion / this.numQuestion) * 100
          )
          user.wrongRate = Math.ceil(
            (numWrongQuestion / this.numQuestion) * 100
          )
        }
        user.timeSpent = edge.cache?.userStudentStats?.timeSpent
      })
    }
  }
}
</script>
<style src="" />
