<template>
  <div class="select-types">
    <div
      @click="$emit('input', 'correct-rate')"
      class="type-correctrate select-item"
      :class="{ selected: value == 'correct-rate' }"
    >
      {{ $t($interfaceLang, 'Correct rate', '正確率') }}
    </div>
    <div
      @click="$emit('input', 'time-spent')"
      class="type-timespent select-item"
      :class="{ selected: value == 'time-spent' }"
    >
      {{ $t($interfaceLang, 'Average time spent', '平均作答時間') }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: String
  }
}
</script>
<style src="../../ReportInside.css" scoped></style>