<template></template>
<script>
import SetFavQgroupQuery from './SetFavQgroup.graphql'
export default {
  props: {
    currentUserId: String,
    usergroupId: String
  },
  methods: {
    async save (qgroupId, isFav) {
      return;
      this.$emit('mutate-start')
      try {
        if (qgroupId != null && isFav != null) {
          let success = await this.$apolloMutation(SetFavQgroupQuery, {
            currentUserId: this.currentUserId,
            usergroupId: this.usergroupId,
            qgroupId,
            isFav
          }).then(res => res?.data?.updateMyFavQgroup.success)
          success ? this.$emit('mutate-done') : this.$emit('mutate-error')
        } else {
          this.$emit('mutate-error')
        }
      } catch (e) {
        this.$emit('mutate-error')
      }
    }
  }
}
</script>
<style src="" />
