var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-row",class:_vm.typeClass},[_c('a',{staticClass:"click-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('goto')}}},[_c('div',{staticClass:"item-name item-hightlight-text"},[_vm._v(" "+_vm._s((_vm.paper.name ?? '') == '' ? _vm.defaultAssignmentName[_vm.$interfaceLang] : _vm.paper.name)+" ")]),_c('div',{staticClass:"item-date"},[_c('p',{staticClass:"sm-title"},[_vm._v(_vm._s(_vm.$t(_vm.$interfaceLang, 'Due', '遞交日期')))]),_c('p',{staticClass:"item-hightlight-text"},[_vm._v(" "+_vm._s(_vm.$displayDate( _vm.paper.expiryTime != null ? Number(_vm.paper.expiryTime) - 3600 * 24 * 1000 : null ))+" ")])]),_c('div',{staticClass:"item-submission"},[_c('p',{staticClass:"sm-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Assignment submission', '作業提交數量'))+" ")]),_c('div',{staticClass:"progress-col"},[(_vm.numStudent != null)?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",style:({ width: _vm.submissionRate + '%' }),attrs:{"role":"progressbar"}})]):_vm._e(),(_vm.numStudent != null)?_c('span',{staticClass:"item-hightlight-text"},[_vm._v(" "+_vm._s(_vm.paper.numSubmission ?? 0)+"/"+_vm._s(_vm.numStudent)+" ")]):_c('span',{staticClass:"item-hightlight-text"},[_vm._v(" "+_vm._s(_vm.noData)+" ")])])]),_c('div',{staticClass:"item-average-rate"},[_c('p',{staticClass:"sm-title",domProps:{"innerHTML":_vm._s(
          _vm.$t(
            _vm.$interfaceLang,
            ' Average <br /> correct rate',
            '平均<br />正確率'
          )
        )}}),_c('div',{class:`average-rate-c c100 p${_vm.paper.usergroupAvgCorrectRate} ${_vm.correctRateColor}`},[(_vm.paper.usergroupAvgCorrectRate != null)?_c('span',[_vm._v(" "+_vm._s(_vm.paper.usergroupAvgCorrectRate)+" %")]):_c('span',[_vm._v("-")]),_vm._m(0)])]),_c('div',{staticClass:"item-qty"},[_c('p',{staticClass:"sm-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'No. of questions', '問題數量'))+" ")]),_c('p',{staticClass:"item-hightlight-text"},[_vm._v(_vm._s(_vm.paper.numQuestion ?? _vm.noData))])]),_c('div',{staticClass:"item-time"},[_c('p',{staticClass:"sm-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Average time spent', '平均作答時間'))+" ")]),_c('p',{staticClass:"item-hightlight-text"},[_vm._v(" "+_vm._s(_vm.avgTimeSpent ?? _vm.noData)+" ")])])]),(false && _vm.hasPaperExpired)?_c('button',{staticClass:"btn-follow_up item-btn",on:{"click":function($event){return _vm.$emit('followup', { paper: _vm.paper })}}},[_c('i',{staticClass:"icon-font icon-follow_up"},[_vm._v("  ")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slice"},[_c('div',{staticClass:"bar"}),_c('div',{staticClass:"fill"})])
}]

export { render, staticRenderFns }