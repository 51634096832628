<template>
  <DatePicker
    :locale="$t($interfaceLang, 'en', 'zh')"
    :firstDayOfWeek="0"
    :timezone="$appStore.appInfo.timezoneText"
    :modelConfig="{
      type: 'string',
      start: {
        timeAdjust: '00:00:00'
      }
    }"
    :min-date="minDate"
    :attributes="[
      {
        key: 'weekdays',
        highlight: {
          contentStyle: {
            color: '#f45e5c'
          }
        },
        dates: {
          weekdays: [1]
        }
      },
      {
        highlight: {
          color: 'yellow',
          fillMode: 'outline'
        },
        dates: new Date()
      }
    ]"
    class="inline-block date-box-col"
    v-model="date"
    is-required
  >
    <template v-slot="{ inputValue, togglePopover }">
      <div class="date-box" @click="togglePopover()">
        <i class="icon-title icon-font icon-calendar">&#xe823;</i>
        <span class="date-text">{{ $displayDate(date) }}</span>
      </div>
    </template>
  </DatePicker>
</template>
<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  components: {
    DatePicker
  },
  props: {
    minDate: Date,
    value: Date
  },
  data () {
    return {
      // timezoneOffset: $appStore.appInfo.$timezoneOffset,
      date: this.value
    }
  },
  computed: {},
  watch: {
    value (newValue) {
      this.date = newValue
    },
    date () {
      this.$emit('input', this.date)
    },
    minDate (newValue) {
      if (this.date != null && Number(this.date) < newValue) {
        this.date = newValue
      }
    }
  }
}
</script>
<style scoped>
.date-box {
  font-size: 14px;
  color: #1c2d60;
  border-radius: 6px;
  background-color: #f1f3f1;
  border: none;
  padding: 3px 10px;
  min-height: 26px;
  min-width: 110px;
  cursor: pointer;
}

.date-box i {
  color: #1c2d60;
  font-size: 14px;
  padding-right: 5px;
}

.date-box-col {
  position: relative;
  width: 120px;
  display: block;
}
</style>
