<template>
  <AddAssignmentModal
    :topics="topics"
    :enableRecursive="numAutohomework < maxNumAutohomework"
    @close="$emit('close')"
    @create-oneoff="createOneoff($event)"
    @create-recursive="createRecursive($event)"
  />
</template>
<script>
import config from '@/config.json'
import CreateRecursiveHomeworkQuery from './CreateRecursiveHomework.graphql'

import AddAssignmentModal from '../../modal/add-assignment/AddAssignment.vue'
export default {
  components: {
    AddAssignmentModal
  },
  props: {
    currentUserId: String,
    usergroupId: String,
    topics: Array,
    numAutohomework: Number
  },
  data () {
    return {
      maxNumAutohomework: config.app.maxNumAutohomework
    }
  },
  computed: {},
  methods: {
    createOneoff (event) {
      let startTime = '' + Number(event.homeworkReleaseDate)
      let expiryTime = '' + (Number(event.homeworkDeadline) + 3600 * 24 * 1000)
      let previewData = {
        name: event.paperName,
        startTime: event.isScheduled ? startTime : null,
        expiryTime: event.isScheduled ? expiryTime : null,
        numQuestion: event.numQuestion,
        difficulty: event.difficulty,
        subtopicIds: event.selectedSubtopics
      }
      this.$appStore.updatePreviewSetting(previewData)
      this.$router.push({
        path: '/teacher/editassignment',
        query: { mode: 'preview' }
      })
    },
    async createRecursive (event) {
      this.$emit('mutate-start')
      try {
        let recursiveData = {
          paperName: event.paperName,
          startTime:
            event.recursiveStartDate != null
              ? '' + Number(event.recursiveStartDate)
              : null,
          expiryTime:
            event.recursiveEndDate != null
              ? '' + (Number(event.recursiveEndDate) + 3600 * 24 * 1000)
              : null,
          numQuestion: event.numQuestion,
          difficulty: event.difficulty,
          scheduleWeekdays: event.recurrence,
          scheduleRepeatFrequency: event.selectedScheduleRepeat,
          homeworkDuration: Number(event.selectedDFA),
          numFollowupQuestion: event.numFollowupQuestion,
          numAutohomeworkPerFollowup: event.numAutohomeworkPerFollowup
        }
        let success = await this.$apolloMutation(CreateRecursiveHomeworkQuery, {
          ...recursiveData,
          currentUserId: this.currentUserId,
          usergroupId: this.usergroupId
        }).then(res => res?.data?.createMyAutohomework?.success)
        if (success) {
          this.$nextTick(_ => this.$emit('mutate-success'))
        } else {
          this.$nextTick(_ => this.$emit('mutate-error'))
        }
      } catch (e) {
        this.$nextTick(_ => this.$emit('mutate-error'))
      }
    }
  }
}
</script>
<style src="" />
