<template>
  <div class="date-container-content">
    <p class="sm-title">
      {{
        $t(
          $interfaceLang,
          'Select date by the below calendar',
          '使用下面的月曆選擇日期'
        )
      }}
    </p>
    <div class="date-col">
      <ExtendDatePicker
        v-model="inputDate"
        :startTime="startTime"
        :expiryTime="expiryTime"
        @input="$emit('input', inputDate)"
      />
    </div>
    <div class="date-input-col">
      <p class="sm-title">
        {{ $t($interfaceLang, 'Start date', '開始日期') }}
      </p>
      <div class="input-date-text">
        {{ $displayDate(new Date(Number(startTime))) }}
      </div>
      <div class="remark">
        {{ $t($interfaceLang, startDayWarningEn, startDayWarningZh) }}
      </div>

      <p class="sm-title">
        {{ $t($interfaceLang, 'Due date', '遞交日期') }}
      </p>
      <div class="input-date-text">
        {{ $displayDate(inputDate) }}
      </div>
    </div>
  </div>
</template>
<script>
import ExtendDatePicker from './ExtendDatePicker.vue'
export default {
  components: {
    ExtendDatePicker
  },
  props: {
    startTime: String,
    expiryTime: String,
    value: Date
  },
  data () {
    return {
      inputDate: this.value,
      startDayWarningEn:
        'This assignment is started, you can only extend the deadline.',
      startDayWarningZh: '這份習作已經開始了，你只能延期。'
    }
  },
  computed: {},
  methods: {}
}
</script>
<style src="@/components/teacher/modal/modal.css" scoped></style>
<style scoped>
.remark {
  width: 100%;
  font-size: 11px;
  color: #007fca;
  margin-top: 1em;
}
</style>
