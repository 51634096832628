var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.name == 'header')?_c('Header'):_vm._e(),(_vm.name == 'header-adapter')?_c('HeaderAdapter',{attrs:{"currentUserId":'491'}}):_vm._e(),(_vm.name == 'homework-info')?_c('HomeworkInfo',{attrs:{"numNew":10,"numProgress":20}}):_vm._e(),(_vm.name == 'homework-info-adapter')?_c('HomeworkInfoAdapter',{attrs:{"currentUserId":'591'}}):_vm._e(),(_vm.name == 'homework-calendar')?_c('HomeworkCalendar',{attrs:{"papers":[
      {
        id: 'test',
        name: 'Test',
        type: 'homework',
        isDynamic: false,
        startTime: '' + Date.now(),
        expiryTime: '' + Date.now()
      },
      {
        id: 'test2',
        name: 'Test2',
        type: 'recursive',
        isDynamic: false,
        startTime: '' + Date.now(),
        expiryTime: '' + Date.now()
      },
      {
        id: 'test3',
        name: 'Test3',
        type: 'recursive_followup',
        isDynamic: false,
        startTime: '' + Date.now(),
        expiryTime: '' + Date.now()
      }
    ]}}):_vm._e(),(_vm.name == 'homework-calendar-adapter')?_c('HomeworkCalendarAdpater',{attrs:{"currentUserId":'591'}}):_vm._e(),(_vm.name == 'homework-todo-row')?_c('HomeworkTodoRow',{attrs:{"numSubmssion":5,"numQuestion":10,"paper":{
      id: 'test',
      name: 'Test',
      type: 'homework',
      isDynamic: false,
      startTime: '' + Date.now(),
      expiryTime: '' + Date.now()
    }}}):_vm._e(),(_vm.name == 'homework-list')?_c('HomeworkList',{attrs:{"papers":[
      {
        id: 'test',
        name: 'Test',
        type: 'homework',
        isDynamic: false,
        startTime: '' + Date.now(),
        expiryTime: '' + Date.now()
      },
      {
        id: 'test2',
        name: 'Test2',
        type: 'homework',
        isDynamic: false,
        startTime: '' + Date.now(),
        expiryTime: '' + Date.now()
      }
    ]}}):_vm._e(),(_vm.name == 'homework-list-adapter')?_c('HomeworkListAdapter',{attrs:{"currentUserId":'684'}}):_vm._e(),(_vm.name == 'paper-question-nav')?_c('PaperQuestionNav',{attrs:{"status":['correct', 'wrong', '', '', '']},model:{value:(_vm.paperQuestionListNumber),callback:function ($$v) {_vm.paperQuestionListNumber=$$v},expression:"paperQuestionListNumber"}}):_vm._e(),(_vm.name == 'paper-question-list')?_c('PaperQuestionList',{attrs:{"status":['correct', 'wrong', '', '', '']},model:{value:(_vm.paperQuestionListNumber),callback:function ($$v) {_vm.paperQuestionListNumber=$$v},expression:"paperQuestionListNumber"}}):_vm._e(),(_vm.name == 'paper-question')?_c('PaperQuestion',{attrs:{"indexNo":4,"useranswer":{
      id: 'test',
      type: 'homework',
      question: {
        displayQuestion: {
          id: '22188',
          type: 'mc',
          sampleEdgeKey: '361b692d770811e3f49d0d1b8ecde4c4',
          qgroup: { id: '152', myUserEdge: null, __typename: 'Qgroup' }
        }
      },
      data: {
        permutation: [0, 2, 1, 3],
        displayAnswerIndexNo: 2
      }
    }},on:{"choose-ans":function($event){return _vm.$log('choose-ans', $event)}}}):_vm._e(),(_vm.name == 'paper-solution')?_c('PaperSolution',{attrs:{"solutionEdgeKey":"89a811bbc1a7823612363569a25b9ac8","useranswer":{
      id: 'test',
      type: 'homework',
      question: {
        displayQuestion: {
          id: '22188',
          type: 'mc',
          sampleEdgeKey: '361b692d770811e3f49d0d1b8ecde4c4',
          qgroup: { id: '152', myUserEdge: null, __typename: 'Qgroup' }
        }
      },
      data: {
        permutation: [0, 2, 1, 3]
      }
    }}}):_vm._e(),(_vm.name == 'test-image')?_c('img',{staticClass:"question-image",attrs:{"src":_vm.testImage}}):_vm._e(),(_vm.name == 'chart-performance')?_c('ChartPerformance',{attrs:{"paperEdges":[
      {
        paper: {
          name: 'Assignment 1',
          expiryTime: '' + Date.now(),
          cache: { stats: { usergroupAvgCorrectRate: 30 } }
        },
        cache: {
          userStudentStats: {
            correctRate: 40
          }
        }
      },
      {
        paper: {
          name: 'Assignment 2',
          expiryTime: '' + Date.now(),
          cache: { stats: { usergroupAvgCorrectRate: 40 } }
        },
        cache: {
          userStudentStats: {
            correctRate: 50
          }
        }
      },
      {
        paper: {
          name: 'Assignment 3',
          expiryTime: '' + Date.now(),
          cache: { stats: { usergroupAvgCorrectRate: 80 } }
        },
        cache: {
          userStudentStats: {
            correctRate: 70
          }
        }
      },
      {
        paper: {
          name: 'Assignment 1',
          expiryTime: '' + Date.now(),
          cache: { stats: { usergroupAvgCorrectRate: 30 } }
        },
        cache: {
          userStudentStats: {
            correctRate: 40
          }
        }
      },
      {
        paper: {
          name: 'Assignment 2',
          expiryTime: '' + Date.now(),
          cache: { stats: { usergroupAvgCorrectRate: 40 } }
        },
        cache: {
          userStudentStats: {
            correctRate: 50
          }
        }
      },
      {
        paper: {
          name: 'Assignment 3',
          expiryTime: '' + Date.now(),
          cache: { stats: { usergroupAvgCorrectRate: 80 } }
        },
        cache: {
          userStudentStats: {
            correctRate: 70
          }
        }
      }
    ]}}):_vm._e(),(_vm.name == 'chart-performance-adapter')?_c('ChartPerformanceAdapter',{attrs:{"currentUserId":'684'}}):_vm._e(),(_vm.name == 'topic-performance-adapter')?_c('TopicPerformanceAdapter',{attrs:{"currentUserId":'684',"usergroupId":'57'}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }