<template>
  <Modal>
    <template v-slot:header>
      <span class="title">
        {{ $t($interfaceLang, 'Create Follow-up Exercise', '新增跟進練習') }}
      </span>
      <i @click="$emit('close')" class="icon-font icon-close"> &#xe808; </i>
    </template>
    <template v-slot:body>
      <div class="modal-section">
        <p class="sm-title">
          {{
            $t(
              $interfaceLang,
              'Our AI has picked some questions for your class.',
              '我們的人工智能為你的班別挑選了一些題目。'
            )
          }}
        </p>
        <div class="section-row">
          <InputQuestionQty
            :descText="$t($interfaceLang, 'No. of questions', '問題數量')"
            :defaultInputIndex="0"
            :inputs="[5, 6, 7, 8, 9, 10]"
            @input="numQuestion = $event"
          />
          <button
            @click="$emit('create', { numQuestion })"
            class="modal-btn right-item"
          >
            <i class="icon-font icon-plus">&#xe817;</i>
            {{ $t($interfaceLang, 'Create', '新增') }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/teacher/modal/Modal.vue'
import InputQuestionQty from '@/components/InputQuestionQty.vue'
export default {
  components: {
    Modal,
    InputQuestionQty
  },
  props: {},
  data () {
    return {
      numQuestion: 5
    }
  }
}
</script>
<style scoped>
* >>> .modal-container {
  width: 370px;
}
</style>
<style src="@/components/teacher/modal/modal.css" scoped></style>
