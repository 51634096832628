<template>
  <div class="performance-card-col">
    <ChartPerformance :paperEdges="paperEdges" :isLoading="isLoading" />
  </div>
</template>
<script>
import ChartPerformance from '../../components/chart-performance/ChartPerformance.vue'
import LoadPerformanceQuery from './LoadPerformance.graphql'
export default {
  components: {
    ChartPerformance
  },
  props: {
    currentUserId: String
  },
  data () {
    return {
      paperEdges: [],
      isLoading: false
    }
  },
  computed: {},
  async created () {
    this.isLoading = true
    this.paperEdges = await this.loadPerformance()
    this.isLoading = false
  },
  methods: {
    async loadPerformance () {
      let variables = {
        currentUserId: this.currentUserId,
        expiryToTime: '' + Date.now()
      }
      return await this.$graphqlUtil.graphqlFetchEdges(
        this.$apolloQuery,
        LoadPerformanceQuery,
        variables,
        x => x.paperUserEdges,
        null,
        20
      )
    }
  }
}
</script>
<style src="" />
