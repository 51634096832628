<template>
  <div class="select-types">
    <div
      :class="{ selected: value == 'all' }"
      @click="$emit('input', 'all')"
      id="type-all"
      class="select-item"
    >
      {{ $t($interfaceLang, 'All', '全部') }}
    </div>
    <div
      :class="{ selected: value == 'past1' }"
      @click="$emit('input', 'past1')"
      id="type-past1"
      class="select-item"
    >
      {{ $t($interfaceLang, 'Past 1 month', '過去一個月') }}
    </div>
    <div
      :class="{ selected: value == 'past3' }"
      @click="$emit('input', 'past3')"
      id="type-past3"
      class="select-item "
    >
      {{ $t($interfaceLang, 'Past 3 month', '過去三個月') }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: String
  }
}
</script>
<style src="../../Performance.css" scoped></style>
<style scoped>
.no-data {
  color: darkgrey;
  font-size: 30px;
  font-weight: bold;
}

.nav-row {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.select-types {
  float: left;
}

.select-item {
  padding: 5px 20px;
  float: left;
  background-color: rgb(230, 230, 230);
  border-radius: 16px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #939393;
  position: relative;
  z-index: 50;
  cursor: pointer;
}

.select-item.selected {
  color: white;
  background-color: #007fca;
  z-index: 52;
}

#type-all {
  padding: 5px 40px;
}

#type-last {
  padding: 5px 40px;
  margin-left: -30px;
}

#type-past1 {
  padding: 5px 40px;
  margin-left: -30px;
}

#type-past3 {
  padding: 5px 40px;
  margin-left: -30px;
}
</style>
