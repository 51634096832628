<template>
  <div id="login">
    <div id="login-container">
      <img class="banner" src="@/assets/images/Login_page_banner.png" />
      <div id="firebaseui-auth-container" />
      <div style="color:white">{{ osInfo }}</div>
    </div>
  </div>
</template>
<script>
import firebase from 'firebase/compat/app'
import * as firebaseui from 'firebaseui'

export default {
  data () {
    return {
      major: 0,
      minor: 0
    }
  },
  computed: {
    osInfo () {
      return window.navigator.appVersion
    },
    isMobileSafari16plus () {
      const re1 = /iPhone|Safari/g
      const re2 = /(?<M>[0-9]*)\.(?<m>[0-9]*)\.(?<mm>[0-9]*) Mobile/g
      if (re1.test(this.osInfo)) {
        let execResult = re2.exec(this.osInfo)
        if (execResult != null) {
          let major = execResult.groups['M']
          let minor = execResult.groups['m']
          this.major = major
          this.minor = minor
          if (major > 16 || (major == 16 && minor >= 1)) {
            return true
          }
        }
      }
    },
    isSafari161plus () {
      const re = /(?<M>[0-9]*)\.(?<m>[0-9]*)\.(?<mm>[0-9]*) Safari/g
      let execResult = re.exec(this.osInfo)
      if (execResult != null) {
        let major = execResult.groups['M']
        let minor = execResult.groups['m']
        this.major = major
        this.minor = minor
        if (major > 16 || (major == 16 && minor >= 1)) {
          return true
        }
      }
    },
    signInFlow () {
      if (this.isSafari161plus || this.isMobileSafari16plus) {
        return 'popup'
      }
    }
  },
  mounted () {
    this.$nextTick(_ => {
      const ui = new firebaseui.auth.AuthUI(this.$firebaseApp.auth())
      const uiConfig = {
        signInOptions: [
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            scopes: ['profile', 'email', 'openid']
          },
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            scopes: ['profile', 'email', 'openid'],
            customParameters: {
              // Forces account selection even when one account
              // is available.
              prompt: 'select_account'
            }
          },
          {
            provider: 'microsoft.com',
            customParameters: {
              // Forces account selection even when one account
              // is available.
              prompt: 'select_account'
            }
          }
        ],
        signInSuccessUrl: './'
      }
      ui.start('#firebaseui-auth-container', uiConfig)
    })
  },
  methods: {},
  created () {}
}
</script>
<style scoped>
#login {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: #909090;
}

#login-container {
  position: relative;
  display: block;
  width: 800px;
  margin-bottom: 40px;
  background-color: #fff;
}

.banner {
  position: relative;
  display: block;
  width: 100%;
}

#firebaseui-auth-container {
  padding: 40px 0px;
}
</style>
