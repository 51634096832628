<template>
  <select
    class="select-option input-text select-schedule-repeat"
    @input="$emit('input', $event.target[$event.target.selectedIndex].value)"
  >
    <option value="one_week" :selected="value == 'one_week'">1</option>
    <option value="two_week" :selected="value == 'two_week'">2</option>
    <option value="four_week" :selected="value == 'four_week'">4</option>
  </select>
</template>

<script>
export default {
  props: {
    value: String
  }
}
</script>

<style scoped>
.select-option.input-text {
  width: 50px;
}
</style>
