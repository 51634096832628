<template>
  <div>
    <div class="filter-col">
      <TypeFilters v-model="typeFilter" />
      <div class="right-item">
        <AssignmentFilterDropdown v-model="filters" />
      </div>
      <div class="right-item">
        <SortByDropdown
          v-model="sortBy"
          @input="$emit('sort-by', { sortBy })"
        />
      </div>
    </div>

    <transition-group name="fade">
      <div
        v-for="(paper, i) in papers"
        :key="i + '-assignemnts'"
        class="assignments-col"
      >
        <PaperRow
          ref="paper-row"
          v-if="showPaper(paper, typeFilter, filters) && !paper.isDeleted"
          :readOnly="readOnly"
          :locked="isLocked(paper)"
          :paper="paper"
          :numQuestion="paper.numQuestion"
          @bulk-select="$emit('bulk-select', $event)"
          @isdynamic="$emit('isdynamic', $event)"
          @goto="$emit('goto', $event)"
          @edit="$emit('edit', $event)"
          @share="$emit('share', $event)"
          @delete="$emit('delete', $event)"
          @duplicate="$emit('duplicate', $event)"
        />
      </div>
    </transition-group>
    <InfiniteLoading
      ref="infinite-loading-paper"
      @infinite="$emit('infinite', $event)"
    >
      <div slot="spinner">
        <img class="loading-gif" src="@/assets/images/loading.gif" />
      </div>
      <div slot="no-more">
        {{ $t($interfaceLang, 'No more assignments.', '無其他作業。') }}
      </div>
      <div slot="no-results">
        {{ $t($interfaceLang, 'No more assignments.', '無其他作業。') }}
      </div>
    </InfiniteLoading>
  </div>
</template>
<script>
import PaperRow from './PaperRow.vue'
import TypeFilters from './TypeFilters.vue'
import AssignmentFilterDropdown from './AssignmentFilterDropdown.vue'
import SortByDropdown from './SortByDropdown.vue'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    PaperRow,
    InfiniteLoading,
    TypeFilters,
    AssignmentFilterDropdown,
    SortByDropdown
  },
  props: {
    papers: Array,
    readOnly: Boolean
  },
  data () {
    return {
      typeFilter: 'ANY',
      filters: {},
      sortBy: {}
    }
  },
  computed: {},
  methods: {
    showPaper (paper, typeFilter, filters) {
      return (
        (typeFilter == 'ANY' ||
          (typeFilter == 'HW' && paper.type == 'homework') ||
          (typeFilter == 'AUTO' &&
            (paper.type == 'recursive' ||
              paper.type == 'recursive_followup'))) &&
        filters[this.paperStatus(paper)] == true
      )
    },
    paperStatus (paper) {
      if (paper.startTime == null) {
        return 'draft'
      } else if (Date.now() < Number(paper.startTime)) {
        return 'notReleased'
      } else if (Date.now() < Number(paper.expiryTime)) {
        return 'ongoing'
      } else {
        return 'expired'
      }
    },
    isLocked (paper) {
      return paper.startTime != null && Number(paper.startTime) < Date.now()
    },
    reset () {
      this.$nextTick(this.$refs['infinite-loading-paper'].stateChanger.reset())
    },
    clearSelected () {
      this.$refs['paper-row'].forEach(x => {
        x.setSelected(false)
      })
    }
  }
}
</script>
<style src="@/pages/teacher/manage-assignment/ManageAssignment.css" scoped />
<style scoped>
::v-deep .fade-enter-active {
  transition: opacity 0.5s;
}
::v-deep .fade-leave-active {
  transition: opacity 0s;
}
::v-deep .fade-enter-to {
  opacity: 1;
}
::v-deep .fade-enter {
  opacity: 0;
}
::v-deep .fade-leave {
  /* .fade-leave-active below version 2.1.8 */
  display: none;
}
</style>
