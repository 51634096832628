<template>
  <ReportChartDoughnut
    @sendLegend="setDoughnutLegend"
    :chart-data="chartData"
    :options="options"
  />
</template>
<script>
import ReportChartDoughnut from '@/components/teacher/report/ReportChartDoughnut.vue'
export default {
  components: {
    ReportChartDoughnut
  },
  props: {
    numQuestion: Number,
    topics: Array
  },
  data () {
    return {
      colors: ['#a772ec', '#3ccc89', '#dadf37', '#2fb1c8', '#d0d0d0']
    }
  },
  computed: {
    chartData () {
      if (this.topics != null) {
        let labels = this.topics.map(x => x.name)
        let data = this.topics.map(x => x.count)
        let total = data.reduce((a, b) => a + b, 0)
        if (total < this.numQuestion) {
          labels.push(this.$t(this.$interfaceLang, 'Others', '其他'))
          data.push(this.numQuestion - total)
        }
        return {
          labels,
          datasets: [
            {
              backgroundColor: this.colors,
              data
            }
          ]
        }
      }
    },
    options () {
      return {
        center: '' + this.numQuestion,
        cutoutPercentage: 62,
        tooltips: { enabled: false },
        responsive: true,
        maintainAspectRatio: true,
        legend: {
          display: false
        },
        legendCallback: ({ data }) => {
          console.log('legendCallback', data)
          if (data != null) {
            var legendHtml = []
            legendHtml.push('<ul class="chart-doughnut-legend-list">')
            var item = data.datasets[0]
            for (var i = 0; i < data.datasets[0].data.length; i++) {
              legendHtml.push('<li>')
              legendHtml.push(
                '<span class="chart-doughnut-legend" style="background-color:' +
                  item.backgroundColor[i] +
                  '"></span>'
              )
              legendHtml.push(
                '<span class="chart-doughnut-legend-label-text">' +
                  data.labels[i] +
                  ` (${data.datasets[0].data[i]})` +
                  '</span>'
              )
              legendHtml.push('</li>')
            }
            legendHtml.push('</ul>')
            return legendHtml.join('')
          }
        }
      }
    }
  },
  methods: {
    setDoughnutLegend (html) {
      this.$emit('set-legend', html)
    }
  }
}
</script>
<style src="" />
