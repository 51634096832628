import { render, staticRenderFns } from "./PaperSolution.vue?vue&type=template&id=b66f4804&scoped=true&"
import script from "./PaperSolution.vue?vue&type=script&lang=js&"
export * from "./PaperSolution.vue?vue&type=script&lang=js&"
import style0 from "./PaperSolution.vue?vue&type=style&index=0&id=b66f4804&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b66f4804",
  null
  
)

export default component.exports