<template>
  <div class="toggle-btn">
    <button
      @click="$emit('input', !value)"
      class="toggle-filter"
      :class="{ active: value }"
    />
    <span class="toggle-text">
      {{ title }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    value: Boolean
  }
}
</script>
<style scoped>
.toggle-btn {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.toggle-filter {
  height: 12px;
  width: 24px;
  border-radius: 12px;
  border-style: none;
  background-color: #f1f3f1;
  margin-right: 5px;
}

.toggle-filter:focus,
.toggle-filter:hover,
.toggle-filter:active {
  box-shadow: none;
  outline: none;
}

.toggle-filter::before {
  content: '';
  position: absolute;
  top: 1px;
  left: auto;
  width: 16px;
  height: 16px;
  background-color: #adadad;
  border-radius: 50%;
}

.active.toggle-filter::before {
  left: 0px;
  background-color: #007fca !important;
}

.toggle-text {
  font-size: 13px;
  font-weight: 600;
  color: #1c2d60;
  padding-left: 5px;
}

</style>
