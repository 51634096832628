var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"editAssignmentInfo-modal"}},[(_vm.hasStarted(_vm.unsavedStartTime))?_c('Modal',{on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('i',{staticClass:"icon-title icon-font icon-edit"},[_vm._v("")]),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Edit Assignment Info', '編輯作業資訊'))+" ")]),_c('i',{staticClass:"icon-font icon-close",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("  ")])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"modal-section"},[_c('p',{staticClass:"sm-title"},[_vm._v(_vm._s(_vm.$t(_vm.$interfaceLang, 'Title', '標題')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputName),expression:"inputName"}],staticClass:"input-text",attrs:{"type":"text","placeholder":_vm.$t(_vm.$interfaceLang, '(Default Title)', '(預設題目)')},domProps:{"value":(_vm.inputName)},on:{"input":function($event){if($event.target.composing)return;_vm.inputName=$event.target.value}}})]),_c('div',{staticClass:"modal-section"},[_c('div',{staticClass:"date-container"},[_c('div',{staticClass:"date-container-mask",class:{ hide: _vm.scheduleActive }}),_c('ExtendDatePickerRow',{attrs:{"startTime":_vm.startTime,"expiryTime":_vm.unsavedExpiryTime},model:{value:(_vm.inputExpiryDate),callback:function ($$v) {_vm.inputExpiryDate=$$v},expression:"inputExpiryDate"}}),_c('button',{staticClass:"modal-btn",attrs:{"id":"btn-save"},on:{"click":function($event){_vm.$emit('extend', {
                inputName: _vm.inputName,
                inputExpiryDate: _vm.getResultExpiryDate(_vm.inputExpiryDate)
              })}}},[_c('i',{staticClass:"icon-font icon-save"},[_vm._v("")]),_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Save', '儲存'))+" ")])],1)])]},proxy:true}],null,false,793014311)}):_c('Modal',{on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('i',{staticClass:"icon-title icon-font icon-edit"},[_vm._v("")]),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Edit Assignment Info', '編輯作業資訊'))+" ")]),_c('i',{staticClass:"icon-font icon-close",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("  ")])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"modal-section"},[_c('p',{staticClass:"sm-title"},[_vm._v(_vm._s(_vm.$t(_vm.$interfaceLang, 'Title', '標題')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputName),expression:"inputName"}],staticClass:"input-text",attrs:{"type":"text","placeholder":_vm.$t(_vm.$interfaceLang, '(Default Title)', '(預設標題)')},domProps:{"value":(_vm.inputName)},on:{"input":function($event){if($event.target.composing)return;_vm.inputName=$event.target.value}}})]),(_vm.numQuestion ?? 0 != 0)?_c('div',{staticClass:"modal-section"},[_c('ToggleButton',{model:{value:(_vm.scheduleActive),callback:function ($$v) {_vm.scheduleActive=$$v},expression:"scheduleActive"}}),_c('div',{staticClass:"date-container"},[_c('div',{staticClass:"date-container-mask",class:{ hide: _vm.scheduleActive }}),_c('DatePickerRow',{model:{value:(_vm.inputDateRanges),callback:function ($$v) {_vm.inputDateRanges=$$v},expression:"inputDateRanges"}}),_c('button',{staticClass:"modal-btn",attrs:{"id":"btn-save"},on:{"click":function($event){_vm.$emit('save', {
                inputName: _vm.inputName,
                inputDateRanges: _vm.getResultDateRanges(_vm.inputDateRanges),
                scheduleActive: _vm.scheduleActive
              })}}},[_c('i',{staticClass:"icon-font icon-save"},[_vm._v("")]),_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Save', '儲存'))+" ")])],1)],1):_c('div',{staticClass:"modal-section"},[_c('div',{staticClass:"remark"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, _vm.noQuestionWarningEn, _vm.noQuestionWarningZh))+" ")]),_c('button',{staticClass:"modal-btn",attrs:{"id":"btn-save"},on:{"click":function($event){return _vm.$emit('save', {
              inputName: _vm.inputName,
              inputDateRanges: { start: null, end: null }
            })}}},[_c('i',{staticClass:"icon-font icon-save"},[_vm._v("")]),_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Save', '儲存'))+" ")])])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }