<template>
  <div>
    <ReportAssignmentInfoAdapter
      v-if="name == 'info-adapter'"
      :currentUserId="'672'"
      :paperId="'127'"
      :usergroupId="'57'"
      :numTopic="3"
    />
    <ReportAssignmentQuestionsAdapter
      v-if="name == 'questions-adapter'"
      :currentUserId="'672'"
      :paperId="'127'"
      :batchSize="10"
    />
    <ReportAssignmentTopicMasteryAdapter
      v-if="name == 'topic-mastery-adapter'"
      :currentUserId="'672'"
      :paperId="'127'"
      :batchSize="10"
    />
    <ReportAssignmentStudentsAdapter
      v-if="name == 'students-adapter'"
      :currentUserId="'672'"
      :paperId="'26'"
      :usergroupId="'50'"
      :first="25"
    />
    <ReportAssignmentStudentsAdapter
      v-if="name == 'students-adapter'"
      :currentUserId="'672'"
      :paperId="'26'"
      :usergroupId="'50'"
      :first="25"
    />
  </div>
</template>
<script>
import ReportAssignmentInfo from '@/pages/teacher/report-assignment/components/report-assignment-info/Info.vue'
import ReportAssignmentInfoAdapter from '@/pages/teacher/report-assignment/components/report-assignment-info/InfoAdapter.vue'
import ReportAssignmentQuestions from '@/pages/teacher/report-assignment/components/report-assignment-questions/Questions.vue'
import ReportAssignmentQuestionsAdapter from '@/pages/teacher/report-assignment/components/report-assignment-questions/QuestionsAdapter.vue'
import ReportAssignmentTopicMastery from '@/pages/teacher/report-assignment/components/report-assignment-topic-mastery/TopicMastery.vue'
import ReportAssignmentTopicMasteryAdapter from '@/pages/teacher/report-assignment/components/report-assignment-topic-mastery/TopicMasteryAdapter.vue'
import ReportAssignmentStudents from '@/pages/teacher/report-assignment/components/report-assignment-students/Students.vue'
import ReportAssignmentStudentsAdapter from '@/pages/teacher/report-assignment/components/report-assignment-students/StudentsAdapter.vue'
import ReportAssignmentWostQuestions from '@/pages/teacher/report-assignment/components/report-assignment-worst-questions/WorstQuestions.vue'
import ReportAssignmentDetailQuestionTable from '@/pages/teacher/report-assignment-details/components/question-table/QuestionTable.vue'
export default {
  components: {
    ReportAssignmentInfo,
    ReportAssignmentInfoAdapter,
    ReportAssignmentQuestions,
    ReportAssignmentQuestionsAdapter,
    ReportAssignmentTopicMastery,
    ReportAssignmentTopicMasteryAdapter,
    ReportAssignmentStudents,
    ReportAssignmentStudentsAdapter,
    ReportAssignmentWostQuestions,
    ReportAssignmentDetailQuestionTable
  },
  props: {},
  data () {
    return {
      name: this.$route.query?.name
    }
  },
  computed: {},
  methods: {}
}
</script>
<style src="" />
