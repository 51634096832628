const log = require('../log.js')
const { LRUMap } = require('lru_map')
const cachedTypes = ['Paper', 'Qgroup', 'Topic']

function cache(objects, item) {
  if (typeof item === 'object' && item != null) {
    let keys = Object.keys(item)
    keys.forEach(k => {
      item[k] = cache(objects, item[k])
    })
    if (cachedTypes.includes(item.__typename) && item.id != null) {
      var itemKey = item.__typename + ':' + item.id
      if (objects.get(itemKey) != null) {
        Object.assign(objects.get(itemKey), item)
      } else {
        objects.set(itemKey, item)
      }
      let cachedItem = objects.get(itemKey)
      if (process.env.NODE_ENV !== 'production') {
        console.log('apolloCache', itemKey, cachedItem)
      }
      return cachedItem
    }
  }
  return item
}

module.exports = {
  objects: new LRUMap(1024),
  cache(object) {
    return cache(this.objects, object)
  },
  get(typename, id) {
    return this.objects.get(typename + ':' + id)
  }
}