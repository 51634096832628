<template>
  <div class="question-content" v-if="questionData != null">
    <div
      v-if="questionData.contentImageData == null"
      v-katex
      class="question-title katex"
      v-html="questionData.content"
    />
    <div v-else v-katex class="question-title katex">
      <p v-html="questionData.content" />
      <img class="question-image" :src="questionData.contentImageData" />
    </div>
    <ul class="question-ans-list" v-if="answers != null">
      <li
        v-for="(a, i) in answers"
        :key="'ans-' + i"
        :class="{
          'correct-ans': isCorrectAnswerAt(i),
          'wrong-ans': !isCorrectAnswerAt(i)
        }"
      >
        <i class="icon-font icon-profile" v-if="isShowUserMarkerAt(i)">
          &#xe818;
        </i>
        <span v-if="isShuffled" class="mr-2">
          {{ displayAnswerAlphabet(i) }}.
        </span>
        <span v-katex v-else class="mr-2">
          {{ displayAnswerVX(i) }}
        </span>
        <img v-if="questionData.isAnswerImage" class="ans-image" :src="a" />
        <span v-else v-katex class="katex"> {{ a }} </span>
      </li>
    </ul>
  </div>
</template>
<script>
import VKatex from 'vue-katex-auto-render'
export default {
  directives: {
    katex: VKatex
  },
  components: {},
  props: {
    isShuffled: Boolean,
    questionData: Object,
    userMarkerIndex: Number,
    permutation: Array,
    correctAnswerIndex: Number,
    mcAnswerPermutation: Array
  },
  data () {
    return {}
  },
  computed: {
    answers () {
      if (this.isShuffled) {
        if (this.permutation != null) {
          return this.permute(this.questionData.answers, this.permutation)
        }
      } else {
        if (this.permutation != null && this.mcAnswerPermutation != null) {
          return this.ipermute(
            this.permute(this.questionData.answers, this.permutation),
            this.mcAnswerPermutation
          )
        }
      }
    }
  },
  methods: {
    isShowUserMarkerAt (i) {
      if (this.isShuffled) {
        return i == this.userMarkerIndex
      } else if (this.mcAnswerPermutation != null) {
        return i == this.mcAnswerPermutation[this.userMarkerIndex]
      } else {
        return false
      }
    },
    isCorrectAnswerAt (i) {
      if (this.isShuffled) {
        return i == this.correctAnswerIndex
      } else {
        return i == 0
      }
    },
    displayAnswerAlphabet (i) {
      var alphabet = ['A', 'B', 'C', 'D']
      return alphabet[i]
    },
    displayAnswerVX (i) {
      var VX = [
        '$\\checkmark$',
        '$\\char"2717_1$',
        '$\\char"2717_2$',
        '$\\char"2717_3$'
      ]
      return VX[i]
    },
    permute (x, permutation) {
      if (
        permutation != null &&
        permutation.length > 0 &&
        x.length == permutation.length &&
        x.length == [...new Set(permutation)].length &&
        Math.max(...permutation) < x.length &&
        Math.min(...permutation) >= 0
      ) {
        return permutation.map(p => x[p])
      }
    },
    ipermute (x, permutation) {
      if (
        permutation != null &&
        permutation.length > 0 &&
        x.length == permutation.length &&
        x.length == [...new Set(permutation)].length &&
        Math.max(...permutation) < x.length &&
        Math.min(...permutation) >= 0
      ) {
        let z = new Array(x.length).fill(0)
        x.forEach((y, i) => {
          z[permutation[i]] = y
        })
        return z
      }
    }
  }
}
</script>
<style scoped>
.question-title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
}

.question-ans-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.question-ans-list li {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  border: 1px solid black;
  border-radius: 20px;
  float: left;
}

.correct-ans {
  color: green;
}

.wrong-ans {
  color: red;
}

.question-image {
  position: relative;
  max-width: 350px;
  width: 100%;
  margin: 0px auto;
  display: block;
  padding: 10px 0px;
}

.ans-image {
  position: relative;
  width: 90%;
  max-width: 250px;
}
</style>
