<template>
  <ModalFollowup @create="createFollowup($event)" @close="$emit('close')" />
</template>
<script>
import LoadQuestionsQuery from './LoadQuestions.graphql'
import TemplateMutationQuery from './TemplateMutation.graphql'
import CreatePaperQuery from './CreatePaper.graphql'
import ModalFollowup from '../../components/modal-followup/ModalFollowup.vue'
export default {
  components: {
    ModalFollowup
  },
  props: {
    currentUserId: String,
    usergroupId: String,
    paper: Object
  },
  data () {
    return {
      batchSize: 20,
      lang: this.$interfaceLang
    }
  },
  computed: {},
  methods: {
    async createFollowup ({ numQuestion }) {
      let edges = await this.fetchQuestionEdges()
      let questions = this.parseQuestionFromEdges(edges)
        .filter(x => x.correctRate != null)
        .sort((a, b) =>
          a.correctRate == b.correctRate
            ? Math.random() - 0.5
            : a.correctRate - b.correctRate
        )
        .slice(0, numQuestion)
      let questionIds = []
      for (var i = 0; i < questions.length; ++i) {
        questionIds.push(
          await this.templateMutation(questions[i].id, questionIds)
        )
      }
      let paper = await this.createPaper(questionIds)
      this.$router.push({
        path: '/teacher/editassignment',
        query: { id: paper.id }
      })
    },
    parseQuestionFromEdges (edges) {
      return edges.map(e => ({
        id: e.question?.id,
        correctRate: e.cache?.paperStats?.usergroupAvgCorrectRate,
        timeSpent: e.cache?.paperStats?.usergroupAvgTimeSpent
      }))
    },
    async fetchQuestionEdges () {
      let variables = {
        currentUserId: this.currentUserId,
        paperId: this.paper.id,
        first: this.batchSize,
        lang: this.lang
      }
      return await this.$graphqlUtil.graphqlFetchEdges(
        this.$apolloQuery,
        LoadQuestionsQuery,
        variables,
        x => x.paperQuestionEdges
      )
    },
    async templateMutation (questionId, exceptQuestionIds) {
      return await this.$apolloQuery(TemplateMutationQuery, {
        currentUserId: this.currentUserId,
        lang: this.lang,
        id: questionId,
        exceptQuestionIds
      }).then(res => res.data.question.templateMutation.displayQuestion.id)
    },
    async createPaper (questionIds) {
      console.log(questionIds)
      return this.$apolloMutation(CreatePaperQuery, {
        currentUserId: this.currentUserId,
        usergroupId: this.usergroupId,
        paperType: 'homework',
        paperName: this.$t(this.$interfaceLang, 'Follow-up', '跟進練習'),
        questionIds
      }).then(res => res.data.createMyPaperWithQuestions.paper)
    }
  }
}
</script>
<style src="" />
