var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal-section"},[_c('div',{staticClass:"select-topic-col"},[_c('p',{staticClass:"sm-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Topics', '題目'))+" ")]),_c('div',{staticClass:"topic-list-col"},[_c('p',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('topics'),expression:"'topics'"}],staticClass:"topics-collapse-btn"},[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t(
                _vm.$interfaceLang,
                'Auto selected by teacher schedule',
                '自動參考教學日程'
              )
            )}})])]),_c('div',{staticClass:"topic-setting-col"},[_c('div',{staticClass:"no-of-question-input"},[_c('InputQuestionQty',{attrs:{"inputs":[5, 6, 7, 8, 9, 10, 15, 20, 30, 40],"defaultInputIndex":5,"descText":_vm.$t(_vm.$interfaceLang, 'No of Question', '題目數量'),"advancedText":''},model:{value:(_vm.numQuestion),callback:function ($$v) {_vm.numQuestion=$$v},expression:"numQuestion"}})],1),_c('div',{staticClass:"diff-input"},[_c('span',{staticClass:"topic-setting-text"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Difficulty', '難度'))+" ")]),_c('DifficultyInput',{model:{value:(_vm.difficulty),callback:function ($$v) {_vm.difficulty=$$v},expression:"difficulty"}})],1)])])]),_c('div',{staticClass:"modal-section section-row align-right"},[_c('button',{staticClass:"modal-btn modal-btn-white",attrs:{"id":"btn-back"},on:{"click":function($event){return _vm.$emit('prev')}}},[_c('i',{staticClass:"icon-font icon-arrow_left"},[_vm._v("  ")]),_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Back', '上一步'))+" ")]),_c('button',{staticClass:"btn-preview modal-btn2 right-item",attrs:{"id":"btn-preview"},on:{"click":function($event){_vm.$emit('next', _vm.getData())}}},[_c('i',{staticClass:"icon-font icon-inspect"},[_vm._v("")]),_vm._v(" "+_vm._s(_vm.$t(_vm.$interfaceLang, 'Create', '創建'))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }