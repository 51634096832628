<template>
  <div>
    <AssignmentRow
      v-for="paper in papers"
      v-if="!isShowDueOnly || hasExpired(paper.expiryTime)"
      @goto="$emit('goto', paper)"
      @followup="$emit('followup', $event)"
      :paper="paper"
      :numStudent="numStudent"
    />
    <InfiniteLoading @infinite="atInfinity($event)">
      <div slot="spinner">
        <img class="loading-gif" src="@/assets/images/loading.gif" />
      </div>
      <div slot="no-more">
        {{ $t($interfaceLang, 'No more assignments.', '無其他習作。') }}
      </div>
      <div slot="no-results">
        {{ $t($interfaceLang, 'No more assignments.', '無其他習作。') }}
      </div>
    </InfiniteLoading>
  </div>
</template>
<script>
import AssignmentRow from './AssignmentRow.vue'
import InfiniteLoading from 'vue-infinite-loading'
export default {
  components: {
    AssignmentRow,
    InfiniteLoading
  },
  props: {
    papers: Array,
    numStudent: Number,
    isShowDueOnly: Boolean
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    atInfinity (state) {
      setTimeout(
        (async _ => {
          this.$emit('infinite', state)
        }).bind(this),
        200
      )
    },
    hasExpired (expiryTime) {
      return expiryTime != null && Number(expiryTime) < Date.now()
    }
  }
}
</script>
<style scoped>
.loading-gif {
  width: 50px;
  height: 50px;
}
</style>
