<template>
  <div class="progress">
    <div
      class="progress-bar"
      role="progressbar"
      :style="{ width: (rate ?? 0) + '%' }"
    /></div
></template>
<script>
export default {
  props: {
    rate: Number
  }
}
</script>
<style src="" />
